import {Tag} from 'platform/components';

import {useSelector} from 'react-redux';

import {isNil, slice} from 'ramda';

import {useSourcingGetSourcingServersQuery} from '@dms/api/sourcing';
import {selectTenant} from '@dms/api/tenant';
import {i18n} from '@dms/i18n';
import {filterLimits} from '@dms/teas';

import {suffixTestId, TestIdProps, useToggle} from 'shared';

import {useMakeModelFilter} from '../../../hooks/useMakeModelFilter';
import {useFilters} from '../../FiltersContext/FiltersContext';
import {useGetBooleanBasedTags} from '../hooks/useGetBooleanBasedTags';
import {useGetNumberBasedTags} from '../hooks/useGetNumberBasedTags';
import {useGetStringBasedTags} from '../hooks/useGetStringBasedTags';
import {FilterTagButtons} from './FilterTagButtons';

const NUMBER_OF_VISIBLE_TAGS = 8;

export function FilterTags(props: TestIdProps) {
  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const {filters, onUpdateFilters} = useFilters();
  const {
    selectedMakes,
    selectedModelFamilies,
    selectedModels,
    getMake,
    getModel,
    getModelFamily,
    unselectMake,
    unselectModel,
    unselectModelFamily,
  } = useMakeModelFilter();
  const {data: {country: countryCode} = {}} = useSelector(selectTenant);
  const {data: sellerServers} = useSourcingGetSourcingServersQuery(
    {countryCode: countryCode!},
    {skip: isNil(countryCode)}
  );

  const getStringBasedTags = useGetStringBasedTags();
  const getNumberBasedTags = useGetNumberBasedTags();
  const getBooleanBasedTags = useGetBooleanBasedTags();

  const sellerServerTags =
    filters.sellerServer?.map((sellerServer) => ({
      label: sellerServers?.find(({code}) => code === sellerServer)?.name,
      onDelete: () =>
        onUpdateFilters(
          ['sellerServer'],
          filters.sellerServer?.filter((item) => sellerServer !== item)
        ),
    })) ?? [];

  const makeTags = selectedMakes.map((item) => ({
    label: getMake(item)?.label,
    onDelete: () => unselectMake(item),
  }));

  const modelFamilyTags = selectedModelFamilies.map((item) => ({
    label: getModelFamily(item)?.label,
    onDelete: () => unselectModelFamily(item),
  }));

  const modelTags = selectedModels.map((item) => ({
    label: getModel(item)?.label,
    onDelete: () => unselectModel(item),
  }));

  const colorTags =
    filters.selectedColors?.map((color) => ({
      label: color.name,
      onDelete: () =>
        onUpdateFilters(
          ['selectedColors'],
          filters.selectedColors?.filter((item) => color.key !== item.key)
        ),
    })) ?? [];

  const tags = [
    ...getStringBasedTags('sellerCountry', 'country'),
    ...sellerServerTags,
    ...getStringBasedTags('sellerType', 'seller_type'),
    ...getNumberBasedTags(
      'avgNumberAds',
      i18n.t('entity.warehouse.labels.averageCarsInStock'),
      filterLimits.AVG_NUMBER_ADS_MAX
    ),
    ...getNumberBasedTags('priceCZK', i18n.t('general.labels.price'), filterLimits.PRICE_MAX),
    ...getNumberBasedTags(
      'margin',
      i18n.t('entity.vehicle.labels.margin'),
      filterLimits.MARGIN_LIMIT_MAX
    ),
    ...getNumberBasedTags(
      'daysOnDisplay',
      i18n.t('entity.vehicle.labels.daysOnDisplay'),
      filterLimits.DAYS_ON_DISPLAY
    ),
    ...getNumberBasedTags(
      'liquidity',
      i18n.t('entity.vehicle.labels.liquidity'),
      filterLimits.LIQUIDITY_MAX
    ),
    ...getBooleanBasedTags('reclaimableVat', i18n.t('general.labels.reclaimableVAT')),
    ...getBooleanBasedTags(
      'carvagoAvailability',
      i18n.t('entity.sourcing.filters.labels.availableOnCarvago')
    ),
    ...modelTags,
    ...modelFamilyTags,
    ...makeTags,
    ...getStringBasedTags('fuelType', 'fuel_type'),
    ...getStringBasedTags('transmission', 'transmission'),
    ...getStringBasedTags('bodyType', 'car_style'),
    ...getStringBasedTags('drive', 'drive'),
    ...getNumberBasedTags(
      'mileageRange',
      i18n.t('entity.vehicle.labels.mileageRange'),
      filterLimits.MILEAGE_RANGE_MAX
    ),
    ...getNumberBasedTags('year', i18n.t('entity.vehicle.labels.year')),
    ...getNumberBasedTags(
      'power',
      i18n.t('entity.vehicle.labels.power'),
      filterLimits.POWER_LIMIT_MAX
    ),
    ...getNumberBasedTags(
      'cubicCapacity',
      i18n.t('entity.vehicle.labels.cubicCapacity'),
      filterLimits.CUBIC_CAPACITY_LIMITS[1]
    ),
    ...getNumberBasedTags(
      'numberOfSeats',
      i18n.t('entity.vehicle.labels.numberOfSeats'),
      filterLimits.NUMBER_OF_SEATS_MAX
    ),
    ...getNumberBasedTags(
      'featureScore',
      i18n.t('entity.vehicle.labels.rankEquipment'),
      filterLimits.FEATURE_SCORE_MAX,
      '%'
    ),
    ...colorTags,
    ...getStringBasedTags('features', 'feature'),
  ];

  const mappedTags = tags.map(({label, onDelete}) => (
    <Tag
      key={label}
      onDelete={onDelete}
      label={label ?? ''}
      colorScheme="blue"
      data-testid={suffixTestId(`tag-${label?.replace(' ', '_')}`, props)}
    />
  ));

  return (
    <>
      {tags.length <= NUMBER_OF_VISIBLE_TAGS || isExpanded
        ? mappedTags
        : slice(0, NUMBER_OF_VISIBLE_TAGS, mappedTags)}
      <FilterTagButtons
        tagCount={tags.length}
        collapsedTagCount={tags.length - NUMBER_OF_VISIBLE_TAGS}
        isExpanded={isExpanded}
        toggleIsExpanded={toggleIsExpanded}
        data-testid={suffixTestId('filterTagButtons', props)}
      />
    </>
  );
}
