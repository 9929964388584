import {useFormatCurrency, useFormatNumber} from 'platform/locale';

import {TRANSLATED_LANGUAGES} from '@dms/api/shared';
import {CURRENCY_CZK} from '@dms/shared';
import {useSourcingCurrency} from '@dms/teas';

import {getSourcingTableColumns} from '../utils/getSourcingTableColumns';

export function useSourcingTableColumns() {
  const currency = useSourcingCurrency();

  const formatNumber = useFormatNumber();
  const formatCurrency = useFormatCurrency();

  return getSourcingTableColumns({
    currency: currency?.code ?? CURRENCY_CZK,
    formatNumber,
    formatCurrency,
    locale: TRANSLATED_LANGUAGES.czech,
  });
}
