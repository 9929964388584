import {Button, ButtonGroup, Dialog, Label, showNotification} from 'platform/components';
import {Box, HStack, Space} from 'platform/foundation';

import {ReactElement, use, useMemo, useState} from 'react';

import {isNilOrEmpty} from 'ramda-adjunct';

import {AddressRequestBodyV2, AddressResponseBodyV2} from '@dms/api/customer';
import i18n from '@dms/i18n';
import {useAddress} from '@dms/shared';
import {FormContext, useFormRenderer, FieldName, Address, PossibleObject} from '@dms/teas';

import {Nullish, TestIdProps} from 'shared';

import {AddressForm, AddressFormProps} from './AddressForm';

type AddressSelectProps<T extends PossibleObject> = {
  name: FieldName<T, undefined>;
  label: string | Nullish;
  address?: AddressResponseBodyV2[];
  initialValue?: AddressResponseBodyV2 | null;
  useInitialValueAsOption?: boolean;
  handleAddressSubmit: (
    addressData: AddressRequestBodyV2,
    addressId?: string | null
  ) => Promise<string | null>;
} & TestIdProps;

export const AddressSelect = <T extends PossibleObject>({
  label,
  address,
  initialValue,
  handleAddressSubmit,
  ...props
}: AddressSelectProps<T>): ReactElement => {
  const {formId} = use(FormContext);
  const [tempAddressForOption, setTempAddressForOption] = useState<AddressRequestBodyV2[]>([]);

  // eslint-disable-next-line no-restricted-syntax
  const _name = props.name as unknown as string | string[];
  const name = typeof _name === 'string' ? _name : _name.join('.');

  const testId = props['data-testid'] ?? [formId, name.replace('.', '-')].join('-');

  const [addressDialog, setAddressDialog] = useState<{open: boolean; id: string | null}>({
    open: false,
    id: null,
  });

  const {composeAddress} = useAddress();

  const closeAddressDialog = () => setAddressDialog({open: false, id: null});

  const {form, Field, Subscribe} = useFormRenderer();

  const handleSubmitAddress = async (
    addressData: AddressRequestBodyV2
  ): Promise<Record<string, string> | void> => {
    const addressId = await handleAddressSubmit(addressData, addressDialog.id);
    if (!addressId) {
      showNotification.error();
      return;
    }
    if (props.useInitialValueAsOption) {
      setTempAddressForOption((prevState) => [...prevState, {...addressData, id: addressId}]);
    }
    form.change(name, addressId);
    closeAddressDialog();
  };

  const options = [
    ...(props.useInitialValueAsOption && initialValue ? [initialValue] : []),
    ...(tempAddressForOption.length > 0 ? tempAddressForOption : []),
    ...(address ? [address] : []),
  ];

  const WrapperComponent = useMemo<AddressFormProps['WrapperComponent']>(
    () =>
      ({children, handleSubmit}) => (
        <>
          {children}
          <Space vertical={4} />
          <ButtonGroup align="right" data-testid={`${testId}-form`}>
            <Button
              variant="secondary"
              onClick={closeAddressDialog}
              data-testid={`${testId}-form-discard`}
              title={i18n.t('general.actions.discard')}
            />
            <Button
              variant="primary"
              onClick={handleSubmit}
              data-testid={`${testId}-form-save`}
              title={i18n.t('general.actions.save')}
            />
          </ButtonGroup>
        </>
      ),
    [testId]
  );

  return (
    <HStack spacing={3}>
      <Box flex={1}>
        <Field<string, Address>
          name={name}
          as="select"
          options={options}
          placeholder={
            initialValue && !props.useInitialValueAsOption
              ? `${composeAddress(initialValue?.address)}`
              : undefined
          }
          getOptionLabel={(address) => `${composeAddress(address?.address)}`}
          getOptionValue={(address) => address.id}
          label={label}
          data-id="select-address"
          selectProps={{
            creatableOption: true,
            handleCreate: () =>
              setAddressDialog({
                open: true,
                id: null,
              }),
          }}
        />
      </Box>
      <Subscribe
        name={name}
        component={({input: {value}}) => (
          <div>
            <Label>&nbsp;</Label>
            {value && address?.find((addr) => addr.id === value) && (
              <Button
                variant="ghostLink"
                leftIcon="image/edit"
                onClick={() =>
                  setAddressDialog({
                    open: true,
                    id: value || null,
                  })
                }
                data-testid={`${testId}-edit`}
                title={i18n.t('general.actions.edit')}
              />
            )}
          </div>
        )}
      />
      <Dialog
        data-testid={`${testId}-form`}
        isOpen={addressDialog.open}
        onClose={closeAddressDialog}
        scrollBehavior="outside"
        title={
          isNilOrEmpty(addressDialog.id)
            ? i18n.t('entity.customer.actions.newAddress')
            : i18n.t('entity.customer.labels.address')
        }
      >
        <AddressForm
          data-testid={`${testId}-form`}
          initialValues={address?.find((address) => address.id === addressDialog.id)}
          onSubmit={handleSubmitAddress}
          WrapperComponent={WrapperComponent}
        />
      </Dialog>
    </HStack>
  );
};
