import type {i18n} from 'i18next';
import {z} from 'zod';

import {MoneySchema} from '@dms/api/shared';

export const CoercedMoneySchema = MoneySchema.extend({
  amount: z.coerce.string().nullable(),
  currency: z.string(),
});

export type CoercedMoney = z.infer<typeof CoercedMoneySchema>;

export const VatDirectionSchema = z.enum(['FROM_PRICE_WITH_VAT', 'FROM_PRICE_WITHOUT_VAT']);

export type VatDirection = z.infer<typeof VatDirectionSchema>;

export const VatCodePriceSchema = z.object({
  withVat: CoercedMoneySchema,
  withoutVat: CoercedMoneySchema,
  vatDirection: VatDirectionSchema,
  vatRate: z.string(),
  vatType: z.string(),
});

const BaseFormSchema = z.object({
  name: z.string().nonempty(),
  category: z.tuple([z.string()]).nullable().optional(),
  note: z.string().nonempty().nullable(),
  fileId: z.string().uuid().nullable(),
});

export const VehicleTransactionFormSchema = z
  .object({
    expectedPrice: VatCodePriceSchema.nullable().transform((val) => {
      if (!val?.withVat?.amount || !val?.withoutVat.amount) {
        return null;
      }
      return val;
    }),
    realPrice: VatCodePriceSchema.nullable().transform((val) => {
      if (!val?.withVat?.amount || !val?.withoutVat.amount) {
        return null;
      }
      return val;
    }),
  })
  .merge(BaseFormSchema);

export type VehicleTransactionFormSchemaType = z.infer<typeof VehicleTransactionFormSchema>;

export const getVehicleTransactionFormSchema = (i18nInstance: i18n) =>
  VehicleTransactionFormSchema.superRefine((data, ctx) => {
    if (data.expectedPrice === null && data.realPrice === null) {
      ['expectedPrice.withoutVat.amount', 'realPrice.withoutVat.amount'].forEach((path) => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18nInstance.t('general.validations.oneOfThoseFieldsIsRequired'),
          path: [path],
        });
      });
    }
  });
