import {z} from 'zod';

import {SalesActivityManualTypeEnumSchema} from '@dms/api/sales';

export const LogContactDialogSchema = z.object({
  subject: z.string().min(1),
  note: z.string().min(1).nullable(),
  date: z.date(),
  time: z.string().nullable().optional(),
  type: z.array(SalesActivityManualTypeEnumSchema),
});

export type LogContactDialogSchemaType = z.infer<typeof LogContactDialogSchema>;
