import i18n from 'i18next';

import {createTransform} from 'redux-persist';

import {isNotNil, uniq} from 'ramda';

import {GetAlphaCatalogueApiResponse} from '@dms/api/shared';

const outboundTransform = (outboundState: object | undefined) => {
  const alphaCatalogueKey = `getAlphaCatalogue({"lang":"${i18n.language}"})`;
  if (isNotNil(outboundState) && alphaCatalogueKey in outboundState) {
    const alphaCatalogueObject = (outboundState as any)[alphaCatalogueKey];
    if ('data' in alphaCatalogueObject) {
      const alphaCatalogue = alphaCatalogueObject.data as GetAlphaCatalogueApiResponse;
      const semiTrailerFeatures =
        alphaCatalogue
          ?.find((item) => item.vehicle_type === 'VEHICLETYPE_SEMI_TRAILER')
          ?.keys.map((item) => item.const_key) ?? [];
      if (semiTrailerFeatures?.length - uniq(semiTrailerFeatures).length !== 0) {
        return {};
      }
    }
  }

  return outboundState;
};

/**
 * This transform is used to check if there are any duplicate keys in the alpha catalogue
 * as it is described in T20-67317. Alpha catalogue is now fixed and doesn't contain any
 * duplicates, but the catalogue is cached in the local storage and the state is rehydrated
 * from the local storage on the app start.
 *
 * If there are any duplicates, the cache will be cleared and new data will be fetched from the server.
 *
 * TODO Delete this transform when it is long enough after the release of the fix
 */
export const duplicateKeysCheckTransform = createTransform(undefined, outboundTransform);
