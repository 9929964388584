import {last} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetInterestQuery,
  useGetMinipurchaseQuery,
  useGetMinisaleQuery,
  useGetOfferedVehiclesListQuery,
} from '@dms/api/interest';
import {interestRoutes} from '@dms/routes';
import {useGetVehicleTitle} from '@dms/shared';

import {composePath} from 'shared';

export function useTaskRelationInterest(interestId: string) {
  const {
    data: interest,
    isLoading: isInterestLoading,
    isError: hasInterestError,
  } = useGetInterestQuery(
    {
      id: interestId,
    },
    {
      skip: isNilOrEmpty(interestId),
    }
  );

  const minisaleId = interest?.minisalesIds ? last(interest.minisalesIds) : null;
  const {
    data: miniSale,
    isLoading: isMiniSaleLoading,
    isError: hasMinisaleError,
  } = useGetMinisaleQuery(
    {
      minisaleId: minisaleId!,
    },
    {
      skip: isNilOrEmpty(minisaleId),
    }
  );

  const miniPurchaseId = interest?.minipurchasesIds ? last(interest.minipurchasesIds) : null;
  const {
    data: miniPurchase,
    isLoading: isMiniPurchaseLoading,
    isError: hasMiniPurchaseError,
  } = useGetMinipurchaseQuery(
    {minipurchaseId: miniPurchaseId!},
    {
      skip: isNilOrEmpty(miniPurchaseId),
    }
  );

  const {
    data: offeredVehiclesList,
    isLoading: isOfferedVehicleLoading,
    isError: hasOfferedVehiclesError,
  } = useGetOfferedVehiclesListQuery(
    {body: {vehicleIds: miniPurchase?.offeredVehiclesIds ?? ['']}},
    {skip: isNilOrEmpty(miniPurchase?.offeredVehiclesIds)}
  );

  const [title, {isLoading: isVehicleTitleLoading, isError: hasVehicleTitleError}] =
    useGetVehicleTitle(
      miniSale
        ? {
            make: miniSale?.vehicleData?.make,
            vehicleType: miniSale?.vehicleData?.type?.toString(),
            modelFamily: miniSale?.vehicleData?.modelFamily,
            variant: miniSale?.vehicleData?.variant,
            trim: miniSale?.vehicleData?.trim,
          }
        : offeredVehiclesList?.[0]
    );

  return {
    title,
    customerId: interest?.customerId,
    isLoading:
      isInterestLoading ||
      isMiniSaleLoading ||
      isMiniPurchaseLoading ||
      isOfferedVehicleLoading ||
      isVehicleTitleLoading,
    isError:
      hasInterestError ||
      hasMinisaleError ||
      hasMiniPurchaseError ||
      hasOfferedVehiclesError ||
      hasVehicleTitleError,
    pathname: composePath(interestRoutes.detail, {
      params: {id: interestId},
      isSearchParamsPreserved: false,
    }),
  };
}
