import {createSelector, Selector} from '@reduxjs/toolkit';

import {AuditDataVideoAssetsFilesBody, InspectionType} from '@dms/api/carAudit';

import {AuditCategoryOfStructure} from '../../types/AuditCategoryOfStructure';
import {AuditCategoryUniqueKey} from '../../types/AuditCategoryUniqueKey';
import {AuditDataAssetsFilesBody} from '../../types/AuditDataAssetsFilesBody';
import {AuditParamType} from '../../types/AuditParamType';
import {TConditionFormValue} from '../../types/ConditionTypes';
import {LoadAuditDataResponseItemBody} from '../../types/LoadAuditDataResponseItemBody';
import type {TeasState} from '../../types/TeasState';
import {getFormFieldName} from '../../utils/getFormFieldName';
import {getParamFromCategory} from '../../utils/getParamFromCategory';
import {CarAuditState} from './reducer';

export const selectCarAudit: Selector<TeasState, CarAuditState> = (state) => state.carAudit;

export const selectNewestConditionSettingsStructure = (state: TeasState) =>
  state.carAudit.newestConditionSettingsStructure;

export const selectInspections = createSelector(selectCarAudit, (state) =>
  [
    ...state.validationInspections,
    ...state.nonValidationInspections,
    ...state.complaintInspections,
    ...state.checkinInspections,
    ...state.handoverInspections,
  ].sort((a, b) => ((a?.updateAt ?? 0) < (b?.updateAt ?? 0) ? 1 : -1))
);

export const selectAudit = createSelector(selectCarAudit, (state) => state.audit);
export const selectActiveAuditId = createSelector(selectAudit, (audit) => audit?.id);

export const selectAuditFields = createSelector(selectAudit, (audit) => audit?.fields);
export const selectAuditUser = createSelector(selectAudit, (audit) => audit?.user);
export const selectAuditUpdateAt = createSelector(selectAudit, (audit) => audit?.updateAt);
export const selectAuditState = createSelector(selectAudit, (audit) => audit?.state);

export const selectStructures = createSelector(selectCarAudit, (state) => state.auditStructures);
export const selectStructure = createSelector(selectCarAudit, selectAudit, (state, audit) =>
  audit?.isNew
    ? state.newestAuditStructure
    : audit?.controlStructureId
      ? state.auditStructures?.[audit?.controlStructureId]
      : null
);

export const selectStructureCategories = createSelector(
  selectStructure,
  (auditStructure) => auditStructure?.categories
);

export const selectExterior = createSelector(selectStructureCategories, (structureCategories) =>
  getCategory(structureCategories, AuditCategoryUniqueKey.EXTERIOR)
);

export const selectInterior = createSelector(selectStructureCategories, (structureCategories) =>
  getCategory(structureCategories, AuditCategoryUniqueKey.INTERIOR)
);

export const selectTechnicalCondition = createSelector(
  selectStructureCategories,
  (structureCategories) =>
    getCategory(structureCategories, AuditCategoryUniqueKey.TECHNICAL_CONDITION)
);

export const selectMechanics = createSelector(selectStructureCategories, (structureCategories) =>
  structureCategories?.find((category) => category.uniqueKey === AuditCategoryUniqueKey.MECHANICS)
);

export const selectOverallComment = createSelector(
  selectStructureCategories,
  (structureCategories) => getCategory(structureCategories, AuditCategoryUniqueKey.OVERALL_COMMENT)
);

export const selectFeatures = createSelector(selectStructureCategories, (structureCategories) =>
  structureCategories?.find((category) => category.uniqueKey === AuditCategoryUniqueKey.EQUIPMENT)
);

export const selectTestDrive = createSelector(selectStructureCategories, (structureCategories) =>
  structureCategories?.find((category) => category.uniqueKey === AuditCategoryUniqueKey.TESTDRIVE)
);

export const selectPhotoDocumentation = createSelector(
  selectStructureCategories,
  (structureCategories) =>
    structureCategories?.find(
      (category) => category.uniqueKey === AuditCategoryUniqueKey.PHOTODOCUMENTATION
    )
);
// end main categories

export const selectPaintAndThickness = createSelector(selectExterior, (structureCategories) =>
  structureCategories?.childCategories?.find(
    (category) => category.uniqueKey === AuditCategoryUniqueKey.PAINT_THICKNESS
  )
);

// overall evaluation categories
export const selectExteriorOverallEvaluation = createSelector(selectExterior, (exterior) =>
  getEvaluationCategories(exterior, AuditCategoryUniqueKey.OVERALL_EVALUATION_OF_THE_EXTERIOR)
);

export const selectInteriorOverallEvaluation = createSelector(selectInterior, (interior) =>
  getEvaluationCategories(interior, AuditCategoryUniqueKey.OVERALL_EVALUATION_OF_THE_INTERIOR)
);

export const selectMechanicsOverallEvaluation = createSelector(
  selectTechnicalCondition,
  selectMechanics,
  (technicalCondition, mechanics) =>
    (technicalCondition ?? mechanics)?.childCategories?.find(
      (category) =>
        category.uniqueKey === AuditCategoryUniqueKey.OVERALL_EVALUATION_OF_THE_TECHNICAL_CONDITION
    )
);
// end overall evaluation categories

export const selectAuditAssets = (categoryId: string, paramDefinitionId?: string) =>
  createSelector(selectAudit, (audit): AuditDataAssetsFilesBody[] =>
    Object.values(audit?.assets?.[getFormFieldName(categoryId, paramDefinitionId)] ?? {})
  );

export const selectVideoAuditAssets = (categoryId: string, paramDefinitionId?: string) =>
  createSelector(selectAudit, (audit): AuditDataVideoAssetsFilesBody[] =>
    Object.values(audit?.videoAssets?.[getFormFieldName(categoryId, paramDefinitionId)] ?? {})
  );
export const selectAuditLocalData = createSelector(
  selectCarAudit,
  selectAudit,
  (state, audit) => state.auditsLocalData?.[audit?.id ?? '']
);

export const selectNavigationCategories = createSelector(
  selectStructureCategories,
  (structureCategories) =>
    structureCategories?.filter(
      (category) =>
        category.visible && category.uniqueKey !== AuditCategoryUniqueKey.OVERALL_COMMENT
    )
);

export const selectNavigationCategoriesStateParams = createSelector(
  selectNavigationCategories,
  (navigationCategories) => {
    const categoriesParams: Record<string, string> = {};
    navigationCategories?.forEach((category) => {
      const categoryStateParam = getParamFromCategory(category, AuditParamType.CATEGORY_STATE);
      if (!categoryStateParam) {
        return;
      }

      categoriesParams[category.id] = categoryStateParam.id;
    });

    return categoriesParams;
  }
);

export const selectStatesOfNavigationCategories = createSelector(
  selectNavigationCategories,
  selectAudit,
  (navigationCategories, audit) => {
    const statesOfCategories: Record<string, TConditionFormValue> = {};

    navigationCategories?.forEach((category) => {
      const categoryStateParam = getParamFromCategory(category, AuditParamType.CATEGORY_STATE);

      if (audit?.fields?.[getFormFieldName(category.id, categoryStateParam?.id)] !== undefined) {
        statesOfCategories[category.id] =
          audit?.fields?.[getFormFieldName(category.id, categoryStateParam?.id)];
      }
    });

    return statesOfCategories;
  }
);

export const selectErrorCategories = createSelector(selectAudit, (audit) => audit?.errorCategories);

export const selectSettingBasicStructure = (key: string) =>
  createSelector(selectCarAudit, (state) => state.auditSettingBasicStructures?.[key]);

export const selectSettingBasicStructureByTemplate = (templateId?: string) =>
  createSelector(selectCarAudit, (state) =>
    templateId ? state.auditSettingBasicStructures?.[templateId] : undefined
  );
export const selectStructureSetting = createSelector(
  selectCarAudit,
  (state) => state.auditStructureSettings
);

export const selectStructureTemplates = createSelector(
  selectCarAudit,
  (state) => state.auditStructureTemplates
);

export const selectConditionStructureTemplate = createSelector(
  selectStructureTemplates,
  (templates) =>
    templates?.find(
      ({inspectionType}) =>
        inspectionType === LoadAuditDataResponseItemBody.inspectionType.VEHICLE_CONDITION
    )
);

export const selectStructureTemplate = (id?: string) =>
  createSelector(selectStructureTemplates, (templates) =>
    templates?.find((template) => template.id === id)
  );

export const selectAuditExportTemplates = createSelector(
  selectCarAudit,
  selectAudit,
  (state, audit) =>
    state.auditExportTemplates.filter(
      (template) =>
        audit?.inspectionType &&
        template.documentKindCode === getTemplateKindCode(audit?.inspectionType)
    )
);

export const getCategory = (
  auditStructureCategories?: AuditCategoryOfStructure[],
  auditCategoryUniqueKey?: AuditCategoryUniqueKey
): AuditCategoryOfStructure | undefined =>
  auditStructureCategories?.find((category) => category.uniqueKey === auditCategoryUniqueKey);

export const getEvaluationCategories = (
  auditStructureCategory?: AuditCategoryOfStructure,
  auditCategoryUniqueKey?: AuditCategoryUniqueKey
): AuditCategoryOfStructure | undefined =>
  auditStructureCategory?.childCategories?.find(
    (category) => category.uniqueKey === auditCategoryUniqueKey
  );

export const getTemplateKindCode = (inspectionType: string) => {
  if (
    inspectionType === InspectionType.VALIDATION_INSPECTION ||
    inspectionType === InspectionType.NON_VALIDATION_INSPECTION
  ) {
    return 'inspection';
  }

  return 'vehicle-condition';
};
