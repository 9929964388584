import {isFeatureEnabled} from 'feature-flags';
import {DataStatus} from 'platform/components';

import {isFalse, isNilOrEmpty, isNotNil, isPositive} from 'ramda-adjunct';

import {
  useGetBusinessCaseQuery,
  useGetBusinessCasesByVehicleCountQuery,
} from '@dms/api/businessCase';
import {useGetServiceVehicleQuery} from '@dms/api/metadaServiceVehicle';
import {useGetTireVehicleCountQuery} from '@dms/api/metadaTires';
import {useGetPostponeJobsByVehicleIdQuery} from '@dms/api/metadaWorkshopPostponeJob';
import {useGetVehicleServiceCampaignQuery} from '@dms/api/metadaWorkshopServiceCampaign';
import {useGetParticipationQuery} from '@dms/api/participation';
import {useGetSaleVehicleByVehicleQuery, useGetSaleVehicleQuery} from '@dms/api/saleVehicle';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetVehicleQuery} from '@dms/api/vehicle';
import {useGetVehicleComplaintsQuery} from '@dms/api/vehicleComplaint';
import {useGetVehicleWarehouseManagementSettingsQuery} from '@dms/api/vehicleWarehouse';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';
import {
  ActivityLogs,
  ErrorPage,
  NavigationItem,
  OwnershipSection,
  Page,
  Tires,
  is404ApiError,
  useDocumentContextCount,
  usePricePermissions,
  usePermissions,
  useVehiclePhotosPermissions,
  VehicleWarehouseProvider,
  useChecklist,
  useChecklistBadge,
} from '@dms/shared';
import {VehiclePhotosRoutes} from '@dms/teas';

import {buildArray, buildObject, composePath, useRequiredParams} from 'shared';

import {ThreeHundredAndSixtyPhotos} from './(sections)/360Photos/360Photos';
import {Advertisements} from './(sections)/Advertisements/Advertisements';
import {BusinessCases} from './(sections)/BusinessCases/BusinessCases';
import {Campaigns} from './(sections)/Campaigns/Campaigns';
import {Claims} from './(sections)/Claims/Claims';
import {Customers} from './(sections)/Customers/Customers';
import {Documents} from './(sections)/Documents/Documents';
import {HistoryOfRepairs} from './(sections)/HistoryOfRepairs/HistoryOfRepairs';
import {Insights} from './(sections)/Insights/Insights';
import {Interests} from './(sections)/Interests/Interests';
import {InspectionPhotos} from './(sections)/Photos/InspectionPhotos';
import {VehiclePhotos} from './(sections)/Photos/VehiclePhotos';
import {VehiclePostponedJobs} from './(sections)/PostponedJobs/VehiclePostponedJobs';
import {PricesAndFinances} from './(sections)/PricesAndFinances/PricesAndFinances';
import {Reservations} from './(sections)/Reservations/Reservations';
import {TestDrives} from './(sections)/TestDrives/TestDrives';
import {VehicleChecklists} from './(sections)/VehicleChecklists/VehicleChecklists';
import {Conditions} from './(sections)/VehicleCondition/Conditions';
import {InspectionDetail} from './(sections)/VehicleCondition/InspectionDetail';
import {Inspections} from './(sections)/VehicleCondition/Inspections';
import {VehicleDetails} from './(sections)/VehicleDetails/VehicleDetails';
import {VehicleWarehouseInfo} from './(sections)/WarehouseInfo/VehicleWarehouseInfo';
import {VehicleHeader} from './components/VehicleHeader';

export function VehicleDetail() {
  const {id} = useRequiredParams();

  const documentsCountBadge = useDocumentContextCount('vehicle', id);
  const {
    data: vehicleData,
    isLoading: isVehicleLoading,
    isError: isVehicleErrored,
    error: vehicleError,
  } = useGetVehicleQuery({vehicleId: id});

  const {data: allInStockSaleVehicles} = useGetSaleVehicleByVehicleQuery({
    vehicleId: id,
    type: 'IN_STOCK',
  });

  const {data: saleVehicle, isError: isSaleVehicleError} = useGetSaleVehicleQuery({
    vehicleId: id,
  });

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: id,
  });

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.businessCase,
      recordId: saleVehicle?.saleBusinessCaseId ?? '',
    },
    {skip: isNilOrEmpty(saleVehicle?.saleBusinessCaseId)}
  );

  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: saleVehicle?.saleBusinessCaseId ?? ''},
    {skip: isNilOrEmpty(saleVehicle?.saleBusinessCaseId)}
  );

  const [
    hasComplaintReadPermission,
    hasReadBusinessCasePurchasePermission,
    hasReadBusinessCaseSellingPermission,
    hasVehicleReadActiveReservationPermission,
    hasVehicleReadReservationHistoryPermission,
    hasVehicleReadWarehouseInfoPermission,
    canReadBusinessCase,
    hasReadAdvertisingSettingsPermission,
    canReadServiceCase,
    canReadDocuments,
    canReadInterest,
  ] = usePermissions({
    permissionKeys: [
      'complaintRead',
      'viewBusinessCasePurchase',
      'viewBusinessCaseSelling',
      'vehicleReadActiveReservation',
      'vehicleReadReservationHistory',
      'vehicleReadWarehouseInformation',
      'businessCaseRead',
      'readAdvertisingSettings',
      'serviceCaseRead',
      'readDocuments',
      'interestRead',
    ],
    scopes: {
      complaintRead: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      vehicleReadActiveReservation: {
        participation: vehicleParticipation,
        branchId: saleVehicle?.branchId,
      },
      vehicleReadReservationHistory: {
        participation: vehicleParticipation,
        branchId: saleVehicle?.branchId,
      },
      readAdvertisingSettings: {
        participation: vehicleParticipation,
        branchId: saleVehicle?.branchId,
      },
      viewBusinessCasePurchase: {
        // BC tab should be allowed regardless of participation scope
        participation: 'SKIP',
        // BC tab should be allowed regardless of branch scope
        branchId: 'SKIP',
      },
      viewBusinessCaseSelling: {
        // BC tab should be allowed regardless of participation scope
        participation: 'SKIP',
        // BC tab should be allowed regardless of branch scope
        branchId: 'SKIP',
      },
      interestRead: {
        // interest tab should be allowed regardless of participation scope
        participation: 'SKIP',
        // interest tab should be allowed regardless of branch scope
        branchId: 'SKIP',
      },
    },
  });

  const isWorkShopEnabled = isFeatureEnabled(featureFlags.ACL_WORKSHOP) && canReadServiceCase;

  const {data: serviceVehicle, isError: isServiceVehicleError} = useGetServiceVehicleQuery(
    {vehicleId: id},
    {skip: !isWorkShopEnabled}
  );

  const {canReadVehicleAlbumPhotos} = useVehiclePhotosPermissions({
    vehicleId: id,
  });

  const {data: vehicleServiceCampaign} = useGetVehicleServiceCampaignQuery(
    {vehicleId: id},
    {skip: !isWorkShopEnabled}
  );
  const {data: postopnedJobsData} = useGetPostponeJobsByVehicleIdQuery(
    {vehicleId: id},
    {skip: !isWorkShopEnabled}
  );
  const {data: businessCaseCountData} = useGetBusinessCasesByVehicleCountQuery({vehicleId: id});
  const {data: vehicleComplaints} = useGetVehicleComplaintsQuery({vehicleId: id});
  const {data: warehouseSettings} = useGetVehicleWarehouseManagementSettingsQuery();
  const {data: vehicleTires} = useGetTireVehicleCountQuery(
    {vehicleId: id},
    {skip: !isWorkShopEnabled || !isFeatureEnabled(featureFlags.AFTS_TIRES_VEHICLE_CARD)}
  );

  const isSalesModuleEnabled = isFeatureEnabled(featureFlags.ACL_SALES) && canReadBusinessCase;

  const {canViewAnyOfVehiclePriceFields: canReadFinances} = usePricePermissions({
    vehicleRecordId: id,
    businessCaseRecordId: saleVehicle?.saleBusinessCaseId,
  });

  const activeServiceCampaignsLength =
    vehicleServiceCampaign?.serviceCampaign?.filter(
      (campaign) => campaign.kind === 'ACTIVE_CAMPAIGN'
    ).length ?? 0;

  const isSaleVehicle = isNotNil(saleVehicle) && !isSaleVehicleError;
  const isServiceVehicle = isNotNil(serviceVehicle) && !isServiceVehicleError;

  const {progress: saleVehicleProgress} = useChecklist({
    recordId: saleVehicle?.id || '',
    resourceId: 'SALE_VEHICLE',
  });

  const {progress: serviceVehicleProgress} = useChecklist({
    recordId: serviceVehicle?.serviceVehicleId || '',
    resourceId: 'SERVICE_VEHICLE',
  });

  const {badgeSettings} = useChecklistBadge({
    progress: [saleVehicleProgress, serviceVehicleProgress],
  });

  const navigationItems = buildArray<NavigationItem>()
    .when(isSaleVehicle, {
      id: 'insights',
      label: i18n.t('entity.interest.labels.overviewTab'),
      href: composePath(vehiclesRoutes.insights, {params: {id}}),
      content: <Insights vehicleId={id} />,
      'data-testid': testIds.vehicles.detail('navigation-item-insights'),
      hasSeparator: true,
    })
    .add({
      id: 'details',
      label: i18n.t('entity.vehicle.labels.vehicleDetails'),
      href: composePath(vehiclesRoutes.detail, {params: {id}}),
      content: [
        {route: vehiclesRoutes.detail, element: <VehicleDetails />},
        {route: vehiclesRoutes.vehicleData, element: <VehicleDetails />},
        {route: vehiclesRoutes.features, element: <VehicleDetails />},
        {route: vehiclesRoutes.serviceIntervals, element: <VehicleDetails />},
      ],
      'data-testid': testIds.vehicles.detail('navigation-item-details'),
    })
    .when(isSalesModuleEnabled, {
      id: 'conditions',
      label: i18n.t('entity.vehicle.labels.vehicleCondition'),
      href: composePath(
        isSaleVehicle ? vehiclesRoutes.conditionDetail : vehiclesRoutes.conditionsInspections,
        {
          params: {
            id,
          },
        }
      ),
      content: isSaleVehicle
        ? [
            {route: vehiclesRoutes.conditionsInspections, element: <Inspections />},
            {route: vehiclesRoutes.inspectionDetail, element: <InspectionDetail />},
            {route: vehiclesRoutes.inspectionDetailTab, element: <InspectionDetail />},
            {route: vehiclesRoutes.conditions, element: <Conditions />},
            {route: vehiclesRoutes.conditionDetail, element: <Conditions />},
            {route: vehiclesRoutes.conditionDetailTab, element: <Conditions />},
          ]
        : [
            {route: vehiclesRoutes.conditionsInspections, element: <Inspections />},
            {route: vehiclesRoutes.inspectionDetail, element: <InspectionDetail />},
            {route: vehiclesRoutes.inspectionDetailTab, element: <InspectionDetail />},
          ],
      'data-testid': testIds.vehicles.detail('navigation-item-conditions'),
    })
    .when(
      isFeatureEnabled(featureFlags.SALES_VEHICLE_WAREHOUSE_MANAGEMENT) &&
        isSaleVehicle &&
        warehouseSettings?.isEnabled &&
        hasVehicleReadWarehouseInfoPermission &&
        isSalesModuleEnabled,
      {
        id: 'vehicleWarehouses',
        label: i18n.t('entity.vehicle.labels.vehicleWarehouseInfo'),
        href: composePath(vehiclesRoutes.vehicleWarehouseInfo, {params: {id}}),
        content: [
          {
            route: vehiclesRoutes.vehicleWarehouseInfo,
            element: (
              <VehicleWarehouseInfo
                vehicleId={id}
                data-testid={testIds.vehicles.vehicleWarehouseInfo()}
              />
            ),
          },
        ],
        isDisabled: isFalse((allInStockSaleVehicles?.activeSaleVehicleId ?? []).length),
      }
    )
    .when(isSaleVehicle && canReadFinances && isSalesModuleEnabled, {
      id: 'finances',
      label: i18n.t('entity.vehicle.labels.finances'),
      href: composePath(vehiclesRoutes.finances, {params: {id}}),
      content: [
        {route: vehiclesRoutes.finances, element: <PricesAndFinances />},
        {route: vehiclesRoutes.financesPrices, element: <PricesAndFinances />},
        {route: vehiclesRoutes.financesEarningsCosts, element: <PricesAndFinances />},
      ],
      'data-testid': testIds.vehicles.detail('navigation-item-finances'),
    })
    .when(isSalesModuleEnabled && isSaleVehicle && hasReadAdvertisingSettingsPermission, {
      id: 'advertisements',
      label: i18n.t('entity.vehicle.labels.advertisements'),
      href: composePath(vehiclesRoutes.publishing, {params: {id}}),
      content: [{route: vehiclesRoutes.publishing, element: <Advertisements />}],
      'data-testid': testIds.vehicles.detail('navigation-item-advertisements'),
    })
    .when(isSalesModuleEnabled, {
      id: 'photos',
      label: i18n.t('entity.photo.labels.photos'),
      href: composePath(vehiclesRoutes.photos, {
        params: {
          id,
          tab: canReadVehicleAlbumPhotos
            ? VehiclePhotosRoutes.vehiclePhotos
            : VehiclePhotosRoutes.condition,
        },
      }),
      content: [
        {route: vehiclesRoutes.photos, element: <VehiclePhotos />},
        {route: vehiclesRoutes.photoByInspectionId, element: <InspectionPhotos />},
      ],
      'data-testid': testIds.vehicles.detail('navigation-item-photos'),
    })
    .whenFeatureEnabled(featureFlags.VEHICLE_THREE_HUNDRED_AND_SIXTY_PHOTOS, {
      id: 'threeHundredAndSixtyPhotos',
      label: i18n.t('entity.vehicle.labels.threeHundredAndSixtyPhotos'),
      href: composePath(vehiclesRoutes.threeHundredAndSixtyPhotos, {params: {id}}),
      content: <ThreeHundredAndSixtyPhotos />,
      hasSeparator: true,
      'data-testid': testIds.vehicles.detail('navigation-item-threeHundredAndSixtyPhotos'),
    })
    .when(canReadDocuments, {
      id: 'documents',
      label: i18n.t('page.vehicle.documents.title'),
      href: composePath(vehiclesRoutes.documents, {params: {id}}),
      content: <Documents />,
      hasSeparator: !isFeatureEnabled(featureFlags.SALE_TASKS_CHECKLIST),
      badge: documentsCountBadge,
      'data-testid': testIds.vehicles.detail('navigation-item-documents'),
    })
    .whenFeatureEnabled(featureFlags.SALE_TASKS_CHECKLIST, {
      id: 'checklists',
      label: i18n.t('entity.checklist.labels.title'),
      href: composePath(vehiclesRoutes.checklists, {params: {id}}),
      content: (
        <VehicleChecklists
          saleVehicleId={saleVehicle?.id || ''}
          serviceVehicleId={serviceVehicle?.serviceVehicleId || ''}
        />
      ),
      badge: badgeSettings,
      hasSeparator: true,
      'data-testid': testIds.vehicles.detail('navigation-item-checklists'),
    })
    .when(isSaleVehicle, {
      id: 'customers',
      label: i18n.t('page.customers.title'),
      href: composePath(vehiclesRoutes.customers, {params: {id}}),
      content: <Customers />,
      'data-testid': testIds.vehicles.detail('navigation-item-customers'),
    })
    .when(isSaleVehicle && canReadInterest && isSalesModuleEnabled, {
      id: 'interests',
      label: i18n.t('page.interests.title'),
      href: composePath(vehiclesRoutes.interests, {params: {id}}),
      content: <Interests />,
      'data-testid': testIds.vehicles.detail('navigation-item-interests'),
    })
    .when(isSaleVehicle, {
      id: 'test-drives',
      label: i18n.t('entity.testDrive.labels.testDrives'),
      href: composePath(vehiclesRoutes.testDrives, {params: {id}}),
      content: <TestDrives />,
      'data-testid': testIds.vehicles.detail('navigation-item-test-drive'),
    })
    .when(
      isSaleVehicle &&
        hasVehicleReadActiveReservationPermission &&
        hasVehicleReadReservationHistoryPermission &&
        isSalesModuleEnabled,
      {
        id: 'reservations',
        label: i18n.t('entity.vehicle.labels.reservations'),
        href: composePath(vehiclesRoutes.reservations, {params: {id}}),
        content: <Reservations />,
        'data-testid': testIds.vehicles.detail('navigation-item-reservations'),
      }
    )
    .when(
      isSalesModuleEnabled &&
        isSaleVehicle &&
        saleVehicle?.id &&
        (hasReadBusinessCasePurchasePermission || hasReadBusinessCaseSellingPermission),
      {
        id: 'business-cases',
        label: i18n.t('entity.vehicle.labels.businessCases'),
        href: composePath(vehiclesRoutes.businessCases, {params: {id}}),
        content: <BusinessCases />,
        badge: {
          colorScheme: 'neutral',
          value: businessCaseCountData?.count ?? 0,
        },
        'data-testid': testIds.vehicles.detail('navigation-item-business-cases'),
      }
    )
    .when(
      isFeatureEnabled(featureFlags.SALES_CLAIMS) &&
        hasComplaintReadPermission &&
        isSaleVehicle &&
        isSalesModuleEnabled,
      {
        id: 'vehicle-complaints',
        label: i18n.t('entity.vehicle.labels.complaints'),
        href: composePath(vehiclesRoutes.complaints, {params: {id}}),
        content: <Claims />,
        badge: isPositive(vehicleComplaints?.length)
          ? {
              value: vehicleComplaints?.length ?? 0,
              colorScheme: 'neutral',
            }
          : undefined,
        'data-testid': testIds.vehicles.detail('complaints'),
      }
    )
    .when(
      isWorkShopEnabled && isServiceVehicle,
      buildObject<NavigationItem>()
        .id('postponed-jobs')
        .label(i18n.t('entity.vehicle.labels.postponedJobs'))
        .href(composePath(vehiclesRoutes.postponedJobs, {params: {id}}))
        .content(<VehiclePostponedJobs />)
        .badge(
          {value: String(postopnedJobsData?.count ?? ''), colorScheme: 'neutral'},
          Boolean(postopnedJobsData?.count)
        )
        .build()
    )
    .when(
      isWorkShopEnabled &&
        isFeatureEnabled(featureFlags.AFS_SERVICE_CASE_OWNERSHIP) &&
        isServiceVehicle,
      {
        id: 'ownership',
        label: i18n.t('entity.serviceCase.labels.ownership'),
        href: composePath(vehiclesRoutes.ownership, {params: {id}}),
        content: <OwnershipSection vehicleId={id} />,
        'data-testid': testIds.vehicles.detail('navigation-item-ownership'),
      }
    )
    .when(isWorkShopEnabled && isServiceVehicle, {
      id: 'historyOfRepairs',
      label: i18n.t('entity.serviceCase.labels.historyOfRepairs'),
      href: composePath(vehiclesRoutes.historyOfRepairs, {params: {id}}),
      content: [
        {
          route: vehiclesRoutes.historyOfRepairs,
          element: <HistoryOfRepairs vehicleId={id} />,
        },
      ],
      'data-testid': testIds.vehicles.detail('navigation-item-historyOfRepairs'),
    })
    .when(isWorkShopEnabled && isServiceVehicle, {
      id: 'serviceCampaigns',
      label: i18n.t('entity.campaigns.labels.serviceCampaigns'),
      href: composePath(vehiclesRoutes.serviceCampaigns, {params: {id}}),
      badge: {value: activeServiceCampaignsLength, colorScheme: 'neutral'},
      content: <Campaigns vehicleId={id} />,
      'data-testid': testIds.vehicles.detail('navigation-item-serviceCampaigns'),
    })
    .when(isFeatureEnabled(featureFlags.AFTS_TIRES_VEHICLE_CARD), {
      id: 'tires',
      label: i18n.t('page.tiresInventory.labels.tires'),
      href: composePath(vehiclesRoutes.tires, {params: {id}}),
      content: <Tires vehicleId={id} gridCode="vehicle-tire-list" />,
      isDisabled: !Boolean(vehicleTires?.count),
      'data-testid': testIds.vehicles.detail('navigation-item-tires'),
      hasSeparator: true,
    })
    .whenFeatureEnabled(featureFlags.CORE_ACTIVITY_LOGS, {
      id: 'activityLogs',
      label: i18n.t('general.labels.activityLogs'),
      href: composePath(vehiclesRoutes.activityLog, {params: {id}}),
      content: <ActivityLogs code="vehicle-audit-log" />,
      'data-testid': testIds.vehicles.detail('navigation-item-activityLogs'),
    });

  if (!isVehicleLoading && is404ApiError(vehicleError)) {
    return <ErrorPage message={i18n.t('page.vehicle.notifications.notFound')} />;
  }

  return (
    <DataStatus isLoading={isVehicleLoading} isError={isVehicleErrored} minHeight={100}>
      {isNotNil(vehicleData) && !isVehicleLoading ? (
        <VehicleWarehouseProvider>
          <Page
            key={id}
            navigation={navigationItems}
            header={<VehicleHeader vehicleId={id} data-testid={testIds.vehicles.detail()} />}
            data-testid={testIds.vehicles.detail()}
          />
        </VehicleWarehouseProvider>
      ) : null}
    </DataStatus>
  );
}
