import {Button, Flag} from 'platform/components';
import {Box, HStack, Show, useWindowDimensions} from 'platform/foundation';

import {useRef, useState} from 'react';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps, useToggle} from 'shared';

import {HighlightItemType} from './types/HighlightItemType';

const SHOWN_ROWS = 2;
const EXPAND_BUTTON_WIDTH = 44;

interface VehicleHighlightsProps extends TestIdProps {
  data: HighlightItemType[];
}

export function VehicleHighlights(props: VehicleHighlightsProps) {
  const [isExpanded, toggleExpanded] = useToggle(false);
  const [highlightsVisibleCount, setHighlightsVisibleCount] = useState(props.data.length);
  const lastVisibleElement = useRef<HTMLDivElement>(null);
  const lastVisibleElementIndex = useRef<number>(null);
  const {width: windowWidth} = useWindowDimensions();

  const calculateVisibleElement = (ref: HTMLDivElement | null, index: number) => {
    if (ref) {
      const rect = ref.getBoundingClientRect();
      const parentRect = ref.parentElement?.getBoundingClientRect();

      const row = Math.floor((rect.top - (parentRect?.top ?? 0)) / rect.height) + 1;

      if (row === SHOWN_ROWS) {
        lastVisibleElement.current = ref;
        lastVisibleElementIndex.current = index;
      }

      if (row === SHOWN_ROWS + 1) {
        const lastRect = lastVisibleElement.current?.getBoundingClientRect();
        const rightSpace = lastRect && parentRect ? parentRect.right - lastRect.right : 0;

        setHighlightsVisibleCount(
          (lastVisibleElementIndex.current ?? 0) - (rightSpace < EXPAND_BUTTON_WIDTH ? 1 : 0) + 1
        );
      }
    }
  };

  const visibleHighlights = isExpanded ? props.data : props.data.slice(0, highlightsVisibleCount);
  const hiddenItemsCount = props.data.length - highlightsVisibleCount;
  const hasMoreItems = hiddenItemsCount > 0;

  return (
    <Box>
      {/* Hidden container for calculating item positioning */}
      <Box height={0} overflow="hidden" key={`calculator-${windowWidth}`}>
        <HStack spacing={1} wrap>
          {props.data.map((item, index) => (
            <div key={item.key} ref={(ref) => calculateVisibleElement(ref, index)}>
              <Flag label={item.label} isSubtle />
            </div>
          ))}
        </HStack>
      </Box>

      {/* Visible highlights */}
      <HStack spacing={1} wrap>
        {visibleHighlights.map((item, index) => (
          <Flag
            key={item.key}
            label={item.label}
            isSubtle
            colorScheme="blue"
            data-testid={suffixTestId(`feature[${index}]`, props)}
          />
        ))}

        <Show when={!isExpanded && hasMoreItems}>
          <Button
            title={`${hiddenItemsCount} +`}
            size="small"
            onClick={toggleExpanded}
            data-testid={suffixTestId('expandButton', props)}
          />
        </Show>

        <Show when={isExpanded && hasMoreItems}>
          <Button
            title={i18n.t('general.actions.hide')}
            size="small"
            onClick={toggleExpanded}
            data-testid={suffixTestId('collapseButton', props)}
          />
        </Show>
      </HStack>
    </Box>
  );
}
