import {TableCellBaseProps, useTableRowContext} from 'platform/components';
import {Box, HStack, Icon, Show, Text} from 'platform/foundation';
import styled, {css, useTheme} from 'styled-components';

import {ChecklistResourceType} from '../types/ChecklistResourceType';
import {TableCellType} from '../types/TableCellType';

const ContainerDiv = styled.div`
  padding: ${({theme}) => theme.space.step * 32}px;
  min-height: ${({theme}) => theme.space.step * 192}px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface TaskNameCellProps extends TableCellBaseProps<TableCellType> {
  name: string;
  isMandatory: boolean;
  resourceId: keyof typeof ChecklistResourceType;
  recordId: string;
  checklistId: string;
  taskId: string;
  markedAsDone: boolean;
  cellType: TableCellType;
}

export function TaskNameCell(props: TaskNameCellProps) {
  const theme = useTheme();
  const {isHovered} = useTableRowContext();

  return (
    <ContainerDiv>
      <HStack align="center" spacing={2}>
        <Show when={props.markedAsDone}>
          <Box flexShrink={0}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="11"
                fill={
                  isHovered ? theme.colors.palettes.neutral[50][100] : theme.colors.severity.success
                }
                stroke={
                  isHovered ? theme.colors.palettes.neutral[50][100] : theme.colors.severity.success
                }
                strokeWidth="2"
                css={css`
                  transition:
                    fill 0.2s ease,
                    stroke 0.2s ease;
                `}
              />
              {!isHovered ? (
                <path
                  d="M6.5 11.5L10.5 15.5L17.5 8"
                  stroke={theme.colors.general.white}
                  strokeWidth="2"
                />
              ) : (
                <>
                  <path d="M7 7L17 17" stroke={theme.colors.general.white} strokeWidth="2" />
                  <path d="M7 17L17 7" stroke={theme.colors.general.white} strokeWidth="2" />
                </>
              )}
            </svg>
          </Box>
        </Show>
        <Show when={!props.markedAsDone}>
          <Box flexShrink={0}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="11"
                fill={
                  isHovered ? theme.colors.palettes.neutral[50][100] : theme.colors.general.white
                }
                stroke={theme.colors.palettes.neutral[50][100]}
                strokeWidth="2"
                css={css`
                  transition:
                    fill 0.2s ease,
                    stroke 0.2s ease;
                `}
              />
              <path
                d="M6.5 11.5L10.5 15.5L17.5 8"
                stroke={theme.colors.general.white}
                strokeWidth="2"
                css={css`
                  stroke-dasharray: 20;
                  stroke-dashoffset: ${isHovered ? 0 : 20};
                  transition: stroke-dashoffset 0.3s ease;
                `}
              />
            </svg>
          </Box>
        </Show>
        <Text size="small" overflowWrap="anywhere" alternative>
          {props.name}
        </Text>
        <Show when={props.isMandatory}>
          <Box flexShrink={0}>
            <Icon value="content/asterisk" size={3} color="severity.danger" />
          </Box>
        </Show>
      </HStack>
    </ContainerDiv>
  );
}
