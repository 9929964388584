import {Card} from 'platform/components';
import {Box} from 'platform/foundation';

import {Dispatch, SetStateAction} from 'react';
import {useSelector} from 'react-redux';

import {isNil} from 'ramda';

import {SourcingVehicleDetailResponseBody} from '@dms/api/sourcing';
import {selectTenant} from '@dms/api/tenant';
import {i18n} from '@dms/i18n';
import {testIds} from '@dms/routes';
import {DrillDown} from '@dms/shared';
import {selectUserSettings} from '@dms/teas';

import {convertMileageToAlphaMileageRange} from '../FullScreenDetailLayout/utils/convertMileageToAlphaMileageRange';

interface DrillDownTabProps {
  vehicle?: SourcingVehicleDetailResponseBody;
  setFullScreen: Dispatch<SetStateAction<boolean>>;
}

export function DrillDownTab(props: DrillDownTabProps) {
  const {data: selectedTenant} = useSelector(selectTenant);
  const tenantCountry = selectedTenant?.country;
  const {currency, country = tenantCountry} = useSelector(selectUserSettings);

  if (isNil(props.vehicle) || isNil(country) || isNil(currency)) {
    return null;
  }

  return (
    <Card
      title={i18n.t('general.labels.drillDown')}
      tooltip={i18n.t('entity.vehicle.labels.drillDownTooltip')}
      actions={[
        {
          type: 'iconButton',
          icon: 'action/zoom_in',
          onClick: () => props.setFullScreen(true),
        },
      ]}
    >
      <Box overflowX="auto">
        <DrillDown
          regions={[country]}
          currency={currency ?? 'CZK'}
          hiddenLevels={['make', 'model_family_group', 'model']}
          permanentFilters={{
            make: props.vehicle.sourcingVehicle.make?.key,
            model_family_group: props.vehicle.sourcingVehicle.modelFamilyGroup?.key,
            model: props.vehicle.sourcingVehicle.model?.key,
          }}
          selectedRow={{
            make: props.vehicle.sourcingVehicle.make?.key,
            model_family_group: props.vehicle.sourcingVehicle.modelFamilyGroup?.key,
            model: props.vehicle.sourcingVehicle.model?.key,
            year: props.vehicle.sourcingVehicle.year?.toString(),
            fuel_type: props.vehicle.sourcingVehicle.fuelType?.key,
            drive: props.vehicle.sourcingVehicle.driveType?.key,
            transmission: props.vehicle.sourcingVehicle.transmission?.key,
            power: props.vehicle.sourcingVehicle.power?.toString(),
            mileage_range: convertMileageToAlphaMileageRange(props.vehicle.sourcingVehicle.mileage),
            car_style: props.vehicle.sourcingVehicle.carStyle?.key,
          }}
          hideConfigurator
          data-testid={testIds.sourcing.vehicleDetail('drillDownTab-drillDown')}
        />
      </Box>
    </Card>
  );
}
