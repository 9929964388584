import {VStack, Text} from 'platform/foundation';

import {InterestActivityResponseBody} from '@dms/api/sales';

import {ActivityItem} from './ActivityItem';

interface ActivityListProps {
  label: string;
  items: InterestActivityResponseBody[];
}

export function ActivityList(props: ActivityListProps) {
  return (
    <VStack spacing={4}>
      <Text size="xSmall" color="secondary">
        {props.label}
      </Text>
      <VStack spacing={0}>
        {props.items.map((item, index) => (
          <ActivityItem
            key={item.id}
            item={item}
            showLineToNextItem={index === props.items.length - 1}
          />
        ))}
      </VStack>
    </VStack>
  );
}
