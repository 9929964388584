import {openDialog} from 'platform/components';
import {ThemeColorPath, ThemeIconKey} from 'platform/foundation';
import {match} from 'ts-pattern';

import {BusinessCaseActivityResponseBody} from '@dms/api/sales';
import i18n from '@dms/i18n';
import {interestRoutes} from '@dms/routes';
import {LogContactDialog} from '@dms/shared';

import {composePath} from 'shared';

type ActivityItemData = {
  id: string;
  color: ThemeColorPath;
  description?: string | null;
  userName?: string;
  userId?: string;
  timestamp?: string;
  businessCaseId?: string;
  interestType?: string | null;
  icon?: ThemeIconKey;
  title?: string;
  links?: {
    label: string;
    url: string;
    icon?: ThemeIconKey;
  }[];
  onClick?: () => void;
};

type TransformedActivityItem = ActivityItemData | null;

export const transformActivityItem = (
  item: BusinessCaseActivityResponseBody
): TransformedActivityItem =>
  match<string, TransformedActivityItem>(item.activityType)
    .with('INTEREST_CREATED', () => ({
      id: item.id,
      color: 'palettes.blue.20.100',
      title: i18n.t('entity.activityPanel.activity.interestCreated'),
      description: item.description,
      interestType: item.interestType,
      userName: item.userName,
      userId: item.userId,
      timestamp: item.timestamp,
      icon: 'content/next_week',
      links: [
        {
          label: i18n.t('entity.activityPanel.activity.viewInterest'),
          url: composePath(interestRoutes.detail, {params: {id: item.interestId ?? ''}}),
          icon: 'action/open_in_new',
        },
      ],
    }))
    .with('INTEREST_CLOSED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.interestClosed'),
      timestamp: item.timestamp,
      color: 'palettes.red.20.100',
      interestType: item.interestType,
      userName: item.userName,
      userId: item.userId,
      description: item.description,
      icon: 'content/next_week',
    }))
    .with('BUSINESS_CASE_CREATED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.businessCaseCreated'),
      timestamp: item.timestamp,
      color: 'palettes.green.20.100',
      businessCaseId: item.interestId ?? undefined,
      interestType: item.interestType,
      userName: item.userName,
      userId: item.userId,
      description: item.description,
      icon: 'places/business_center',
    }))
    .with('MESSAGE_TO_CUSTOMER', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.messageToCustomer'),
      timestamp: item.timestamp,
      color: 'palettes.green.20.100',
      interestType: 'Message',
      userId: item.userId,
      userName: item.userName,
      description: item.description,
      icon: 'communication/comment',
      onClick: () => {
        openDialog(<LogContactDialog customerId={item.userId} salesActivity={item} />, {
          id: 'LOG_CONTACT_DIALOG',
          title: i18n.t('entity.activityPanel.logContact.editLog'),
          scrollBehavior: 'outside',
        });
      },
    }))
    .with('CALL_FROM_CUSTOMER', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.callFromCustomer'),
      timestamp: item.timestamp,
      color: 'palettes.green.20.100',
      interestType: 'Inbound call',
      userName: item.userName,
      userId: item.userId,
      description: item.description,
      icon: 'notification/phone_inbound',
      onClick: () => {
        openDialog(<LogContactDialog customerId={item.userId} salesActivity={item} />, {
          id: 'LOG_CONTACT_DIALOG',
          title: i18n.t('entity.activityPanel.logContact.editLog'),
          scrollBehavior: 'outside',
        });
      },
    }))
    .with('CALL_TO_CUSTOMER', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.callToCustomer'),
      timestamp: item.timestamp,
      color: 'palettes.green.20.100',
      interestType: 'Outbound call',
      userName: item.userName,
      userId: item.userId,
      description: item.description,
      icon: 'notification/phone_outbound',
      onClick: () => {
        openDialog(<LogContactDialog customerId={item.userId} salesActivity={item} />, {
          id: 'LOG_CONTACT_DIALOG',
          title: i18n.t('entity.activityPanel.logContact.editLog'),
          scrollBehavior: 'outside',
        });
      },
    }))
    .with('MESSAGE_FROM_CUSTOMER', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.messageFromCustomer'),
      timestamp: item.timestamp,
      color: 'palettes.green.20.100',
      interestType: 'Message',
      userName: item.userName,
      userId: item.userId,
      description: item.description,
      icon: 'communication/comment',
      onClick: () => {
        openDialog(<LogContactDialog customerId={item.userId} salesActivity={item} />, {
          id: 'LOG_CONTACT_DIALOG',
          title: i18n.t('entity.activityPanel.logContact.editLog'),
          scrollBehavior: 'outside',
        });
      },
    }))
    .with('CUSTOMER_VISIT', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.customerVisit'),
      timestamp: item.timestamp,
      color: 'palettes.green.20.100',
      userName: item.userName,
      userId: item.userId,
      interestType: 'Visit',
      description: item.description,
      icon: 'communication/location_on',
      onClick: () => {
        openDialog(<LogContactDialog customerId={item.userId} salesActivity={item} />, {
          id: 'LOG_CONTACT_DIALOG',
          title: i18n.t('entity.activityPanel.logContact.editLog'),
          scrollBehavior: 'outside',
        });
      },
    }))
    .with('CONTRACT_CLOSED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.contractClosed'),
      icon: 'custom/invoice',
      timestamp: item.timestamp,
      color: 'palettes.green.20.100',
      userName: item.userName,
      userId: item.userId,
    }))
    .with('DEPOSIT_PAYMENT_ISSUED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.depositPaymentIssued'),
      icon: 'action/payment',
      timestamp: item.timestamp,
      color: 'palettes.green.20.100',
      userName: item.userName,
      userId: item.userId,
    }))
    .with('BALANCE_PAYMENT_ISSUED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.balancePaymentIssued'),
      icon: 'action/payment',
      timestamp: item.timestamp,
      color: 'palettes.green.20.100',
      userName: item.userName,
      userId: item.userId,
    }))
    .with('PAYMENT_PAID', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.paymentPaid'),
      icon: 'action/payment',
      timestamp: item.timestamp,
      color: 'palettes.green.20.100',
      userName: item.userName,
      userId: item.userId,
    }))
    .with('TASK_FINISHED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.taskFinished'),
      timestamp: item.timestamp,
      icon: 'sidebar/task_management',
      color: 'palettes.green.20.100',
      userName: item.userName,
      userId: item.userId,
    }))
    .with('ONLINE_OFFER_SHARING_CANCELED', () => ({
      id: item.id,
      timestamp: item.timestamp,
      color: 'palettes.red.20.100',
      userName: item.userName,
      userId: item.userId,
      title: i18n.t('entity.activityPanel.activity.onlineOfferSharingCanceled'),
      icon: 'content/mail_opened',
    }))
    .otherwise(() => null);
