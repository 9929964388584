import {match} from 'ts-pattern';

import {useEffect} from 'react';

import {isNil, path} from 'ramda';

import {catchUnhandledDataGridActions, useWarehouseDataGridTreeFolder} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {ActionCallback, DataGrid, ExternalFilters} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../../../../components/FullscreenDataGridWrapper';
import {ItemRowData} from '../types/ItemRowData';
import {MaterialWarehouseTabExternalFilters} from './MaterialWarehouseTabExternalFilters';

interface ReceiveNoteMaterialWarehouseTabProps extends RequiredTestIdProps {
  onAddToBasket: (itemRowData: ItemRowData) => void;
  onArticleDetail: (warehouseId: string, articleId: string) => void;
  onActiveFolderIdChange?: (activeFolderId?: string) => void;
  onExternalFilterChange: (filters: ExternalFilters) => void;
}

const RECEIVE_NOTE_MATERIAL_EXTERNAL_FILTER_ID = 'receiveNote-material-external-filter';

export function ReceiveNoteMaterialWarehouseTab(props: ReceiveNoteMaterialWarehouseTabProps) {
  const [folderTreeRef, dataGridModifier, activeFolderId] = useWarehouseDataGridTreeFolder();

  useEffect(() => {
    props.onActiveFolderIdChange?.(activeFolderId);
  }, [activeFolderId]);

  const actionCallback: ActionCallback = ({actionKey, rowData}) => {
    const name = path(['name', 'value'], rowData) as string;
    const warehouseId = path(['warehouseId', 'value', 'id'], rowData) as string;
    const articleId = path(['articleId', 'value'], rowData) as string;
    const catalogNumber = path(['catalogNumber', 'value'], rowData) as string;

    if (isNil(name)) {
      throw new Error('name is not defined');
    }

    if (isNil(warehouseId)) {
      throw new Error('warehouseId is not defined');
    }

    if (isNil(articleId)) {
      throw new Error('articleId is not defined');
    }

    if (isNil(catalogNumber)) {
      throw new Error('catalogNumber is not defined');
    }

    const articleRowData: ItemRowData = {
      warehouseId,
      articleId,
      catalogNumber,
      name,
    };

    match(actionKey)
      .with('toBasket', () => props.onAddToBasket(articleRowData))
      .with('articleDetail', () => props.onArticleDetail(warehouseId, articleId))
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };
  return (
    <FullscreenDataGridWrapper
      externalFilterId={RECEIVE_NOTE_MATERIAL_EXTERNAL_FILTER_ID}
      externalFilterHeight={100}
      folderTreeRef={folderTreeRef}
      hideCardWrapper
      data-testid={props['data-testid']}
    >
      <DataGrid
        // DG must be re-rendered on folder change to update the query modifier
        // eslint-disable-next-line no-restricted-syntax
        key={dataGridModifier?.key}
        gridCode="receive-note-basket-article"
        actionCallback={actionCallback}
        externalFilterId={RECEIVE_NOTE_MATERIAL_EXTERNAL_FILTER_ID}
        onFilterChanged={props.onExternalFilterChange}
        filterComponent={MaterialWarehouseTabExternalFilters}
        queryModifier={dataGridModifier?.queryModifier}
        data-testid={suffixTestId('warehouse', props)}
      />
    </FullscreenDataGridWrapper>
  );
}
