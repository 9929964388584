import {useState} from 'react';

import {isNilOrEmpty} from 'ramda-adjunct';

import {BusinessCaseType} from '@dms/api/commission';
import {datagridApi} from '@dms/api/datagrid';
import {useLazyDataQuery} from '@dms/shared';

export const useKanbanControl = () => {
  const [businessCaseType, setBusinessCaseType] = useState<BusinessCaseType>('SELLING');

  const [
    getBusinessCaseQueryParams,
    {
      data: businessCaseQuery,
      isLoading: isLoadingBusinessCaseQuery,
      isError: isLoadingBusinessCaseQueryErrored,
    },
  ] = useLazyDataQuery('business-case');

  const [
    getInterestQueryParams,
    {
      data: interestQuery,
      isLoading: isLoadingInterestQuery,
      isError: isLoadingInterestQueryErrored,
    },
  ] = useLazyDataQuery('interest');

  const {
    data: businessCases,
    isLoading: isLoadingBusinessCases,
    isError: isLoadingBusinessCasesErrored,
  } = datagridApi.useGetDataByDataQueryQuery(
    {
      gridCode: 'business-case',
      dataQueryId: businessCaseQuery?.dataQueryId!,
      offset: 0,
      limit: 100000,
    },
    {
      skip: isNilOrEmpty(businessCaseQuery?.dataQueryId),
      refetchOnMountOrArgChange: true,
      pollingInterval: KANBAN_POLLING_INTERVAL,
    }
  );

  const {
    data: interests,
    isLoading: isLoadingInterests,
    isError: isLoadingInterestsErrored,
  } = datagridApi.useGetDataByDataQueryQuery(
    {
      gridCode: 'interest',
      dataQueryId: interestQuery?.dataQueryId!,
      offset: 0,
      limit: 100000,
    },
    {
      skip: isNilOrEmpty(interestQuery?.dataQueryId),
      refetchOnMountOrArgChange: true,
      pollingInterval: KANBAN_POLLING_INTERVAL,
    }
  );

  const onFilterChange = async (businessCaseType: BusinessCaseType, filteredUserIds: string[]) => {
    if (isNilOrEmpty(filteredUserIds)) {
      return;
    }

    await getBusinessCaseQueryParams({
      filters: {
        owner: filteredUserIds,
        businessCaseType: [businessCaseType],
      },
      order: [
        {
          columnKey: 'businessCaseCreatedAt',
          order: 'DESC',
        },
      ],
      smartSearch: null,
    });

    await getInterestQueryParams({
      filters: {owner: filteredUserIds, type: [businessCaseType]},
      order: [{columnKey: 'createdAt', order: 'DESC'}],
      smartSearch: null,
    });

    setBusinessCaseType(businessCaseType);
  };

  return {
    businessCaseType,
    businessCases,
    interests,
    isLoading:
      isLoadingInterestQuery ||
      isLoadingBusinessCaseQuery ||
      isLoadingBusinessCases ||
      isLoadingInterests,
    isError:
      isLoadingInterestQueryErrored ||
      isLoadingBusinessCaseQueryErrored ||
      isLoadingBusinessCasesErrored ||
      isLoadingInterestsErrored,
    onFilterChange,
  };
};

/**
 * Polling interval for updates in milliseconds (60 minutes)
 * This is used to refresh the data in the kanban
 * This is needed because the kanban is not updated when the data is updated
 * and the data is not updated in the kanban when the user is not in the kanban
 *
 */
const KANBAN_POLLING_INTERVAL = 60 * 60 * 1000;
