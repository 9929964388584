import {Dropdown} from 'platform/components';

import {RequiredTestIdProps, useBoolean} from 'shared';

import {TaskCenterContent} from './components/TaskCenterContent';
import {TaskCenterHeaderButton} from './components/TaskCenterHeaderButton';

interface TaskCenterPopupProps extends RequiredTestIdProps {}

export function TaskCenterPopup(props: TaskCenterPopupProps) {
  const [isOpen, setOpen, setClosed] = useBoolean();

  return (
    <Dropdown
      closeOnSelect
      closeOnBlur
      onOpen={setOpen}
      onClose={setClosed}
      isOpen={isOpen}
      placement="bottom-end"
      dropdownControl={
        <TaskCenterHeaderButton isOpen={isOpen} data-testid={props['data-testid']} />
      }
    >
      <TaskCenterContent onClick={setClosed} data-testid={props['data-testid']} />
    </Dropdown>
  );
}
