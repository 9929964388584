import {IconButton} from 'platform/components';
import {Box, Center, Icon, Show, VStack, Text} from 'platform/foundation';
import styled from 'styled-components';

import {useGetMyTasksCountQuery} from '@dms/api/taskManagement';
import featureFlags from '@dms/feature-flags';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface TaskCenterHeaderButtonProps extends RequiredTestIdProps {
  isOpen: boolean;
}

export function TaskCenterHeaderButton(props: TaskCenterHeaderButtonProps) {
  const {data: tasksCount} = useGetMyTasksCountQuery();

  return (
    <>
      <Show whenFeatureDisabled={featureFlags.SIDEBAR}>
        <HeaderButtonWrapper $isOpen={props.isOpen}>
          <VStack
            justify="center"
            align="center"
            width={12}
            height={12}
            data-testid={suffixTestId('headerButton', props)}
          >
            <Box
              position="relative"
              width={6}
              height={6}
              backgroundColor="palettes.neutral.30.100"
              borderRadius="circular"
            >
              <Center height="100%">
                <Icon size={5} value="action/assignment_turned_in" cursor="pointer" />
                <Show when={(tasksCount?.count ?? 0) > 0}>
                  <Box
                    position="absolute"
                    bottom={3}
                    left={3}
                    backgroundColor="severity.danger"
                    width={4}
                    height={4}
                    borderRadius="circular"
                  >
                    <Center>
                      <Text size="xSmall" alternative color="white">
                        {tasksCount?.count}
                      </Text>
                    </Center>
                  </Box>
                </Show>
              </Center>
            </Box>
          </VStack>
        </HeaderButtonWrapper>
      </Show>
      <Show whenFeatureEnabled={featureFlags.SIDEBAR}>
        <Box position="relative" width="100%" height="100%">
          <Center width="100%" height="100%">
            <IconButton
              aria-label="topBar"
              size="small"
              iconSize="large"
              icon="action/assignment_turned_in"
              data-testid={suffixTestId('taskCenter-button', props)}
            />
            <Show when={(tasksCount?.count ?? 0) > 0}>
              <Box
                position="absolute"
                top={2}
                right={2}
                backgroundColor="severity.danger"
                width={4}
                height={4}
                borderRadius="circular"
              >
                <Center justify="center" width="100%" height="100%">
                  <Text size="xxSmall" alternative color="white">
                    {tasksCount?.count}
                  </Text>
                </Center>
              </Box>
            </Show>
          </Center>
        </Box>
      </Show>
    </>
  );
}

const HeaderButtonWrapper = styled.div<{$isOpen?: boolean}>`
  cursor: pointer;
  background-color: ${({theme, $isOpen}) => $isOpen && theme.colors.palettes.neutral[30][100]};
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  }
`;
