import {IconButton} from 'platform/components';
import {Box, Icon, Show, Center} from 'platform/foundation';

import {useNotifications} from '@dms/notifications';

import {isDefinedNonZeroValue, suffixTestId, TestIdProps} from 'shared';

import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {HeaderButtonWrapper} from './HeaderButtonWrapper';
import {NavigationButton} from './NavigationButton';

const NOTIFICATION_PAGE_SIZE = 20;

interface NotificationsButtonProps extends TestIdProps {
  isOpen: boolean;
}

function NotificationsButtonMobile(props: NotificationsButtonProps) {
  const {unreadNotificationsCount} = useNotifications(NOTIFICATION_PAGE_SIZE);

  return (
    <NavigationButton
      isActive={props.isOpen}
      badgeValue={
        isDefinedNonZeroValue(unreadNotificationsCount) ? unreadNotificationsCount : undefined
      }
    >
      <Icon size={5} value="social/notifications" color="text.white" />
    </NavigationButton>
  );
}

function NotificationsButtonDesktop(props: NotificationsButtonProps) {
  const {hasUnreadNotifications} = useNotifications(NOTIFICATION_PAGE_SIZE);

  return (
    <Box position="relative" width="100%" height="100%">
      <Center width="100%" height="100%">
        <HeaderButtonWrapper $isOpen={props.isOpen}>
          <IconButton
            aria-label="topBar"
            size="small"
            iconSize="large"
            icon="social/notifications"
            data-testid={suffixTestId('notifications', props)}
          />

          <Show when={hasUnreadNotifications}>
            <Box
              position="absolute"
              top={0}
              right={0}
              backgroundColor="severity.danger"
              width={2}
              height={2}
              borderRadius="circular"
            />
          </Show>
        </HeaderButtonWrapper>
      </Center>
    </Box>
  );
}

export function NotificationsButton(props: NotificationsButtonProps) {
  const isMobileNavigation = useIsMobileNavigation();

  if (isMobileNavigation) {
    return <NotificationsButtonMobile {...props} />;
  }

  return <NotificationsButtonDesktop {...props} />;
}
