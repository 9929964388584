import type {useDateTimeFormatter} from 'platform/locale';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  GetCurrentUserInfoApiResponse,
  TaskPriorityEnumSchema,
  TaskStateEnumSchema,
  TaskTypeEnumSchema,
} from '@dms/api/shared';

import {Nullish, parseDate} from 'shared';

import {TaskForm} from '../../../types/TaskForm';

export function getTaskFormDefaultValues(
  formatter?: ReturnType<typeof useDateTimeFormatter>,
  user?: GetCurrentUserInfoApiResponse,
  values?: Partial<TaskForm>
): TaskForm {
  const formatTime = (time: string | Nullish) => {
    if (isNilOrEmpty(time)) {
      return null;
    }
    const formatted = formatter?.('timeShort', parseDate(time));
    // Add leading zero if needed
    if (formatted?.length === 4) {
      return `0${formatted}`;
    }
    return formatted ?? null;
  };

  const defaultValues: TaskForm = {
    title: '',
    priority: TaskPriorityEnumSchema.enum.MEDIUM,
    type: TaskTypeEnumSchema.enum.TASK,
    state: TaskStateEnumSchema.enum.TODO,
    reportedBy: user?.id ?? '',
    assignedTo: user?.id ?? '',
    dueDate: null,
    description: '',
    relatedRecordId: null,
    ...values,
    dueFrom: formatTime(values?.dueFrom),
    dueTo: formatTime(values?.dueTo),
  };
  return defaultValues;
}
