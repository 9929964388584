import {isFeatureEnabled} from 'feature-flags';
import {openDialog, showNotification} from 'platform/components';
import {useLocale} from 'platform/locale';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useGetBusinessCaseQuery,
  useSendBusinessCaseOnlineOfferMutation,
} from '@dms/api/businessCase';
import {getWorkspaceFromUri, urlBuilder, UrlBuilderConfig} from '@dms/api/core';
import {useGetParticipationQuery} from '@dms/api/participation';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetUserQuery} from '@dms/api/user';
import {environment} from '@dms/environment';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {
  createSearchParamsString,
  handleApiError,
  InvalidateOnlineOfferDialog,
  usePermissions,
} from '@dms/shared';

const {workspace} = getWorkspaceFromUri();

export const useBcToOnlineOffer = (businessCaseId: string) => {
  const {language} = useLocale();

  const {data: businessCaseData} = useGetBusinessCaseQuery({businessCaseId});

  const {data: participation} = useGetParticipationQuery({
    recordId: businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const {data: userInfo} = useGetUserQuery(
    {id: participation?.owner?.userId || ''},
    {skip: isNilOrEmpty(participation?.owner?.userId)}
  );

  const [canSendOnlineOffer, canInvalidateOnlineOffer] = usePermissions({
    permissionKeys: ['sendOnlineOffer', 'invalidateOnlineOffer'],
    scopes: {
      sendOnlineOffer: {
        participation,
        branchId: businessCaseData?.branchId,
      },
      invalidateOnlineOffer: {
        participation,
        branchId: businessCaseData?.branchId,
      },
    },
  });

  const canSendOffer =
    businessCaseData?.businessCaseState !== 'CONCEPT' &&
    businessCaseData?.businessCaseState !== 'UNSUCCESSFUL' &&
    isNilOrEmpty(businessCaseData?.offerId) &&
    isFeatureEnabled(featureFlags.BUSINESS_CASE_ONLINE_OFFER) &&
    canSendOnlineOffer;

  const isOnlineOfferSent =
    isNotNilOrEmpty(businessCaseData?.offerId) &&
    isFeatureEnabled(featureFlags.BUSINESS_CASE_ONLINE_OFFER);

  const canResendOffer = isOnlineOfferSent && canSendOnlineOffer;
  const canInvalidateOffer = isOnlineOfferSent && canInvalidateOnlineOffer;

  const [sendOnlineOffer, {isLoading: isSendingOnlineOffer, isError: hasSendOnlineOfferError}] =
    useSendBusinessCaseOnlineOfferMutation();

  const sendOnlineOfferToCustomer = () =>
    sendOnlineOffer({businessCaseId})
      .unwrap()
      .then(() => {
        showNotification.success();
      })
      .catch(handleApiError);

  const invalidateOnlineOfferToCustomer = () => {
    if (businessCaseData?.offerId) {
      openDialog(<InvalidateOnlineOfferDialog offerId={businessCaseData.offerId} />, {
        title: i18n.t('entity.invalidateOnlineOfferDialog.title'),
        id: 'invalidateOnlineOfferDialog',
        'data-testid': 'invalidateOnlineOfferDialog',
      });
    }
  };

  const openOffer = () => {
    if (isOnlineOfferSent) {
      const builderConfig: UrlBuilderConfig = {
        workspace,
        protocol: window.location.protocol,
        domain: environment.ONLINE_OFFER_DOMAIN,
        route: `/${businessCaseData?.offerId}`,
        searchParam: createSearchParamsString({
          lang: language,
          sellerEmail: userInfo?.email,
        }),
      };

      const digitalCertificateUrl = urlBuilder(builderConfig);

      window.open(digitalCertificateUrl, '_blank')?.focus();
    }
  };

  const isLoading = isSendingOnlineOffer;
  const hasError = hasSendOnlineOfferError;

  return {
    canSendOffer,
    canResendOffer,
    canInvalidateOffer,
    isOnlineOfferSent,
    isLoading,
    hasError,
    actions: {
      sendOnlineOfferToCustomer,
      invalidateOnlineOfferToCustomer,
      openOffer,
    },
  };
};
