import {useTheme} from 'styled-components';

import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {selectActiveBranchId} from '@dms/api/features';
import {useGetUserStatsQuery} from '@dms/api/user';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {useRouter} from '../../hooks/useRouter';
import {
  SOURCING_USER_LISTS,
  SourcingSubPageRoute,
  UserListPage,
} from '../../types/SourcingSomeTypes';
import {Menu} from '../Menu/Menu';
import {ControlSectionWrapper} from './components/ControlSectionWrapper';
import {Counter} from './components/Counter';
import {HeaderSettings} from './components/HeaderSettings';
import {HeaderSettingsContainer} from './components/HeaderSettingsContainer';
import {OneLine} from './components/OneLine';
import {StyledMenuItem} from './components/StyledMenuItem';
import {TitleSection} from './components/TitleSection';

export function Navigation(props: TestIdProps) {
  const theme = useTheme();
  const router = useRouter();
  const pageRoute = router?.route;

  const activeBranchId = useSelector(selectActiveBranchId);

  const {data: userStats} = useGetUserStatsQuery(undefined, {
    skip: !activeBranchId,
  });

  const {toBuyCount, favouriteCount, hiddenCount, comparisonCount} = useMemo(
    () =>
      userStats
        ? userStats.userStats
        : {toBuyCount: 0, favouriteCount: 0, hiddenCount: 0, comparisonCount: 0},
    [userStats]
  );

  const getNewPathname = (pageType = UserListPage.DEFAULT) =>
    SOURCING_USER_LISTS(SourcingSubPageRoute.classifieds, pageType);

  return (
    <ControlSectionWrapper>
      <TitleSection data-testid={suffixTestId('titleSection', props)} />
      <Menu data-testid={suffixTestId('menu', props)}>
        <StyledMenuItem isActive={pageRoute === getNewPathname()}>
          <Link to={getNewPathname()} data-testid={suffixTestId('userListPage', props)}>
            {i18n.t('general.actions.allOffers')}
          </Link>
        </StyledMenuItem>
        <StyledMenuItem
          isActive={pageRoute === getNewPathname(UserListPage.FAVORITES_VEHICLE_PAGE)}
        >
          <Link
            to={getNewPathname(UserListPage.FAVORITES_VEHICLE_PAGE)}
            data-testid={suffixTestId('favoritesPage', props)}
          >
            <OneLine>
              {i18n.t('general.actions.favourites')}
              <Counter count={favouriteCount} color={theme.colors.palettes.orange[60][100]} />
            </OneLine>
          </Link>
        </StyledMenuItem>
        <StyledMenuItem isActive={pageRoute === getNewPathname(UserListPage.CART_PAGE)}>
          <Link
            to={getNewPathname(UserListPage.CART_PAGE)}
            data-testid={suffixTestId('cartPage', props)}
          >
            <OneLine>
              {i18n.t('entity.vehicle.labels.toBuyPage')}
              <Counter count={toBuyCount} color={theme.colors.palettes.blue[60][100]} />
            </OneLine>
          </Link>
        </StyledMenuItem>
        <StyledMenuItem isActive={pageRoute === getNewPathname(UserListPage.COMPARISON_PAGE)}>
          <Link
            to={getNewPathname(UserListPage.COMPARISON_PAGE)}
            data-testid={suffixTestId('comparisonPage', props)}
          >
            <OneLine>
              {i18n.t('entity.vehicle.labels.comparisionPage')}
              <Counter count={comparisonCount} color={theme.colors.palettes.purple[60][100]} />
            </OneLine>
          </Link>
        </StyledMenuItem>
        <StyledMenuItem isActive={pageRoute === getNewPathname(UserListPage.HIDDEN_VEHICLE_PAGE)}>
          <Link
            to={getNewPathname(UserListPage.HIDDEN_VEHICLE_PAGE)}
            data-testid={suffixTestId('hiddenPage', props)}
          >
            <OneLine>
              {i18n.t('entity.vehicle.labels.hiddenPage')}
              <Counter count={hiddenCount} color={theme.colors.palettes.neutral[200][100]} />
            </OneLine>
          </Link>
        </StyledMenuItem>
      </Menu>
      <HeaderSettingsContainer>
        <HeaderSettings />
      </HeaderSettingsContainer>
    </ControlSectionWrapper>
  );
}
