import {
  Action,
  Card,
  closeCurrentDialog,
  DataStatus,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {isNil, mergeAll, not, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {GetWarehouseResponse} from '@dms/api/metadaWarehouse';
import {GetWarehouseAccountResponse} from '@dms/api/metadaWarehouseAccount';
import {GetReceiveNoteResponse} from '@dms/api/metadaWarehouseReceiveNote';
import {usePostReceiveNoteItemsDeleteMutation} from '@dms/api/metadaWarehouseReceiveNoteItem';
import {TenantResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {warehouseRoutes} from '@dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@dms/shared';

import {
  composePath,
  Nullish,
  RequiredTestIdProps,
  suffixTestId,
  useBoolean,
  useNavigate,
} from 'shared';

import {
  ActionCallback,
  ActionCallbackParams,
  DataGrid,
  QueryFilterObject,
  refreshDatagrid,
} from 'features/datagrid';

import {OverviewTotalprice} from '../../../../../components/OverviewTotalPrice';
import {MISSING_EDITING_DETAILS_MESSAGE} from '../../../../../constants/missingEditingDetailsMessage';
import {RECEIVE_NOTE_ITEM_DIALOG} from '../constants/receiveNoteItemDialog';
import {ItemRowData} from '../types/ItemRowData';
import {ReceiveNoteItemDetail} from './ReceiveNoteItemDetail';
import {ReceiveNoteMaterialModal} from './ReceiveNoteMaterialModal';

interface ReceiveNoteItemListProps extends RequiredTestIdProps {
  receiveNoteId: string;
  receiveNote: GetReceiveNoteResponse | Nullish;
  warehouse: GetWarehouseResponse | Nullish;
  warehouseAccount: GetWarehouseAccountResponse | Nullish;
  tenant: TenantResponseBody | Nullish;
  isDraftReceiveNote: boolean;
  isReceiveNotePending: boolean;
  isFromReceiveNote: boolean;
  totalPriceWithoutVat: string | null;
  totalPriceWithVat: string | null;
}

export function ReceiveNoteItemList(props: ReceiveNoteItemListProps) {
  const navigate = useNavigate();

  const [isMaterialModalVisible, openMaterialModal, closeMaterialModal] = useBoolean();

  const [deleteReceiveNoteItems] = usePostReceiveNoteItemsDeleteMutation();

  const actionCallback: ActionCallback = ({actionKey, rowData, refreshData, rowId}) => {
    const isBulk = isArray(rowData);
    const getName = (rowData: ActionCallbackParams['rowData']) =>
      path(['name', 'value'], rowData) as string;
    const receiveNoteItemId = path(['id'], rowData) as string;
    const warehouseId = props.receiveNote?.warehouseId;
    const articleId = path(['articleId', 'value'], rowData) as string;
    const name = isBulk
      ? (rowData.map((data: ActionCallbackParams['rowData']) => getName(data)).join() as string)
      : (path(['name', 'value'], rowData) as string);
    const catalogNumber = path(['catalogNumber', 'value'], rowData) as string;

    if (
      !isBulk &&
      (isNil(receiveNoteItemId) ||
        isNil(articleId) ||
        isNil(name) ||
        isNil(catalogNumber) ||
        isNil(warehouseId))
    ) {
      throw new Error(MISSING_EDITING_DETAILS_MESSAGE);
    }

    const itemRowData: ItemRowData = {
      articleId,
      warehouseId: warehouseId as string,
      catalogNumber,
      name,
    };

    match(actionKey)
      .with('redirectDetail', () => {
        navigate(
          composePath(warehouseRoutes.articleDetailOverview, {
            params: {
              warehouseId: props.receiveNote?.warehouseId,
              id: articleId,
            },
          })
        );
      })
      .with('edit', () => {
        handleOpenEditingDialog(receiveNoteItemId, itemRowData);
      })
      .with('delete', () => {
        handleDeleteReceiveNoteItem(rowId as string[], name, refreshData);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const handleOpenEditingDialog = (receiveNoteItemId: string, itemRowData: ItemRowData) => {
    openDialog(
      <ReceiveNoteItemDetail
        isEditing
        receiveNoteId={props.receiveNoteId}
        receiveNoteItemId={receiveNoteItemId}
        itemRowData={itemRowData}
        warehouse={props.warehouse}
        warehouseAccount={props.warehouseAccount}
        tenant={props.tenant}
        state={props.receiveNote?.state}
        data-testid={suffixTestId('forms.editItem', props)}
      />,
      {
        id: RECEIVE_NOTE_ITEM_DIALOG,
        title: i18n.t('general.labels.edit'),
        size: 'large',
        withAdditionalFooter: true,
        'data-testid': suffixTestId('dialogs.editItem', props),
      }
    );
  };

  const handleDeleteReceiveNoteItem = (
    rowDataIds: string[],
    name: string,
    refreshData: VoidFunction
  ) => {
    openDeleteDialog({
      name,
      onConfirm: async () => {
        await deleteReceiveNoteItems({
          receiveNoteId: props.receiveNoteId,
          body: {
            receiveNoteItemId: rowDataIds,
          },
        })
          .unwrap()
          .then(refreshData)
          .then(() => showNotification.success(i18n.t('general.notifications.successfullyDeleted')))
          .then(closeCurrentDialog)
          .catch(handleApiError);
      },
    });
  };

  const handleDiscardMaterialModal = () => {
    closeMaterialModal();
    refreshDatagrid('receive-note-item');
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {receiveNoteId: props.receiveNoteId}]),
    [props.receiveNoteId]
  );

  const listOfItemsCardActions: Action[] = [
    {
      type: 'button',
      variant: 'link',
      leftIcon: 'content/add_circle',
      title: i18n.t('general.labels.add'),
      onClick: openMaterialModal,
      isDisabled: not(props.isReceiveNotePending) || not(props.isFromReceiveNote),
      'data-testid': suffixTestId('actions.add', props),
    },
  ];

  return (
    <>
      <Card
        title={i18n.t('general.labels.listOfItems')}
        actions={listOfItemsCardActions}
        data-testid={suffixTestId(props['data-testid'], props)}
      >
        <DataStatus isEmpty={props.isDraftReceiveNote} minHeight={60}>
          <DataGrid
            gridCode="receive-note-item"
            actionCallback={actionCallback}
            queryModifier={queryModifier}
            autoHeight
            data-testid={suffixTestId('datagrid.receiveNoteItems', props)}
          />
          <OverviewTotalprice
            totalPriceWithoutVat={props.totalPriceWithoutVat}
            totalPriceWithVat={props.totalPriceWithVat}
            data-testid={suffixTestId('totalPrice', props)}
          />
        </DataStatus>
      </Card>

      <Show when={isMaterialModalVisible}>
        <ReceiveNoteMaterialModal
          receiveNoteId={props.receiveNoteId}
          warehouse={props.warehouse}
          warehouseAccount={props.warehouseAccount}
          tenant={props.tenant}
          state={props.receiveNote?.state}
          onDiscard={handleDiscardMaterialModal}
          data-testid={suffixTestId('dialogs.addMaterial', props)}
        />
      </Show>
    </>
  );
}
