import {Form, FormField, FormSubmitHandler, showNotification} from 'platform/components';
import {Grid, Hide, VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import {
  useCreateCorrectiveTaxDocumentSettingMutation,
  useUpdateCorrectiveTaxDocumentSettingMutation,
} from '@dms/api/accounting';
import {
  CorrectiveTaxDocumentSettingResponseBody,
  CreateCorrectiveTaxDocumentSettingRequestBody,
} from '@dms/api/metadaErrorLogs';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError, useBranches} from '@dms/shared';

import {useNavigate} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../components/SettingsSection/SettingsSection';
import {correctiveTaxDocumentFormValidations} from './correctiveTaxDocumentFormValidations';

interface CorrectiveTaxDocumentFormProps {
  correctiveTaxDocumentSetting?: CorrectiveTaxDocumentSettingResponseBody;
}

export const CorrectiveTaxDocumentForm = (props: CorrectiveTaxDocumentFormProps) => {
  const isCreating = isNil(props.correctiveTaxDocumentSetting);
  const navigate = useNavigate();
  const {branchOptions} = useBranches();

  const [createCorrectiveTaxDocumentSetting] = useCreateCorrectiveTaxDocumentSettingMutation();
  const [updateCorrectiveTaxDocumentSetting] = useUpdateCorrectiveTaxDocumentSettingMutation();

  const onCancel = () => {
    navigate(settingsRoutes.correctiveTaxDocuments);
  };

  const handleSubmit: FormSubmitHandler<CreateCorrectiveTaxDocumentSettingRequestBody> = async (
    data
  ) => {
    if (isCreating) {
      createCorrectiveTaxDocumentSetting({
        createCorrectiveTaxDocumentSettingRequestBody: data,
      })
        .unwrap()
        .then(() => {
          showNotification.success();
          navigate(settingsRoutes.correctiveTaxDocuments);
        })
        .catch(handleApiError);
    } else if (props.correctiveTaxDocumentSetting) {
      await updateCorrectiveTaxDocumentSetting({
        id: props.correctiveTaxDocumentSetting.id,
        updateCorrectiveTaxDocumentSettingRequestBody: data,
      })
        .unwrap()
        .then(() => {
          showNotification.success();
          navigate(settingsRoutes.correctiveTaxDocuments);
        })
        .catch(handleApiError);
    }
  };

  return (
    <Form<CreateCorrectiveTaxDocumentSettingRequestBody>
      schema={correctiveTaxDocumentFormValidations(!!props.correctiveTaxDocumentSetting?.isSystem)}
      defaultValues={{
        createCorrectiveTaxDocumentType: 'without_proforma',
        ...(props.correctiveTaxDocumentSetting as Partial<CreateCorrectiveTaxDocumentSettingRequestBody>),
      }}
      onSubmit={handleSubmit}
    >
      {(control) => (
        <SettingsSection>
          <VStack spacing={4}>
            <Grid columns={2}>
              <Hide when={props.correctiveTaxDocumentSetting?.isSystem}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  options={branchOptions}
                  label={i18n.t('entity.branch.labels.branch')}
                  data-testid={testIds.settings.correctiveTaxDocumentsEdit('branchId')}
                  isRequired
                />
              </Hide>
              <FormField
                control={control}
                type="choice"
                name="createCorrectiveTaxDocumentType"
                options={[
                  {
                    value: 'without_proforma',
                    label: i18n.t('entity.correctiveTaxDocumentSetting.labels.withoutProforma'),
                  },
                  {
                    value: 'with_proforma',
                    label: i18n.t('entity.correctiveTaxDocumentSetting.labels.withProforma'),
                  },
                ]}
                label={i18n.t(
                  'entity.correctiveTaxDocumentSetting.labels.createCorrectiveTaxDocumentType'
                )}
                data-testid={testIds.settings.correctiveTaxDocumentsEdit(
                  'createCorrectiveTaxDocumentType'
                )}
                isRequired
              />
            </Grid>

            <SettingsFooter
              actions={[
                {
                  variant: 'secondary',
                  type: 'button',
                  onClick: onCancel,
                  title: i18n.t('general.actions.cancel'),
                  'data-testid': testIds.settings.correctiveTaxDocumentsEdit('cancel'),
                },
                {
                  type: 'form-button',
                  title: isCreating
                    ? i18n.t('general.actions.create')
                    : i18n.t('general.actions.edit'),
                  'data-testid': testIds.settings.correctiveTaxDocumentsEdit('submit'),
                  control,
                  buttonType: 'submit',
                },
              ]}
            />
          </VStack>
        </SettingsSection>
      )}
    </Form>
  );
};
