import {Card, DataStatus, showNotification} from 'platform/components';
import {Box, Heading, Space, Text, VStack} from 'platform/foundation';

import {
  useCreateChecklistMutation,
  useLazyGetChecklistQuery,
  useListChecklistDefinitionQuery,
} from '@dms/api/checklist';
import i18n from '@dms/i18n';

import {handleApiError} from '../../../utils/handleApiError';
import {ChecklistResourceType} from '../types/ChecklistResourceType';
import {ChecklistImage} from './ChecklistImage';
import {TemplateChooser} from './TemplateChooser';

interface EmptyStateProps {
  recordId: string;
  resourceId: keyof typeof ChecklistResourceType;
}

export function EmptyState(props: EmptyStateProps) {
  const checklistDefinitionsQuery = useListChecklistDefinitionQuery({
    resourceId: props.resourceId,
  });

  const [createChecklist, createChecklistQuery] = useCreateChecklistMutation();
  const [getChecklist] = useLazyGetChecklistQuery();

  return (
    <DataStatus
      isLoading={checklistDefinitionsQuery.isLoading}
      isError={checklistDefinitionsQuery.isError}
    >
      <Box maxWidth={400} width="100%">
        <Card>
          <VStack spacing={4} align="center">
            <Space vertical={2} />
            <ChecklistImage />
            <Box maxWidth={120}>
              <VStack spacing={4} align="center">
                <Heading size={2}>{i18n.t('entity.checklist.labels.titleSelect')}</Heading>
                <Text color="secondary" size="small" align="center">
                  {i18n.t('entity.checklist.labels.description')}
                </Text>
              </VStack>
            </Box>
            <TemplateChooser
              onTemplateSelected={(id: string) => {
                createChecklist({
                  recordId: props.recordId,
                  resourceId: props.resourceId,
                  body: {
                    definitionId: id,
                  },
                })
                  .unwrap()
                  .then(() => {
                    showNotification.success();
                    getChecklist({
                      recordId: props.recordId,
                      resourceId: props.resourceId,
                    });
                  })
                  .catch(handleApiError);
              }}
              isLoading={createChecklistQuery.isLoading}
              resourceId={props.resourceId}
              variant="primary"
            />
            <Space vertical={6} />
          </VStack>
        </Card>
      </Box>
    </DataStatus>
  );
}
