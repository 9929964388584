import {isFeatureEnabled} from 'feature-flags';
import {
  Button,
  Choice,
  FormControl,
  IconButton,
  IntegerInput,
  NumberInput,
} from 'platform/components';
import {Box, HStack, Show, Space, VStack} from 'platform/foundation';

import {useEffect} from 'react';
import {useFieldArray, UseFormReturn} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNil, isString} from 'ramda-adjunct';

import {selectActiveBranchId} from '@dms/api/features';
import {useGetEmployeeMechanicsQuery} from '@dms/api/metadaEmployee';
import {useGetEmployeeCostCentersListQuery} from '@dms/api/metadaEmployeeCostCenter';
import {useGetServiceOrderQuery} from '@dms/api/metadaWorkshopServiceOrder';
import {useGetServiceOrderVariantQuery} from '@dms/api/metadaWorkshopServiceOrderVariant';
import {useGetBranchQuery} from '@dms/api/tenant';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {getActiveOptions} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {EditItemFormType} from '../types/EditItemFormType';

const DEFAULT_RATIO = 100;

interface EditItemMechanicsProps extends TestIdProps {
  control: FormControl<EditItemFormType>;
  formApi: UseFormReturn<EditItemFormType>;
  serviceCaseId: string;
  serviceOrderId: string;
}

export function EditItemMechanics(props: EditItemMechanicsProps) {
  const branchId = useSelector(selectActiveBranchId);
  const {data: branchDetail} = useGetBranchQuery({branchId});
  const {data: order} = useGetServiceOrderQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.serviceOrderId,
  });
  const {data: orderVariant} = useGetServiceOrderVariantQuery(
    {serviceOrderVariantId: order?.serviceOrderVariantId ?? ''},
    {skip: isNil(order?.serviceOrderVariantId)}
  );
  const {mechanicOptions, mechanics} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: orderVariant?.authorizationProfileId ?? ''},
    {
      selectFromResult: ({data}) => ({
        mechanics: data?.employees ?? [],
        mechanicOptions: data?.employees?.map((mechanic) => ({
          label: mechanic?.name ?? '',
          value: mechanic?.id ?? '',
        })),
      }),
      skip:
        isNil(orderVariant?.authorizationProfileId) ||
        !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
    }
  );
  const {costCenterOptions} = useGetEmployeeCostCentersListQuery(
    {company: branchDetail?.billingInformation?.id ?? '', branch: branchDetail?.id ?? ''},
    {
      selectFromResult: ({data}) => ({costCenterOptions: getActiveOptions(data?.costCenters)}),
      skip:
        isNilOrEmpty(branchDetail?.billingInformation?.id) ||
        isNilOrEmpty(branchDetail?.id) ||
        !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
    }
  );

  const {fields, append, remove, update} = useFieldArray({
    control: props.control,
    name: 'assignMechanics',
    keyName: 'rowId',
  });

  useEffect(() => {
    if (fields.length === 1) {
      const totalQuantity = props.formApi.getValues('quantity');

      update(0, {...fields[0], amount: totalQuantity ?? 0, ratio: DEFAULT_RATIO});
    }
  }, [fields.length, props.formApi, update]);

  const handleMechanicChange = (index: number) => (value: string | number | string[] | null) => {
    if (!isString(value)) {
      return;
    }

    const selectedMechanic = mechanics?.find((mechanic) => mechanic?.id === value);

    update(index, {
      ...fields[index],
      id: value,
      costCenterId: selectedMechanic?.costCenterId ?? null,
    });
  };

  const handleQuantityChange = (index: number) => (value: number | null) => {
    const totalQuantity = props.formApi.getValues('quantity') ?? 0;

    update(index, {
      ...fields[index],
      amount: value ?? 0,
      ratio: isNotNil(value) ? Math.round((value / totalQuantity) * 100) : 0,
    });
  };

  const handleRatioChange = (index: number) => (value: number | null) => {
    const totalQuantity = props.formApi.getValues('quantity') ?? 0;

    update(index, {
      ...fields[index],
      ratio: value ?? 0,
      amount: isNotNil(value) ? Math.round(value * totalQuantity) / 100 : 0,
    });
  };

  return (
    <VStack spacing={4} align="flex-start">
      {fields.map((field, index) => (
        <VStack key={field.id} spacing={4} width="100%">
          <HStack spacing={4}>
            <Box flex={1}>
              <Choice
                value={field.id}
                label={index === 0 ? i18n.t('entity.order.labels.mechanic') : undefined}
                filterOption={(option) =>
                  props.formApi
                    .watch('assignMechanics')
                    ?.every((mechanic) => mechanic.id !== option.value) ?? true
                }
                options={mechanicOptions ?? []}
                onChange={handleMechanicChange(index)}
                menuInPortal
                data-testid={suffixTestId(`mechanic-[${index}]`, props)}
              />
            </Box>
            <Box flex={1}>
              <HStack spacing={4}>
                <Box flex={2}>
                  <Choice
                    value={field.costCenterId}
                    onChange={(costCenterId) => update(index, {...field, costCenterId})}
                    label={index === 0 ? i18n.t('entity.order.labels.costCenter') : undefined}
                    options={costCenterOptions ?? []}
                    menuInPortal
                    data-testid={suffixTestId(`costCenter-[${index}]`, props)}
                  />
                </Box>
                <Box flex={1}>
                  <NumberInput
                    value={field.amount}
                    decimalPlaces={2}
                    label={index === 0 ? i18n.t('entity.order.itemQuantity') : undefined}
                    onChange={handleQuantityChange(index)}
                    isDisabled={fields.length === 1}
                    data-testid={suffixTestId(`quantity-[${index}]`, props)}
                  />
                </Box>
                <Box flex={1}>
                  <IntegerInput
                    value={field.ratio}
                    label={index === 0 ? i18n.t('entity.orderRequest.labels.ratio') : undefined}
                    onChange={handleRatioChange(index)}
                    isDisabled={fields.length === 1}
                    data-testid={suffixTestId(`ratio-[${index}]`, props)}
                  />
                </Box>
                <Show when={fields.length > 1}>
                  <VStack>
                    <Space vertical={index === 0 ? 5 : 0} />
                    <IconButton
                      icon="content/clear"
                      onClick={() => remove(index)}
                      priority="tertiary"
                      severity="danger"
                      data-testid={suffixTestId(`remove-[${index}]`, props)}
                    />
                  </VStack>
                </Show>
              </HStack>
            </Box>
          </HStack>
        </VStack>
      ))}
      <Button
        variant="link"
        title={i18n.t('entity.order.actions.addMechanic')}
        onClick={() => append({id: null, costCenterId: null, amount: 0, ratio: 0})}
        data-testid={suffixTestId('add', props)}
      />
    </VStack>
  );
}
