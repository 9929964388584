import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  closeDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import {useKillTransactionMutation} from '@dms/api/digitalSignature';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {DOCUMENT_SIGNATURE_DIALOG_ID} from '../constants/DOCUMENT_SIGNATURE_DIALOG_ID';

interface CancelTransactionButtonProps extends RequiredTestIdProps {
  transactionId: string | Nullish;
  refreshData: () => void;
}

export function CancelTransactionButton(props: CancelTransactionButtonProps) {
  const [killTransaction, {isLoading: isKillingTransaction}] = useKillTransactionMutation();

  const openKillTransactionDialog = () => {
    const transactionId = props.transactionId;
    if (!transactionId) {
      throw new Error('Transaction ID is required');
    }

    const handleKillTransaction = () => {
      closeCurrentDialog();

      killTransaction({transactionId})
        .unwrap()
        .then(() => {
          props.refreshData();
          closeDialog(DOCUMENT_SIGNATURE_DIALOG_ID);
          showNotification.success(i18n.t('entity.document.labels.cancelTransactionCompleted'));
        })
        .catch(handleApiError);
    };

    openDialog(
      <VStack spacing={4}>
        <Text>{i18n.t('entity.document.labels.cancelTransactionConfirmation')}</Text>
        <ButtonGroup
          align="right"
          buttons={[
            {
              variant: 'secondary',
              onClick: closeCurrentDialog,
              title: i18n.t('general.actions.close'),
              'data-testid': suffixTestId('deleteDialog-cancel', props),
            },
            {
              variant: 'danger',
              onClick: handleKillTransaction,
              isLoading: isKillingTransaction,
              title: i18n.t('general.actions.cancel'),
              'data-testid': suffixTestId('deleteDialog-confirm', props),
            },
          ]}
        />
      </VStack>,
      {
        size: 'small',
      }
    );
  };

  return (
    <Button
      title={i18n.t('entity.document.actions.killTransaction')}
      variant="dangerOutlined"
      onClick={openKillTransactionDialog}
      isLoading={isKillingTransaction}
      data-testid={props['data-testid']}
    />
  );
}
