import {useLayoutEffect, useRef} from 'react';

export const APP_CONTAINER_ID = 'dms-app-container';

/**
 * Since the app body has an overflow hidden, we can not use window.scroll events.
 * Instead we use a ref to get a container that wraps the app content and use that for scroll events.
 */
export function useAppContainerRef() {
  const ref = useRef<HTMLElement | null>(null);

  useLayoutEffect(() => {
    const container = document.getElementById(APP_CONTAINER_ID) as HTMLElement;
    ref.current = container;
  }, []);

  return ref;
}
