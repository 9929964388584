import {format, isSameMonth, isToday} from 'date-fns';
import {
  closeCurrentDialog,
  IconButton,
  openDialog,
  useTranslationContext,
} from 'platform/components';
import {Box, getSize, Heading, HStack, Show, Space, Text} from 'platform/foundation';
import {convertStringLocaleToDateFns, useLocale} from 'platform/locale';
import {css, useTheme} from 'styled-components';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {BigCalendarEvent} from '../types/BigCalendarEvent';
import {MonthEventItem} from './MonthEventItem';

interface MonthCellProps extends RequiredTestIdProps {
  date: Date;
  currentMonth: Date;
  events: BigCalendarEvent[];
  isReadOnly?: boolean;
  onDateClick?: (date: Date) => void;
  onEventClick?: (event: BigCalendarEvent) => void;
}

export function MonthCell(props: MonthCellProps) {
  const theme = useTheme();

  const {language} = useLocale();
  const t = useTranslationContext();

  const isCurrentMonth = isSameMonth(props.date, props.currentMonth);
  const visibleEvents = props.events.slice(0, MAX_VISIBLE_EVENTS);
  const hiddenEvents = props.events.slice(MAX_VISIBLE_EVENTS);
  const remainingEvents = props.events.length - MAX_VISIBLE_EVENTS;

  return (
    <div
      data-testid={suffixTestId('monthCell', props)}
      css={css`
        height: 100%;
        min-height: ${getSize(30)};
        background-color: ${theme.colors.general.white};
        border-bottom: 1px solid ${theme.colors.general.separator};
        border-left: 1px solid ${theme.colors.general.separator};
        opacity: ${isCurrentMonth ? 1 : 0.3};
        padding: 4px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        position: relative;
      `}
    >
      <Text
        data-testid={suffixTestId('monthCell-day', props)}
        size="small"
        color={isToday(props.date) ? 'warning' : 'secondary'}
        alternative={isToday(props.date)}
      >
        {format(props.date, 'd', {
          locale: convertStringLocaleToDateFns(language),
        })}
      </Text>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 2px;
        `}
      >
        {visibleEvents.map((visibleEvent, eventIndex) => (
          <MonthEventItem
            key={visibleEvent.id}
            data-testid={suffixTestId(`monthCell-event-[${eventIndex}]`, props)}
            event={visibleEvent}
            onClick={props.onEventClick}
          />
        ))}

        <Show when={remainingEvents > 0}>
          <div
            data-testid={suffixTestId('monthCell-moreEvents', props)}
            css={css`
              background-color: ${theme.colors.palettes.neutral[30][100]};
              border-radius: 4px;
              padding: 2px 4px;
              cursor: pointer;
              height: ${getSize(5)};
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              transition: background-color 0.2s ease;

              &:hover {
                background-color: ${theme.colors.palettes.neutral[40][100]};
              }
            `}
            onClick={() =>
              openDialog(
                <Box padding={3}>
                  <HStack align="center" justify="space-between">
                    <Heading
                      data-testid={suffixTestId('monthCell-moreEvents-dialog-title', props)}
                      size={5}
                    >
                      {format(props.date, 'd. LLLL yyyy, EEEE', {
                        locale: convertStringLocaleToDateFns(language),
                      })}
                    </Heading>
                    <IconButton
                      data-testid={suffixTestId('monthCell-moreEvents-dialog-close', props)}
                      icon="navigation/close"
                      priority="default"
                      onClick={closeCurrentDialog}
                    />
                  </HStack>
                  <Space vertical={3} />
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: 2px;
                    `}
                  >
                    {hiddenEvents.map((event, eventIndex) => (
                      <MonthEventItem
                        key={event.id}
                        data-testid={suffixTestId(`monthCell-hiddenEvents-[${eventIndex}]`, props)}
                        event={event}
                        onClick={props.onEventClick}
                      />
                    ))}
                  </div>
                </Box>,
                {
                  'data-testid': suffixTestId('monthCell-moreEvents-dialog', props),
                  size: 'small',
                  scrollBehavior: 'outside',
                  disableBodyPadding: true,
                }
              )
            }
          >
            <Text size="xSmall" color="secondary" noWrap>
              {t('bigCalendar.moreEvents', {count: remainingEvents})}
            </Text>
          </div>
        </Show>
      </div>
    </div>
  );
}
const MAX_VISIBLE_EVENTS = 3;
