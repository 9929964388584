import {DataStatus, TabProps, Tabs} from 'platform/components';

import {isNil} from 'ramda';

import {useGetServiceCheckInQuery} from '@dms/api/metadaWorkshopCheckin';
import {useGetServiceHandOverQuery} from '@dms/api/metadaWorkshopHandover';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {queryParams, Section} from '@dms/shared';

import {useQueryState} from 'shared';

import {
  CHECKIN_SECTION,
  HANDOVER_SECTION,
  VEHICLE_INSPECTION_SECTION,
} from '../../../../constants/detailSections';
import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';
import {CheckIn} from './components/CheckIn';
import {HandOver} from './components/HandOver';
import {InspectionsTab} from './components/InspectionsTab';

interface CheckinHandoverProps {
  vehicleId: string;
}

export function CheckinHandover(props: CheckinHandoverProps) {
  const {serviceCaseId: id} = useWorkshopUrl();
  const {
    data: checkin,
    isLoading: isCheckinLoading,
    isError: isCheckinError,
  } = useGetServiceCheckInQuery({serviceCaseId: id});
  const {
    data: handover,
    isLoading: isHandoverLoading,
    isError: isHandoverError,
  } = useGetServiceHandOverQuery({serviceCaseId: id});

  const [activeTabId, setActiveTabId] = useQueryState(queryParams.SERVICE_CASE_CHECKIN_HANDOVER);

  const tabs: TabProps[] = [
    {
      id: CHECKIN_SECTION,
      title: i18n.t('entity.workshop.labels.checkIn'),
      'data-testid': testIds.workshop.serviceCaseDetail('checkinTab'),
      content: <CheckIn vehicleId={props.vehicleId} />,
      icon: isNil(checkin) ? 'default' : 'success',
    },
    {
      id: HANDOVER_SECTION,
      title: i18n.t('entity.workshop.labels.handover'),
      'data-testid': testIds.workshop.serviceCaseDetail('handoverTab'),
      content: <HandOver vehicleId={props.vehicleId} />,
      icon: isNil(handover) ? 'default' : 'success',
    },
    {
      id: VEHICLE_INSPECTION_SECTION,
      title: i18n.t('entity.vehicle.labels.inspections'),
      'data-testid': testIds.workshop.serviceCaseDetail('inspectionsTab'),
      content: <InspectionsTab vehicleId={props.vehicleId} />,
    },
  ];

  return (
    <Section data-testid={testIds.workshop.serviceCaseDetail('checkinHandover')}>
      <DataStatus
        isLoading={isCheckinLoading || isHandoverLoading}
        isError={isCheckinError || isHandoverError}
      >
        <Tabs
          variant="condensed"
          activeTabId={activeTabId}
          onChange={setActiveTabId}
          tabs={tabs}
          isLazy
          data-testid={testIds.workshop.serviceCaseDetail('checkinHandover')}
        />
      </DataStatus>
    </Section>
  );
}
