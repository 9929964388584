export const interestSearchParams = {
  interestDetail: {
    overview: 'overview',
    customer: 'customer',
    selling: 'selling',
    sellingTabs: {
      request: 'request',
      offeredVehicles: 'offeredVehicles',
    },
    buying: 'buying',
    history: 'history',
    actionLogs: 'actionLogs',
    checklists: 'checklists',
  },
};
