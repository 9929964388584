import {
  Button,
  closeCurrentDialog,
  FormControl,
  FormField,
  FormSubmitHandler,
  IconButton,
  openDialog,
} from 'platform/components';
import {Box, HStack, Show, Space, VStack} from 'platform/foundation';

import {useEffect} from 'react';
import {UseFormReturn, useWatch} from 'react-hook-form';

import {isEmpty, isNil, isNotNil, last} from 'ramda';

import {useGetBusinessCaseQuery} from '@dms/api/businessCase';
import {useLazyGetOrderQuery} from '@dms/api/checkout';
import {OrderResponseBody} from '@dms/api/commission';
import {
  BankAccountData,
  CustomerBankAccountResponseBody,
  usePatchCustomerContractInformationMutation,
} from '@dms/api/customer';
import {useGetTenantQuery} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {BankAccountForm, DEFAULT_COUNTRY, handleApiError, useBank} from '@dms/shared';

import {buildArray, suffixTestId, TestIdProps, useRequiredParams} from 'shared';

import {FormValuesType} from '../types/FormValuesType';

interface CustomerBankAccountFieldsProps extends TestIdProps {
  formApi: UseFormReturn<FormValuesType>;
  control: FormControl<FormValuesType>;
  order: OrderResponseBody;
  checkoutId: string;
}

export function CustomerBankAccountField(props: CustomerBankAccountFieldsProps) {
  const {composeBankAccount} = useBank();
  const customerBankAccountId = useWatch({control: props.control, name: 'customerBankAccountId'});

  const {id: businessCaseId} = useRequiredParams();

  const {data: tenant} = useGetTenantQuery();

  const [getOrder] = useLazyGetOrderQuery();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const [patchContractInformation] = usePatchCustomerContractInformationMutation();
  const tenantCountry = tenant?.country ?? DEFAULT_COUNTRY;

  const customerBankAccounts =
    props.order.contractInformation.customerContractInformation.bankAccounts;
  const customerBankAccountsOptions = customerBankAccounts.map((bankAcc) => ({
    value: bankAcc.id,
    label: composeBankAccount(bankAcc.bankAccountData),
  }));

  const handleCreateOrEditBankAccount = (bankAccountId?: string | null) => {
    const isCreating = isNil(bankAccountId);
    const customerContractInformation = props.order.contractInformation.customerContractInformation;
    const bankAccountData = customerBankAccounts.find(
      (bankAccount) => bankAccount.id === bankAccountId
    )?.bankAccountData;

    const initialValues = {
      ...bankAccountData,
      countryCode: bankAccountData?.countryCode ?? tenantCountry,
    };

    const handleSubmitBankAccount: FormSubmitHandler<BankAccountData> = async (bankAccountData) => {
      if (isNil(businessCase)) {
        return;
      }

      const editedBankAccounts = customerContractInformation.bankAccounts.map((bankAccount) => {
        if (bankAccount.id !== bankAccountId) {
          return bankAccount;
        }

        return {
          ...bankAccount,
          bankAccountData,
        };
      });

      const bankAccounts = buildArray<
        Omit<CustomerBankAccountResponseBody, 'id'> & {id: string | null}
      >(editedBankAccounts).when(isCreating, {
        id: null,
        bankAccountData,
      });

      await patchContractInformation({
        contractInformationId: customerContractInformation.id,
        customerId: businessCase.customerId,
        contractInformationRequestBody: {
          businessInfo: customerContractInformation.businessInfo
            ? {
                businessAddressId:
                  customerContractInformation.businessInfo.businessAddress?.id ?? null,
                businessInfoData: customerContractInformation.businessInfo.businessInfoData,
              }
            : null,
          personId: customerContractInformation.person?.id ?? null,
          customFieldsPayload: customerContractInformation.customFieldsPayload ?? null,
          legalForm: customerContractInformation.legalForm ?? null,
          bankAccounts,
        },
      })
        .unwrap()
        .then(async (data) => {
          await getOrder({checkoutId: props.checkoutId, orderId: props.order.id})
            .unwrap()
            .catch(handleApiError);

          const newlyCreatedBankAccount = last(data.bankAccounts);

          return newlyCreatedBankAccount?.id ?? null;
        })
        .then((id) => {
          if (isCreating && isNotNil(id)) {
            props.formApi.setValue('customerBankAccountId', id);
          }
        })
        .then(closeCurrentDialog)
        .catch(handleApiError);
    };

    openDialog(
      <BankAccountForm initialValues={initialValues} onSubmit={handleSubmitBankAccount} />,
      {title: i18n.t('general.labels.bankAccount'), scrollBehavior: 'outside'}
    );
  };

  const hasSelectedCustomerBankAccount = isNotNil(customerBankAccountId);
  const isSelectedBankAccountInOptions = customerBankAccountsOptions.some(
    (account) => account.value === customerBankAccountId
  );

  const canEditBankAccount = hasSelectedCustomerBankAccount && isSelectedBankAccountInOptions;
  const areOptionEmpty = isEmpty(customerBankAccountsOptions);

  useEffect(() => {
    if (areOptionEmpty) {
      props.formApi.setError('customerBankAccountId', {
        message: i18n.t('general.validations.fieldIsRequired'),
      });
    } else {
      props.formApi.clearErrors('customerBankAccountId');
    }
  }, [areOptionEmpty, props.formApi]);

  return (
    <VStack align="flex-start">
      <HStack width="100%" spacing={2}>
        <Box flex={1}>
          <FormField
            name="customerBankAccountId"
            label={i18n.t('entity.checkout.labels.customerBankAccount')}
            type="choice"
            control={props.control}
            options={customerBankAccountsOptions}
            data-testid={suffixTestId('customerBankAccountId', props)}
            isNotClearable
            isRequired
          />
        </Box>
        <Show when={canEditBankAccount}>
          <VStack>
            <Space vertical={5} />
            <IconButton
              severity="informational"
              icon="image/edit"
              data-testid={suffixTestId('customerBankAccountId-edit', props)}
              onClick={() => handleCreateOrEditBankAccount(customerBankAccountId)}
            />
          </VStack>
        </Show>
      </HStack>
      <Button
        title={i18n.t('general.customer.addBankAccount')}
        data-testid={suffixTestId('customerBankAccountId-create', props)}
        leftIcon="content/add_circle"
        variant="link"
        onClick={() => handleCreateOrEditBankAccount()}
      />
    </VStack>
  );
}
