import {useLazyGetAutoFillTemplateQuery} from '@dms/api/carAudit';
import {handleApiError} from '@dms/shared';

import {useBoolean} from 'shared';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {updateAudit} from '../../../store/carAudit/actions';
import {AuditData, getAuditDataFromAutoFillTemplate} from '../../../store/carAudit/reducerUtils';
import {getUpdateRequestBodyFromFormData} from '../../../utils/getUpdateRequestBodyFromFormData';
import {useConditionContext} from './useConditionContext';

export function useApplyAutoFillTemplate() {
  const dispatch = useThunkDispatch();
  const {loadActualData} = useConditionContext();
  const [getAutoFillTemplateData] = useLazyGetAutoFillTemplateQuery();
  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const applyAutoFillTemplate = async (templateId: string, audit: AuditData) => {
    startLoading();
    const autoFillTemplate = await getAutoFillTemplateData(
      {
        autoFillTemplateId: templateId,
        inspectionId: audit.id,
      },
      true
    )
      .unwrap()
      .catch(handleApiError);

    if (autoFillTemplate) {
      const mappedAuditFields = getAuditDataFromAutoFillTemplate(audit, autoFillTemplate, {
        overrideFields: true,
      });
      const updateAuditData = getUpdateRequestBodyFromFormData(mappedAuditFields.fields);

      await dispatch(
        updateAudit.action({
          auditId: audit.id,
          requestBody: updateAuditData,
        })
      )
        .unwrap()
        .catch(handleApiError);

      await loadActualData(audit.id).catch((error: {response: Error}) => {
        handleApiError(error.response);
      });
    }

    stopLoading();
  };

  return {applyAutoFillTemplate, isLoading};
}
