import {Card, TabProps, TabsHeader} from 'platform/components';

import {GetWarehousesResponse} from '@dms/api/metadaWarehouse';
import {GetDirectSaleResponse} from '@dms/api/metadaWarehouseDirectSale';
import {HeaderDynamicActionsResponse} from '@dms/api/metadaWarehouseHeaderDynamicActions';
import i18n from '@dms/i18n';
import {queryParams, Section} from '@dms/shared';

import {buildArray, RequiredTestIdProps, suffixTestId, useQueryState} from 'shared';

import {CheckoutTabV2} from './CheckoutTabV2';
import {DiscountsTab} from './DiscountsTab';
import {RequestTab} from './RequestTab';
import {TABS_IDS} from './utils/tabIds';

interface OverviewProps extends RequiredTestIdProps {
  directSale: GetDirectSaleResponse;
  directSaleActions?: HeaderDynamicActionsResponse['actions'];
  isDirectSaleLoading: boolean;
  isEditingDisabled: boolean;
  availableWarehouses: GetWarehousesResponse;
  isMaterialReturnModalVisible: boolean;
  onCloseMaterialReturnModal: VoidFunction;
}

export const Overview = (props: OverviewProps) => {
  const [activeTabId, setActiveTabId] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);

  const isArchived = props.directSale?.state === 'ARCHIVED';

  const tabs = buildArray<TabProps>()
    .add({
      id: TABS_IDS.requestTab,
      title: i18n.t('entity.warehouse.labels.requestTab'),
      content: (
        <Card>
          <RequestTab
            directSale={props.directSale}
            isDirectSaleLoading={props.isDirectSaleLoading}
            isEditingDisabled={props.isEditingDisabled}
            availableWarehouses={props.availableWarehouses}
            isMaterialReturnModalVisible={props.isMaterialReturnModalVisible}
            onCloseMaterialReturnModal={props.onCloseMaterialReturnModal}
            data-testid={suffixTestId('tabContent.request', props)}
          />
        </Card>
      ),
      'data-testid': suffixTestId('tab.request', props),
    })
    .add({
      id: TABS_IDS.discountTab,
      title: i18n.t('entity.warehouse.labels.discountTab'),
      content: (
        <Card>
          <DiscountsTab
            directSale={props.directSale}
            isEditingDisabled={props.isEditingDisabled}
            data-testid={suffixTestId('tabContent.discount', props)}
          />
        </Card>
      ),
      isDisabled: isArchived,
      'data-testid': suffixTestId('tab.discount', props),
    })
    .add({
      id: TABS_IDS.checkoutTab,
      title: i18n.t('entity.warehouse.labels.checkoutTab'),
      content: (
        <Card>
          <CheckoutTabV2
            directSale={props.directSale}
            isEditingDisabled={props.isEditingDisabled}
            data-testid={suffixTestId('tabContent.checkout', props)}
          />
        </Card>
      ),
      'data-testid': suffixTestId('tab.checkout', props),
    });

  const activeTabContent = tabs.find(
    (tab) => tab.id === (activeTabId ?? TABS_IDS.requestTab)
  )?.content;

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <TabsHeader
        variant="container"
        tabs={tabs}
        activeTabId={activeTabId}
        onChange={setActiveTabId}
        isFullWidth
      />
      {activeTabContent}
    </Section>
  );
};
