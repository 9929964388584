import {isNil} from 'ramda';

import {omneticApi} from '@dms/api/core';

import {
  AllowedCountryAndCurrencyApiArg,
  AllowedCountryAndCurrencyApiResponse,
  BulkCreateUserComparisonApiArg,
  BulkCreateUserComparisonApiResponse,
  BulkCreateUserFavouriteApiArg,
  BulkCreateUserFavouriteApiResponse,
  BulkCreateUserHiddenApiArg,
  BulkCreateUserHiddenApiResponse,
  BulkCreateUserToBuyApiArg,
  BulkCreateUserToBuyApiResponse,
  BulkDeleteUserComparisonApiArg,
  BulkDeleteUserComparisonApiResponse,
  BulkDeleteUserFavouriteApiArg,
  BulkDeleteUserFavouriteApiResponse,
  BulkDeleteUserHiddenApiArg,
  BulkDeleteUserHiddenApiResponse,
  BulkDeleteUserToBuyApiArg,
  BulkDeleteUserToBuyApiResponse,
  CreatePresetApiResponse,
  CreateUserVehicleCostApiArg,
  CreateUserVehicleCostsApiArg,
  CreateUserVehicleSummaryApiArg,
  DeletePresetApiResponse,
  DeleteUserVehicleCostApiArg,
  DetailDefaultUserPresetCostApiResponse,
  DetailUserCostVehicleNoteApiArg,
  DetailUserCostVehicleNoteApiResponse,
  DrillDownApiArg,
  DrillDownResponse,
  ExportAllUserToBuyToCsvApiArg,
  ExportVehiclesToCsvApiArg,
  GetCarFeaturesApiArg,
  GetCarFeaturesApiResponse,
  GetPresetApiArg,
  GetPresetApiResponse,
  GetPresetsListApiResponse,
  GetSourcingVehicleCountApiArg,
  GetSourcingVehicleCountApiResponse,
  HighlightResponseBody,
  ListUserComparisonApiArg,
  ListUserComparisonApiResponse,
  ListUserFavouriteApiArg,
  ListUserFavouriteApiResponse,
  ListUserHiddenApiArg,
  ListUserHiddenApiResponse,
  ListUserToBuyApiArg,
  ListUserToBuyApiResponse,
  PriceMapStatistics,
  PushVehiclesToSaleApiArg,
  RemoveHighlightBulkApiArg,
  RemoveHighlightBulkApiResponse,
  RemoveShortcomingBulkApiArg,
  RemoveShortcomingBulkApiResponse,
  ReorderPresetsApiArg,
  ReorderPresetsApiResponse,
  SalesClaim,
  SetUserVehicleCostNoteApiArg,
  SimilarVehicleList,
  SimilarVehicleRequestBody,
  SimilarVehicleStatistics,
  SortUserComparisonRequestBody,
  SourcingCreateCommentApiArg,
  SourcingCreateCommentApiResponse,
  SourcingCreatePresetApiArg,
  SourcingDeleteCommentApiArg,
  SourcingDeletePresetApiArg,
  SourcingGetCommentsApiArg,
  SourcingGetCommentsApiResponse,
  SourcingGetPriceReportApiArg,
  SourcingGetPriceReportApiResponse,
  SourcingGetTopSellingApiArg,
  SourcingGetTopSellingApiResponse,
  SourcingServer,
  SourcingUpdateCommentApiArg,
  SourcingUpdateCommentApiResponse,
  SourcingUpdatePresetApiArg,
  SourcingVehicleDetailApiArg,
  SourcingVehicleDetailApiResponse,
  SourcingVehicleListApiArg,
  SourcingVehicleListApiResponse,
  UpdatePresetApiResponse,
  UpdateUserVehicleCostApiArg,
  UserCostVehicleListApiArg,
  UserCostVehicleListApiResponse,
  VehicleTypeForPriceMap,
} from './types';
import {fixAlfaBugsInDrillDownResponse} from './utils/fixAlfaBugsInDrillDownResponse';
import {reduceArrayToObject} from './utils/reduceArrayToObject';
import {
  isSourcingListEndpointName,
  List,
  OmneticMutationLifecycleApi,
  setUserVehicleSummary,
} from './utils/sourcingApiHelpers';

const ROWS_PER_PAGE = 20;

function getOffset(args: {page?: number}) {
  const page = args.page ?? 1;
  return (page - 1) * ROWS_PER_PAGE;
}

const renameStatisticsKeyToString = (key: string) => {
  if (key === '1-30') {
    return 'soldRecently';
  }

  if (key === '31-90') {
    return 'sold';
  }

  return 'current';
};

const sourcingRtkApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    exportAllUserToBuyToCsv: build.query<unknown, ExportAllUserToBuyToCsvApiArg>({
      // cannot be mutation, because RTK Query does not allow POST without arguments
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/user-to-buy/export/csv`,
        responseHandler: 'text',
        cache: 'no-cache',
        params: {
          buyerCountry: queryArg.buyerCountry,
          currency: queryArg.currency,
          margin: queryArg.margin,
          orderBy: queryArg.orderBy,
        },
      }),
    }),
    sourcingCreatePreset: build.mutation<CreatePresetApiResponse, SourcingCreatePresetApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/filter-presets`,
        method: 'POST',
        body: queryArg.createPresetRequestBody,
      }),
      invalidatesTags: () => [{type: 'SourcingFilterPresetList'}],
    }),
    sourcingGetPresetsList: build.query<GetPresetsListApiResponse, void>({
      query: () => ({
        url: `/dms/v1/sourcing/filter-presets`,
      }),
      providesTags: (result) =>
        result
          ? [
              {type: 'SourcingFilterPresetList'},
              ...result.map((item) => ({type: 'SourcingFilterPreset' as const, id: item.id})),
            ]
          : [{type: 'SourcingFilterPresetList'}],
    }),
    sourcingUpdatePreset: build.mutation<UpdatePresetApiResponse, SourcingUpdatePresetApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/filter-presets`,
        method: 'PUT',
        body: queryArg.updatePresetRequestBody,
      }),
      invalidatesTags: (result) => [{type: 'SourcingFilterPreset', id: result?.id}],
    }),
    sourcingDeletePreset: build.mutation<DeletePresetApiResponse, SourcingDeletePresetApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/filter-presets/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, queryArg) => [{type: 'SourcingFilterPreset', id: queryArg.id}],
    }),
    sourcingGetPreset: build.query<GetPresetApiResponse, GetPresetApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/filter-presets/${queryArg.id}`,
      }),
    }),
    sourcingReorderPresets: build.mutation<ReorderPresetsApiResponse, ReorderPresetsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/filter-presets/reorder`,
        method: 'PUT',
        body: queryArg.reorderPresetsRequestBody,
      }),
      onQueryStarted: async ({reorderPresetsRequestBody: body}, {dispatch, queryFulfilled}) => {
        // OPTIMISTIC RESPONSE
        const patchResult = dispatch(
          sourcingRtkApi.util.updateQueryData('sourcingGetPresetsList', undefined, (draft) => {
            draft.forEach((item) => {
              item.position = body.items.find((reqItem) => reqItem.id === item.id)?.position ?? 1;
            });
          })
        );

        await queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: () => [{type: 'SourcingFilterPresetList'}],
    }),
    sourcingGetCarFeatures: build.query<GetCarFeaturesApiResponse, GetCarFeaturesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/car-features`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    sourcingGetTopSelling: build.query<
      SourcingGetTopSellingApiResponse,
      SourcingGetTopSellingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/sold-vehicle/top-selling`,
        method: 'POST',
        body: queryArg.body,
        params: {size: queryArg.size},
      }),
    }),
    sourcingGetPriceReport: build.mutation<
      SourcingGetPriceReportApiResponse,
      SourcingGetPriceReportApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/price-report`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    sourcingVehicleDetail: build.query<
      SourcingVehicleDetailApiResponse,
      SourcingVehicleDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/${queryArg.externalId}`,
        params: {
          marginLte: queryArg.marginLte,
          marginGte: queryArg.marginGte,
          positionGte: queryArg.positionGte,
          positionLte: queryArg.positionLte,
          currency: queryArg.currency,
          buyerCountry: queryArg.buyerCountry,
        },
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'SourcingVehicleDetail', id: queryArg.externalId},
      ],
    }),
    sourcingVehicleList: build.query<SourcingVehicleListApiResponse, SourcingVehicleListApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle`,
        method: 'POST',
        body: {
          ...queryArg.body,
          offset: getOffset(queryArg),
          size: ROWS_PER_PAGE,
          page: undefined,
        },
        params: {
          size: ROWS_PER_PAGE,
          offset: getOffset(queryArg),
          currency: queryArg.currency,
          orderBy: queryArg.orderBy,
        },
      }),
      serializeQueryArgs({endpointName, queryArgs}) {
        return `${endpointName}_${queryArgs.orderBy}_${queryArgs.currency}_${JSON.stringify(
          queryArgs.body
        )}`;
      },
      merge(currentCache, newData) {
        currentCache.data.push(...newData.data);
      },
      forceRefetch(params) {
        return params.currentArg?.page === (params.previousArg?.page ?? 1) + 1;
      },
      providesTags: () => [
        {type: 'SourcingAllListsWithSameResponseStructure'},
        {type: 'SourcingVehicleList'},
      ],
      // we need to keep cache 30mins because when you go to vehicle detail and return back we have to scroll to last visited vehicle
      keepUnusedDataFor: 1800,
    }),
    sourcingGetVehicleCount: build.query<
      GetSourcingVehicleCountApiResponse,
      GetSourcingVehicleCountApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/_count`,
        method: 'POST',
        body: queryArg.body,
        params: {currency: queryArg.currency},
      }),
    }),
    sourcingAllowedCountryAndCurrency: build.query<
      AllowedCountryAndCurrencyApiResponse,
      AllowedCountryAndCurrencyApiArg
    >({
      query: () => ({
        url: `/dms/v1/sourcing/allowed-country-and-currency`,
      }),
    }),
    sourcingListUserFavourite: build.query<ListUserFavouriteApiResponse, ListUserFavouriteApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/user-favourite`,
        params: {
          size: queryArg.size,
          offset: queryArg.offset,
          currency: queryArg.currency,
          buyerCountry: queryArg.buyerCountry,
          margin: queryArg.margin,
          orderBy: queryArg.orderBy,
        },
      }),
      providesTags: () => [
        {type: 'SourcingAllListsWithSameResponseStructure'},
        {type: 'SourcingFavouriteList'},
      ],
    }),
    sourcingListUserToBuy: build.query<ListUserToBuyApiResponse, ListUserToBuyApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/user-to-buy`,
        params: {
          size: queryArg.size,
          offset: queryArg.offset,
          currency: queryArg.currency,
          buyerCountry: queryArg.buyerCountry,
          margin: queryArg.margin,
          orderBy: queryArg.orderBy,
        },
      }),
      providesTags: () => [
        {type: 'SourcingAllListsWithSameResponseStructure'},
        {type: 'SourcingToBuyList'},
      ],
    }),
    sourcingListUserHidden: build.query<ListUserHiddenApiResponse, ListUserHiddenApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/user-hidden`,
        params: {
          size: queryArg.size,
          offset: queryArg.offset,
          currency: queryArg.currency,
          buyerCountry: queryArg.buyerCountry,
          margin: queryArg.margin,
          orderBy: queryArg.orderBy,
        },
      }),
      providesTags: () => [
        {type: 'SourcingAllListsWithSameResponseStructure'},
        {type: 'SourcingHiddenList'},
      ],
    }),
    sourcingListUserComparison: build.query<
      ListUserComparisonApiResponse,
      ListUserComparisonApiArg
    >({
      query: (queryArg) => ({
        url: '/dms/v1/sourcing-vehicle/user-comparison',
        params: {
          size: queryArg.size,
          offset: queryArg.offset,
          currency: queryArg.currency,
          buyerCountry: queryArg.buyerCountry,
          margin: queryArg.margin,
          orderBy: queryArg.orderBy,
        },
      }),
      providesTags: () => [
        {type: 'SourcingAllListsWithSameResponseStructure'},
        {type: 'SourcingComparisonList'},
      ],
    }),
    sourcingBulkDeleteUserToBuy: build.mutation<
      BulkDeleteUserToBuyApiResponse,
      BulkDeleteUserToBuyApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/user-to-buy/_bulk`,
        method: 'DELETE',
        body: queryArg.body,
      }),
      onQueryStarted(args, mutationLifecycleApi) {
        optimisticRemoveVehiclesFromList(args, mutationLifecycleApi, 'sourcingListUserToBuy');
        optimisticUpdateVehicleLists(args, mutationLifecycleApi, 'inCart', false);
        optimisticUpdateVehicleInList(args, mutationLifecycleApi, 'inCart', false);
      },
      invalidatesTags: [{type: 'User'}, {type: 'SourcingPriceMapVehicles'}],
    }),
    sourcingBulkCreateUserToBuy: build.mutation<
      BulkCreateUserToBuyApiResponse,
      BulkCreateUserToBuyApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/user-to-buy/_bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
      onQueryStarted(args, mutationLifecycleApi) {
        optimisticUpdateVehicleLists(args, mutationLifecycleApi, 'inCart', true);
        optimisticUpdateVehicleInList(args, mutationLifecycleApi, 'inCart', true);
      },
      invalidatesTags: [
        {type: 'User'},
        {type: 'SourcingToBuyList'},
        {type: 'SourcingPriceMapVehicles'},
      ],
    }),
    sourcingBulkDeleteUserHidden: build.mutation<
      BulkDeleteUserHiddenApiResponse,
      BulkDeleteUserHiddenApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/user-hidden/_bulk`,
        method: 'DELETE',
        body: queryArg.body,
      }),
      onQueryStarted(args, mutationLifecycleApi) {
        optimisticRemoveVehiclesFromList(args, mutationLifecycleApi, 'sourcingListUserHidden');
        optimisticUpdateVehicleLists(args, mutationLifecycleApi, 'hidden', false);
        optimisticUpdateVehicleInList(args, mutationLifecycleApi, 'hidden', false);
      },
      invalidatesTags: [
        {type: 'User'},
        {type: 'SourcingVehicleList'},
        {type: 'SourcingPriceMapVehicles'},
      ],
    }),
    sourcingBulkCreateUserHidden: build.mutation<
      BulkCreateUserHiddenApiResponse,
      BulkCreateUserHiddenApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/user-hidden/_bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
      onQueryStarted(args, mutationLifecycleApi) {
        optimisticRemoveVehiclesFromList(args, mutationLifecycleApi, 'sourcingVehicleList');
        optimisticUpdateVehicleLists(args, mutationLifecycleApi, 'hidden', true);
        optimisticUpdateVehicleInList(args, mutationLifecycleApi, 'hidden', true);
      },
      invalidatesTags: [
        {type: 'User'},
        {type: 'SourcingHiddenList'},
        {type: 'SourcingPriceMapVehicles'},
      ],
    }),
    sourcingBulkDeleteUserFavourite: build.mutation<
      BulkDeleteUserFavouriteApiResponse,
      BulkDeleteUserFavouriteApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/user-favourite/_bulk`,
        method: 'DELETE',
        body: queryArg.body,
      }),
      onQueryStarted(args, mutationLifecycleApi) {
        optimisticRemoveVehiclesFromList(args, mutationLifecycleApi, 'sourcingListUserFavourite');
        optimisticUpdateVehicleLists(args, mutationLifecycleApi, 'favourite', false);
        optimisticUpdateVehicleInList(args, mutationLifecycleApi, 'favourite', false);
      },
      invalidatesTags: [{type: 'User'}, {type: 'SourcingPriceMapVehicles'}],
    }),
    sourcingBulkCreateUserFavourite: build.mutation<
      BulkCreateUserFavouriteApiResponse,
      BulkCreateUserFavouriteApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/user-favourite/_bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
      onQueryStarted(args, mutationLifecycleApi) {
        optimisticUpdateVehicleLists(args, mutationLifecycleApi, 'favourite', true);
        optimisticUpdateVehicleInList(args, mutationLifecycleApi, 'favourite', true);
      },
      invalidatesTags: [
        {type: 'User'},
        {type: 'SourcingFavouriteList'},
        {type: 'SourcingPriceMapVehicles'},
      ],
    }),
    sourcingReorderUserComparison: build.mutation<void, SortUserComparisonRequestBody>({
      query: (requestBody) => ({
        url: '/dms/v1/sourcing-vehicle/user-comparison/sort',
        method: 'POST',
        body: {sourcingVehiclesWithPositions: requestBody},
      }),
      invalidatesTags: [{type: 'SourcingComparisonList'}],
    }),
    sourcingBulkDeleteUserComparison: build.mutation<
      BulkDeleteUserComparisonApiResponse,
      BulkDeleteUserComparisonApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/user-comparison/_bulk`,
        method: 'DELETE',
        body: queryArg.body,
      }),
      onQueryStarted(args, mutationLifecycleApi) {
        optimisticRemoveVehiclesFromList(args, mutationLifecycleApi, 'sourcingListUserComparison');
        optimisticUpdateVehicleLists(args, mutationLifecycleApi, 'comparison', false);
        optimisticUpdateVehicleInList(args, mutationLifecycleApi, 'comparison', false);
      },
      invalidatesTags: [{type: 'User'}, {type: 'SourcingPriceMapVehicles'}],
    }),
    sourcingBulkCreateUserComparison: build.mutation<
      BulkCreateUserComparisonApiResponse,
      BulkCreateUserComparisonApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/user-comparison/_bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
      onQueryStarted(args, mutationLifecycleApi) {
        optimisticUpdateVehicleLists(args, mutationLifecycleApi, 'comparison', true);
        optimisticUpdateVehicleInList(args, mutationLifecycleApi, 'comparison', true);
      },
      invalidatesTags: [{type: 'User'}, {type: 'SourcingPriceMapVehicles'}],
    }),
    sourcingCreateUserVehicleSummary: build.mutation<void, CreateUserVehicleSummaryApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/vehicle-summary/${queryArg.sourcingVehicleId}`,
        method: 'POST',
        body: queryArg.body,
      }),
      onQueryStarted(queryArgs, mutationLifecycleApi) {
        // get all cached queries sourcingVehicleList and change values comment, purchasePrice and sellingPrice
        sourcingRtkApi.util
          .selectInvalidatedBy(mutationLifecycleApi.getState(), [
            {type: 'SourcingAllListsWithSameResponseStructure'},
          ])
          .forEach(({endpointName, originalArgs}) => {
            if (!isSourcingListEndpointName(endpointName)) {
              throw new Error(`Invalid endpoint name (${endpointName}).`);
            }

            mutationLifecycleApi.dispatch(
              sourcingRtkApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
                setUserVehicleSummary(queryArgs.sourcingVehicleId, queryArgs.body, draft);
              })
            );
          });
        // update also vehicle detail
        const queriesOriginalArgs = getSourcingVehicleDetailQueriesOriginalArgs(
          queryArgs.sourcingVehicleId,
          mutationLifecycleApi
        );
        queriesOriginalArgs.forEach((originalArgs) => {
          const patchResult = mutationLifecycleApi.dispatch(
            sourcingRtkApi.util.updateQueryData('sourcingVehicleDetail', originalArgs, (draft) => ({
              ...draft,
              vehicleSummary: queryArgs.body,
            }))
          );
          mutationLifecycleApi.queryFulfilled.catch(patchResult.undo);
        });
      },
    }),
    sourcingGetSalesClaim: build.query<SalesClaim, {adId: string}>({
      query: ({adId}) => ({
        url: `/dms/v1/sourcing-vehicle/${adId}/sales-claim`,
      }),
    }),
    sourcingSetSalesClaim: build.mutation<SalesClaim, {adId: string; note: string | null}>({
      query: ({adId, note}) => ({
        url: `/dms/v1/sourcing-vehicle/${adId}/sales-claim`,
        method: 'PUT',
        body: {note},
      }),
      async onQueryStarted({adId}, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(sourcingRtkApi.util.upsertQueryData('sourcingGetSalesClaim', {adId}, data));
        } catch {}
      },
    }),
    sourcingSetFeatureAsHighlighted: build.mutation<void, {externalId: string; featureKey: string}>(
      {
        query: ({externalId, featureKey}) => ({
          url: `/dms/v1/sourcing-vehicle/${externalId}/feature/${featureKey}`,
          method: 'PUT',
        }),
        onQueryStarted: (queryArgs, mutationLifecycleApi) => {
          updateSourcingVehicleDetailWithOriginalArgs(queryArgs, mutationLifecycleApi, (draft) => {
            draft.features[queryArgs.featureKey].isHighlight = true;
          });
        },
      }
    ),
    sourcingSetFeatureAsNotHighlighted: build.mutation<
      void,
      {externalId: string; featureKey: string}
    >({
      query: ({externalId, featureKey}) => ({
        url: `/dms/v1/sourcing-vehicle/${externalId}/feature/${featureKey}`,
        method: 'DELETE',
      }),
      onQueryStarted: (queryArgs, mutationLifecycleApi) => {
        updateSourcingVehicleDetailWithOriginalArgs(queryArgs, mutationLifecycleApi, (draft) => {
          draft.features[queryArgs.featureKey].isHighlight = false;
        });
      },
    }),
    sourcingAddVehicleHighlightBulk: build.mutation<
      {highlights: HighlightResponseBody[]},
      {externalId: string; highlights: string[]}
    >({
      query: ({externalId, highlights}) => ({
        url: `/dms/v1/sourcing-vehicle/${externalId}/highlight/_bulk`,
        method: 'PUT',
        body: {highlights: highlights.map((highlight) => ({text: highlight}))},
      }),
      onQueryStarted: async (queryArgs, mutationLifecycleApi) => {
        const {data} = await mutationLifecycleApi.queryFulfilled;
        updateSourcingVehicleDetailWithOriginalArgs(queryArgs, mutationLifecycleApi, (draft) => {
          draft.highlights = data.highlights;
        });
      },
    }),
    sourcingRemoveVehicleHighlight: build.mutation<void, {externalId: string; highlightId: string}>(
      {
        query: ({externalId, highlightId}) => ({
          url: `/dms/v1/sourcing-vehicle/${externalId}/highlight/${highlightId}`,
          method: 'DELETE',
        }),
        onQueryStarted: (queryArgs, mutationLifecycleApi) => {
          updateSourcingVehicleDetailWithOriginalArgs(queryArgs, mutationLifecycleApi, (draft) => {
            draft.highlights = draft.highlights.filter(
              (highlight) => highlight.id !== queryArgs.highlightId
            );
          });
        },
      }
    ),
    sourcingRemoveVehicleHighlightBulk: build.mutation<
      RemoveHighlightBulkApiResponse,
      RemoveHighlightBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/${queryArg.externalId}/highlight/bulk-delete`,
        method: 'PUT',
        body: queryArg.removeHighlightBulkRequestBody,
      }),
      onQueryStarted: (queryArgs, mutationLifecycleApi) => {
        updateSourcingVehicleDetailWithOriginalArgs(queryArgs, mutationLifecycleApi, (draft) => {
          draft.highlights = draft.highlights.filter(
            (highlight) =>
              !queryArgs.removeHighlightBulkRequestBody.highlightIds.includes(highlight.id)
          );
        });
      },
    }),
    sourcingAddVehicleShortcomingBulk: build.mutation<
      {shortcomings: HighlightResponseBody[]},
      {externalId: string; shortcomings: string[]}
    >({
      query: ({externalId, shortcomings}) => ({
        url: `/dms/v1/sourcing-vehicle/${externalId}/shortcoming/_bulk`,
        method: 'PUT',
        body: {shortcomings: shortcomings.map((shortcoming) => ({text: shortcoming}))},
      }),
      onQueryStarted: async (queryArgs, mutationLifecycleApi) => {
        const {data} = await mutationLifecycleApi.queryFulfilled;
        updateSourcingVehicleDetailWithOriginalArgs(queryArgs, mutationLifecycleApi, (draft) => {
          draft.shortcomings = data.shortcomings;
        });
      },
    }),
    sourcingRemoveVehicleShortcoming: build.mutation<
      void,
      {externalId: string; shortcomingId: string}
    >({
      query: ({externalId, shortcomingId}) => ({
        url: `/dms/v1/sourcing-vehicle/${externalId}/shortcoming/${shortcomingId}`,
        method: 'DELETE',
      }),
      onQueryStarted: (queryArgs, mutationLifecycleApi) => {
        updateSourcingVehicleDetailWithOriginalArgs(queryArgs, mutationLifecycleApi, (draft) => {
          draft.shortcomings = draft.shortcomings.filter(
            (shortcoming) => shortcoming.id !== queryArgs.shortcomingId
          );
        });
      },
    }),
    sourcingRemoveVehicleShortcomingBulk: build.mutation<
      RemoveShortcomingBulkApiResponse,
      RemoveShortcomingBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/${queryArg.externalId}/shortcoming/bulk-delete`,
        method: 'PUT',
        body: queryArg.removeShortcomingBulkRequestBody,
      }),
      onQueryStarted: (queryArgs, mutationLifecycleApi) => {
        updateSourcingVehicleDetailWithOriginalArgs(queryArgs, mutationLifecycleApi, (draft) => {
          draft.shortcomings = draft.shortcomings.filter(
            (shortcoming) =>
              !queryArgs.removeShortcomingBulkRequestBody.shortcomingIds.includes(shortcoming.id)
          );
        });
      },
    }),
    sourcingGetPriceMapVehicles: build.query<
      Record<string, VehicleTypeForPriceMap>,
      SimilarVehicleRequestBody
    >({
      query: (queryArg) => ({
        url: '/dms/v1/sourcing/similar-vehicle/price-map',
        method: 'GET',
        params: queryArg,
      }),
      transformResponse: (response: SimilarVehicleList) =>
        reduceArrayToObject<VehicleTypeForPriceMap>(
          //@ts-expect-error Unable to fix reduceArrayToObject types
          Object.entries(response).reduce(
            //@ts-expect-error Unable to fix reduce types
            (prev, [type, value]) => [
              ...prev,
              ...value.map((vehicle) => ({
                ...vehicle,
                type,
                price: vehicle.sourcingVehicle?.price?.withVat,
                mileage: vehicle.sourcingVehicle?.mileage ?? 0,
              })),
            ],
            []
          ),
          ['sourcingVehicle', 'adId']
        ) as Record<string, VehicleTypeForPriceMap>,
      providesTags: [{type: 'SourcingPriceMapVehicles'}],
    }),
    sourcingGetPriceMapStatistics: build.query<
      Record<string, PriceMapStatistics>,
      SimilarVehicleRequestBody
    >({
      query: (queryArg) => ({
        url: '/dms/v1/sourcing/similar-vehicle/statistics',
        method: 'GET',
        params: queryArg,
      }),
      transformResponse: (response: SimilarVehicleStatistics) => {
        const stats = response.groups;
        return reduceArrayToObject(
          stats.map((stat) => ({
            ...stat,
            key: renameStatisticsKeyToString(stat.key),
          })),
          ['key']
        ) as Record<string, PriceMapStatistics>;
      },
    }),
    sourcingDrillDown: build.query<DrillDownResponse, DrillDownApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sourcing/drill-down`,
        method: 'POST',
        body: queryArg,
      }),
      // TODO T20-30972 - fix response on BE
      transformResponse: (response: DrillDownResponse, meta, arg: DrillDownApiArg) =>
        fixAlfaBugsInDrillDownResponse(
          arg.levels.map((level) => level.level),
          response
        ),
    }),
    sourcingGetSourcingServers: build.query<SourcingServer[], {countryCode: string}>({
      query: ({countryCode}) => ({
        url: '/common/v1/sourcing-servers',
        query: {
          countryCode,
        },
      }),
    }),
    sourcingPushVehiclesToSale: build.mutation<void, PushVehiclesToSaleApiArg>({
      query: ({externalIds}) => ({
        url: '/dms/v1/sourcing-vehicle/user-to-buy/buy-vehicles',
        method: 'POST',
        body: {sourcingVehiclesToBuy: externalIds},
      }),
      onQueryStarted: ({externalIds}, {dispatch, queryFulfilled}) => {
        queryFulfilled
          .then(() => {
            dispatch(
              sourcingRtkApi.endpoints.sourcingBulkDeleteUserToBuy.initiate({body: {externalIds}})
            );
          })
          .catch(() => {
            // We handle failure on component level – here we just skip initiating another mutation
          });
      },
    }),
    sourcingExportVehiclesToCsv: build.mutation<unknown, ExportVehiclesToCsvApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing-vehicle/user-to-buy/export/csv`,
        method: 'POST',
        body: {externalIds: queryArg.externalIds},
        responseHandler: 'text',
        cache: 'no-cache',
        params: {
          buyerCountry: queryArg.buyerCountry,
          currency: queryArg.currency,
          margin: queryArg.margin,
          orderBy: queryArg.orderBy,
        },
      }),
    }),
    sourcingGetComments: build.query<SourcingGetCommentsApiResponse, SourcingGetCommentsApiArg>({
      query: ({externalId}) => ({
        url: `/dms/v1/sourcing/comment/${externalId}`,
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'SourcingComments', id: queryArg.externalId},
      ],
    }),
    sourcingCreateComment: build.mutation<
      SourcingCreateCommentApiResponse,
      SourcingCreateCommentApiArg
    >({
      query: ({externalId, body}) => ({
        url: `/dms/v1/sourcing/comment/${externalId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SourcingComments', id: queryArg.externalId},
      ],
    }),
    sourcingDeleteComment: build.mutation<void, SourcingDeleteCommentApiArg>({
      query: ({externalId, uuid}) => ({
        url: `/dms/v1/sourcing/comment/${externalId}/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SourcingComments', id: queryArg.externalId},
      ],
    }),
    sourcingUpdateComment: build.mutation<
      SourcingUpdateCommentApiResponse,
      SourcingUpdateCommentApiArg
    >({
      query: ({externalId, uuid, body}) => ({
        url: `/dms/v1/sourcing/comment/${externalId}/${uuid}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'SourcingComments', id: queryArg.externalId},
      ],
    }),
    sourcingGetUserCostVehicleNote: build.query<
      DetailUserCostVehicleNoteApiResponse,
      DetailUserCostVehicleNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/cost/vehicle/${queryArg.sourcingVehicleId}/note`,
      }),
    }),
    sourcingSetUserVehicleCostNote: build.mutation<void, SetUserVehicleCostNoteApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/cost/vehicle/${queryArg.sourcingVehicleId}/note`,
        method: 'PUT',
        body: queryArg.updateCostVehicleNoteRequestBody,
      }),
    }),
    sourcingGetDefaultUserPresetCost: build.query<DetailDefaultUserPresetCostApiResponse, void>({
      query: () => ({
        url: `/dms/v1/sourcing/cost/preset/_default`,
      }),
    }),
    sourcingCreateUserVehicleCost: build.mutation<void, CreateUserVehicleCostApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/cost/vehicle/${queryArg.sourcingVehicleId}/item`,
        method: 'POST',
        body: queryArg.userVehicleCostRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {
          type: 'SourcingVehicleCosts',
          id: queryArg.sourcingVehicleId,
        },
      ],
    }),
    sourcingCreateUserVehicleCosts: build.mutation<void, CreateUserVehicleCostsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/cost/vehicle/${queryArg.sourcingVehicleId}`,
        method: 'POST',
        body: queryArg.userVehicleCostsRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {
          type: 'SourcingVehicleCosts',
          id: queryArg.sourcingVehicleId,
        },
      ],
    }),
    sourcingGetUserCostVehicleList: build.query<
      UserCostVehicleListApiResponse,
      UserCostVehicleListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/cost/vehicle/${queryArg.sourcingVehicleId}`,
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'SourcingVehicleCosts', id: queryArg.sourcingVehicleId},
      ],
    }),
    sourcingDeleteUserVehicleCost: build.mutation<void, DeleteUserVehicleCostApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/cost/vehicle/${queryArg.sourcingVehicleId}/item/${queryArg.itemUuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {
          type: 'SourcingVehicleCosts',
          id: queryArg.sourcingVehicleId,
        },
      ],
    }),
    sourcingUpdateUserVehicleCost: build.mutation<void, UpdateUserVehicleCostApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/sourcing/cost/vehicle/${queryArg.sourcingVehicleId}/item/${queryArg.itemUuid}`,
        method: 'PUT',
        body: queryArg.userVehicleCostRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {
          type: 'SourcingVehicleCosts',
          id: queryArg.sourcingVehicleId,
        },
      ],
    }),
  }),
  overrideExisting: false,
});

export {sourcingRtkApi as sourcingApi};

export const {
  useLazyExportAllUserToBuyToCsvQuery: useSourcingExportAllUserToBuyToCsvMutation,
  useSourcingCreatePresetMutation,
  useSourcingGetPresetsListQuery,
  useSourcingUpdatePresetMutation,
  useSourcingDeletePresetMutation,
  useSourcingGetCarFeaturesQuery,
  useLazySourcingGetCarFeaturesQuery,
  useLazySourcingGetPresetQuery,
  useSourcingReorderPresetsMutation,
  useSourcingGetTopSellingQuery,
  useSourcingGetPriceReportMutation,
  useSourcingVehicleDetailQuery,
  useSourcingVehicleListQuery,
  useSourcingGetVehicleCountQuery,
  useSourcingAllowedCountryAndCurrencyQuery,
  useSourcingListUserFavouriteQuery,
  useSourcingListUserToBuyQuery,
  useSourcingListUserHiddenQuery,
  useSourcingListUserComparisonQuery,
  useSourcingBulkCreateUserFavouriteMutation,
  useSourcingBulkDeleteUserFavouriteMutation,
  useSourcingReorderUserComparisonMutation,
  useSourcingBulkCreateUserComparisonMutation,
  useSourcingBulkDeleteUserComparisonMutation,
  useSourcingBulkCreateUserHiddenMutation,
  useSourcingBulkDeleteUserHiddenMutation,
  useSourcingBulkCreateUserToBuyMutation,
  useSourcingBulkDeleteUserToBuyMutation,
  useSourcingCreateUserVehicleSummaryMutation,
  useSourcingGetSalesClaimQuery,
  useSourcingSetSalesClaimMutation,
  useSourcingSetFeatureAsHighlightedMutation,
  useSourcingSetFeatureAsNotHighlightedMutation,
  useSourcingAddVehicleHighlightBulkMutation,
  useSourcingRemoveVehicleHighlightMutation,
  useSourcingRemoveVehicleHighlightBulkMutation,
  useSourcingAddVehicleShortcomingBulkMutation,
  useSourcingRemoveVehicleShortcomingMutation,
  useSourcingRemoveVehicleShortcomingBulkMutation,
  useSourcingGetPriceMapVehiclesQuery,
  useSourcingGetPriceMapStatisticsQuery,
  useSourcingDrillDownQuery,
  useSourcingGetSourcingServersQuery,
  useSourcingPushVehiclesToSaleMutation,
  useSourcingExportVehiclesToCsvMutation,
  useSourcingGetCommentsQuery,
  useSourcingCreateCommentMutation,
  useSourcingDeleteCommentMutation,
  useSourcingUpdateCommentMutation,
  useSourcingGetUserCostVehicleNoteQuery,
  useSourcingSetUserVehicleCostNoteMutation,
  useSourcingGetDefaultUserPresetCostQuery,
  useSourcingCreateUserVehicleCostMutation,
  useSourcingCreateUserVehicleCostsMutation,
  useSourcingGetUserCostVehicleListQuery,
  useSourcingDeleteUserVehicleCostMutation,
  useSourcingUpdateUserVehicleCostMutation,
} = sourcingRtkApi;

function getSourcingVehicleDetailQueriesOriginalArgs<T>(
  externalId: string,
  mutationLifecycleApi: OmneticMutationLifecycleApi<T>
) {
  return sourcingRtkApi.util
    .selectInvalidatedBy(mutationLifecycleApi.getState(), [{type: 'SourcingVehicleDetail'}])
    .filter(({endpointName, originalArgs}) => {
      if (endpointName !== 'sourcingVehicleDetail') {
        throw new Error(`Invalid endpoint name (${endpointName}).`);
      }
      return externalId === originalArgs.externalId;
    })
    .map((item) => item.originalArgs);
}

function updateSourcingVehicleDetailWithOriginalArgs<T extends {externalId: string}>(
  queryArgs: T,
  mutationLifecycleApi: OmneticMutationLifecycleApi<T>,
  updateFn: (draft: SourcingVehicleDetailApiResponse) => void
) {
  const queriesOriginalArgs = getSourcingVehicleDetailQueriesOriginalArgs(
    queryArgs.externalId,
    mutationLifecycleApi
  );
  queriesOriginalArgs.forEach((originalArgs) => {
    const patchResult = mutationLifecycleApi.dispatch(
      sourcingRtkApi.util.updateQueryData('sourcingVehicleDetail', originalArgs, updateFn)
    );
    mutationLifecycleApi.queryFulfilled.catch(patchResult.undo);
  });
}

// Gets affected vehicles details (those queries which provide `SourcingVehicleDetail` tag) and set their list assignment
function optimisticUpdateVehicleInList<T extends {body: {externalIds: string[]}}>(
  queryArgs: T,
  mutationLifecycleApi: OmneticMutationLifecycleApi<T>,
  list: List,
  isInList: boolean
) {
  sourcingRtkApi.util
    .selectInvalidatedBy(mutationLifecycleApi.getState(), [{type: 'SourcingVehicleDetail'}])
    .forEach(({endpointName, originalArgs}) => {
      if (endpointName !== 'sourcingVehicleDetail') {
        throw new Error(`Invalid endpoint name (${endpointName}).`);
      }

      if (queryArgs.body.externalIds.includes(originalArgs.externalId)) {
        const patchResult = mutationLifecycleApi.dispatch(
          sourcingRtkApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
            draft[list] = isInList;
          })
        );
        mutationLifecycleApi.queryFulfilled.catch(patchResult.undo);
      }
    });
}

// Gets all cached queries sourcingVehicleList and set sourcing vehicles list assignment
function optimisticUpdateVehicleLists<T extends {body: {externalIds: string[]}}>(
  queryArgs: T,
  mutationLifecycleApi: OmneticMutationLifecycleApi<T>,
  list: List,
  isInList: boolean
) {
  sourcingRtkApi.util
    .selectInvalidatedBy(mutationLifecycleApi.getState(), [
      {type: 'SourcingAllListsWithSameResponseStructure'},
    ])
    .forEach(({endpointName, originalArgs}) => {
      if (!isSourcingListEndpointName(endpointName)) {
        throw new Error(`Invalid endpoint name (${endpointName}).`);
      }

      const patchResult = mutationLifecycleApi.dispatch(
        sourcingRtkApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
          draft.data.forEach((vehicle) => {
            if (queryArgs.body.externalIds.includes(vehicle.sourcingVehicle.adId)) {
              vehicle[list] = isInList;
            }
          });
        })
      );
      mutationLifecycleApi.queryFulfilled.catch(patchResult.undo);
    });
}

function optimisticRemoveVehiclesFromList<T extends {body: {externalIds: string[]}}>(
  queryArgs: T,
  mutationLifecycleApi: OmneticMutationLifecycleApi<T>,
  listToRemoveEndpointName?: string
) {
  sourcingRtkApi.util
    .selectInvalidatedBy(mutationLifecycleApi.getState(), [
      {type: 'SourcingAllListsWithSameResponseStructure'},
    ])
    .forEach(({endpointName, originalArgs}) => {
      if (!isSourcingListEndpointName(endpointName)) {
        throw new Error(`Invalid endpoint name (${endpointName}).`);
      }

      if (isNil(listToRemoveEndpointName) || endpointName === listToRemoveEndpointName) {
        const patchResult = mutationLifecycleApi.dispatch(
          sourcingRtkApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
            draft.data = draft.data.filter(
              (vehicle) => !queryArgs.body.externalIds.includes(vehicle.sourcingVehicle.adId)
            );
          })
        );
        mutationLifecycleApi.queryFulfilled.catch(patchResult.undo);
      }
    });
}
