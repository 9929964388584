import {Show, HStack, Box, getValueByDevice, useDevice} from 'platform/foundation';

import {useState} from 'react';

import {ActivitySidebarItem} from './components/ActivitySidebarItem';
import {ActivitySidebarMenu} from './components/ActivitySidebarMenu';
import {ActivitySidebarAction} from './types/ActivitySidebarAction';

interface ActivitySidebarProps {
  actions: ActivitySidebarAction[];
}

export function ActivitySidebar(props: ActivitySidebarProps) {
  const [openTab, setOpenTab] = useState<string | null>(null);
  const device = useDevice();

  const handleCloseTab = () => setOpenTab(null);

  const handleToggleTab = (tab: string) => {
    setOpenTab(openTab === tab ? null : tab);
  };

  const activeAction = props.actions.find((action) => action.label === openTab);

  return (
    <HStack spacing={0}>
      <Show when={openTab}>
        <Box
          position={getValueByDevice(device, 'absolute', 'absolute', 'absolute', 'initial')}
          height="100%"
          right={12}
        >
          <ActivitySidebarItem title={activeAction?.label ?? ''} onClose={handleCloseTab}>
            {activeAction?.component ?? null}
          </ActivitySidebarItem>
        </Box>
      </Show>
      <ActivitySidebarMenu actions={props.actions} onClick={handleToggleTab} activeTab={openTab} />
    </HStack>
  );
}
