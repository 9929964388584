import {Action, FlagProps, Parameter, showNotification} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {always, defaultTo, isEmpty, isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useGetWarehouseHeaderDynamicActionsQuery,
  usePutWarehouseHeaderDynamicActionsMutation,
} from '@dms/api/metadaWarehouseHeaderDynamicActions';
import {useGetReceiveNoteQuery} from '@dms/api/metadaWarehouseReceiveNote';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetUserQuery} from '@dms/api/user';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {
  DetailTemplate,
  DetailTemplateHeader,
  getUserName,
  handleApiError,
  NavigationItem,
} from '@dms/shared';

import {
  buildArray,
  composePath,
  EMPTY_PLACEHOLDER,
  generateHashFromObjects,
  parseDate,
  useNavigate,
} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {createHeaderActions} from '../../utils/createHeaderActions';
import {Documents} from './(sections)/Documents/Documents';
import {Overview} from './(sections)/Overview/Overview';

export function ReceiveNoteDetail() {
  const navigate = useNavigate();

  const {receiveNoteId} = useWarehouseParams();

  const dateTimeFormatter = useDateTimeFormatter();

  const {
    data: receiveNote,
    isLoading: isReceiveNoteLoading,
    isError: hasReceiveNoteError,
  } = useGetReceiveNoteQuery({receiveNoteId}, {skip: isNilOrEmpty(receiveNoteId)});

  const {data: createdByUser, isLoading: isCreatedByUserLoading} = useGetUserQuery(
    {id: receiveNote?.createdBy as string},
    {skip: isNilOrEmpty(receiveNote?.createdBy)}
  );

  const {data: updatedByUser, isLoading: isUpdatedByUserLoading} = useGetUserQuery(
    {id: receiveNote?.updatedBy as string},
    {skip: isNilOrEmpty(receiveNote?.updatedBy)}
  );

  const {
    data: receiveNoteAction,
    isFetching: isReceiveNoteActionFetching,
    isError: hasReceiveNoteActionError,
  } = useGetWarehouseHeaderDynamicActionsQuery(
    {id: receiveNoteId, resource: 'receive-note'},
    {skip: isNilOrEmpty(receiveNoteId)}
  );

  const [putReceiveNoteAction, {isLoading: isReceiveNoteActionLoading}] =
    usePutWarehouseHeaderDynamicActionsMutation();

  const handleDiscard = () => {
    navigate(warehouseRoutes.receiveNoteList);
  };

  const handleActionClick = (actionKey: string) => {
    const navigatePaths: Record<string, string | undefined> = {
      corrective: composePath(warehouseRoutes.receiveNoteCorrectionCreate, {
        params: {receiveNoteId},
        isSearchParamsPreserved: false,
      }),
      complete: warehouseRoutes.receiveNoteList,
      delete: warehouseRoutes.receiveNoteList,
    };

    const handleSuccess = () => {
      showNotification.success();

      const path = navigatePaths[actionKey];
      if (path) {
        navigate(path);
      }
    };

    if (actionKey === 'corrective') {
      handleSuccess();
      return;
    }

    putReceiveNoteAction({
      id: receiveNoteId,
      resource: 'receive-note',
      body: {actionKey},
    })
      .unwrap()
      .then(() => handleSuccess())
      .catch(handleApiError);
  };

  const headerActions = createHeaderActions({
    actions: receiveNoteAction?.actions,
    callback: handleActionClick,
    isLoading: isReceiveNoteActionLoading,
  });

  const formatToShortDate = (date?: string) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return dateTimeFormatter('dateShort', parseDate(date));
  };

  const isLoading =
    isReceiveNoteLoading ||
    isCreatedByUserLoading ||
    isReceiveNoteActionFetching ||
    isUpdatedByUserLoading;

  const hasError = hasReceiveNoteError || hasReceiveNoteActionError;

  const isDraftReceiveNote = isNil(receiveNote);
  const isCompletedReceiveNote = receiveNote?.state === 'COMPLETED';

  const createdBy = `${i18n.t('general.labels.createdBy')}: ${getUserName(createdByUser) ?? EMPTY_PLACEHOLDER}`;
  const createdAt = `${i18n.t('general.labels.createdAt')}: ${formatToShortDate(receiveNote?.created)}`;

  const updatedBy = `${i18n.t('general.labels.updatedBy')}: ${getUserName(updatedByUser) ?? EMPTY_PLACEHOLDER}`;
  const updatedAt = `${i18n.t('general.labels.updatedAt')}: ${formatToShortDate(receiveNote?.updated)}`;

  const completedBy = `${i18n.t('general.labels.completedBy')}: ${isCompletedReceiveNote ? getUserName(updatedByUser) : EMPTY_PLACEHOLDER}`;
  const completedAt = `${i18n.t('general.labels.completedAt')}: ${isCompletedReceiveNote ? formatToShortDate(receiveNote?.updated) : EMPTY_PLACEHOLDER}`;

  const headerTitle = isEmpty(receiveNoteId)
    ? i18n.t('entity.warehouse.labels.newReceiveNote')
    : `${i18n.t('entity.warehouse.labels.receiveNote')} ${defaultTo('', receiveNote?.receiveNoteNumber)}`;

  const headerParameters = match(isDraftReceiveNote)
    .returnType<Parameter[]>()
    .with(true, always([]))
    .otherwise(always([createdBy, createdAt, updatedBy, updatedAt, completedBy, completedAt]));

  const headerFlags = match(receiveNote?.state)
    .returnType<FlagProps[]>()
    .with('PENDING', () => [{colorScheme: 'blue', label: i18n.t('general.labels.pending')}])
    .with('COMPLETED', () => [{colorScheme: 'green', label: i18n.t('general.labels.completed')}])
    .otherwise(() => [{colorScheme: 'neutral', label: i18n.t('entity.warehouse.labels.draft')}]);

  const staticActions = buildArray<Action>().when(isDraftReceiveNote, {
    type: 'button',
    onClick: handleDiscard,
    title: i18n.t('general.actions.discard'),
    variant: 'secondary',
    'data-testid': testIds.warehouse.receiveNoteDetailOverview('headerActions.discard'),
  });

  const header: DetailTemplateHeader = {
    title: headerTitle,
    icon: 'action/deployed',
    parameters: headerParameters,
    recordId: receiveNote?.receiveNoteId,
    resourceId: EntityResourceIds.receiveNote,
    flags: headerFlags,
    actions: [...headerActions, ...staticActions],
    controls: ['ASSIGNEE'],
  };

  const receiveNoteDetailHref = isNotNilOrEmpty(receiveNoteId)
    ? composePath(warehouseRoutes.receiveNoteDetailOverview, {
        params: {receiveNoteId},
      })
    : warehouseRoutes.receiveNoteCreate;

  const navigation = buildArray<NavigationItem>([])
    .add({
      id: 'receiveNoteDetail',
      label: i18n.t('page.warehouse.labels.overview'),
      href: receiveNoteDetailHref,
      content: (
        <Overview
          receiveNote={receiveNote}
          isReceiveNoteLoading={isReceiveNoteLoading}
          hasReceiveNoteError={hasReceiveNoteError}
          data-testid={testIds.warehouse.receiveNoteDetailOverview('sections.overview')}
        />
      ),
      'data-testid': testIds.warehouse.receiveNoteDetailOverview('navigation.overview'),
    })
    .when(isNotNilOrEmpty(receiveNoteId), {
      id: 'receiveNoteDocuments',
      label: i18n.t('page.warehouse.labels.documents'),
      href: composePath(warehouseRoutes.receiveNoteDetailDocuments, {
        params: {receiveNoteId},
      }),
      content: (
        <Documents
          data-testid={testIds.warehouse.receiveNoteDetailDocuments('sections.documents')}
        />
      ),
      'data-testid': testIds.warehouse.receiveNoteDetailDocuments('navigation.documents'),
    });

  return (
    <>
      <Helmet title={i18n.t('entity.warehouse.labels.receiveNote')} />
      <DetailTemplate
        key={generateHashFromObjects(receiveNote)}
        isLoading={isLoading}
        isError={hasError}
        isLocked={isReceiveNoteActionLoading}
        header={header}
        navigation={navigation}
        data-testid={testIds.warehouse.receiveNoteDetailOverview('page')}
      />
    </>
  );
}
