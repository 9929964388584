import {Dialog, showNotification} from 'platform/components';

import {useState} from 'react';
import {Helmet} from 'react-helmet-async';

import {head, isNil} from 'ramda';

import {usePostServiceOrderIssueNoteMutation} from '@dms/api/metadaWarehouseServiceOrderIssueNote';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, Nullish, useNavigate} from 'shared';

import {DataGrid, RowData} from 'features/datagrid';

export function ServiceOrderIssueCreate() {
  const navigate = useNavigate();

  const [selectedServiceOrder, setSelectedServiceOrder] = useState<string | Nullish>(null);

  const [postServiceOrderIssueNote, {isLoading: isCreatingServiceOrderIssue}] =
    usePostServiceOrderIssueNoteMutation();

  const handleCreateServiceOrderIssueNote = () => {
    if (isNil(selectedServiceOrder)) {
      throw new Error('Missing serviceOrderId');
    }

    postServiceOrderIssueNote({
      serviceOrderId: selectedServiceOrder,
    })
      .unwrap()
      .then((result) => {
        showNotification.success(i18n.t('entity.warehouse.notifications.serviceOrderIssueCreated'));
        navigate(
          composePath(warehouseRoutes.serviceOrderIssueDetailOverview, {
            params: {id: result.id},
          })
        );
      })
      .catch(handleApiError);
  };

  const handleOnRowSelectionChange = (rows: RowData[]) => {
    setSelectedServiceOrder(head(rows)?.id);
  };

  const handleDiscard = () => {
    navigate(warehouseRoutes.serviceOrderIssues);
  };

  return (
    <>
      <Helmet title={i18n.t('page.warehouse.labels.newServiceOrderIssue')} />
      <Dialog
        isOpen
        title={i18n.t('page.warehouse.labels.newServiceOrderIssue')}
        size="large"
        onClose={handleDiscard}
        data-testid={testIds.warehouse.serviceOrderIssueCreate('assignDialog')}
        buttons={[
          {
            type: 'button',
            variant: 'secondary',
            title: i18n.t('general.labels.discard'),
            onClick: handleDiscard,
            isLoading: isCreatingServiceOrderIssue,
          },
          {
            type: 'button',
            title: i18n.t('general.labels.assign'),
            onClick: handleCreateServiceOrderIssueNote,
            isDisabled: isNil(selectedServiceOrder),
            isLoading: isCreatingServiceOrderIssue,
            'data-testid': testIds.warehouse.serviceOrderIssueCreate('assign'),
          },
        ]}
      >
        <DataGrid
          gridCode="issue-note-service-orders"
          onRowSelectionChange={handleOnRowSelectionChange}
          autoHeight
          data-testid={testIds.warehouse.serviceOrderIssueCreate('service-orders')}
        />
      </Dialog>
    </>
  );
}
