import {diff} from 'deep-object-diff';
import {
  Button,
  ButtonGroup,
  DataStatus,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Segment,
  Separator,
  closeCurrentDialog,
  closeDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {Box, Grid, GridItem, HStack, VStack} from 'platform/foundation';

import {useEffect, useRef, useState} from 'react';
import {DeepPartial, UseFormReturn} from 'react-hook-form';

import {
  assocPath,
  clone,
  concat,
  defaultTo,
  flip,
  head,
  isEmpty,
  isNil,
  last,
  not,
  path,
  pipe,
} from 'ramda';
import {isFunction, isNilOrEmpty, isString} from 'ramda-adjunct';

import {useGetManufacturerQuery} from '@dms/api/metadaAdminManufacturer';
import {
  PutSupplierArticlePromptValidationRequest,
  useGetArticleQuery,
  usePutSupplierArticlePromptValidationMutation,
} from '@dms/api/metadaWarehouseArticle';
import {
  useGetHandlingUnitQuery,
  useGetHandlingUnitsQuery,
} from '@dms/api/metadaWarehouseHandlingUnit';
import {useGetSuppliersQuery} from '@dms/api/metadaWarehouseSupplier';
import {
  GetSupplierOrderItemResponse,
  PatchSupplierOrderItemRequest,
  SupplierOrderItemChangedField,
  useGetSupplierOrderItemQuery,
  usePatchSupplierOrderItemMutation,
  usePutSupplierOrderItemCalculationMutation,
} from '@dms/api/metadaWarehouseSupplierOrder';
import i18n from '@dms/i18n';
import {DEFAULT_CURRENCY, handleApiError} from '@dms/shared';

import {
  ApiError,
  generateHashFromObjects,
  Nullish,
  RequiredTestIdProps,
  sanitizeObject,
  suffixTestId,
} from 'shared';

import {SupplierArticlePrompt} from '../../../../../components/SupplierArticlePrompt';
import {SUPPLIER_ARTICLE_PROMPT} from '../../../../../constants/supplierArticlePrompt';
import {filterElementsBySuffixMatch} from '../../../../../utils/filterElementsBySuffixMatch';
import {getArticleDetailCardPrices} from '../../../../../utils/getArticleDetailCardPrices';
import {objectPaths} from '../../../../../utils/objectPaths';
import {removeKeys} from '../../../../../utils/removeKeys';
import {EDIT_SUPPLIER_ORDER_ITEM_DIALOG} from '../constants/editSupplierOrderItemDialog';
import {SupplierOrderItemForm, SupplierOrderItemFormSchema} from '../types/SupplierOrderItemForm';
import {ArticleDetailCard} from './ArticleDetailCard';
import {AvailabilitySegment} from './AvailabilitySegment';
import {DeliveriesSegment} from './DeliveriesSegment';
import {OrderDetailForm} from './OrderDetailForm';
import {RequestDetailForm} from './RequestDetailForm';
import {RequestsSegment} from './RequestsSegment';
import {SupplierArticleForm} from './SupplierArticleForm';

const ABORT_ERROR = 'AbortError';

enum Segments {
  AVAILABILITY = 'availability',
  REQUESTS = 'requests',
  DELIVERIES = 'deliveries',
}

interface EditItemProps extends RequiredTestIdProps {
  supplierOrderId: string;
  supplierOrderItemId: string;
  onSubmit?: VoidFunction;
}

export function EditItem(props: EditItemProps) {
  const [activeSegment, setActiveSegment] = useState<Segments>(Segments.AVAILABILITY);

  const {
    data: supplierOrderItem,
    isLoading: isSupplierOrderItemLoading,
    isError: hasSupplierOrderItemError,
  } = useGetSupplierOrderItemQuery(
    {
      orderId: props.supplierOrderId,
      itemId: props.supplierOrderItemId,
    },
    {skip: isNilOrEmpty(props.supplierOrderItemId)}
  );

  // Fetching article separately to keep refetched data independent from supplierOrderItem
  const {
    data: article,
    isFetching: isArticleFetching,
    refetch: refreshArticle,
  } = useGetArticleQuery(
    {
      warehouseId: supplierOrderItem?.article?.warehouseId as string,
      articleId: supplierOrderItem?.article?.id as string,
    },
    {
      skip:
        isNilOrEmpty(supplierOrderItem?.article?.warehouseId) ||
        isNilOrEmpty(supplierOrderItem?.article?.id),
    }
  );

  const [putSupplierArticlePromptValidation] = usePutSupplierArticlePromptValidationMutation();

  const [recalculateSupplierOrderItemPrices] = usePutSupplierOrderItemCalculationMutation();

  const [patchSupplierOrderItem, {isLoading: isPatchSupplierOrderItemLoading}] =
    usePatchSupplierOrderItemMutation();

  const {data: suppliers, isLoading: areSuppliersLoading} = useGetSuppliersQuery();

  const {data: handlingUnits, isLoading: areHandlingUnitsLoading} = useGetHandlingUnitsQuery();

  const {data: handlingUnit} = useGetHandlingUnitQuery(
    {id: supplierOrderItem?.article?.handlingUnit as string},
    {skip: isNil(supplierOrderItem?.article?.handlingUnit)}
  );

  const {data: manufacturer} = useGetManufacturerQuery(
    {manufacturerId: supplierOrderItem?.orderDetail?.manufacturerId},
    {skip: isNil(supplierOrderItem?.orderDetail?.manufacturerId)}
  );

  const articleId = supplierOrderItem?.article?.id;

  const supplierId = supplierOrderItem?.supplierArticle?.supplierId;

  const manufacturerNumber = supplierOrderItem?.article?.manufacturerNumber;

  const currency = defaultTo(DEFAULT_CURRENCY, supplierOrderItem?.orderDetail?.currency);

  const articlePrices = getArticleDetailCardPrices(supplierOrderItem?.article);

  const defaultValues: DeepPartial<SupplierOrderItemForm> = {
    orderDetail: {
      supplierQuantity: defaultTo(1, supplierOrderItem?.orderDetail?.supplierQuantity),
      supplierUnit: supplierOrderItem?.orderDetail?.supplierUnit,
      supplierUnitPrice: defaultTo(0, supplierOrderItem?.orderDetail?.supplierUnitPrice),
      supplierTotalPrice: defaultTo(0, supplierOrderItem?.orderDetail?.supplierTotalPrice),
      name: supplierOrderItem?.orderDetail?.name,
      catalogueNumber: supplierOrderItem?.orderDetail?.catalogueNumber,
      manufacturerId: supplierOrderItem?.orderDetail?.manufacturerId,
      supplierOrderingNumber: supplierOrderItem?.orderDetail?.supplierOrderingNumber,
      vat: defaultTo('S', supplierOrderItem?.orderDetail?.vat),
      vin: supplierOrderItem?.orderDetail?.vin,
      keyCode: supplierOrderItem?.orderDetail?.keyCode,
      isIntelligentPart: supplierOrderItem?.orderDetail?.isInteligentPart ?? false,
      engineNumber: supplierOrderItem?.orderDetail?.engineNumber,
      labelInfo: supplierOrderItem?.orderDetail?.labelInfo,
      serialNumber: supplierOrderItem?.orderDetail?.serialNumber,
    },
    requestDetail: {
      warehouseRequestedQuantity: defaultTo(
        1,
        supplierOrderItem?.requestDetail?.warehouseRequestedQuantity
      ),
      warehouseUnitPurchasePrice: defaultTo(
        0,
        supplierOrderItem?.requestDetail?.warehouseUnitPurchasePrice
      ),
    },
    supplierArticle: {
      supplierQuantity: defaultTo(1, supplierOrderItem?.supplierArticle?.supplierQuantity),
      supplierUnitId: supplierOrderItem?.supplierArticle?.supplierUnitId,
      warehouseQuantity: defaultTo(1, supplierOrderItem?.supplierArticle?.warehouseQuantity),
      warehouseUnitId: supplierOrderItem?.article?.handlingUnit,
      supplierOrderingNumber: supplierOrderItem?.supplierArticle?.supplierOrderingNumber,
      supplierBulkPackageQuantity: defaultTo(
        1,
        supplierOrderItem?.supplierArticle?.supplierBulkPackageQuantity
      ),
      supplier: suppliers?.find(
        (supplier) => supplier.id === supplierOrderItem?.supplierArticle?.supplierId
      )?.name,
      isUpdateMapping: false,
    },
  };

  // Needed for proper recalculations
  const prevFormValues = useRef<DeepPartial<SupplierOrderItemForm>>(defaultValues);
  // Needed for cancelling recalculating requests from the form onChange event
  const abortControllerInstance = useRef<AbortController | Nullish>(null);

  useEffect(() => {
    if (isNil(supplierOrderItem)) {
      return;
    }

    // Set the prevFormValues, we need them for proper price calculations
    handleMapPrevFormValues(supplierOrderItem);
  }, [supplierOrderItem]);

  const handleSupplierArticlePromptValidation: FormSubmitHandler<SupplierOrderItemForm> = async (
    formValues
  ) => {
    if (isNilOrEmpty(articleId)) {
      throw new Error('Missing article id');
    }

    if (isNilOrEmpty(supplierId)) {
      throw new Error('Missing supplier id');
    }

    if (isNilOrEmpty(manufacturerNumber)) {
      throw new Error('Missing manufacturer number');
    }

    const supplierArticlePromptValidationRequestPayload: PutSupplierArticlePromptValidationRequest =
      {
        articleId: articleId as string,
        supplierId: supplierId as string,
        manufacturerNumber: manufacturerNumber as string,
        supplierOrderingNumber: formValues.supplierArticle.supplierOrderingNumber,
        supplierToWarehouseMapping: {
          supplierQuantity: formValues.supplierArticle.supplierQuantity,
          supplierUnitId: formValues.supplierArticle.supplierUnitId,
          warehouseQuantity: formValues.supplierArticle.warehouseQuantity,
          supplierBulkPackageQuantity: formValues.supplierArticle.supplierBulkPackageQuantity,
        },
      };

    await putSupplierArticlePromptValidation(supplierArticlePromptValidationRequestPayload)
      .unwrap()
      .then((result) => {
        if (not(result.isChanged)) {
          return handleSubmit(formValues);
        }

        openDialog(
          <SupplierArticlePrompt
            onDontSave={() => handleSubmit(formValues, false)}
            onSave={() => handleSubmit(formValues, true)}
            data-testid={suffixTestId('supplierArticlePrompt', props)}
          />,
          {
            id: SUPPLIER_ARTICLE_PROMPT,
            size: 'small',
            'data-testid': suffixTestId('dialogs.supplierArticlePrompt', props),
          }
        );
      })
      .catch(handleApiError);
  };

  const handleSubmit = async (formValues: SupplierOrderItemForm, shouldUpdateMapping?: boolean) => {
    if (isNilOrEmpty(articleId)) {
      throw new Error('Article id is not defined');
    }

    const patchRequest: PatchSupplierOrderItemRequest = {
      orderId: props.supplierOrderId,
      itemId: props.supplierOrderItemId,
      body: {
        ...formValues,
        article: {
          id: articleId as string,
        },
        orderDetail: {
          supplierQuantity: formValues.orderDetail.supplierQuantity,
          supplierUnit: formValues.orderDetail.supplierUnit,
          supplierUnitPrice: formValues.orderDetail.supplierUnitPrice,
          supplierTotalPrice: formValues.orderDetail.supplierTotalPrice,
          supplierOrderingNumber: formValues.orderDetail.supplierOrderingNumber,
          vat: formValues.orderDetail.vat,
          vin: formValues.orderDetail.vin,
          keyCode: formValues.orderDetail.keyCode,
          isIntelligentPart: formValues.orderDetail.isIntelligentPart,
          engineNumber: formValues.orderDetail.engineNumber,
          labelInfo: formValues.orderDetail.labelInfo,
          currency,
        },
        supplierArticle: {
          supplierQuantity: formValues.supplierArticle.supplierQuantity,
          supplierUnitId: formValues.supplierArticle.supplierUnitId,
          warehouseQuantity: formValues.supplierArticle.warehouseQuantity,
          supplierOrderingNumber: formValues.supplierArticle.supplierOrderingNumber,
          supplierBulkPackageQuantity: formValues.supplierArticle.supplierBulkPackageQuantity,
          isUpdateMapping: shouldUpdateMapping ?? false,
        },
      },
    };

    await patchSupplierOrderItem(patchRequest)
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .then(() => closeDialog(EDIT_SUPPLIER_ORDER_ITEM_DIALOG))
      .then(props.onSubmit)
      .catch(handleApiError);
  };

  const handleChange = (
    values: DeepPartial<SupplierOrderItemForm>,
    // We don't know the correct type here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: any,
    formApi: UseFormReturn<SupplierOrderItemForm>
  ) => {
    const copiedValues = JSON.parse(JSON.stringify(values)) as object;
    const {asString} = pipe(
      defaultTo({}),
      flip(diff)(sanitizeObject(copiedValues)),
      objectPaths
    )(prevFormValues.current);

    // Name of the changed field
    const changedField = last<string>(change.name?.split('.') ?? []);
    // Indicate if the changed field is allowed to trigger recalculation
    const shouldTriggerRecalculation = ALLOWED_OBJECTS_KEYS.includes(changedField);
    // Get the difference between the previous and current form values
    const difference = diff(sanitizeObject(prevFormValues.current), sanitizeObject(copiedValues));
    // Difference that contains only allowed values
    const allowedDifference = removeKeys(difference, concat(ALLOWED_OBJECTS_KEYS, ALLOWED_OBJECTS));

    const isOrderDetailDiffEmpty = allowedDifference?.orderDetail
      ? isEmpty(allowedDifference?.orderDetail)
      : false;
    const isRequestDetailDiffEmpty = allowedDifference?.requestDetail
      ? isEmpty(allowedDifference?.requestDetail)
      : false;
    const isSupplierArticleDiffEmpty = allowedDifference?.supplierArticle
      ? isEmpty(allowedDifference?.supplierArticle)
      : false;

    const isDiffEmpty =
      isOrderDetailDiffEmpty && isRequestDetailDiffEmpty && isSupplierArticleDiffEmpty;

    if (not(shouldTriggerRecalculation) || isNilOrEmpty(allowedDifference) || isDiffEmpty) {
      return;
    }

    const arrayOfChangedValues = asString.includes('.') ? asString.split('.') : asString;
    const pathToChangedValues = head(
      filterElementsBySuffixMatch(arrayOfChangedValues, ALLOWED_OBJECTS_KEYS)
    )?.split('.') as string[];
    const changedValue = path(pathToChangedValues ?? [], values);
    const newPrevFormValues = assocPath(
      pathToChangedValues ?? [],
      changedValue,
      prevFormValues.current
    );

    prevFormValues.current = newPrevFormValues;

    if (abortControllerInstance.current && isFunction(abortControllerInstance.current.abort)) {
      abortControllerInstance.current.abort();
    }

    const supplierOrderItemCalculateController = new AbortController();

    abortControllerInstance.current = supplierOrderItemCalculateController;

    recalculateSupplierOrderItemPrices({
      orderItemId: defaultTo('', props.supplierOrderItemId),
      body: {
        changedField: {field: changedField as SupplierOrderItemChangedField},
        orderDetail: {
          supplierQuantity: values.orderDetail?.supplierQuantity,
          supplierUnitPrice: values.orderDetail?.supplierUnitPrice,
        },
        requestDetail: {
          warehouseRequestedQuantity: values.requestDetail?.warehouseRequestedQuantity,
          warehouseUnitPurchasePrice: values.requestDetail?.warehouseUnitPurchasePrice,
        },
        supplierArticle: {
          supplierQuantity: values.supplierArticle?.supplierQuantity,
          warehouseQuantity: values.supplierArticle?.warehouseQuantity,
        },
      },
      signal: supplierOrderItemCalculateController.signal,
    })
      .unwrap()
      .then((response) => {
        // New values must have exactly the same structure as prevFormValues in handleMapPrevFormValues
        // for recalculations to work properly!
        const newValues = clone(
          sanitizeObject({
            orderDetail: {
              supplierQuantity: response.orderDetail.supplierQuantity,
              supplierUnit: values?.orderDetail?.supplierUnit,
              supplierUnitPrice: response.orderDetail.supplierUnitPrice,
              supplierTotalPrice: response.orderDetail.supplierTotalPrice,
              name: values?.orderDetail?.name,
              catalogueNumber: values?.orderDetail?.catalogueNumber,
              manufacturerId: values?.orderDetail?.manufacturerId,
              supplierOrderingNumber: values?.orderDetail?.supplierOrderingNumber,
              vat: defaultTo('S', values.orderDetail?.vat),
              vin: values?.orderDetail?.vin,
              keyCode: values?.orderDetail?.keyCode,
              isIntelligentPart: values?.orderDetail?.isIntelligentPart,
              engineNumber: values?.orderDetail?.engineNumber,
              labelInfo: values?.orderDetail?.labelInfo,
              serialNumber: values?.orderDetail?.serialNumber,
            },
            requestDetail: {
              warehouseRequestedQuantity: response.requestDetail.warehouseRequestedQuantity,
              warehouseUnitPurchasePrice: response.requestDetail.warehouseUnitPurchasePrice,
            },
            supplierArticle: {
              supplierQuantity: response.supplierArticle.supplierQuantity,
              supplierUnitId: values.supplierArticle?.supplierUnitId,
              warehouseQuantity: response.supplierArticle.warehouseQuantity,
              warehouseUnitId: values.supplierArticle?.warehouseUnitId,
              supplierOrderingNumber: values.supplierArticle?.supplierOrderingNumber,
              supplierBulkPackageQuantity: values.supplierArticle?.supplierBulkPackageQuantity,
              supplier: values.supplierArticle?.supplier,
              isUpdateMapping: values.supplierArticle?.isUpdateMapping ?? false,
            },
          })
        );

        prevFormValues.current = newValues;
        formApi.reset(newValues);
      })
      .catch((error: {error: Error}) => {
        // If the request is aborted, then we don't want to show error notification
        if (
          error &&
          'error' in error &&
          isString(error.error) &&
          error.error.includes(ABORT_ERROR)
        ) {
          return;
        }
        handleApiError(error as ApiError);
      });
  };

  const handleMapPrevFormValues = (data: GetSupplierOrderItemResponse | Nullish) => {
    prevFormValues.current = {
      orderDetail: {
        supplierQuantity: defaultTo(1, data?.orderDetail?.supplierQuantity),
        supplierUnit: data?.orderDetail?.supplierUnit,
        supplierUnitPrice: defaultTo(0, data?.orderDetail?.supplierUnitPrice),
        supplierTotalPrice: defaultTo(0, data?.orderDetail?.supplierTotalPrice),
        name: data?.orderDetail?.name,
        catalogueNumber: data?.orderDetail?.catalogueNumber,
        manufacturerId: data?.orderDetail?.manufacturerId,
        supplierOrderingNumber: data?.orderDetail?.supplierOrderingNumber,
        vat: defaultTo('S', data?.orderDetail?.vat),
        vin: data?.orderDetail?.vin,
        keyCode: data?.orderDetail?.keyCode,
        isIntelligentPart: data?.orderDetail?.isInteligentPart,
        labelInfo: data?.orderDetail?.labelInfo,
        serialNumber: data?.orderDetail?.serialNumber,
      },
      requestDetail: {
        warehouseRequestedQuantity: defaultTo(1, data?.requestDetail?.warehouseRequestedQuantity),
        warehouseUnitPurchasePrice: defaultTo(0, data?.requestDetail?.warehouseUnitPurchasePrice),
      },
      supplierArticle: {
        supplierQuantity: defaultTo(1, data?.supplierArticle?.supplierQuantity),
        supplierUnitId: data?.supplierArticle.supplierUnitId,
        warehouseQuantity: defaultTo(1, data?.supplierArticle?.warehouseQuantity),
        warehouseUnitId: data?.article?.handlingUnit,
        supplierOrderingNumber: data?.supplierArticle?.supplierOrderingNumber,
        supplierBulkPackageQuantity: defaultTo(
          1,
          data?.supplierArticle?.supplierBulkPackageQuantity
        ),
        supplier: suppliers?.find((supplier) => supplier.id === data?.supplierArticle?.supplierId)
          ?.name,
        isUpdateMapping: false,
      },
    };
  };

  const handleDiscard = () => {
    closeCurrentDialog();
  };

  const segments = [
    {
      value: Segments.AVAILABILITY,
      label: i18n.t('entity.warehouse.labels.availability'),
      content: (
        <AvailabilitySegment
          articleId={articleId}
          manufacturerId={supplierOrderItem?.orderDetail?.manufacturerId}
          manufacturerNumber={supplierOrderItem?.orderDetail?.catalogueNumber}
          data-testid={suffixTestId('segments.availability', props)}
        />
      ),
    },
    {
      value: Segments.REQUESTS,
      label: i18n.t('entity.warehouse.labels.requests'),
      content: (
        <RequestsSegment
          orderItemId={props.supplierOrderItemId}
          data-testid={suffixTestId('segments.requests', props)}
        />
      ),
    },
    {
      value: Segments.DELIVERIES,
      label: i18n.t('entity.warehouse.labels.deliveries'),
      content: (
        <DeliveriesSegment
          orderItemId={props.supplierOrderItemId}
          data-testid={suffixTestId('segments.deliveries', props)}
        />
      ),
    },
  ];

  const segmentContent = segments.find((segment) => segment.value === activeSegment)?.content;

  return (
    <Form<SupplierOrderItemForm>
      key={generateHashFromObjects(supplierOrderItem)}
      experimentalZodSchema={SupplierOrderItemFormSchema}
      defaultValues={defaultValues}
      onSubmit={handleSupplierArticlePromptValidation}
    >
      {(control, formApi) => {
        // For controlling recalculations of prices
        formApi.watch((data, change) => handleChange(data, change, formApi));

        return (
          <VStack spacing={4}>
            <DataStatus
              isLoading={isSupplierOrderItemLoading || areSuppliersLoading}
              isError={hasSupplierOrderItemError}
              minHeight={290}
            >
              <Grid columns={4}>
                <GridItem span={1}>
                  <FormField
                    isDisabled
                    control={control}
                    name="orderDetail.serialNumber"
                    type="text"
                    label={i18n.t('entity.warehouse.labels.serialNumber')}
                    data-testid={suffixTestId('inputs.serialNumber', props)}
                  />
                </GridItem>
              </Grid>

              <OrderDetailForm
                control={control}
                manufacturer={manufacturer?.name}
                handlingUnit={handlingUnit?.name}
                handlingUnits={handlingUnits}
                areHandlingUnitsLoading={areHandlingUnitsLoading}
                currency={currency}
                data-testid={suffixTestId('sections.orderDetail', props)}
              />

              <Separator spacing={0} />

              <RequestDetailForm
                control={control}
                handlingUnit={handlingUnit?.name}
                currency={currency}
                data-testid={suffixTestId('sections.requestDetail', props)}
              />

              <Separator spacing={0} />

              <SupplierArticleForm
                control={control}
                handlingUnit={handlingUnit?.name}
                handlingUnits={handlingUnits}
                areHandlingUnitsLoading={areHandlingUnitsLoading}
                data-testid={suffixTestId('sections.supplierArticle', props)}
              />

              <ArticleDetailCard
                articleDetail={{
                  warehouseId: article?.warehouseId,
                  articleId: article?.id,
                  name: article?.name,
                  manufacturerNumber: article?.manufacturerNumber,
                  manufacturerId: article?.manufacturerId,
                  warehouseAccountId: article?.warehouseAccount,
                  storageLocation: article?.storageLocation,
                  leafId: article?.treeFolder?.leafId,
                }}
                prices={articlePrices}
                currency={currency}
                isRefreshingArticle={isArticleFetching}
                onRefresh={refreshArticle}
                data-testid={suffixTestId('sections.articleDetailCard', props)}
              />

              <HStack>
                <Segment<Segments>
                  value={activeSegment}
                  onChange={setActiveSegment}
                  options={segments}
                />
              </HStack>

              <Box>{segmentContent}</Box>

              <DialogFooter>
                <ButtonGroup align="right">
                  <Button
                    variant="secondary"
                    title={i18n.t('general.labels.discard')}
                    onClick={handleDiscard}
                    data-testid={suffixTestId('actions.discard', props)}
                  />
                  <FormButton
                    control={control}
                    type="submit"
                    variant="primary"
                    title={i18n.t('general.labels.saveChanges')}
                    isDisabled={isPatchSupplierOrderItemLoading}
                    data-testid={suffixTestId('actions.submit', props)}
                  />
                </ButtonGroup>
              </DialogFooter>
            </DataStatus>
          </VStack>
        );
      }}
    </Form>
  );
}

const ALLOWED_OBJECTS = ['orderDetail', 'requestDetail', 'supplierArticle'];

// Nested keys from ALLOWED_OBJECTS
const ALLOWED_OBJECTS_KEYS = [
  'supplierQuantity',
  'supplierUnitPrice',
  'warehouseRequestedQuantity',
  'warehouseQuantity',
];
