import {addMonths, isWithinInterval, subMonths} from 'date-fns';
import {BigCalendarUser} from 'platform/big-calendar';

import {useMemo, useState} from 'react';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {datagridApi} from '@dms/api/datagrid';
import {useGetCurrentUserInfoQuery, useGetUsersQuery} from '@dms/api/user';
import {DataQueryOptions, useLazyDataQuery} from '@dms/shared';

import {useQueryState, parseDate, getApiDateString, useOnMount, Nullish} from 'shared';

import {getBigCalendarUser} from '../utils/getBigCalendarUser';
import {getSelectedUserIds} from '../utils/getSelectedUserIds';
import {getUserColorVariants} from '../utils/getUserColorVariants';

export function useBigCalendarControl() {
  const [selectedDate, setSelectedDate] = useQueryState(SELECTED_DATE);
  const [dueDateFrom, setDueDateFrom] = useQueryState(DUE_DATE_FROM);
  const [dueDateTo, setDueDateTo] = useQueryState(DUE_DATE_TO);

  const parsedSelectedDate = isNotNilOrEmpty(selectedDate) ? parseDate(selectedDate) : new Date();

  const [
    getLazyDataQuery,
    {data: dataQuery, isLoading: isLoadingGridQuery, isError: isGridQueryErrored},
  ] = useLazyDataQuery('task');

  const {
    data: tasks,
    isLoading: isGridLoading,
    isError: isGridErrored,
  } = datagridApi.useGetDataByDataQueryQuery(
    {
      gridCode: 'task',
      dataQueryId: dataQuery?.dataQueryId!,
      offset: 0,
      limit: 100000,
    },
    {
      skip: isNilOrEmpty(dataQuery?.dataQueryId),
      refetchOnMountOrArgChange: true,
      pollingInterval: TASK_POLLING_INTERVAL,
    }
  );

  const {data: currentUser} = useGetCurrentUserInfoQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      data: result.data ? getBigCalendarUser(result.data) : null,
    }),
  });

  const {data: usersData, isFetching: isLoadingUsers, isError: isUsersErrored} = useGetUsersQuery();
  const [selectedUsers, setSelectedUsers] = useState<BigCalendarUser[]>(
    getInitialSelectedUsers(currentUser)
  );

  const onFilterChange = (filteredUserIds: string[], date: Date) => {
    const dateFrom = subMonths(date, 3);
    const dateTo = addMonths(date, 3);

    const args: DataQueryOptions = {
      filters: {
        assignedTo: filteredUserIds,
        dueDate: {
          from: getApiDateString(dateFrom),
          to: getApiDateString(dateTo),
        },
      },
    };

    getLazyDataQuery(args);
    setDueDateFrom(getApiDateString(dateFrom));
    setDueDateTo(getApiDateString(dateTo));
    setSelectedDate(getApiDateString(date));
  };

  /**
   * Handles changes in user filter selection
   * Assigns colors to selected users and triggers the filter change callback
   * @param {BigCalendarUser[]} selectedUsers - Array of newly selected users
   */
  const onUserControlChange = (selectedUsers: BigCalendarUser[]) => {
    const selectedUsersWithColor = selectedUsers.map((user, index) => ({
      ...user,
      color: getUserColorVariants(index).USER,
    }));
    setSelectedUsers(selectedUsersWithColor);
    const userIds = getSelectedUserIds(selectedUsersWithColor);
    onFilterChange(userIds, parsedSelectedDate);
  };

  /**
   * Handles calendar date navigation
   * Updates the current date in URL query params and triggers the date change callback
   * @param {Date} date - New selected date
   */
  const onNavigate = (date: Date) => {
    if (!isNilOrEmpty(dueDateFrom) && !isNilOrEmpty(dueDateTo)) {
      const isDateInRange = isWithinInterval(parseDate(date), {
        start: parseDate(dueDateFrom),
        end: parseDate(dueDateTo),
      });

      if (isDateInRange) {
        setSelectedDate(getApiDateString(date));
        return;
      }
    }

    onFilterChange(getSelectedUserIds(selectedUsers), date);
  };

  useOnMount(() => {
    onFilterChange(getSelectedUserIds(selectedUsers), parsedSelectedDate);
  });

  const users = useMemo(
    () => usersData?.filter((user) => !user?.blocked).map((user) => getBigCalendarUser(user)),
    [usersData]
  );

  return {
    currentUser,
    users,
    selectedUsers,
    tasks,
    isLoading: isLoadingGridQuery || isGridLoading || isLoadingUsers,
    isError: isGridQueryErrored || isGridErrored || isUsersErrored,
    selectedDate: parsedSelectedDate,
    onNavigate,
    onUserControlChange,
  };
}

/** @constant {string} SELECTED_DATE - Query parameter key for storing current calendar date */
const SELECTED_DATE = 'selectedDate';

/** @constant {string} DUE_DATE_FROM - Query parameter key for storing due date from */
const DUE_DATE_FROM = 'dueDateFrom';

/** @constant {string} DUE_DATE_TO - Query parameter key for storing due date to */
const DUE_DATE_TO = 'dueDateTo';

/**
 * @constant {number} TASK_POLLING_INTERVAL - Polling interval for task updates in milliseconds
 */
const TASK_POLLING_INTERVAL = 15 * 60 * 1000;

const getInitialSelectedUsers = (currentUser?: BigCalendarUser | Nullish): BigCalendarUser[] => {
  if (!currentUser) {
    return [];
  }
  return [{...currentUser, color: getUserColorVariants(0).USER}];
};
