import {Box, HStack, Icon, Text} from 'platform/foundation';

import {SIDEBAR_MENU_ICON_SIZE} from '../constants/sidebarMenuIconSize';
import {MenuItemProps} from '../types/MenuItemProps';
import {getMenuItemDepthPadding} from '../utils/getMenuItemDepthPadding';

interface MenuItemContentProps
  extends Pick<MenuItemProps, 'depth'>,
    Pick<MenuItemProps['layoutProps'], 'title' | 'icon'> {}

export function MenuItemContent(props: MenuItemContentProps) {
  return (
    <Box overflow="hidden" paddingLeft={getMenuItemDepthPadding(props.depth)}>
      <HStack spacing={2} justify="space-between" align="center">
        <Icon size={SIDEBAR_MENU_ICON_SIZE} color="text.white" value={props.icon} />
        <Box width="100%" overflow="hidden">
          <Text size="small" color="white" noWrap>
            {props.title}
          </Text>
        </Box>
      </HStack>
    </Box>
  );
}
