import {Switch} from 'platform/components';
import {Grid, Show} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {isTrue} from 'ramda-adjunct';

import {selectCurrentUserInfo} from '@dms/api/user';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {filterLimits} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {useFilters} from '../../../FiltersContext/FiltersContext';
import {SourcingSlider} from '../SourcingSlider/SourcingSlider';

export function FilterStatistics(props: TestIdProps) {
  const {filters, onUpdateFilters} = useFilters();

  const {margin, priceCZK, daysOnDisplay, liquidity, reclaimableVat, carvagoAvailability} = filters;

  const selectedCurrency = useSelector(selectCurrentUserInfo)?.data?.settings.currency;
  const selectedCurrencySuffix = selectedCurrency ? ` (${selectedCurrency})` : null;
  return (
    <Grid columns={4} spacing={8} data-testid="block.filters-statistics">
      <SourcingSlider
        label={`${i18n.t('general.labels.priceWithVat')} ${selectedCurrencySuffix}`}
        min={0}
        max={filterLimits.PRICE_MAX}
        marks={filterLimits.PRICE_MARKS}
        hasMaxPlus
        value={priceCZK}
        onChange={(value) => onUpdateFilters(['priceCZK'], value)}
        data-testid={suffixTestId('priceWithVat', props)}
      />

      <SourcingSlider
        label={`${i18n.t('entity.vehicle.labels.margin')} ${selectedCurrencySuffix}`}
        labelTooltip={i18n.t('entity.vehicle.labels.marginTooltip')}
        step={1000}
        min={0}
        max={filterLimits.MARGIN_LIMIT_MAX}
        hasMaxPlus
        value={margin}
        onChange={(value) => onUpdateFilters(['margin'], value)}
        data-testid={suffixTestId('margin', props)}
      />

      <SourcingSlider
        label={i18n.t('entity.vehicle.labels.daysOnDisplay')}
        labelTooltip={i18n.t('entity.vehicle.labels.daysOnDisplayTooltip')}
        min={0}
        max={filterLimits.DAYS_ON_DISPLAY}
        hasMaxPlus
        value={daysOnDisplay}
        onChange={(value) => onUpdateFilters(['daysOnDisplay'], value)}
        data-testid={suffixTestId('daysOnDisplay', props)}
      />

      <SourcingSlider
        label={i18n.t('entity.vehicle.labels.liquidity')}
        labelTooltip={i18n.t('entity.vehicle.labels.liquidityTooltip')}
        step={12}
        min={0}
        max={filterLimits.LIQUIDITY_MAX}
        hasMaxPlus
        value={liquidity}
        onChange={(value) => onUpdateFilters(['liquidity'], value)}
        data-testid={suffixTestId('liquidity', props)}
      />

      <Switch
        value={isTrue(reclaimableVat)}
        onChange={(checked) => onUpdateFilters(['reclaimableVat'], checked)}
        label={i18n.t('general.labels.reclaimableVAT')}
        data-testid={suffixTestId('reclaimableVat', props)}
      />

      <Show whenFeatureEnabled={featureFlags.SOURCING_CARVAGO_AVAILABILITY}>
        <Switch
          value={isTrue(carvagoAvailability)}
          onChange={(checked) => onUpdateFilters(['carvagoAvailability'], checked)}
          label={i18n.t('entity.sourcing.filters.labels.availableOnCarvago')}
          data-testid={suffixTestId('carvagoAvailability', props)}
        />
      </Show>
    </Grid>
  );
}
