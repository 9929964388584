import {z} from 'zod';

import {
  DiscountSourceTypeSchema,
  ItemsDiscountsSchema,
  BasePriceSchema,
  PriceWithAndWithoutVatSchema,
  BaseVoidResponseSchema,
} from '@dms/api/shared';

const QuantitySchema = z.object({
  amount: z.number(),
  unit: z.string(),
});

const VATDetailsSchema = z.object({
  type: z.string(),
  freeCode: z.string().optional(),
});

const MechanicSchema = z.object({
  id: z.string(),
  name: z.string(),
  isDefault: z.boolean(),
  costCenterId: z.string(),
  amount: z.number(),
});

const MarginSchema = PriceWithAndWithoutVatSchema.merge(
  z.object({
    marginPercentage: z.number(),
    isMarginUnderMinimum: z.boolean(),
    isMarginExisting: z.boolean(),
  })
);

const AvailableDiscountSchema = z.object({
  discountSourceType: DiscountSourceTypeSchema,
  discountSourceTypeName: z.string(),
  discountRate: z.number().nullish(),
  isSet: z.boolean(),
  isChangeable: z.boolean(),
});
export type AvailableDiscount = z.infer<typeof AvailableDiscountSchema>;

export const ItemDiscountSchema = z.object({
  isDoNotApplyDiscount: z.boolean(),
  isDiscountEnable: z.boolean(),
  availableDiscounts: z.array(AvailableDiscountSchema).optional(),
});

export type ItemsDiscounts = z.infer<typeof ItemsDiscountsSchema>;

const PriceTypeSchema = z.enum([
  'SVCPRICETYPE_TIME_NORM',
  'SVCPRICETYPE_DIRECT_PRICE',
  'SVCPRICETYPE_CALCULATION_PRICE',
  'SVCPRICETYPE_COOPERATION',
  'SVCPRICETYPE_SUBCONTRACT',
]);

export type PriceType = z.infer<typeof PriceTypeSchema>;

/**
 * GET requests
 */
export const GetDirectSaleLabourItemResponseSchema = z.object({
  id: z.string(),
  labourCatalogId: z.string(),
  labourRateId: z.string(),
  priceType: PriceTypeSchema,
  name: z.string(),
  number: z.string(),
  workType: z.string(),
  quantity: QuantitySchema,
  isUnitPriceWithVat: z.boolean(),
  purchasePricePerUnit: BasePriceSchema,
  sellingPricePerUnit: BasePriceSchema,
  sellingPriceVat: VATDetailsSchema,
  isCustomPrice: z.boolean(),
  basePurchasePricePerUnit: BasePriceSchema,
  baseSellingPricePerUnit: BasePriceSchema,
  baseSellingPriceVat: VATDetailsSchema,
  assignMechanics: z.array(MechanicSchema).optional(),
  discountSource: z.string().optional(),
  itemEditingAllowed: z.boolean(),
  quantityEditingAllowed: z.boolean(),
  sellingTotalPrice: PriceWithAndWithoutVatSchema,
  purchaseTotalPrice: PriceWithAndWithoutVatSchema,
  marginTotal: MarginSchema,
  marginPerUnit: MarginSchema,
  discount: BasePriceSchema,
  itemsDiscounts: ItemDiscountSchema,
});
export type GetDirectSaleLabourItemResponse = z.infer<typeof GetDirectSaleLabourItemResponseSchema>;

export const GetDirectSaleLabourItemRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
});
export type GetDirectSaleLabourItemRequest = z.infer<typeof GetDirectSaleLabourItemRequestSchema>;

/**
 * POST requests
 */

const AssignedMechanicSchema = z.object({
  id: z.string().nullish(),
  isDefault: z.boolean(),
  costCenterId: z.string().nullish(),
});

const AssignedMechanicWithAmountSchema = AssignedMechanicSchema.extend({
  amount: z.number().nullish(),
});

const PostDirectSaleLabourItemCalculatePriceRequestBodySchema = z.object({
  quantity: z.number().nullish(),
  workType: z.string().nullish(),
  sellingPricePerUnit: z.number().nullish(),
  isCustomPrice: z.boolean().nullish(),
  vatType: z.string().nullish(),
  assignMechanics: z.array(AssignedMechanicWithAmountSchema).nullish(),
  discount: z.object({
    isDoNotApplyDiscount: z.boolean().nullish(),
    isDiscountEnable: z.boolean().nullish(),
    discountSourceType: DiscountSourceTypeSchema.nullish(),
    discountRate: z.number().nullish(),
  }),
  getCurrentPrice: z.boolean().nullish(),
});

export const PostDirectSaleLabourItemCalculatePriceRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
  body: PostDirectSaleLabourItemCalculatePriceRequestBodySchema,
});
export type PostDirectSaleLabourItemCalculatePriceRequest = z.infer<
  typeof PostDirectSaleLabourItemCalculatePriceRequestSchema
>;

export const PostDirectSaleLabourItemCalculatePriceResponseSchema = z.object({
  sellingPricePerUnit: BasePriceSchema,
  sellingTotalPrice: PriceWithAndWithoutVatSchema,
  purchaseTotalPrice: PriceWithAndWithoutVatSchema,
  marginTotal: MarginSchema,
  marginPerUnit: MarginSchema,
  discount: BasePriceSchema,
  isCustomPrice: z.boolean(),
  itemsDiscounts: ItemsDiscountsSchema,
  minimalWorkMargin: z.number().nullish(),
});
export type PostDirectSaleLabourItemCalculatePriceResponse = z.infer<
  typeof PostDirectSaleLabourItemCalculatePriceResponseSchema
>;

/**
 * PATCH requests
 */

const BaseDirectSalePriceTypeSchema = z
  .enum([
    'SVCPRICETYPE_TIME_NORM',
    'SVCPRICETYPE_DIRECT_PRICE',
    'SVCPRICETYPE_CALCULATION_PRICE',
    'SVCPRICETYPE_COOPERATION_PRICE',
  ])
  .nullable();
export type BaseDirectSalePriceType = z.infer<typeof BaseDirectSalePriceTypeSchema>;

const PatchDirectSaleLabourItemRequestBodySchema = z.object({
  basePriceType: BaseDirectSalePriceTypeSchema,
  name: z.string(),
  number: z.string(),
  workType: z.string(),
  assignMechanics: z.array(AssignedMechanicWithAmountSchema),
  quantity: z.number(),
  isUnitPriceWithVat: z.boolean(),
  purchasePricePerUnit: z.number().nullish(),
  sellingPricePerUnit: z.number(),
  vatType: z.string(),
  vatFreeCode: z.string().nullish(),
  isCustomPrice: z.boolean(),
  discount: z.object({
    isDiscountEnable: z.boolean(),
    discountSourceType: DiscountSourceTypeSchema.nullish(),
    discountRate: z.number(),
    isDoNotApplyDiscount: z.boolean(),
  }),
});

export const PatchDirectSaleLabourItemRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
  body: PatchDirectSaleLabourItemRequestBodySchema,
});
export type PatchDirectSaleLabourItemRequest = z.infer<
  typeof PatchDirectSaleLabourItemRequestSchema
>;

export const PatchDirectSaleLabourItemResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleLabourItemResponse = z.infer<
  typeof PatchDirectSaleLabourItemResponseSchema
>;
