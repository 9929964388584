import {captureException} from '@sentry/browser';

import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {
  useLazyGetAlphaCatalogueQuery,
  useLazyGetBankListQuery,
  useLazyGetCountriesQuery,
  useLazyGetCurrenciesQuery,
  useLazyGetGendersQuery,
  useLazyGetIdentityCardTypesQuery,
  useLazyGetPhoneNumbersQuery,
  useLazyGetRoundTypeQuery,
  useLazyGetVatRatesQuery,
} from '@dms/api/cached';
import {selectTenant} from '@dms/api/tenant';
import {browserStorageKey} from '@dms/config';
import {handleApiError} from '@dms/shared';

import {persistor} from '../../../store/store';

export const useUpdateApp = () => {
  const {data: tenantData} = useSelector(selectTenant);

  const [getAlphaCatalogue] = useLazyGetAlphaCatalogueQuery();
  const [getCountries] = useLazyGetCountriesQuery();
  const [getCurrencies] = useLazyGetCurrenciesQuery();
  const [getPhoneNumbers] = useLazyGetPhoneNumbersQuery();
  const [getBankList] = useLazyGetBankListQuery();
  const [getGenders] = useLazyGetGendersQuery();
  const [getIdentityCardTypes] = useLazyGetIdentityCardTypesQuery();
  const [getRoundType] = useLazyGetRoundTypeQuery();
  const [getVatRates] = useLazyGetVatRatesQuery();

  return useCallback(
    () =>
      persistor
        .purge()
        .then(() =>
          Promise.all([
            getAlphaCatalogue({
              lang: String(localStorage.getItem(browserStorageKey.LAST_KNOWN_LANGUAGE)),
            })
              .unwrap()
              .catch(handleApiError),
            getCountries().unwrap().catch(handleApiError),
            getCurrencies().unwrap().catch(handleApiError),
            getPhoneNumbers().unwrap().catch(handleApiError),
            getBankList({countryCode: tenantData?.country}).unwrap().catch(handleApiError),
            getVatRates().unwrap().catch(handleApiError),
            getGenders().unwrap().catch(handleApiError),
            getIdentityCardTypes().unwrap().catch(handleApiError),
            getRoundType().unwrap().catch(handleApiError),
          ])
            .then(() => window.location.reload())
            .catch(() => window.location.reload())
        )
        .catch(captureException),
    [
      getAlphaCatalogue,
      getBankList,
      getCountries,
      getCurrencies,
      getGenders,
      getIdentityCardTypes,
      getPhoneNumbers,
      getRoundType,
      getVatRates,
      tenantData?.country,
    ]
  );
};
