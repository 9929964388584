import {Card, Column, Label, Table, DataStatus, Tabs} from 'platform/components';
import {Heading, HStack, Icon, Space, VStack} from 'platform/foundation';

import {useMemo} from 'react';

import {isNil} from 'ramda';

import {
  useGetTemplatesQuery,
  useGetTemplateGroupsQuery,
  useGetDocumentKindsQuery,
} from '@dms/api/document';
import i18n from '@dms/i18n';
import {usePermissions} from '@dms/shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {TemplateTableRow} from './components/TemplateTableRow';
import {createDocumentTemplatesStructure} from './utils/createDocumentTemplatesStructure';
import {getDocumentKindActions} from './utils/getDocumentKindActions';

const COLUMNS: Column[] = [
  {width: '10%', element: <Label>{i18n.t('entity.document.labels.type')}</Label>},
  {width: '25%', element: <Label>{i18n.t('entity.document.labels.templateFile')}</Label>},
  {width: '20%', element: <Label>{i18n.t('entity.document.labels.templateTitle')}</Label>},
  {width: '10%', element: <Label>{i18n.t('entity.document.labels.lastUpdate')}</Label>},
  {width: '10%', element: <Label>{i18n.t('entity.document.labels.addedBy')}</Label>},
  {width: '15%', element: <Label>{i18n.t('entity.document.labels.note')}</Label>},
];

export function DocumentTemplates() {
  const {
    data: templates,
    isLoading: isLoadingTemplates,
    isError: isTemplateError,
  } = useGetTemplatesQuery({});
  const {
    data: templateGroups,
    isLoading: isLoadingTemplateGroups,
    isError: isTemplateGroupError,
  } = useGetTemplateGroupsQuery({});
  const {
    data: documentKinds,
    isLoading: isLoadingDocumentKinds,
    isError: isDocumentKindsError,
  } = useGetDocumentKindsQuery({});

  const [canReadBusinessCase] = usePermissions({
    permissionKeys: ['businessCaseRead'],
  });

  const isLoading = isLoadingTemplates || isLoadingTemplateGroups || isLoadingDocumentKinds;
  const isError = isTemplateError || isTemplateGroupError || isDocumentKindsError;

  const templatesData = useMemo(() => {
    if (isNil(templates) || isNil(documentKinds) || isNil(templateGroups)) {
      return [];
    }

    return createDocumentTemplatesStructure(
      templates,
      templateGroups,
      documentKinds,
      canReadBusinessCase
    );
  }, [documentKinds, templateGroups, templates, canReadBusinessCase]);

  return (
    <SettingsTemplate
      header={{title: i18n.t('entity.document.labels.documentTemplates')}}
      description={i18n.t('entity.document.labels.documentTemplatesDescription')}
      data-testid="documents-templates-settings-modal"
    >
      <DataStatus isLoading={isLoading} isError={isError} minHeight={180}>
        <VStack spacing={4}>
          {templatesData.map((group) => (
            <Card key={group.id} variant="inlineGrey" colorStripe={group.colorStripe}>
              <HStack spacing={2} align="center">
                <Icon value={group.icon} size={5} />
                <Heading size={4}>{group.label}</Heading>
              </HStack>

              <Space vertical={4} />

              <Tabs
                tabs={group.documentKinds.map((item) => ({
                  id: item.id,
                  title: item.title,
                  content: (
                    <VStack spacing={2}>
                      {item.documentTemplates.map((document) => (
                        <Card
                          key={document.code}
                          title={document.title}
                          variant="inlineWhite"
                          isExpandable
                          subtitle={`(${document.templates.length})`}
                          isClosedByDefault
                          actions={getDocumentKindActions(document.templates)}
                        >
                          <Space vertical={2} />

                          <Table columns={COLUMNS}>
                            {document.templates.map((template) => (
                              <TemplateTableRow key={template.id} template={template} />
                            ))}
                          </Table>
                        </Card>
                      ))}
                    </VStack>
                  ),
                }))}
              />
            </Card>
          ))}
        </VStack>
      </DataStatus>
    </SettingsTemplate>
  );
}
