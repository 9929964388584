import {Action, Card, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useState} from 'react';

import {useGetCurrenciesQuery} from '@dms/api/cached';
import {useGetWarehousesQuery} from '@dms/api/metadaWarehouse';
import {useGetSuppliersQuery} from '@dms/api/metadaWarehouseSupplier';
import {
  GetSupplierOrderResponse,
  useGetSupplierOrderTypeQuery,
} from '@dms/api/metadaWarehouseSupplierOrder';
import {useGetSupplierOrderVariantsQuery} from '@dms/api/metadaWarehouseSupplierOrderVariants';
import i18n from '@dms/i18n';
import {Section} from '@dms/shared';

import {buildArray, Nullish, suffixTestId, TestIdProps} from 'shared';

import {getWarehouseById} from '../../../../utils/getWarehouseById';
import {BasicInformationForm} from './components/BasicInformationForm';
import {BasicInformationReadOnly} from './components/BasicInformationReadOnly';
import {SupplierOrderItemList} from './components/SupplierOrderItemList';

interface OverviewProps extends TestIdProps {
  supplierOrder: GetSupplierOrderResponse | Nullish;
  isPending: boolean;
  isOrderLoading: boolean;
  hasOrderError: boolean;
}

export function Overview(props: OverviewProps) {
  const [isReadOnly, setIsReadOnly] = useState(true);

  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: hasWarehousesError,
  } = useGetWarehousesQuery();

  const {
    data: suppliers,
    isLoading: isSuppliersLoading,
    isError: hasSuppliersError,
  } = useGetSuppliersQuery();

  const {
    data: currencies,
    isLoading: isCurrenciesLoading,
    isError: hasCurrenciesError,
  } = useGetCurrenciesQuery();

  const {
    data: supplierOrderVariants,
    isLoading: isSupplierOrderVariantsLoading,
    isError: hasSupplierOrderVariantsError,
  } = useGetSupplierOrderVariantsQuery();

  const {
    data: supplierOrderTypes,
    isLoading: isSupplierOrderTypesLoading,
    isError: hasSupplierOrderTypesError,
  } = useGetSupplierOrderTypeQuery({
    supplierId: props.supplierOrder?.order.supplierId as string,
    supplierOrderVariantId: props.supplierOrder?.order.orderVariantId as string,
  });

  const isLoading =
    props.isOrderLoading ||
    isWarehousesLoading ||
    isSuppliersLoading ||
    isCurrenciesLoading ||
    isSupplierOrderVariantsLoading ||
    isSupplierOrderTypesLoading;

  const hasError =
    props.hasOrderError ||
    hasWarehousesError ||
    hasSuppliersError ||
    hasCurrenciesError ||
    hasSupplierOrderVariantsError ||
    hasSupplierOrderTypesError;

  const warehouse = getWarehouseById(warehouses, props.supplierOrder?.order?.warehouseId);

  const supplier = suppliers?.find(
    (supplier) => supplier.id === props.supplierOrder?.order?.supplierId
  );

  const supplierOrderType = supplierOrderTypes?.find(
    (supplierOrderType) => supplierOrderType.value === props.supplierOrder?.order?.type
  );

  const selectedSupplierOrderVariant = supplierOrderVariants?.find(
    (supplierOrderVariant) =>
      supplierOrderVariant.orderVariantId === props?.supplierOrder?.order?.orderVariantId
  );

  const supplierBasicInformationActions = buildArray<Action>().when(isReadOnly && props.isPending, {
    type: 'button',
    variant: 'link',
    leftIcon: 'image/edit',
    title: i18n.t('general.labels.edit'),
    onClick: () => setIsReadOnly(false),
  });

  return (
    <VStack width="100%">
      <Section>
        <VStack spacing={4}>
          <Card
            title={i18n.t('general.labels.basicInformation')}
            actions={supplierBasicInformationActions}
          >
            <DataStatus isLoading={isLoading} isError={hasError}>
              {isReadOnly ? (
                <BasicInformationReadOnly
                  supplierOrder={props?.supplierOrder?.order}
                  warehouse={warehouse}
                  supplier={supplier}
                  supplierOrderType={supplierOrderType}
                  selectedSupplierOrderVariant={selectedSupplierOrderVariant}
                  data-testid={suffixTestId('basicInformationReadOnly', props)}
                />
              ) : (
                <BasicInformationForm
                  supplierOrder={props?.supplierOrder?.order}
                  warehouses={warehouses}
                  suppliers={suppliers}
                  currencies={currencies}
                  supplierOrderTypes={supplierOrderTypes}
                  supplierOrderVariants={supplierOrderVariants}
                  onSetReadonly={(isReadOnly) => setIsReadOnly(isReadOnly)}
                  data-testid={suffixTestId('basicInformationForm', props)}
                />
              )}
            </DataStatus>
          </Card>

          <SupplierOrderItemList
            totalPrice={props.supplierOrder?.totals}
            isPending={props.isPending}
            data-testid={suffixTestId('listOfItems', props)}
          />
        </VStack>
      </Section>
    </VStack>
  );
}
