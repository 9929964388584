import {omneticApi} from '@dms/api/core';

import {
  AddFilesToSaleVehicleAlbumApiArg,
  AddFilesToSaleVehicleAlbumApiResponse,
  AddToSaleVehicleApiArg,
  AddToSaleVehicleApiResponse,
  CopySaleVehiclePhotoApiArg,
  CopySaleVehiclePhotoApiResponse,
  CreateSaleVehicleAlbumApiArg,
  CreateSaleVehicleAlbumApiResponse,
  DeleteSaleVehicleAlbumApiArg,
  DeleteSaleVehicleAlbumApiResponse,
  DeleteSaleVehiclePhotoApiArg,
  DeleteSaleVehiclePhotoApiResponse,
  DownloadSaleVehicleGalleryApiArg,
  DownloadSaleVehicleGalleryApiResponse,
  GetForSaleVehicleApiArg,
  GetForSaleVehicleApiResponse,
  GetSaleVehiclePreviewApiArg,
  GetSaleVehiclePreviewApiResponse,
  GetSaleVehicleAlbumPhotoListApiArg,
  GetSaleVehicleAlbumPhotoListApiResponse,
  ListSaleVehicleAlbumsApiArg,
  ListSaleVehicleAlbumsApiResponse,
  ListSaleVehicleAlbumsByVehicleApiArg,
  ListSaleVehicleAlbumsByVehicleApiResponse,
  RemoveInSaleVehicleApiArg,
  RemoveInSaleVehicleApiResponse,
  RemoveSaleVehiclePhotosBackgroundApiArg,
  RemoveSaleVehiclePhotosBackgroundApiResponse,
  RotateSaleVehiclePhotosApiArg,
  RotateSaleVehiclePhotosApiResponse,
  SetSaleVehicleAlbumCoverPhotoApiArg,
  SetSaleVehicleAlbumCoverPhotoApiResponse,
  UnsetSaleVehicleAlbumCoverPhotoApiArg,
  UnsetSaleVehicleAlbumCoverPhotoApiResponse,
  UpdateSaleVehiclePhotoAlbumApiArg,
  UpdateSaleVehiclePhotoAlbumApiResponse,
  GetSaleVehicleCoverPhotoApiResponse,
  GetSaleVehicleCoverPhotoApiArg,
  UpdatePositionsInSaleVehicleApiResponse,
  UpdatePositionsInSaleVehicleApiArg,
  ResetPositionsInSaleVehicleApiResponse,
  ResetPositionsInSaleVehicleApiArg,
  GetSaleVehiclePhotoListApiResponse,
  GetSaleVehiclePhotoListApiArg,
  CopyAuditPhotoToSaleVehicleAlbumApiResponse,
  CopyAuditPhotoToSaleVehicleAlbumApiArg,
  ListSaleVehiclePhotosByVehicleApiResponse,
  ListSaleVehiclePhotosByVehicleApiArg,
} from './types';

export const saleVehiclePhotoApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    listSaleVehicleAlbums: build.query<
      ListSaleVehicleAlbumsApiResponse,
      ListSaleVehicleAlbumsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/sale-vehicle-album`,
        params: {saleVehicleId: queryArg.saleVehicleId},
      }),
    }),
    createSaleVehicleAlbum: build.mutation<
      CreateSaleVehicleAlbumApiResponse,
      CreateSaleVehicleAlbumApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/sale-vehicle-album`,
        method: 'POST',
        body: queryArg.createSaleVehicleAlbumRequestBody,
      }),
      invalidatesTags: ['SaleVehicleAlbums'],
    }),
    getSaleVehicleAlbumPhotoList: build.query<
      GetSaleVehicleAlbumPhotoListApiResponse,
      GetSaleVehicleAlbumPhotoListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.saleVehicleId}/sale-vehicle-album/${queryArg.saleVehicleAlbumId}/sale-vehicle-photo`,
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehicleAlbumPhotos', id: queryArg.saleVehicleAlbumId},
      ],
    }),
    deleteSaleVehicleAlbum: build.mutation<
      DeleteSaleVehicleAlbumApiResponse,
      DeleteSaleVehicleAlbumApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/sale-vehicle-album/${queryArg.saleVehicleAlbumId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SaleVehicleAlbums'],
    }),
    addFilesToSaleVehicleAlbum: build.mutation<
      AddFilesToSaleVehicleAlbumApiResponse,
      AddFilesToSaleVehicleAlbumApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/sale-vehicle-album/${queryArg.saleVehicleAlbumId}`,
        method: 'POST',
        body: queryArg.addFilesToSaleVehicleAlbumRequestBody,
      }),
      invalidatesTags: ['SaleVehicleAlbumPhotos', 'SaleVehicleFlags', 'Vehicle'],
    }),
    addFilesToSaleVehicleAlbumOptimistic: build.mutation<
      boolean,
      {
        saleVehicleId: string;
        saleVehicleAlbumId: string;
        data: {fileId: string; fileUri: string};
      }
    >({
      queryFn: () => ({
        data: true,
      }),
      onQueryStarted: (queryArg, {dispatch}) => {
        dispatch(
          saleVehiclePhotoApi.util.updateQueryData(
            'getSaleVehicleAlbumPhotoList',
            {
              saleVehicleId: queryArg.saleVehicleId,
              saleVehicleAlbumId: queryArg.saleVehicleAlbumId,
            },
            (draft) => {
              const data = queryArg.data;
              const weight = draft.at(-1)?.weight ?? 0;
              draft.push({
                id: data.fileId,
                url: data.fileUri,
                remoteFileId: data.fileId,
                weight: weight + 100,
                fileId: data.fileId,
                isCoverPhoto: false,
                latestFileOperation: null,
                category: null,
              });
            }
          )
        );
      },
    }),
    copySaleVehiclePhoto: build.mutation<
      CopySaleVehiclePhotoApiResponse,
      CopySaleVehiclePhotoApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/sale-vehicle-photo/copy/${queryArg.saleVehicleId}`,
        method: 'PUT',
        body: queryArg.saleVehiclePhotoCopyRequestBody,
      }),
      invalidatesTags: (_, _1, queryArg) => [
        {
          type: 'SaleVehicleAlbumPhotos',
          id: queryArg.saleVehiclePhotoCopyRequestBody.sourceAlbum,
        },
      ],
    }),
    rotateSaleVehiclePhotos: build.mutation<
      RotateSaleVehiclePhotosApiResponse,
      RotateSaleVehiclePhotosApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.saleVehicleId}/album/${queryArg.saleVehicleAlbumId}/rotate-photo`,
        method: 'POST',
        body: queryArg.rotateSaleVehiclePhotosRequestBody,
      }),
      invalidatesTags: (_, _1, queryArg) => [
        {type: 'SaleVehicleAlbumPhotos', id: queryArg.saleVehicleAlbumId},
      ],
    }),
    setSaleVehicleAlbumCoverPhoto: build.mutation<
      SetSaleVehicleAlbumCoverPhotoApiResponse,
      SetSaleVehicleAlbumCoverPhotoApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/sale-vehicle-album/${queryArg.saleVehicleAlbumId}/cover-photo/${queryArg.saleVehiclePhotoId}`,
        method: 'PUT',
      }),
      invalidatesTags: (_, _1) => [
        {type: 'SaleVehicleAlbumPhotos'},
        {type: 'VehiclePhotos'},
        {type: 'SaleVehicleCoverPhoto'},
      ],
    }),
    unsetSaleVehicleAlbumCoverPhoto: build.mutation<
      UnsetSaleVehicleAlbumCoverPhotoApiResponse,
      UnsetSaleVehicleAlbumCoverPhotoApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/sale-vehicle-album/${queryArg.saleVehicleAlbumId}/cover-photo`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, _1, queryArg) => [
        {type: 'SaleVehicleAlbumPhotos', id: queryArg.saleVehicleAlbumId},
      ],
    }),
    listSaleVehicleAlbumsByVehicle: build.query<
      ListSaleVehicleAlbumsByVehicleApiResponse,
      ListSaleVehicleAlbumsByVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/sale-vehicle-album/by-vehicle`,
        params: {vehicleId: queryArg.vehicleId},
      }),
      providesTags: ['SaleVehicleAlbums'],
    }),
    deleteSaleVehiclePhoto: build.mutation<
      DeleteSaleVehiclePhotoApiResponse,
      DeleteSaleVehiclePhotoApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/sale-vehicle-photo/${queryArg.saleVehicleId}/${queryArg.saleVehicleAlbumId}`,
        method: 'DELETE',
        body: queryArg.saleVehiclePhotoDeleteRequestBody,
      }),
      invalidatesTags: (_, _1, queryArg) => [
        {type: 'SaleVehicleAlbumPhotos', id: queryArg.saleVehicleAlbumId},
        {type: 'SaleVehicleCoverPhoto'},
        {type: 'VehiclePhotos'},
        {type: 'Vehicle'},
        {type: 'SaleVehicleFlags'},
      ],
    }),
    updateSaleVehiclePhotoAlbum: build.mutation<
      UpdateSaleVehiclePhotoAlbumApiResponse,
      UpdateSaleVehiclePhotoAlbumApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/photo`,
        method: 'PUT',
        body: queryArg.saleVehiclePhotoUpdateAlbumRequestBody,
      }),
      invalidatesTags: (_, _1, queryArg) => [
        {
          type: 'SaleVehicleAlbumPhotos',
          id: queryArg.saleVehiclePhotoUpdateAlbumRequestBody.sourceAlbum,
        },
        {
          type: 'SaleVehicleAlbumPhotos',
          id: queryArg.saleVehiclePhotoUpdateAlbumRequestBody.destinationAlbum,
        },
        {type: 'SaleVehicleFlags'},
        {type: 'Vehicle'},
        {type: 'SaleVehicleFlags'},
      ],
    }),
    downloadSaleVehicleGallery: build.mutation<
      DownloadSaleVehicleGalleryApiResponse,
      DownloadSaleVehicleGalleryApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.saleVehicleId}/download-gallery/vehicle`,
        method: 'POST',
        params: {albumId: queryArg.albumId},
      }),
    }),
    removeSaleVehiclePhotosBackground: build.mutation<
      RemoveSaleVehiclePhotosBackgroundApiResponse,
      RemoveSaleVehiclePhotosBackgroundApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.saleVehicleId}/sale-vehicle-album/${queryArg.saleVehicleAlbumId}/remove-photo-background`,
        method: 'POST',
        body: queryArg.removeSaleVehiclePhotosBackgroundRequestBody,
      }),
      invalidatesTags: (_, _1, queryArg) => [
        {
          type: 'SaleVehicleAlbumPhotos',
          id: queryArg.saleVehicleAlbumId,
        },
      ],
    }),
    addPromoPhotosToSaleVehicle: build.mutation<
      AddToSaleVehicleApiResponse,
      AddToSaleVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/sale-vehicle/add-all-to-sale-vehicle`,
        method: 'POST',
        body: queryArg.addToSaleVehicleRequestBody,
      }),
      invalidatesTags: ['SaleVehiclePromoPhotos', 'Vehicle'],
    }),
    getPromoPhotosForSaleVehicle: build.query<
      GetForSaleVehicleApiResponse,
      GetForSaleVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/sale-vehicle/${queryArg.saleVehicleId}`,
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'SaleVehiclePromoPhotos', id: queryArg.saleVehicleId},
      ],
    }),
    removeInSaleVehicle: build.mutation<RemoveInSaleVehicleApiResponse, RemoveInSaleVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/sale-vehicle/remove-in-sale-vehicle`,
        method: 'DELETE',
        body: queryArg.removeInSaleVehicleRequestBody,
      }),
      invalidatesTags: ['SaleVehiclePromoPhotos'],
    }),
    getSaleVehicleWatermarkPreview: build.query<
      GetSaleVehiclePreviewApiResponse,
      GetSaleVehiclePreviewApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/watermark/preview/${queryArg.saleVehiclePhotoId}`,
      }),
    }),
    getSaleVehicleCoverPhoto: build.query<
      GetSaleVehicleCoverPhotoApiResponse,
      GetSaleVehicleCoverPhotoApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.saleVehicleId}/cover-photo`,
      }),
      providesTags: ['SaleVehicleCoverPhoto'],
    }),
    updatePositionsInSaleVehicle: build.mutation<
      UpdatePositionsInSaleVehicleApiResponse,
      UpdatePositionsInSaleVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/sale-vehicle/positions`,
        method: 'PUT',
        body: queryArg.updatePositionsInSaleVehicleRequestBody,
      }),
      invalidatesTags: ['SaleVehiclePromoPhotos'],
    }),
    resetPositionsInSaleVehicle: build.mutation<
      ResetPositionsInSaleVehicleApiResponse,
      ResetPositionsInSaleVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/sale-vehicle/reset-positions-in-sale-vehicle`,
        method: 'POST',
        body: queryArg.resetPositionsInSaleVehicleRequestBody,
      }),
      invalidatesTags: ['SaleVehiclePromoPhotos'],
    }),
    getSaleVehiclePhotoList: build.query<
      GetSaleVehiclePhotoListApiResponse,
      GetSaleVehiclePhotoListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.saleVehicleId}/photo`,
      }),
      providesTags: ['SaleVehicleAlbumPhotos', 'SaleVehicleCoverPhoto'],
    }),
    copyAuditPhotoToSaleVehicleAlbum: build.mutation<
      CopyAuditPhotoToSaleVehicleAlbumApiResponse,
      CopyAuditPhotoToSaleVehicleAlbumApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.saleVehicleId}/copy-audit-photo`,
        method: 'POST',
        body: queryArg.saleVehicleAuditPhotoCopyRequestBody,
      }),
      invalidatesTags: ['SaleVehicleAlbumPhotos', 'Vehicle'],
    }),
    listSaleVehiclePhotosByVehicle: build.query<
      ListSaleVehiclePhotosByVehicleApiResponse,
      ListSaleVehiclePhotosByVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/sale-vehicle-photo/by-vehicle`,
        params: {vehicleId: queryArg.vehicleId},
      }),
      providesTags: ['SaleVehicleAlbumPhotos', 'SaleVehicleCoverPhoto'],
    }),
  }),
});

export const {
  useListSaleVehicleAlbumsQuery,
  useCreateSaleVehicleAlbumMutation,
  useGetSaleVehicleAlbumPhotoListQuery,
  useAddFilesToSaleVehicleAlbumMutation,
  useAddFilesToSaleVehicleAlbumOptimisticMutation,
  useCopySaleVehiclePhotoMutation,
  useCopyAuditPhotoToSaleVehicleAlbumMutation,
  useRotateSaleVehiclePhotosMutation,
  useDeleteSaleVehicleAlbumMutation,
  useUnsetSaleVehicleAlbumCoverPhotoMutation,
  useSetSaleVehicleAlbumCoverPhotoMutation,
  useListSaleVehicleAlbumsByVehicleQuery,
  useDeleteSaleVehiclePhotoMutation,
  useUpdateSaleVehiclePhotoAlbumMutation,
  useDownloadSaleVehicleGalleryMutation,
  useRemoveSaleVehiclePhotosBackgroundMutation,
  useAddPromoPhotosToSaleVehicleMutation,
  useGetPromoPhotosForSaleVehicleQuery,
  useRemoveInSaleVehicleMutation,
  useLazyGetSaleVehicleWatermarkPreviewQuery,
  useGetSaleVehicleCoverPhotoQuery,
  useUpdatePositionsInSaleVehicleMutation,
  useResetPositionsInSaleVehicleMutation,
  useGetSaleVehiclePhotoListQuery,
  useListSaleVehiclePhotosByVehicleQuery,
} = saleVehiclePhotoApi;
