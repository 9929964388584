import {
  Card,
  CreatableChoice,
  DECIMAL_DOT,
  FormControl,
  FormField,
  Option,
} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';
import {useLocale} from 'platform/locale';
import {z} from 'zod';

import {useEffect, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {defaultTo, isNil, isNotNil} from 'ramda';

import {BaseArticle, GetArticleResponse} from '@dms/api/metadaWarehouseArticle';
import {useGetHandlingUnitsQuery} from '@dms/api/metadaWarehouseHandlingUnit';
import i18n from '@dms/i18n';
import {getActiveOptionsFromHandlingUnits} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

interface OverviewUnitsFormProps extends RequiredTestIdProps {
  formApi: UseFormReturn<BaseArticle>;
  control: FormControl<BaseArticle>;
  article: GetArticleResponse;
  presetDispensingUnits: Option[] | Nullish;
  arePresetDispensingUnitsLoading: boolean;
}

export function OverviewUnitsForm(props: OverviewUnitsFormProps) {
  const {localeConfig} = useLocale();

  const numberLocale = localeConfig.number;

  const {data: handlingUnits} = useGetHandlingUnitsQuery();

  const [dispensingUnitOptions, setDispensingUnitOptions] = useState<Option[]>([]);

  const [selectedDispensingUnit, setSelectedDispensingUnit] = useState<string | null>(
    defaultTo('1', String(props.article.dispensingUnit))
  );

  const [dispensingUnitError, setDispensingUnitError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isNil(props.presetDispensingUnits)) {
      return;
    }
    setDispensingUnitOptions(props.presetDispensingUnits);
  }, [props.presetDispensingUnits]);

  useEffect(() => {
    if (isNil(props.article.dispensingUnit)) {
      return;
    }

    const dispensingUnitOption = {
      value: String(props.article.dispensingUnit),
      label: String(props.article.dispensingUnit),
    };

    const hasDispensingUnit = dispensingUnitOptions.some(
      (option) => option.value === dispensingUnitOption.value
    );

    if (hasDispensingUnit) {
      return;
    }

    setDispensingUnitOptions((dispensingUnits) => [...dispensingUnits, dispensingUnitOption]);
  }, [props.article.dispensingUnit, dispensingUnitOptions]);

  const handleDispensingUnitChange = (dispensingUnit: string | null) => {
    validateDispensingUnit(dispensingUnit);
    setSelectedDispensingUnit(dispensingUnit);

    const standardizedDispensingUnit = Number(
      dispensingUnit?.replace(numberLocale.decimalSeparator, DECIMAL_DOT)
    );

    props.formApi.setValue('dispensingUnit', standardizedDispensingUnit);
  };

  const handleCreateDispensingUnit = (newDispensingUnit: string) => {
    validateDispensingUnit(newDispensingUnit);
    setSelectedDispensingUnit(newDispensingUnit);

    const standardizedDispensingUnit = Number(
      newDispensingUnit?.replace(numberLocale.decimalSeparator, DECIMAL_DOT)
    );

    props.formApi.setValue('dispensingUnit', standardizedDispensingUnit);

    const newValue = {
      value: newDispensingUnit,
      label: newDispensingUnit,
    };

    setDispensingUnitOptions((dispensingUnits) => [...dispensingUnits, newValue]);
  };

  const validateDispensingUnit = (value: string | null) => {
    setDispensingUnitError(undefined);
    props.formApi.clearErrors('dispensingUnit');

    const result = dispensingUnitSchema(numberLocale.decimalSeparator).safeParse(String(value));

    if (result.success) {
      return;
    }

    const errorMessages = result.error?.errors.map((error) => error.message).join(' ');
    setDispensingUnitError(errorMessages);
    props.formApi.setError('dispensingUnit', {message: errorMessages});
  };

  return (
    <Card title={i18n.t('entity.warehouse.labels.units')}>
      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            name="handlingUnit"
            type="choice"
            label={i18n.t('entity.warehouse.labels.measurementUnit')}
            control={props.control}
            options={getActiveOptionsFromHandlingUnits(handlingUnits)}
            isNotClearable
            data-testid={suffixTestId('inputs.handlingUnit', props)}
          />
        </GridItem>

        <GridItem span={1}>
          <CreatableChoice
            name="dispensingUnit"
            value={selectedDispensingUnit}
            label={i18n.t('entity.warehouse.labels.dispensingUnit')}
            tooltip={i18n.t('entity.warehouse.labels.dispensingUnitTooltip')}
            options={dispensingUnitOptions}
            onChange={(dispensingUnit) => handleDispensingUnitChange(dispensingUnit)}
            onCreateOption={(newDispensingUnit) => handleCreateDispensingUnit(newDispensingUnit)}
            isLoading={props.arePresetDispensingUnitsLoading}
            isInvalid={isNotNil(dispensingUnitError)}
            errorMessage={dispensingUnitError}
            isNotClearable
          />
        </GridItem>
      </Grid>
    </Card>
  );
}

const dispensingUnitSchema = (decimalSeparator: string) =>
  z.string().refine(
    (value) => {
      if (isNil(value)) {
        return true;
      }
      // Convert to string and handle decimal separator
      const stringValue = value.toString();
      const parts = stringValue.split(decimalSeparator);

      // If there's no decimal part, it's valid
      if (parts.length === 1) {
        return true;
      }

      // Check if decimal part has more than 4 places
      const decimalPlaces = parts[1];
      return decimalPlaces.length <= 4;
    },
    {
      message: i18n.t('entity.warehouse.labels.maxFourDecimalPlaces'),
    }
  );
