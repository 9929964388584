import {differenceInDays, startOfDay} from 'date-fns';
import {Avatar} from 'platform/components';
import {
  Box,
  Center,
  Hide,
  Show,
  HStack,
  VStack,
  Link,
  Text,
  Heading,
  Space,
  Clickable,
} from 'platform/foundation';
import {DayMonthFormat} from 'platform/locale';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {BusinessCaseActivityResponseBody} from '@dms/api/sales';
import i18n from '@dms/i18n';

import {DOT_CHARACTER, EMPTY_PLACEHOLDER, parseDate} from 'shared';

import {transformActivityItem} from '../utils/transformActivityItem';

interface ActivityItemProps {
  item: BusinessCaseActivityResponseBody;
  showLineToNextItem: boolean;
}

export function ActivityItem(props: ActivityItemProps) {
  const parsedTimestamp = parseDate(props.item.timestamp);
  const transformedItem = transformActivityItem(props.item);
  const daysAgo = calculateDaysAgo(transformedItem?.timestamp ?? '');

  if (!transformedItem) {
    return null;
  }

  return (
    <HStack spacing={2}>
      <VStack>
        <Avatar color={transformedItem.color} icon={transformedItem.icon} variant="square" />
        <Hide when={props.showLineToNextItem}>
          <Center width="100%" height="100%">
            <Box height="100%" backgroundColor="general.separator" width="0.125rem" />
          </Center>
        </Hide>
      </VStack>
      <VStack width="100%">
        <Box paddingBottom={4}>
          <HStack width="100%">
            <VStack width="100%" spacing={1}>
              <Clickable onClick={transformedItem.onClick}>
                <Heading size={6}>{transformedItem?.title ?? EMPTY_PLACEHOLDER}</Heading>
              </Clickable>
              <Text color="tertiary" size="xSmall">
                <HStack align="center">
                  <Show when={transformedItem?.interestType}>
                    {transformedItem?.interestType}
                    <Space horizontal={1} />
                    {DOT_CHARACTER}
                    <Space horizontal={1} />
                  </Show>
                  {transformedItem?.userName}
                </HStack>
              </Text>
            </VStack>
            <VStack>
              <Text size="xxSmall" color="tertiary" align="right" noWrap>
                <DayMonthFormat
                  value={{
                    day: parsedTimestamp?.getDate(),
                    month: parsedTimestamp?.getMonth() + 1,
                  }}
                  format="dateShort"
                />
              </Text>
              <Show when={daysAgo !== 0}>
                <Text size="xxSmall" color="tertiary" align="right" noWrap>
                  {i18n.t('general.labels.nDaysAgo', {n: Math.abs(daysAgo)})}
                </Text>
              </Show>
            </VStack>
          </HStack>
          <Show when={isNotNilOrEmpty(transformedItem?.description)}>
            <Text size="xSmall" color="secondary">
              {transformedItem?.description}
            </Text>
          </Show>
          <Show when={isNotNilOrEmpty(transformedItem?.links)}>
            <HStack>
              {transformedItem?.links?.map((link) => (
                <Link
                  size="xSmall"
                  iconSize={3}
                  key={link.url}
                  leftIcon={link.icon ?? 'image/crop'}
                  href={link.url}
                  title={link.label}
                />
              ))}
            </HStack>
          </Show>
        </Box>
      </VStack>
    </HStack>
  );
}

const calculateDaysAgo = (timestamp: string) => {
  const normalizedDate = startOfDay(timestamp);
  const today = startOfDay(new Date());

  const differenceDays = differenceInDays(normalizedDate, today);

  return differenceDays;
};
