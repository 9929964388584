import styled from 'styled-components';

import {forwardRef, ForwardRefRenderFunction, Fragment} from 'react';
import {DraggableProvided} from 'react-beautiful-dnd';

import {SourcingVehicleDetailResponseBody} from '@dms/api/sourcing';

import {suffixTestId, TestIdProps} from 'shared';

import {SectionType} from '../../../types/SectionType';
import {ColumnHeader} from './ColumnHeader';

const Container = styled.div`
  opacity: 0.7;
  width: 150px !important;
`;

const ColumnHeaderWrapper = styled.div`
  height: 214px;
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
`;

const ColumnBody = styled.div`
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
`;

const List = styled.div`
  > div {
    min-height: 24px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    background-color: ${({theme}) => theme.colors.palettes.white[10][100]};

    &:nth-child(even) {
      background-color: ${({theme}) => theme.colors.palettes.neutral[10][100]};
    }
  }
`;

const Divider = styled.div`
  height: 24px;
  border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
`;

interface ComparisonTableColumnCloneProps extends DraggableProvided, TestIdProps {
  vehicle: SourcingVehicleDetailResponseBody;
  sections: SectionType[];
}

const renderComparisonTableColumnClone: ForwardRefRenderFunction<
  HTMLDivElement,
  ComparisonTableColumnCloneProps
> = (props, ref) => (
  <Container {...props.draggableProps} {...props.dragHandleProps} ref={ref}>
    <ColumnHeaderWrapper>
      <ColumnHeader vehicle={props.vehicle} data-testid={suffixTestId('columnHeader', props)} />
    </ColumnHeaderWrapper>
    <ColumnBody>
      {props.sections.map(({key, items}) => (
        <Fragment key={key}>
          <List>
            {items.map(({Cell}, index) => (
              <div key={index}>{Cell(props.vehicle)}</div>
            ))}
          </List>
          <Divider />
        </Fragment>
      ))}
    </ColumnBody>
  </Container>
);

export const ComparisonTableColumnClone = forwardRef(renderComparisonTableColumnClone);
