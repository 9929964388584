import {add} from 'date-fns';
import {Form, FormSubmitHandler, DataStatus} from 'platform/components';
import {match} from 'ts-pattern';
import {object, string} from 'yup';

import {ReactNode} from 'react';

import {always} from 'ramda';
import {isNilOrEmpty, isNotNil, isTrue} from 'ramda-adjunct';

import {GetCheckoutByContextApiResponse} from '@dms/api/metadaCheckout';
import {useGetCustomerPaymentQuery} from '@dms/api/metadaCustomerPayment';
import {useGetServiceOrderQuery} from '@dms/api/metadaWorkshopServiceOrder';
import {useGetServiceOrderVariantPaymentTypeQuery} from '@dms/api/metadaWorkshopServiceOrderVariant';

import {
  Nullish,
  parseDate,
  RequiredTestIdProps,
  suffixTestId,
  yupDate,
  yupNumber,
  yupString,
} from 'shared';

import {CheckoutPaymentFormType} from '../../types/CheckoutPaymentFormType';
import {CheckoutPaymentFormBody} from './CheckoutPaymentFormBody';

const DEFAULT_RATIO = 1;

interface CheckoutPaymentFormProps extends RequiredTestIdProps {
  checkout: GetCheckoutByContextApiResponse;
  isSubmitButtonDisabled: boolean;
  issueButtonTooltip?: ReactNode;
  isIssueButtonDisabled?: boolean;
  onIssuePayment: FormSubmitHandler<CheckoutPaymentFormType>;
  onSaveChanges: (data: CheckoutPaymentFormType) => Promise<unknown>;
  customerId: string | Nullish;
  serviceCaseId: string | Nullish;
  serviceOrderId: string | Nullish;
  shouldWatchForUnsavedChanges?: boolean | Nullish;
}

export function CheckoutPaymentForm(props: CheckoutPaymentFormProps) {
  const {
    data: customerPayment,
    isFetching: isCustomerPaymentFetching,
    isError: isCustomerPaymentError,
  } = useGetCustomerPaymentQuery(
    {customerId: props.customerId ?? ''},
    {
      skip: isNilOrEmpty(props.customerId),
      refetchOnMountOrArgChange: true,
    }
  );
  const {data: serviceOrder, isLoading: isServiceOrderLoading} = useGetServiceOrderQuery({
    serviceCaseId: props.serviceCaseId ?? '',
    serviceOrderId: props.serviceOrderId ?? '',
  });

  const {
    data: orderVariantPaymentType,
    isLoading: isOrderVariantPaymentTypeLoading,
    isError: isOrderVariantPaymentTypeError,
  } = useGetServiceOrderVariantPaymentTypeQuery(
    {serviceOrderVariantId: serviceOrder?.serviceOrderVariantId ?? ''},
    {skip: isNilOrEmpty(serviceOrder?.serviceOrderVariantId)}
  );

  // Fallback when bank payment set as default value but not allowed by customer payment settings

  const defaultPaymentMethod = match([
    props.checkout?.paymentInfo?.method ?? orderVariantPaymentType?.defaultPaymentType ?? 'CARD',
    customerPayment?.allowBankTransfer ?? false,
  ])
    .with(['BANK_TRANSFER', false], always('CARD'))
    .otherwise(([paymentType]) => paymentType);

  // Disable bank payment if it is not allowed by customer payment settings
  const allowedPaymentTypes = orderVariantPaymentType
    ? {
        ...orderVariantPaymentType,
        bankPayment: {
          ...orderVariantPaymentType?.bankPayment,
          isAllowed: customerPayment?.allowBankTransfer || false,
        },
      }
    : null;

  return (
    <DataStatus
      isLoading={
        isCustomerPaymentFetching || isServiceOrderLoading || isOrderVariantPaymentTypeLoading
      }
      isError={isOrderVariantPaymentTypeError || isCustomerPaymentError}
      minHeight={100}
    >
      <Form<CheckoutPaymentFormType>
        mode="onSubmit"
        defaultValues={{
          amount:
            props.checkout?.paymentInfo?.paymentAmount?.amount ??
            props.checkout?.totalAmount?.withVat?.amount ??
            undefined,
          bankAccount: props.checkout?.paymentInfo?.incomingBankAccount?.bankAccount ?? undefined,
          note: props.checkout?.paymentInfo?.note ?? undefined,
          paymentMethod: defaultPaymentMethod,
          due: customerPayment?.invoiceMaturity ?? props.checkout?.paymentInfo?.due ?? 0,
          dueDate: isNotNil(customerPayment?.invoiceMaturity)
            ? add(new Date(), {days: customerPayment?.invoiceMaturity})
            : parseDate(props.checkout?.paymentInfo?.dueDate ?? new Date().toISOString()),
          issuedOn: parseDate(props.checkout?.paymentInfo?.issueDate ?? new Date().toISOString()),
          taxableEventDate: parseDate(new Date().toISOString()),
          currency: props.checkout?.paymentInfo?.foreignCurrencyPayment?.currency,
          ratio: props.checkout?.paymentInfo?.foreignCurrencyPayment?.ratio ?? DEFAULT_RATIO,
          exchangeRate: props.checkout?.paymentInfo?.foreignCurrencyPayment?.exchangeRate,
        }}
        schema={formSchema}
        onSubmit={props.onIssuePayment}
        shouldWatchForUnsavedChanges={isTrue(props.shouldWatchForUnsavedChanges)}
      >
        {(control, formApi) => (
          <CheckoutPaymentFormBody
            control={control}
            formApi={formApi}
            orderVariantPaymentType={allowedPaymentTypes}
            onSaveChanges={props.onSaveChanges}
            issueButtonTooltip={props.issueButtonTooltip}
            isIssueButtonDisabled={props.isIssueButtonDisabled}
            isSubmitButtonDisabled={props.isSubmitButtonDisabled}
            data-testid={suffixTestId('body', props)}
          />
        )}
      </Form>
    </DataStatus>
  );
}

const formSchema = object({
  amount: yupNumber.required(),
  paymentMethod: yupString.required(),
  issuedOn: yupDate.required(),
  bankAccount: string().when('paymentMethod', {
    is: 'BANK_TRANSFER',
    then: string().required().nullable(),
    otherwise: string().optional().nullable(),
  }),
  cashRegisterId: string().when('paymentMethod', {
    is: 'CASH',
    then: string().required().nullable(),
    otherwise: string().optional().nullable(),
  }),
  due: yupNumber.required(),
  dueDate: yupDate.required(),
  taxableEventDate: yupDate.required(),
  note: yupString.optional(),
  exchangeRate: yupNumber.when('currency', {
    is: isNotNil,
    then: yupNumber.required(),
    otherwise: yupNumber,
  }),
  currency: yupString,
});
