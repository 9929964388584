/* eslint-disable eag/match-named-export */
import {z} from 'zod';

import {
  UuidResponseBody,
  Money,
  PhoneNumber,
  BigDecimal,
  BigDecimalSchema,
  AdvertisingPlatformCodeEnum,
  TaskTypeEnumSchema,
  TaskPriorityEnumSchema,
  TaskStateEnumSchema,
} from '@dms/api/shared';

export type UpdateVehicleIsInSaleApiArg = {
  vehicleId: string;
  publish: boolean;
};

export type UpdateVehicleIsInSaleApiRes = unknown;

export type GetSalesPlatformsApiArg = {vehicleId: string};

export enum AdvertisementStatusEnum {
  NOT_PUBLISHED = 'not_published',
  PUBLISHING = 'publishing',
  PUBLISH_FAILED = 'publish_failed',
  PUBLISHED = 'published',
  UPDATING = 'updating',
  UPDATE_FAILED = 'update_failed',
  UNPUBLISHING = 'unpublishing',
  UNPUBLISH_FAILED = 'unpublish_failed',
  UNKNOWN = 'unknown',
}

export type SalesPlatformsResBody = {
  enabled: boolean;
  platform: {
    code: AdvertisingPlatformCodeEnum;
    title: string;
    enabled: boolean;
    branchId: string | null;
  };
  status: AdvertisementStatusEnum;

  statusDescription: string | null;
  statusTriggeredAt: string | null;
  statusTriggeredBy: string | null;
  priceWithVat: {
    amount: string;
    currency: string;
  } | null;
  publishingBranchId: string | null;
  publishingBranchName: string | null;
  hasOverrides: boolean;
};
export type GetSalesPlatformsApiRes = Array<SalesPlatformsResBody>;

export type UpdateVehicleOnAllPlatformsApiResponse = unknown;
export type UpdateVehicleOnAllPlatformsApiArg = {
  vehicleId: string;
};
export type UpdateVehicleOnPlatformApiResponse = unknown;
export type UpdateVehicleOnPlatformApiArg = {
  vehicleId: string;
  platformCode: AdvertisingPlatformCodeEnum;
};
export type EditGeneralSettingsApiResponse =
  /** status 200  */ VehicleAdvertisingSettingsResponseBody;
export type EditGeneralSettingsApiArg = {
  vehicleId: string;
  patchVehicleAdvertisingSettingsRequestBody: PatchVehicleAdvertisingSettingsRequestBody;
};
export type GetGeneralSettingsApiResponse =
  /** status 200  */ VehicleAdvertisingSettingsResponseBody;
export type GetGeneralSettingsApiArg = {
  vehicleId: string;
};
export type EditWindshieldSettingsApiResponse =
  /** status 200  */ VehicleWindshieldAdvertisingSettingsResponseBody;
export type EditWindshieldSettingsApiArg = {
  vehicleId: string;
  patchVehicleWindshieldAdvertisingSettingsRequestBody: PatchVehicleWindshieldAdvertisingSettingsRequestBody;
};
export type GetWindshieldSettingsApiResponse =
  /** status 200  */ VehicleWindshieldAdvertisingSettingsResponseBody;
export type GetWindshieldSettingsApiArg = {
  vehicleId: string;
};
export type VehicleAdvertisingSettingsCodeEnum =
  | 'title_extension'
  | 'model_info'
  | 'vin'
  | 'mileage'
  | 'price'
  | 'description'
  | 'price_note'
  | 'financing_price'
  | 'contact_email'
  | 'contact_phone'
  | 'url'
  | 'condition'
  | 'version'
  | 'generation'
  | 'digital_certificate'
  | 'digital_certificate_theme'
  | 'video_url';
export type VehicleAdvertisingSettingsTypeEnum =
  | 'string'
  | 'text'
  | 'mileage'
  | 'price'
  | 'financing_price'
  | 'email'
  | 'phone'
  | 'condition'
  | 'digital_certificate'
  | 'digital_certificate_theme';
export type CommonTitleExtensionAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonVinAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: true;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type MileageValue = {
  value: number;
  unit: 'km';
};
export type CommonMileageAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: MileageValue | null;
  defaultValue?: MileageValue | null;
  displayValue?: MileageValue | null;
  extra?: object | null;
  required?: boolean;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type PriceValue = {
  vatDeductible?: boolean;
  vatRate?: BigDecimal | null;
  value: Money;
  withVat: boolean;
  vatCode?: string | null;
  countryCode?: string;
};
export type PriceExtra = {
  vatDeductible: boolean;
  vatRate: BigDecimal | null;
  vatCode: string;
  countryCode: string;
};
export type CommonPriceAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: PriceValue | null;
  defaultValue?: PriceValue | null;
  displayValue?: PriceValue | null;
  extra?: PriceExtra;
  required?: boolean;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonFinancingPriceAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: PriceValue | null;
  defaultValue?: PriceValue | null;
  displayValue?: PriceValue | null;
  extra?: PriceExtra;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonPriceNoteAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonDescriptionAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonVersionAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonGenerationAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonDigitalCertificateAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonDigitalCertificateThemeAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonVideoUrlAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonConditionAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: number | null;
  defaultValue?: number | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type VehicleGeneralAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute
  | CommonVersionAttribute
  | CommonGenerationAttribute
  | CommonDigitalCertificateAttribute
  | CommonDigitalCertificateThemeAttribute
  | CommonVideoUrlAttribute
  | CommonConditionAttribute;
export type VehicleGeneralAdvertisingSettingsResponseBody = {
  attributes: VehicleGeneralAdvertisingAttributeResponseBody[];
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehicleSAutoPlatformAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehicleSAutoPlatformAdvertisingSettingsResponseBody = {
  platformCode: AdvertisingPlatformCodeEnum;
  enabled: boolean;
  attributes: VehicleSAutoPlatformAdvertisingAttributeResponseBody[];
  hasOverrides: boolean;
  updatedAt: string | null;
  updatedBy: string | null;
};
export type TipCarsPriceNoteAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type VehicleTipCarsPlatformAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | TipCarsPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehicleTipCarsPlatformAdvertisingSettingsResponseBody = {
  platformCode: AdvertisingPlatformCodeEnum;
  enabled: boolean;
  attributes: VehicleTipCarsPlatformAdvertisingAttributeResponseBody[];
  hasOverrides: boolean;
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehicleMobileDePlatformAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehicleMobileDePlatformAdvertisingSettingsResponseBody = {
  platformCode: AdvertisingPlatformCodeEnum;
  enabled: boolean;
  attributes: VehicleMobileDePlatformAdvertisingAttributeResponseBody[];
  hasOverrides: boolean;
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehicleMyWebPlatformAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehicleMyWebPlatformAdvertisingSettingsResponseBody = {
  platformCode: AdvertisingPlatformCodeEnum;
  enabled: boolean;
  attributes: VehicleMyWebPlatformAdvertisingAttributeResponseBody[];
  hasOverrides: boolean;
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehicleFacebookPlatformAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehicleFacebookPlatformAdvertisingSettingsResponseBody = {
  platformCode: AdvertisingPlatformCodeEnum;
  enabled: boolean;
  attributes: VehicleFacebookPlatformAdvertisingAttributeResponseBody[];
  hasOverrides: boolean;
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehicleAutocarisPlatformAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehicleAutocarisPlatformAdvertisingSettingsResponseBody = {
  platformCode: AdvertisingPlatformCodeEnum;
  enabled: boolean;
  attributes: VehicleAutocarisPlatformAdvertisingAttributeResponseBody[];
  hasOverrides: boolean;
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehiclePlatformAdvertisingSettingsResponseBody =
  | VehicleSAutoPlatformAdvertisingSettingsResponseBody
  | VehicleTipCarsPlatformAdvertisingSettingsResponseBody
  | VehicleMobileDePlatformAdvertisingSettingsResponseBody
  | VehicleMyWebPlatformAdvertisingSettingsResponseBody
  | VehicleFacebookPlatformAdvertisingSettingsResponseBody
  | VehicleAutocarisPlatformAdvertisingSettingsResponseBody;
export type VehicleAdvertisingSettingsResponseBody = {
  vehicleId: string;
  inSale: boolean;
  general: VehicleGeneralAdvertisingSettingsResponseBody;
  platforms?: VehiclePlatformAdvertisingSettingsResponseBody[];
};
export type VehicleGeneralAdvertisingAttributeRequestBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute
  | CommonVersionAttribute
  | CommonGenerationAttribute
  | CommonConditionAttribute;
export type VehicleGeneralAdvertisingSettingsRequestBody = {
  attributes: VehicleGeneralAdvertisingAttributeRequestBody[];
};
export type PatchVehicleAdvertisingSettingsRequestBody = {
  general?: VehicleGeneralAdvertisingSettingsRequestBody | null;
};
export type VehiclePlatformAdvertisingAttributeRequestBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | TipCarsPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehiclePlatformAdvertisingSettingsRequestBody = {
  attributes: VehiclePlatformAdvertisingAttributeRequestBody[];
};
export type GetBusinessCaseDocumentsSettingsApiResponse =
  /** status 200  */ BusinessCaseDocumentsSettingsResponseBody;
export type PutBusinessCaseDocumentsSettingsApiResponse = /** status 204  */ undefined;
export type PutBusinessCaseDocumentsSettingsApiArg = {
  businessCaseDocumentsSettingsRequestBody: BusinessCaseDocumentsSettingsRequestBody;
};
export type SellingBusinessCaseDocumentsResponseBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  saleHandoverProtocol: boolean;
  saleContract: boolean;
  consent: boolean;
};
export type BuyingBusinessCaseDocumentsResponseBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  handoverProtocol: boolean;
  purchaseContract: boolean;
  consent: boolean;
};
export type PurchaseBrokerageBusinessCaseDocumentsResponseBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  purchaseBrokerageHandoverProtocol: boolean;
  purchaseBrokerageContract: boolean;
  consent: boolean;
};
export type SellingBrokerageBusinessCaseDocumentsResponseBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  saleHandoverProtocol: boolean;
  saleBrokerageContract: boolean;
  consent: boolean;
};
export type BusinessCaseDocumentsSettingsResponseBody = {
  selling: SellingBusinessCaseDocumentsResponseBody;
  buying: BuyingBusinessCaseDocumentsResponseBody;
  purchaseBrokerage: PurchaseBrokerageBusinessCaseDocumentsResponseBody;
  sellingBrokerage: SellingBrokerageBusinessCaseDocumentsResponseBody;
};
export type SellingBusinessCaseDocumentsRequestBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  saleHandoverProtocol: boolean;
  saleContract: boolean;
  consent: boolean;
};
export type BuyingBusinessCaseDocumentsRequestBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  handoverProtocol: boolean;
  purchaseContract: boolean;
  consent: boolean;
};
export type PurchaseBrokerageBusinessCaseDocumentsRequestBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  purchaseBrokerageHandoverProtocol: boolean;
  purchaseBrokerageContract: boolean;
  consent: boolean;
};
export type SellingBrokerageBusinessCaseDocumentsRequestBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  saleHandoverProtocol: boolean;
  saleBrokerageContract: boolean;
  consent: boolean;
};
export type BusinessCaseDocumentsSettingsRequestBody = {
  selling: SellingBusinessCaseDocumentsRequestBody;
  buying: BuyingBusinessCaseDocumentsRequestBody;
  purchaseBrokerage: PurchaseBrokerageBusinessCaseDocumentsRequestBody;
  sellingBrokerage: SellingBrokerageBusinessCaseDocumentsRequestBody;
};
export type CreatePurchaseBusinessCaseApiResponse = /** status 201  */ UuidResponseBodyBusinessCase;
export type CreatePurchaseBusinessCaseApiArg = {
  createPurchaseBusinessCaseRequestBody: CreatePurchaseBusinessCaseRequestBody;
};
export const SalesActivityRelatedTaskResponseBodySchema = z.object({
  id: z.string(),
  title: z.string(),
  priority: TaskPriorityEnumSchema,
  type: TaskTypeEnumSchema,
  state: TaskStateEnumSchema,
  assignedTo: z.string(),
  dueDate: z.string().nullable(),
  dueFrom: z.string().nullable(),
  dueTo: z.string().nullable(),
  finishedAt: z.string().nullable(),
  description: z.string().nullable(),
});
export type SalesActivityRelatedTaskResponseBody = z.infer<
  typeof SalesActivityRelatedTaskResponseBodySchema
>;
export const SalesActivityTypeEnumSchema = z.enum([
  'INTEREST_CREATED',
  'INTEREST_CLOSED',
  'BUSINESS_CASE_CREATED',
  'CONTRACT_CLOSED',
  'DEPOSIT_PAYMENT_ISSUED',
  'BALANCE_PAYMENT_ISSUED',
  'PAYMENT_PAID',
  'TASK_CREATED',
  'TASK_FINISHED',
  'CALL_FROM_CUSTOMER',
  'CALL_TO_CUSTOMER',
  'MESSAGE_FROM_CUSTOMER',
  'MESSAGE_TO_CUSTOMER',
  'CUSTOMER_VISIT',
  'ONLINE_OFFER_SHARING_CANCELED',
]);
export type SalesActivityTypeEnum = z.infer<typeof SalesActivityTypeEnumSchema>;

export const PatchSalesActivityRequestBodySchema = z.object({
  createdAt: z.string(),
  description: z.string().nullable(),
});
export type PatchSalesActivityRequestBody = z.infer<typeof PatchSalesActivityRequestBodySchema>;
export type DeleteSalesActivityApiResponse = /** status 200  */ object;
export type DeleteSalesActivityApiArg = {
  salesActivityId: string;
};
export type PatchSalesActivityApiResponse = /** status 204  */ void;
export type PatchSalesActivityApiArg = {
  salesActivityId: string;
  patchSalesActivityRequestBody: PatchSalesActivityRequestBody;
};

export const BusinessCaseActivityResponseBodySchema = z.object({
  id: z.string(),
  interestId: z.string().nullable(),
  interestCode: z.string().nullable(),
  interestType: z.string().nullable(),
  subject: z.string().nullable(),
  activityType: SalesActivityTypeEnumSchema,
  userId: z.string(),
  userName: z.string(),
  timestamp: z.string(),
  relatedTask: SalesActivityRelatedTaskResponseBodySchema.nullable(),
  description: z.string().nullable(),
});
export type BusinessCaseActivityResponseBody = z.infer<
  typeof BusinessCaseActivityResponseBodySchema
>;
export type GetBusinessCaseActivityListApiResponse =
  /** status 200  */ BusinessCaseActivityResponseBody[];
export type GetBusinessCaseActivityListApiArg = {
  businessCaseId: string;
};
export type CreateSaleBusinessCaseApiResponse = /** status 201  */ UuidResponseBodyBusinessCase;
export type CreateSaleBusinessCaseApiArg = {
  createSaleBusinessCaseRequestBody: CreateSaleBusinessCaseRequestBody;
};
export type GetSalePresetSettingsListApiResponse =
  /** status 200  */ GetSalePresetSettingsListItemResponseBody[];
export type GetSalePresetSettingsListApiArg = void;
export const GetSalePresetSettingsListItemResponseBodySchema = z.object({
  id: z.string(),
  name: z.string(),
  makes: z.array(z.string()),
});
export type GetSalePresetSettingsListItemResponseBody = z.infer<
  typeof GetSalePresetSettingsListItemResponseBodySchema
>;
export type GetSalePresetSettingsApiResponse = /** status 200  */ GetSalePresetSettingsResponseBody;
export type GetSalePresetSettingsApiArg = {
  salePresetId: string;
};
export const SalesActivityRelatedMessageResponseBodySchema = z.object({
  id: z.string(),
  body: z.string(),
  subject: z.string().nullable().optional(),
  sentTo: z.string().nullable().optional(),
});
export type SalesActivityRelatedMessageResponseBody = z.infer<
  typeof SalesActivityRelatedMessageResponseBodySchema
>;
export const InterestActivityResponseBodySchema = z.object({
  id: z.string(),
  businessCaseId: z.string().nullable(),
  businessCaseCode: z.string().nullable(),
  businessCaseType: z.string().nullable(),
  subject: z.string().nullable(),
  activityType: SalesActivityTypeEnumSchema,
  userId: z.string(),
  userName: z.string(),
  timestamp: z.string(),
  description: z.string().nullable(),
  relatedTask: SalesActivityRelatedTaskResponseBodySchema.nullable(),
  relatedMessage: SalesActivityRelatedMessageResponseBodySchema.nullable(),
});
export type InterestActivityResponseBody = z.infer<typeof InterestActivityResponseBodySchema>;
export type CreateInterestActivityApiResponse = /** status 201  */ UuidResponseBody;
export type CreateInterestActivityApiArg = {
  interestId: string;
  createSalesActivityRequestBody: CreateSalesActivityRequestBody;
};
export type GetInterestActivityListApiResponse = /** status 200  */ InterestActivityResponseBody[];
export type GetInterestActivityListApiArg = {
  interestId: string;
};
export const SalesActivityManualTypeEnumSchema = z.enum([
  'CALL_FROM_CUSTOMER',
  'CALL_TO_CUSTOMER',
  'MESSAGE_FROM_CUSTOMER',
  'MESSAGE_TO_CUSTOMER',
  'CUSTOMER_VISIT',
]);
export type SalesActivityManualTypeEnum = z.infer<typeof SalesActivityManualTypeEnumSchema>;
export const CreateSalesActivityRequestBodySchema = z.object({
  activityType: SalesActivityManualTypeEnumSchema,
  userId: z.string(),
  createdAt: z.string(),
  description: z.string().nullable(),
});
export type CreateSalesActivityRequestBody = z.infer<typeof CreateSalesActivityRequestBodySchema>;
export type CreateBusinessCaseActivityApiResponse = /** status 201  */ UuidResponseBody;
export type CreateBusinessCaseActivityApiArg = {
  businessCaseId: string;
  createSalesActivityRequestBody: CreateSalesActivityRequestBody;
};
export type GetSalePresetSettingsResponseBody = {
  id: string;
  name: string;
  makes: string[];
  watermarkId: string | null;
  backgroundRemovalId: string | null;
  promotionalPhotoId: string | null;
  digitalCertificateCode: string | null;
};
export type CreateSalePresetSettingsApiResponse = /** status 201  */ UuidResponseBody;
export type CreateSalePresetSettingsApiArg = {
  createSalePresetSettingsRequestBody: CreateSalePresetSettingsRequestBody;
};
export type DeleteSalePresetSettingsApiResponse = unknown;
export type DeleteSalePresetSettingsApiArg = {
  salePresetId: string;
};
export type UpdateSalePresetSettingsApiResponse = unknown;
export type UpdateSalePresetSettingsApiArg = {
  salePresetId: string;
  updateSalePresetSettingsRequestBody: UpdateSalePresetSettingsRequestBody;
};
export type CreateSalePresetSettingsRequestBody = {
  name: string;
  makes: string[];
  watermarkId: string | null;
  backgroundRemovalId: string | null;
  promotionalPhotoId: string | null;
  digitalCertificateCode: string | null;
};
export type UpdateSalePresetSettingsRequestBody = {
  name: string;
  makes: string[];
  watermarkId: string | null;
  backgroundRemovalId: string | null;
  promotionalPhotoId: string | null;
  digitalCertificateCode: string | null;
};
export type CreateSalePresetSettingsV2ApiResponse = /** status 201  */ UuidResponseBody;
export type CreateSalePresetSettingsV2ApiArg = {
  createSalePresetSettingsV2RequestBody: CreateSalePresetSettingsV2RequestBody;
};
export type DeleteSalePresetSettingsV2ApiResponse = unknown;
export type DeleteSalePresetSettingsV2ApiArg = {
  salePresetId: string;
  deleteSalePresetSettingsV2RequestBody: DeleteSalePresetSettingsV2RequestBody;
};
export type GetSalePresetSettingsV2ApiResponse =
  /** status 200  */ GetSalePresetSettingsV2ResponseBody;
export type GetSalePresetSettingsV2ApiArg = {
  salePresetId: string;
};
export type UpdateSalePresetSettingsV2ApiResponse = unknown;
export type UpdateSalePresetSettingsV2ApiArg = {
  salePresetId: string;
  updateSalePresetSettingsV2RequestBody: UpdateSalePresetSettingsV2RequestBody;
};
export const PatchBusinessCaseSourceCodeIdRequestBodySchema = z.object({
  sourceCodeId: z.string().nullable(),
});
export type PatchBusinessCaseSourceCodeIdRequestBody = z.infer<
  typeof PatchBusinessCaseSourceCodeIdRequestBodySchema
>;
export type PatchBusinessCaseSourceCodeIdApiResponse = unknown;
export type PatchBusinessCaseSourceCodeIdApiArg = {
  businessCaseId: string;
  patchBusinessCaseSourceCodeIdRequestBody: PatchBusinessCaseSourceCodeIdRequestBody;
};
export const CreateSalePresetSettingsV2RequestBodySchema = z.object({
  name: z.string(),
  makes: z.array(z.string()),
  watermarkId: z.string().nullable(),
  backgroundRemovalId: z.string().nullable(),
  promotionalPhotoId: z.string().nullable(),
  digitalCertificateThemeId: z.string().nullable(),
  applyToVehicles: z.boolean().optional(),
});
export type CreateSalePresetSettingsV2RequestBody = z.infer<
  typeof CreateSalePresetSettingsV2RequestBodySchema
>;
export const DeleteSalePresetSettingsV2RequestBodySchema = z.object({
  replacementId: z.string().nullable().optional(),
});
export type DeleteSalePresetSettingsV2RequestBody = z.infer<
  typeof DeleteSalePresetSettingsV2RequestBodySchema
>;
export const GetSalePresetSettingsV2ResponseBodySchema = z.object({
  id: z.string(),
  name: z.string(),
  makes: z.array(z.string()),
  watermarkId: z.string().nullable(),
  backgroundRemovalId: z.string().nullable(),
  promotionalPhotoId: z.string().nullable(),
  digitalCertificateThemeId: z.string().nullable(),
});
export type GetSalePresetSettingsV2ResponseBody = z.infer<
  typeof GetSalePresetSettingsV2ResponseBodySchema
>;
export const UpdateSalePresetSettingsV2RequestBodySchema = z.object({
  name: z.string(),
  makes: z.array(z.string()),
  watermarkId: z.string().nullable(),
  backgroundRemovalId: z.string().nullable(),
  promotionalPhotoId: z.string().nullable(),
  digitalCertificateThemeId: z.string().nullable(),
});
export type UpdateSalePresetSettingsV2RequestBody = z.infer<
  typeof UpdateSalePresetSettingsV2RequestBodySchema
>;

export type SalesFinanceSettingsResponseBody = {
  defaultDailyInterestRate: BigDecimal | null;
  expectedDaysToSell: number | null;
};
export type GetSalesFinanceSettingsApiResponse =
  /** status 200  */ SalesFinanceSettingsResponseBody;

export const SalesFinanceSettingsRequestBodySchema = z.object({
  defaultDailyInterestRate: BigDecimalSchema.nullable(),
  expectedDaysToSell: z.number().min(0).nullable(),
});
export type SalesFinanceSettingsRequestBody = z.infer<typeof SalesFinanceSettingsRequestBodySchema>;
export type SetSalesFinanceSettingsApiResponse = unknown;
export type SetSalesFinanceSettingsApiArg = {
  salesFinanceSettingsRequestBody: SalesFinanceSettingsRequestBody;
};

export type UpdateKeyLocationApiResponse = unknown;
export type UpdateKeyLocationApiArg = {
  vehicleId: string;
  updateVehicleKeyLocationRequestBody: UpdateVehicleKeyLocationRequestBody;
};
export type UuidResponseBodyBusinessCase = {
  id: string;
};
export type PurchaseBusinessCaseType = 'IN_STOCK' | 'BROKERAGE';
export type CreatePurchaseBusinessCaseRequestBody = {
  customerId: string;
  vehicleToPurchaseId: string;
  purchaseType: PurchaseBusinessCaseType;
  createdAt?: string | null;
};
export type CreateSaleBusinessCaseRequestBody = {
  customerId: string;
  vehicleToSaleId: string;
  createdAt?: string | null;
};
export type UpdateVehicleKeyLocationRequestBody = {
  keyLocation: string | null;
};
export type WindshieldContactEmailAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type WindshieldContactPhoneAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: PhoneNumber | null;
  defaultValue?: PhoneNumber | null;
  displayValue?: PhoneNumber | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type WindshieldUrl = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type VehicleWindshieldAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonMileageAttribute
  | WindshieldContactEmailAttribute
  | WindshieldContactPhoneAttribute
  | CommonDescriptionAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | WindshieldUrl;
export type VehicleWindshieldAdvertisingSettingsResponseBody = {
  attributes: VehicleWindshieldAdvertisingAttributeResponseBody[];
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehicleWindshieldAdvertisingAttributeRequestBody =
  | CommonTitleExtensionAttribute
  | CommonMileageAttribute
  | WindshieldContactEmailAttribute
  | WindshieldContactPhoneAttribute
  | CommonDescriptionAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | WindshieldUrl;
export type PatchVehicleWindshieldAdvertisingSettingsRequestBody = {
  attributes?: VehicleWindshieldAdvertisingAttributeRequestBody[] | null;
};
export type EditPlatformSettingsApiResponse =
  /** status 200  */ VehiclePlatformAdvertisingSettingsResponseBody;
export type EditPlatformSettingsApiArg = {
  vehicleId: string;
  platformCode: AdvertisingPlatformCodeEnum;
  vehiclePlatformAdvertisingSettingsRequestBody: VehiclePlatformAdvertisingSettingsRequestBody;
};
export type GetPlatformSettingsApiResponse =
  /** status 200  */ VehiclePlatformAdvertisingSettingsResponseBody;
export type GetPlatformSettingsApiArg = {
  vehicleId: string;
  platformCode: AdvertisingPlatformCodeEnum;
};

export type DisablePublishCebiaSmartCodeUrlApiResponse = unknown;
export type DisablePublishCebiaSmartCodeUrlApiArg = {
  vehicleId: string;
};

export type EnablePublishCebiaSmartCodeUrlApiResponse = unknown;
export type EnablePublishCebiaSmartCodeUrlApiArg = {
  vehicleId: string;
};

export type PublishCebiaSmartCodeUrlResponseBody = {
  publishCebiaSmartCodeUrl: boolean;
};
export type GetCebiaSmartCodeUrlConfigApiResponse =
  /** status 200  */ PublishCebiaSmartCodeUrlResponseBody;
export type GetCebiaSmartCodeUrlConfigApiArg = {
  vehicleId: string;
};

export type GetGenerationsApiResponse = /** status 200  */ GenerationResponseBody[];
export type GetGenerationsApiArg = {
  vehicleId: string;
};
export type GetVersionsApiResponse = /** status 200  */ VersionResponseBody[];
export type GetVersionsApiArg = {
  vehicleId: string;
};
export type GenerationResponseBody = {
  code: string;
  name: string;
};
export type VersionResponseBody = {
  code: string;
  name: string;
};

export type GetDigitalCertificateThemesApiResponse =
  /** status 200  */ DigitalCertificateThemeResponseBody[];
export type DigitalCertificateThemeResponseBody = {
  id: string;
  name: string;
};
