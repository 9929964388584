import {colord} from 'colord';
import {useAnimatedPopper} from 'platform/components';
import {Box, Icon, Text, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useRef} from 'react';

import {MenuItemProps} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps, useOutsideClick} from 'shared';

import {SIDEBAR_ICON_SIZE} from '../constants/sidebarIconSize';
import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {MenuLink} from './MenuLink';
import {NavigationButton} from './NavigationButton';
import {ShowOnMobileNavigation} from './ShowOnMobileNavigation';

interface SidebarCreateEntityShortcutProps extends TestIdProps {
  menuItems: MenuItemProps[];
  menuItemsById: Record<string, MenuItemProps>;
}

export function SidebarCreateEntityShortcut(props: SidebarCreateEntityShortcutProps) {
  const ref = useRef<HTMLDivElement>(null);
  const isMobileNavigation = useIsMobileNavigation();
  const {togglePopper, closePopper, referenceRef, popperProps, Popper, isOpen} = useAnimatedPopper({
    placement: isMobileNavigation ? 'top-start' : 'right-end',
    isOutsideClickIgnored: true,
    gutter: isMobileNavigation ? 16 : 8,
  });

  useOutsideClick({
    ref,
    enabled: isOpen,
    handler: () => {
      closePopper();
    },
  });

  return (
    <Box ref={ref} width="100%">
      <NavigationButton
        ref={referenceRef}
        isActive={isOpen}
        variant="primary"
        onClick={togglePopper}
        data-testid={suffixTestId('sidebar-create-entity-shortcut', props)}
      >
        <Icon color="text.white" size={SIDEBAR_ICON_SIZE} value="sidebar/add" />
      </NavigationButton>
      <ShowOnMobileNavigation when={isOpen}>
        <PopoverOverlay onClick={closePopper} />
      </ShowOnMobileNavigation>
      <Popper {...popperProps}>
        <Box width={['100vw', '100vw', 'fit-content']} paddingHorizontal={[2, 2, 0]}>
          <Box
            padding={2}
            borderRadius="medium"
            overflowY="auto"
            maxHeight="100vh"
            backgroundColor={isMobileNavigation ? 'general.white' : 'palettes.neutral.900.100'}
          >
            <VStack>
              {props.menuItems.map((menuItem) => (
                <MenuLink to={menuItem.path} key={menuItem.id} onClick={closePopper}>
                  <Text size="small" color={isMobileNavigation ? 'primary' : 'white'}>
                    {i18n.t(menuItem.layoutProps.title)}
                  </Text>
                </MenuLink>
              ))}
            </VStack>
          </Box>
        </Box>
      </Popper>
    </Box>
  );
}

export const PopoverOverlay = styled.div`
  position: fixed;
  top: calc(-1 * ${({theme}) => theme.getSize(12)});
  left: 0;
  height: 100vh;
  min-width: 100vw;
  padding: ${({theme}) => theme.getSize(1)};
  background-color: ${({theme}) => colord(theme.colors.general.black).alpha(0.2).toRgbString()};
`;
