import {z} from 'zod';

import {defaultTo} from 'ramda';

import i18n from '@dms/i18n';

const MIN_VALUE = 0.0001;

export const ReceiveNoteCorrectionItemFormSchema = z
  .object({
    receiveItem: z.object({
      receiveCorrectionQuantity: z.number(),
      receiveCorrectionUnitPrice: z.number(),
      receiveCorrectionStockValue: z.number(),
      receiveAfterQuantity: z.number().positive().min(MIN_VALUE),
      receiveAfterUnitPrice: z.number(),
      receiveAfterStockValue: z.number().positive().min(MIN_VALUE),
      receiveBeforeQuantity: z.number(),
      receiveBeforeUnitPrice: z.number(),
      receiveBeforeStockValue: z.number(),
    }),
    deliveryItem: z.object({
      deliveryCorrectionQuantity: z.number(),
      deliveryCorrectionUnitPrice: z.number(),
      deliveryCorrectionStockValue: z.number(),
      deliveryAfterQuantity: z.number().positive().min(MIN_VALUE),
      deliveryAfterUnitPrice: z.number(),
      deliveryAfterStockValue: z.number().positive().min(MIN_VALUE),
      deliveryBeforeQuantity: z.number(),
      deliveryBeforeUnitPrice: z.number(),
      deliveryBeforeStockValue: z.number(),
    }),
    supplierArticle: z.object({
      supplierQuantity: z.number(),
      supplierUnitId: z.string(),
      warehouseQuantity: z.number(),
      warehouseUnitId: z.string(),
      supplierOrderingNumber: z.string(),
      supplierBulkPackageQuantity: z.number(),
      supplier: z.string(),
    }),
  })
  .superRefine((data, ctx) => {
    if (
      defaultTo(0, data.receiveItem.receiveAfterQuantity) >= data.receiveItem.receiveBeforeQuantity
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['receiveItem', 'receiveAfterQuantity'],
        message: `${i18n.t('entity.warehouse.labels.mustBeLessThanBeforeCorrection')} (<${data.receiveItem.receiveBeforeQuantity})`,
      });
    }

    if (
      defaultTo(0, data.deliveryItem.deliveryAfterQuantity) >=
      data.deliveryItem.deliveryBeforeQuantity
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['deliveryItem', 'deliveryAfterQuantity'],
        message: `${i18n.t('entity.warehouse.labels.mustBeLessThanBeforeCorrection')} (<${data.deliveryItem.deliveryBeforeQuantity})`,
      });
    }
  });

export type ReceiveNoteCorrectionItemForm = z.infer<typeof ReceiveNoteCorrectionItemFormSchema>;
