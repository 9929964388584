import {showNotification} from 'platform/components';

import {useNavigate} from 'react-router-dom';

import {useCreateChecklistDefinitionMutation} from '@dms/api/checklist';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {SettingsTemplate} from '../../../../components/SettingsTemplate/SettingsTemplate';
import {ChecklistForm} from '../../components/ChecklistForm';

export function Create() {
  const navigate = useNavigate();

  const [createChecklistDefinition, createChecklistDefinitionQuery] =
    useCreateChecklistDefinitionMutation();

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.settings.generalChecklist.create'),
        breadcrumbs: [
          {
            label: i18n.t('page.generalSettings.labels.title'),
            isCurrentPage: true,
          },
          {
            label: i18n.t('page.settings.generalChecklist.title'),
            href: settingsRoutes.generalChecklist,
          },
        ],
      }}
      isCreating
      data-testid={testIds.settings.generalChecklistCreate()}
    >
      <ChecklistForm
        onSubmit={(formData) => {
          createChecklistDefinition({
            body: formData,
          })
            .unwrap()
            .then(() => {
              navigate(settingsRoutes.generalChecklist);
              showNotification.success();
            })
            .catch(handleApiError);
        }}
        isLoading={createChecklistDefinitionQuery.isLoading}
      />
    </SettingsTemplate>
  );
}
