import {DataStatus, Separator, Tabs} from 'platform/components';
import {Box, Grid, Hide, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useCallback, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useSelector} from 'react-redux';

import {isNil, isNotNil} from 'ramda';

import {useSourcingVehicleDetailQuery} from '@dms/api/sourcing';
import {selectTenant} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {formatDocumentTitle} from '@dms/shared';
import {selectUserSettings, useRouter} from '@dms/teas';

import {AdvanceVehicleView} from '../../components/AdvanceVehicleView/AdvanceVehicleView';
import {VehiclePreview} from '../../components/VehiclePreview/VehiclePreview';
import {VehiclePriceForm} from '../../components/VehiclePriceForm/VehiclePriceForm';
import {CommentsTab} from './(sections)/CommentsTab/CommentsTab';
import {CostsTab} from './(sections)/CostsTab/CostsTab';
import {DrillDownTab} from './(sections)/DrillDownTab/DrillDownTab';
import {FullScreenDetailLayout} from './(sections)/FullScreenDetailLayout/FullScreenDetailLayout';
import {HighlightsTab} from './(sections)/HighlightsTab/HighlightsTab';
import {PriceMapTab} from './(sections)/PriceMapTab/PriceMapTab';

const HTML_INPUT_TEXT_TYPES = [
  'tel',
  'url',
  'text',
  'date',
  'time',
  'email',
  'number',
  'search',
  'password',
  'datetime-local',
  'textarea',
];

export function VehicleDetailPage() {
  const router = useRouter();
  const vehicleId = String(router.params.adId);

  const defaultActiveTab = 'drillDown';
  const [tabId, setTabId] = useState(defaultActiveTab);
  const [fullScreen, setFullScreen] = useState(false);

  const {data: selectedTenant} = useSelector(selectTenant);
  const tenantCountry = selectedTenant?.country;
  const {currency, country = tenantCountry} = useSelector(selectUserSettings);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (
        e.key === 'Backspace' &&
        !HTML_INPUT_TEXT_TYPES.includes((e.target as HTMLInputElement).type)
      ) {
        e.preventDefault();
        router.back();
      }
    },
    [router]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const {
    data: vehicle,
    isLoading,
    isError,
  } = useSourcingVehicleDetailQuery(
    {
      externalId: vehicleId ?? '',
      currency,
      buyerCountry: country,
    },
    {skip: isNil(vehicleId)}
  );

  const tabs = [
    {
      id: 'drillDown',
      title: i18n.t('general.labels.drillDown'),
      content: <DrillDownTab vehicle={vehicle} setFullScreen={setFullScreen} />,
    },
    {
      id: 'priceMap',
      title: i18n.t('general.labels.priceMap'),
      content: isNotNil(vehicle) && (
        <PriceMapTab
          vehicle={vehicle}
          data-testid={testIds.sourcing.vehicleDetail('priceMapTab')}
        />
      ),
    },
    {
      id: 'costs',
      title: i18n.t('general.labels.costs'),
      content: isNotNil(vehicle) && <CostsTab id={vehicleId} vehicle={vehicle} />,
    },
    {
      id: 'highlights',
      title: i18n.t('entity.vehicle.labels.highlighted'),
      content: isNotNil(vehicle) && <HighlightsTab adId={vehicleId} vehicle={vehicle} />,
    },
    {
      id: 'comments',
      title: i18n.t('page.comments.labels.comments'),
      content: (
        <CommentsTab
          vehicleId={vehicleId}
          data-testid={testIds.sourcing.vehicleDetail('commensTab')}
        />
      ),
    },
  ];

  return fullScreen && isNotNil(vehicle) ? (
    <FullScreenDetailLayout vehicle={vehicle} onClose={() => setFullScreen(false)} />
  ) : (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle(
            i18n.t('entity.vehicle.labels.vehicle'),
            i18n.t('page.sourcing.labels.sourcing')
          )}
        </title>
      </Helmet>
      <Grid columns={[1, 1, 2, 2]} spacing={0}>
        <DataStatus isLoading={isLoading} isError={isError}>
          <VStack height="100%" minWidth={0}>
            <AdvanceVehicleView
              vehicle={vehicle}
              isVehicleLoading={isLoading}
              data-testid={testIds.sourcing.vehicleDetail('advancedVehicleView')}
            />

            <Box paddingTop={2} paddingHorizontal={4} height="100%">
              <VehiclePriceForm
                vehicleId={vehicleId}
                initialValues={{
                  purchasePrice: vehicle?.vehicleSummary?.purchasePrice?.toString() ?? undefined,
                  sellingPrice: vehicle?.vehicleSummary?.sellingPrice?.toString() ?? undefined,
                  comment: vehicle?.vehicleSummary?.comment ?? undefined,
                }}
                data-testid={testIds.sourcing.vehicleDetail('vehiclePriceForm')}
              />

              <TabsWrapper>
                <Tabs
                  tabs={tabs}
                  activeTabId={tabId}
                  onChange={setTabId}
                  data-testid={testIds.sourcing.vehicleDetail('vehicleDetailPage-tabs')}
                />
              </TabsWrapper>
            </Box>
          </VStack>
        </DataStatus>

        <VStack minWidth={0}>
          <Hide onNotebook onDesktop>
            <Separator />
          </Hide>
          <VehiclePreview vehicleId={vehicleId} isScrollDisabled />
        </VStack>
      </Grid>
    </>
  );
}

// Force Tabs component and all its tabs to have 100% height
// eslint-disable-next-line eag/no-css-property
const TabsWrapper = styled.div`
  height: 100%;

  & > :last-child {
    height: 100%;

    & > * {
      height: 100%;
    }
  }
`;
