import {Button, DataStatus, openDialog} from 'platform/components';
import {VStack, HStack, Show} from 'platform/foundation';

import {isFalse, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetBusinessCaseQuery} from '@dms/api/businessCase';
import {useGetBusinessCaseActivityListQuery} from '@dms/api/sales';
import i18n from '@dms/i18n';
import {usePermissions, ActivitySidebarSendMessage, LogContactDialog} from '@dms/shared';

import {parseDate} from 'shared';

import {ActivityList} from './ActivityList';
import {EmptyState} from './EmptyState';

interface ActivityTabProps {
  businessCaseId: string;
}

export function ActivityTab(props: ActivityTabProps) {
  const [canReadTasks, canCreateTasks] = usePermissions({
    permissionKeys: ['readTask', 'createTask'],
  });

  const {
    data: businessCaseData,
    isLoading: isBusinessCaseLoading,
    isError: isBusinessCaseErrored,
  } = useGetBusinessCaseQuery(
    {businessCaseId: props.businessCaseId},
    {skip: isNilOrEmpty(props.businessCaseId)}
  );

  const {
    data,
    isLoading: isBusinessCaseActivityLoading,
    isError: isBusinessCaseActivityErrored,
  } = useGetBusinessCaseActivityListQuery(
    {businessCaseId: props.businessCaseId},
    {skip: isNilOrEmpty(props.businessCaseId)}
  );

  const pastActivities =
    data?.filter((activity) => parseDate(activity.timestamp) < new Date()) ?? [];
  const nextSteps = data?.filter((activity) => parseDate(activity.timestamp) >= new Date()) ?? [];
  const isAddNewTaskEnabled = canReadTasks && canCreateTasks;

  const isLoading = isBusinessCaseLoading || isBusinessCaseActivityLoading;
  const isError = isBusinessCaseErrored || isBusinessCaseActivityErrored;

  if (isNilOrEmpty(data)) {
    return <EmptyState businessCaseId={props.businessCaseId} />;
  }

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack spacing={4}>
        <HStack spacing={2}>
          <ActivitySidebarSendMessage customerId={businessCaseData!.customerId} />

          <Button
            leftIcon="content/add_circle"
            title={i18n.t('entity.task.actions.createTask')}
            variant="link"
            size="small"
            isDisabled={isFalse(isAddNewTaskEnabled)}
          />
          <Button
            leftIcon="communication/phone"
            title={i18n.t('entity.activityPanel.activity.logCall')}
            onClick={() =>
              openDialog(
                <LogContactDialog
                  businessCaseId={props.businessCaseId}
                  customerId={businessCaseData?.customerId ?? ''}
                />,
                {
                  id: 'LOG_CONTACT_DIALOG',
                  title: i18n.t('entity.activityPanel.logContact.createLog'),
                  scrollBehavior: 'outside',
                }
              )
            }
            variant="link"
            size="small"
          />
        </HStack>

        <Show when={isNotNilOrEmpty(nextSteps)}>
          <ActivityList
            label={i18n.t('entity.activityPanel.activity.nextSteps')}
            items={nextSteps}
          />
        </Show>
        <Show when={isNotNilOrEmpty(pastActivities)}>
          <ActivityList
            label={i18n.t('entity.activityPanel.activity.pastActivities')}
            items={pastActivities}
          />
        </Show>
      </VStack>
    </DataStatus>
  );
}
