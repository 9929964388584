import {Attributes, AttributesRow, Button, Card} from 'platform/components';
import {HStack, VStack, Heading} from 'platform/foundation';
import {useFormatCurrency, useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {GetCheckoutByContextApiResponse, Invoice} from '@dms/api/metadaCheckout';
import i18n from '@dms/i18n';

import {parseDate, buildArray, suffixTestId, Nullish, RequiredTestIdProps, openFile} from 'shared';

import {useBank} from '../../hooks/useBank';
import {useCashRegister} from '../../hooks/useCashRegister';
import {downloadFile} from '../../utils/downloadFile';

interface CheckoutInvoiceOverviewProps extends RequiredTestIdProps {
  checkout: GetCheckoutByContextApiResponse;
  invoice: Invoice | Nullish;
}

const getPaymentMethodLabel = (method: string | Nullish) =>
  match(method)
    .with('BANK_TRANSFER', always(i18n.t('entity.checkout.labels.paymentMethods.bankTransfer')))
    .with('CASH', always(i18n.t('entity.checkout.labels.paymentMethods.cash')))
    .with('CARD', always(i18n.t('entity.checkout.labels.paymentMethods.card')))
    .otherwise(always(undefined));

export function CheckoutInvoiceOverview(props: CheckoutInvoiceOverviewProps) {
  const formatCurrency = useFormatCurrency();
  const formatDateTime = useDateTimeFormatter();

  const {getBankAccountByNumber, getBankAccountName} = useBank();
  const {getCashRegisterById} = useCashRegister();

  const bankAccountNumber = props.checkout?.paymentInfo?.incomingBankAccount?.bankAccount;
  const bankAccout = isNotNil(bankAccountNumber)
    ? getBankAccountByNumber(bankAccountNumber)
    : undefined;
  const bankAccountName = isNotNil(bankAccout) ? getBankAccountName(bankAccout) : undefined;
  const fileUrl = props.invoice?.document?.pdfUrl;
  const foreignPayment = props.checkout?.paymentInfo?.foreignCurrencyPayment;

  const attributes = buildArray<AttributesRow>([
    {
      label: i18n.t('entity.checkout.labels.amount'),
      value:
        props.invoice?.totalPriceWithVat &&
        formatCurrency(
          props.invoice?.totalPriceWithVat?.amount ?? 0,
          props.invoice?.totalPriceWithVat?.currency ?? '',
          2
        ),
    },
    {
      label: i18n.t('general.labels.amountWithoutVat'),
      value:
        props.invoice?.totalPriceWithoutVat &&
        formatCurrency(
          props.invoice?.totalPriceWithoutVat?.amount ?? 0,
          props.invoice?.totalPriceWithoutVat?.currency ?? '',
          2
        ),
    },
  ])
    .when(foreignPayment, {
      label: i18n.t('entity.checkout.labels.amountInForeignCurrency'),
      value: formatCurrency(foreignPayment?.amount ?? 0, foreignPayment?.currency ?? '', 2),
    })
    .when(foreignPayment, {
      label: i18n.t('general.labels.exchangeRate'),
      value: formatCurrency(
        foreignPayment?.exchangeRate ?? 0,
        props.invoice?.totalPriceWithoutVat?.currency ?? '',
        2
      ),
    })
    .add({
      label: i18n.t('entity.checkout.labels.paymentMethod'),
      value: getPaymentMethodLabel(props.checkout?.paymentInfo?.method),
    })
    .add({
      label: i18n.t('entity.checkout.labels.issuedOn'),
      value:
        props.invoice?.issueDate &&
        formatDateTime('dateShort', parseDate(props.invoice?.issueDate)),
    })
    .add({
      label: i18n.t('entity.checkout.labels.dueDate'),
      value:
        props.invoice?.dueDate && formatDateTime('dateShort', parseDate(props.invoice?.dueDate)),
    })
    .add({
      label: i18n.t('entity.checkout.labels.taxableEventDate'),
      value:
        props.checkout?.paymentInfo?.taxableEventDate &&
        formatDateTime('dateShort', parseDate(props.checkout?.paymentInfo?.taxableEventDate)),
    })
    .when(props.checkout?.paymentInfo?.method === 'BANK_TRANSFER', {
      label: i18n.t('general.labels.bankAccount'),
      value: bankAccountName,
    })
    .when(props.checkout?.paymentInfo?.method === 'CASH', {
      label: i18n.t('general.labels.cashRegister'),
      value:
        props.checkout?.paymentInfo?.cashRegisterId &&
        getCashRegisterById(props.checkout?.paymentInfo?.cashRegisterId)?.name,
    })
    .when(fileUrl, {
      label: i18n.t('entity.checkout.labels.invoice'),
      content: (
        <HStack justify="space-between">
          <Button
            variant="link"
            title={i18n.t('entity.checkout.labels.invoice')}
            size="small"
            onClick={() => openFile(fileUrl ?? '')}
            data-testid={suffixTestId('invoice', props)}
          />
          <Button
            variant="link"
            title={i18n.t('general.actions.download')}
            leftIcon="file/download"
            size="small"
            onClick={() => downloadFile(fileUrl ?? '')}
            data-testid={suffixTestId('downloadInvoice', props)}
          />
        </HStack>
      ),
    });

  return (
    <Card variant="inlineGrey">
      <VStack spacing={4}>
        <Heading size={4}>{i18n.t('entity.checkout.labels.totalPayment')}</Heading>
        <Attributes rows={attributes} data-testid={suffixTestId('overview', props)} />
      </VStack>
    </Card>
  );
}
