import {Card, DataStatus} from 'platform/components';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import {GetCostCorrectionResponse} from '@dms/api/metadaWarehouseCostCorrection';
import i18n from '@dms/i18n';
import {Section} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

interface DocumentsProps extends RequiredTestIdProps {
  costCorrection: GetCostCorrectionResponse | Nullish;
  isCostCorrectionLoading: boolean;
}

export function Documents(props: DocumentsProps) {
  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([
        filter,
        {warehouseIssueNoteCorrectionId: props.costCorrection?.costCorrectionInfo.costCorrectionId},
      ]),
    [props.costCorrection?.costCorrectionInfo.costCorrectionId]
  );

  return (
    <Section isFullHeight data-testid={suffixTestId('wrapper', props)}>
      <DataStatus isLoading={props.isCostCorrectionLoading} minHeight={60}>
        <Card isFullHeight title={i18n.t('entity.warehouse.labels.documents')}>
          <DataGrid
            gridCode="document-context-list-warehouse-issue-note-correction"
            queryModifier={queryModifier}
            data-testid="documentContextListWarehouseIssueNoteCorrection"
          />
        </Card>
      </DataStatus>
    </Section>
  );
}
