import {ActivitySidebar, ActivitySidebarAction} from '@dms/shared';

import {buildArray} from 'shared';

import {ActivityTab} from './ActivityTab';

interface InterestActivityPanelProps {
  interestId: string;
}

export function InterestActivityPanel(props: InterestActivityPanelProps) {
  const actions = buildArray<ActivitySidebarAction>().add({
    label: 'Activity',
    icon: 'action/history',
    component: <ActivityTab interestId={props.interestId} />,
  });

  return <ActivitySidebar actions={actions} />;
}
