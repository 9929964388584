import {
  Integer,
  ThemeIconKey,
  Center,
  Hide,
  Icon,
  Show,
  ThemeColorPath,
  getColorByPath,
  Image,
  getSize,
  ThemeColorTextPath,
  getTextColorByPath,
} from 'platform/foundation';
import {css, useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {always, isNil, isNotEmpty} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {getInitials} from './getInitials';

type AvatarSize = 'xSmall' | 'small' | 'default' | 'large';
type AvatarVariant = 'square' | 'circle';

export interface AvatarProps extends TestIdProps {
  size?: AvatarSize;
  variant?: AvatarVariant;
  color?: ThemeColorPath;
  textColor?: ThemeColorTextPath;
  icon?: ThemeIconKey;
  name?: string | null;
  imageUrl?: string | null;
}

export function Avatar(props: AvatarProps) {
  const theme = useTheme();

  const showImage = isNotNil(props.imageUrl) && isNotEmpty(props.imageUrl);
  const showName = isNotNil(props.name) && isNotEmpty(props.name) && !showImage;

  const size = match<AvatarSize, Integer>(props.size ?? 'default')
    .with('xSmall', always(6))
    .with('small', always(7))
    .with('default', always(10))
    .with('large', always(18))
    .exhaustive();

  const iconSize = match<AvatarSize, Integer>(props.size ?? 'default')
    .with('xSmall', always(3))
    .with('small', always(4))
    .with('default', always(6))
    .with('large', always(10))
    .exhaustive();

  const textSize = match<AvatarSize, string>(props.size ?? 'default')
    .with('xSmall', always('0.625rem'))
    .with('small', always(theme.fontSizes.text.xSmall))
    .with('default', always('1rem'))
    .with('large', always('1.75rem'))
    .exhaustive();

  const borderRadius = props.variant === 'square' ? 'medium' : 'circular';

  const backgroundColor = isNil(props.imageUrl)
    ? props.color || DEFAULT_BACKGROUND_COLOR
    : DEFAULT_BACKGROUND_COLOR;

  return (
    <div
      css={css`
        width: ${getSize(size)};
        height: ${getSize(size)};
        border-radius: ${theme.radii[borderRadius]};
        background-color: ${getColorByPath(backgroundColor, theme)};
      `}
      data-testid={suffixTestId('avatar', props)}
    >
      <Center width={size} height={size}>
        <Show when={showImage}>
          <Image
            src={props.imageUrl}
            height="100%"
            width="100%"
            isLazy
            hasSpinner
            borderRadius={borderRadius}
          />
        </Show>

        <Show when={showName}>
          <div
            css={css`
              color: ${getTextColorByPath(props.textColor || DEFAULT_TEXT_COLOR, theme)};
              font-family: ${theme.fonts.body};
              font-size: ${textSize};
              font-weight: ${theme.fontWeights.medium};
              white-space: nowrap;
              line-height: 1;
            `}
          >
            {getInitials(props.name ?? '')}
          </div>
        </Show>

        <Hide when={showName || showImage}>
          <Icon value={props.icon ?? 'social/person'} size={iconSize} />
        </Hide>
      </Center>
    </div>
  );
}

const DEFAULT_TEXT_COLOR = 'primary';
const DEFAULT_BACKGROUND_COLOR = 'palettes.neutral.30.100';
