import {DataStatus} from 'platform/components';
import {HStack} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

import {useApiEnums} from '../../hooks/useApiEnums';
import {useMakeModelFilter} from '../../hooks/useMakeModelFilter';
import {SourcingFilterPresetsControl} from '../../hooks/useSourcingFilterPresets';
import {FilterHeaderButtons} from './components/FilterHeaderButtons';
import {FilterTags} from './components/FilterTags';

interface FilterHeaderProps extends TestIdProps {
  filterPresetsControl: SourcingFilterPresetsControl;
  showSaveButton?: boolean;
}

export function FilterHeader(props: FilterHeaderProps) {
  const {isLoading: areEnumsLoading} = useApiEnums();
  const {isLoading: areMakeModelsLoading} = useMakeModelFilter();

  return (
    <DataStatus isLoading={areEnumsLoading || areMakeModelsLoading}>
      <HStack width="100%" align="center" spacing={1} wrap>
        <FilterTags data-testid={suffixTestId('filterTags', props)} />
        <FilterHeaderButtons
          filterPresetsControl={props.filterPresetsControl}
          showSaveButton={props.showSaveButton}
          data-testid={suffixTestId('filterHeaderButtons', props)}
        />
      </HStack>
    </DataStatus>
  );
}
