import {metadaApi} from '@dms/api/core';
import {BaseVoidResponseSchema} from '@dms/api/shared';

import {
  DeleteAllMaterialBasketItemsApiArg,
  DeleteAllMaterialBasketItemsApiResponse,
  GetMaterialBasketItemsApiArg,
  GetMaterialBasketItemsApiResponse,
  PatchMaterialBasketItemQuantityApiArg,
  PatchMaterialBasketItemQuantityApiResponse,
  PostMaterialBasketItemForPriceListFeApiArg,
  PostMaterialBasketItemForPriceListFeApiResponse,
  PostMaterialBasketItemsToOrderApiArg,
  PostMaterialBasketItemsToOrderApiResponse,
  PostMaterialBasketItemForWarehouseApiArg,
  PostMaterialBasketItemForWarehouseApiResponse,
  DeleteMaterialBasketItemsApiResponse,
  DeleteMaterialBasketItemsApiArg,
  GetMaterialBasketItemsApiResponseSchema,
  GetMaterialBasketItemsApiArgSchema,
  DeleteAllMaterialBasketItemsApiArgSchema,
  DeleteMaterialBasketItemsApiArgSchema,
  PostMaterialBasketItemsToOrderApiArgSchema,
  PatchMaterialBasketItemQuantityApiResponseSchema,
  PatchMaterialBasketItemQuantityApiArgSchema,
  PostMaterialBasketItemForWarehouseApiArgSchema,
  PostMaterialBasketItemApiResponseSchema,
  PostMaterialBasketItemForPriceListFeApiArgSchema,
} from './types';

export const metadaWorkshopMaterialBasketApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getMaterialBasketItems: build.query<
      GetMaterialBasketItemsApiResponse,
      GetMaterialBasketItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/material-basket`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'materialBasketItems', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: GetMaterialBasketItemsApiResponseSchema,
        requestSchema: GetMaterialBasketItemsApiArgSchema,
      },
    }),
    deleteAllMaterialBasketItems: build.mutation<
      DeleteAllMaterialBasketItemsApiResponse,
      DeleteAllMaterialBasketItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/material-basket`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'materialBasketItems', id: queryArg.serviceJobId},
        {type: 'itemsBasketActions', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: DeleteAllMaterialBasketItemsApiArgSchema,
      },
    }),
    deleteMaterialBasketItems: build.mutation<
      DeleteMaterialBasketItemsApiResponse,
      DeleteMaterialBasketItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/material-basket/delete-items`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'materialBasketItems', id: queryArg.serviceJobId},
        {type: 'itemsBasketActions', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: DeleteMaterialBasketItemsApiArgSchema,
      },
    }),
    postMaterialBasketItemsToOrder: build.mutation<
      PostMaterialBasketItemsToOrderApiResponse,
      PostMaterialBasketItemsToOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/material-basket/checkout`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'materialBasketItems', id: queryArg.serviceJobId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceJobId},
        {type: 'itemsBasketActions', id: queryArg.serviceJobId},
        {type: 'serviceActions', id: queryArg.serviceCaseId},
        {type: 'orderActions', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceJobActions', id: queryArg.serviceJobId},
        'checkout',
        'afsCheckout',
        'afsPaymentsList',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
        'afsPaymentIssueRestriction',
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: PostMaterialBasketItemsToOrderApiArgSchema,
      },
    }),
    patchMaterialBasketItemQuantity: build.mutation<
      PatchMaterialBasketItemQuantityApiResponse,
      PatchMaterialBasketItemQuantityApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/material-basket/item/${queryArg.serviceItemId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'materialBasketItems', id: queryArg.serviceJobId},
        {type: 'materialOrderItem', id: queryArg.serviceItemId},
      ],
      extraOptions: {
        responseSchema: PatchMaterialBasketItemQuantityApiResponseSchema,
        requestSchema: PatchMaterialBasketItemQuantityApiArgSchema,
      },
    }),
    postMaterialBasketItemForWarehouse: build.mutation<
      PostMaterialBasketItemForWarehouseApiResponse,
      PostMaterialBasketItemForWarehouseApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/material-basket/item/${queryArg.warehouseAction}`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'materialBasketItems', id: queryArg.serviceJobId},
      ],
      extraOptions: {
        responseSchema: PostMaterialBasketItemApiResponseSchema,
        requestSchema: PostMaterialBasketItemForWarehouseApiArgSchema,
      },
    }),
    postMaterialBasketItemForPriceList: build.mutation<
      PostMaterialBasketItemForPriceListFeApiResponse,
      PostMaterialBasketItemForPriceListFeApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/job/${queryArg.serviceJobId}/material-basket/item/price-list`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostMaterialBasketItemApiResponseSchema,
        requestSchema: PostMaterialBasketItemForPriceListFeApiArgSchema,
      },
    }),
  }),
});

export const {
  useGetMaterialBasketItemsQuery,
  useDeleteAllMaterialBasketItemsMutation,
  usePostMaterialBasketItemsToOrderMutation,
  useDeleteMaterialBasketItemsMutation,
  usePatchMaterialBasketItemQuantityMutation,
  usePostMaterialBasketItemForWarehouseMutation,
  usePostMaterialBasketItemForPriceListMutation,
} = metadaWorkshopMaterialBasketApi;
