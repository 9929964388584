import {
  Action,
  Card,
  DataStatus,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {Show} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isEmpty, isNil, mergeAll, not} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {ReceiveNoteCorrectionTotalPrice} from '@dms/api/metadaWarehouseReceiveNoteCorrection';
import {
  PatchReceiveNoteCorrectionItemsDeleteRequest,
  useDeleteReceiveNoteCorrectionItemsMutation,
} from '@dms/api/metadaWarehouseReceiveNoteCorrectionItem';
import i18n from '@dms/i18n';
import {catchUnhandledDataGridActions, handleApiError} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

import {OverviewTotalprice} from '../../../../../components/OverviewTotalPrice';
import {MISSING_EDITING_DETAILS_MESSAGE} from '../../../../../constants/missingEditingDetailsMessage';
import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';
import {ReceiveNoteCorrectionAddMaterialModal} from './ReceiveNoteCorrectionAddMaterialModal';
import {ReceiveNoteCorrectionItemEdit} from './ReceiveNoteCorrectionItemEdit';

interface ReceiveNoteCorrectionItemsListProps extends RequiredTestIdProps {
  receiveNoteId: string;
  isCreatingReceiveNoteCorrection: boolean;
  isReceiveNoteCorrectionPending: boolean;
  totalPrice: ReceiveNoteCorrectionTotalPrice | Nullish;
  totalPriceCurrency: string;
}

export function ReceiveNoteCorrectionItemsList(props: ReceiveNoteCorrectionItemsListProps) {
  const {receiveNoteCorrectionId} = useWarehouseParams();

  const formatCurrency = useFormatCurrency();

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [isMaterialModalVisible, openMaterialModal, closeMaterialModal] = useBoolean();

  const [deleteReceiveNoteCorrectionItems] = useDeleteReceiveNoteCorrectionItemsMutation();

  const handleClose = () => {
    closeMaterialModal();
    refreshDataGrid();
  };

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    match(actionKey)
      .with('redirectDetail', () => {
        const receiveNoteCorrectionItemId = isArray(rowId) ? head(rowId) : rowId;

        if (
          isEmpty(receiveNoteCorrectionId) ||
          isNil(receiveNoteCorrectionItemId) ||
          isEmpty(receiveNoteCorrectionItemId)
        ) {
          throw new Error(MISSING_EDITING_DETAILS_MESSAGE);
        }

        openDialog(
          <ReceiveNoteCorrectionItemEdit
            receiveNoteCorrectionId={receiveNoteCorrectionId}
            receiveNoteCorrectionItemId={receiveNoteCorrectionItemId}
            onAfterSubmit={refreshDataGrid}
            data-testid={suffixTestId('itemEditDialog', props)}
          />,
          {
            id: 'itemEditDialog',
            title: i18n.t('entity.warehouse.labels.editReceiveNoteCorrectionItem'),
            size: 'large',
            withAdditionalFooter: true,
            'data-testid': suffixTestId('itemEditDialog', props),
          }
        );
      })
      .with('delete', () => {
        const ids = isArray(rowId) ? rowId : [rowId];

        const requestBody: PatchReceiveNoteCorrectionItemsDeleteRequest['body'] = {
          state: 'ADDED',
          creditNoteItemId: ids,
        };

        openDeleteDialog({
          id: 'itemDeleteDialog',
          onConfirm: async () =>
            await deleteReceiveNoteCorrectionItems({
              creditNoteId: receiveNoteCorrectionId,
              body: requestBody,
            })
              .unwrap()
              .then(() =>
                showNotification.success(i18n.t('general.notifications.successfullyDeleted'))
              )
              .then(refreshDataGrid)
              .catch(handleApiError),
          'data-testid': suffixTestId('itemDeleteDialog', props),
        });
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {creditNoteId: receiveNoteCorrectionId}]),
    [receiveNoteCorrectionId]
  );

  const listOfItemsCardActions: Action[] = [
    {
      type: 'button',
      variant: 'link',
      leftIcon: 'content/add_circle',
      title: i18n.t('general.labels.add'),
      onClick: openMaterialModal,
      isDisabled: not(props.isReceiveNoteCorrectionPending),
      'data-testid': suffixTestId('actions.add', props),
    },
  ];

  const totalPriceWithoutVat = formatCurrency(
    props.totalPrice?.totalPriceWithoutVat ?? 0,
    props.totalPriceCurrency,
    2
  );

  const totalPriceWithVat = formatCurrency(
    props.totalPrice?.totalPriceWithVat ?? 0,
    props.totalPriceCurrency,
    2
  );

  return (
    <>
      <Card
        title={i18n.t('general.labels.listOfItems')}
        actions={listOfItemsCardActions}
        data-testid={props['data-testid']}
      >
        <DataStatus isEmpty={props.isCreatingReceiveNoteCorrection} minHeight={55}>
          <DataGrid
            ref={dataGridRef}
            gridCode="warehouse-credit-note-item"
            actionCallback={actionCallback}
            queryModifier={queryModifier}
            autoHeight
            data-testid={suffixTestId('receiveNoteCorrectionItems', props)}
          />
          <OverviewTotalprice
            totalPriceWithoutVat={totalPriceWithoutVat}
            totalPriceWithVat={totalPriceWithVat}
            data-testid={suffixTestId('totalPrice', props)}
          />
        </DataStatus>
      </Card>

      <Show when={isMaterialModalVisible}>
        <ReceiveNoteCorrectionAddMaterialModal
          receiveNoteId={props.receiveNoteId}
          receiveNoteCorrectionId={receiveNoteCorrectionId}
          onClose={handleClose}
          data-testid={suffixTestId('addMaterial', props)}
        />
      </Show>
    </>
  );
}
