import {match} from 'ts-pattern';

import {isNil, path} from 'ramda';

import {catchUnhandledDataGridActions} from '@dms/shared';

import {RequiredTestIdProps} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../../../../components/FullscreenDataGridWrapper';
import {ItemRowData} from '../types/ItemRowData';
import {MaterialPriceListTabExternalFilters} from './MaterialPriceListTabExternalFilters';

interface ReceiveNoteMaterialPriceListTabProps extends RequiredTestIdProps {
  warehouseId: string;
  onAddToBasket: (itemRowData: ItemRowData) => void;
}

const RECEIVE_NOTE_MATERIAL_EXTERNAL_FILTER_ID = 'receiveNote-material-external-filter';

export function ReceiveNoteMaterialPriceListTab(props: ReceiveNoteMaterialPriceListTabProps) {
  const actionCallback: ActionCallback = ({actionKey, rowData}) => {
    const itemManufacturerNumber = path(['catalogNumber', 'value'], rowData) as string;
    const itemManufacturerId = path(['manufacturerId', 'value', 'id'], rowData) as string;
    const itemName = path(['name', 'value'], rowData) as string;

    if (isNil(itemManufacturerNumber)) {
      throw new Error('ItemManufacturerNumber is not defined');
    }

    if (isNil(itemManufacturerId)) {
      throw new Error('ItemManufacturerId is not defined');
    }

    if (isNil(itemName)) {
      throw new Error('ItemName is not defined');
    }

    const itemRowData: ItemRowData = {
      warehouseId: props.warehouseId,
      articleId: '', // not available on price list DG, is handled within props.onAddToBasket
      catalogNumber: itemManufacturerNumber,
      name: itemName,
    };

    match(actionKey)
      .with('addToBasket', () => {
        props.onAddToBasket(itemRowData);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper
      externalFilterId={RECEIVE_NOTE_MATERIAL_EXTERNAL_FILTER_ID}
      externalFilterHeight={100}
      hideCardWrapper
      data-testid={props['data-testid']}
    >
      <DataGrid
        gridCode="supplier-price-list-cart"
        actionCallback={actionCallback}
        externalFilterId={RECEIVE_NOTE_MATERIAL_EXTERNAL_FILTER_ID}
        _useAsLastResort_definitionBehaviorOverrides={{filterMode: 'NATIVE'}}
        filterComponent={MaterialPriceListTabExternalFilters}
        data-testid="supplier-price-list-cart"
      />
    </FullscreenDataGridWrapper>
  );
}
