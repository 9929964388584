import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useGetEmployeeMechanicsQuery} from '@dms/api/metadaEmployee';
import i18n from '@dms/i18n';
import {getOptionsFromMechanics} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {BulkAssignMechanicForm, BulkAssignMechanicFormSchema} from './types/BulkAssignMechanicForm';

interface BulkAssignMechanicProps extends RequiredTestIdProps {
  authorizationProfileId: string;
  isAssigning: boolean;
  onAssign: (mechanicId: string) => Promise<void>;
}

export function BulkAssignMechanic(props: BulkAssignMechanicProps) {
  const {
    data: mechanics,
    isLoading: areMechanicsLoading,
    isError: hasMechanicsError,
  } = useGetEmployeeMechanicsQuery({authorizationProfileId: props.authorizationProfileId});

  const defaultValues: BulkAssignMechanicForm = {mechanicId: ''};

  const handleSubmit: FormSubmitHandler<BulkAssignMechanicForm> = async (formValues) => {
    await props.onAssign(formValues.mechanicId);
  };

  return (
    <Form
      experimentalZodSchema={BulkAssignMechanicFormSchema}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
    >
      {(control) => (
        <VStack spacing={4} data-testid={props['data-testid']}>
          <DataStatus isError={hasMechanicsError}>
            <FormField
              isRequired
              isLoading={areMechanicsLoading}
              control={control}
              name="mechanicId"
              type="choice"
              options={getOptionsFromMechanics(mechanics)}
              data-testid={suffixTestId('mechanic', props)}
            />
            <ButtonGroup align="right">
              <Button
                isLoading={props.isAssigning}
                type="button"
                variant="secondary"
                title={i18n.t('general.actions.discard')}
                onClick={closeCurrentDialog}
                data-testid={suffixTestId('actions.discard', props)}
              />
              <Button
                isLoading={props.isAssigning}
                type="submit"
                variant="primary"
                title={i18n.t('general.actions.assign')}
                data-testid={suffixTestId('actions.submit', props)}
              />
            </ButtonGroup>
          </DataStatus>
        </VStack>
      )}
    </Form>
  );
}
