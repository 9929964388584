import {diff} from 'deep-object-diff';
import {
  Alert,
  Button,
  ButtonGroup,
  Choice,
  closeCurrentDialog,
  closeDialog,
  DataStatus,
  DialogFooter,
  Form,
  FormButton,
  FormSubmitHandler,
  openDialog,
  Segment,
  Separator,
  showNotification,
  TextInput,
} from 'platform/components';
import {Box, Grid, GridItem, Hide, HStack, Show, Space, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';
import {boolean, object} from 'yup';

import {useEffect, useRef, useState} from 'react';
import {DeepPartial, UseFormReturn} from 'react-hook-form';

import {
  always,
  assocPath,
  clone,
  concat,
  defaultTo,
  flip,
  head,
  isEmpty,
  isNil,
  last,
  not,
  path,
  pipe,
} from 'ramda';
import {isFunction, isNilOrEmpty, isString} from 'ramda-adjunct';

import {GetManufacturersResponse, useGetManufacturersQuery} from '@dms/api/metadaAdminManufacturer';
import {GetWarehouseResponse} from '@dms/api/metadaWarehouse';
import {GetWarehouseAccountResponse} from '@dms/api/metadaWarehouseAccount';
import {
  useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery,
  usePutSupplierArticlePromptValidationMutation,
  PutSupplierArticlePromptValidationRequest,
} from '@dms/api/metadaWarehouseArticle';
import {useGetHandlingUnitsQuery} from '@dms/api/metadaWarehouseHandlingUnit';
import {PostReceiveNoteBasketItemRequest} from '@dms/api/metadaWarehouseReceiveNoteBasket';
import {
  GetReceiveNoteItemNewResponse,
  GetReceiveNoteItemResponse,
  PatchReceiveNoteItemManualRequest,
  ReceiveNoteItemChangedField,
  useLazyGetReceiveNoteItemNewQuery,
  useLazyGetReceiveNoteItemQuery,
  useLazyGetReceiveNoteItemRefreshedQuery,
  usePatchReceiveNoteItemManualMutation,
  usePutReceiveNoteItemCalculationMutation,
} from '@dms/api/metadaWarehouseReceiveNoteItem';
import {useGetSuppliersQuery} from '@dms/api/metadaWarehouseSupplier';
import {TenantResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {handleApiError, getOptionsFromManufacturers, ArticleState} from '@dms/shared';

import {
  AllOrNone,
  ApiError,
  generateHashFromObjects,
  Nullish,
  RequiredTestIdProps,
  sanitizeObject,
  suffixTestId,
  useOnMount,
  yupNumber,
  yupString,
} from 'shared';

import {refreshDatagrid} from 'features/datagrid';

import {SupplierArticlePrompt} from '../../../../../components/SupplierArticlePrompt';
import {SUPPLIER_ARTICLE_PROMPT} from '../../../../../constants/supplierArticlePrompt';
import {ReceiveNoteItemDetails, ReceiveNoteItemFormType} from '../../../../../types/ReceiveNote';
import {filterElementsBySuffixMatch} from '../../../../../utils/filterElementsBySuffixMatch';
import {getArticleDetailCardPrices} from '../../../../../utils/getArticleDetailCardPrices';
import {objectPaths} from '../../../../../utils/objectPaths';
import {removeKeys} from '../../../../../utils/removeKeys';
import {RECEIVE_NOTE_ITEM_DIALOG} from '../constants/receiveNoteItemDialog';
import {ItemRowData} from '../types/ItemRowData';
import {ArticleDetailCard} from './ArticleDetailCard';
import {ArticleForm} from './ArticleForm';
import {AvailabilitySegment} from './AvailabilitySegment';
import {DeliveryItemDetailCard} from './DeliveryItemDetailCard';
import {DeliveryItemForm} from './DeliveryItemForm';
import {ReceiveNoteItemForm} from './ReceiveNoteItemForm';
import {RequestsSegment} from './RequestsSegment';
import {SupplierArticleForm} from './SupplierArticleForm';
import {SupplierOrdersSegment} from './SupplierOrdersSegment';

const ABORT_ERROR = 'AbortError';

enum Segments {
  AVAILABILITY = 'availability',
  REQUESTS = 'requests',
  SUPPLIER_ORDERS = 'supplierOrders',
}

export type ReceiveNoteItemDetailProps = {
  receiveNoteId: string;
  itemRowData: ItemRowData;
  warehouse: GetWarehouseResponse | Nullish;
  warehouseAccount: GetWarehouseAccountResponse | Nullish;
  tenant: TenantResponseBody | Nullish;
  state: 'PENDING' | 'COMPLETED' | Nullish;
  receiveNoteItemId?: string;
  isEditing?: boolean;
} & RequiredTestIdProps &
  BasketProps;

type BasketProps = AllOrNone<{
  context?: 'BASKET';
  onAddToBasket?: (requestBody: PostReceiveNoteBasketItemRequest['body']) => Promise<void>;
  isAddingToBasket?: boolean;
}>;

export function ReceiveNoteItemDetail(props: ReceiveNoteItemDetailProps) {
  const [activeSegment, setActiveSegment] = useState<Segments>(Segments.AVAILABILITY);

  const [searchedCatalogueNumber, setSearchedCatalogueNumber] = useState<string | null>(
    props.itemRowData?.catalogNumber ?? null
  );
  const [searchedManufacturerId, setSearchedManufacturerId] = useState<string | null>(
    props.warehouse?.defaultManufacturerId ?? null
  );

  const [catalogueNumberErrorMessage, setCatalogueNumberErrorMessage] = useState<
    string | undefined
  >(undefined);

  const [manufacturerIdErrorMessage, setManufacturerIdErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [articleState, setArticleState] = useState<ArticleState | null>(null);

  const [receiveNoteItem, setReceiveNoteItem] = useState<GetReceiveNoteItemResponse | Nullish>(
    null
  );

  const [receiveNoteItemNew, setReceiveNoteItemNew] = useState<
    GetReceiveNoteItemNewResponse | Nullish
  >(null);

  const [receiveNoteItemDetails, setReceiveNoteItemDetails] = useState<ReceiveNoteItemDetails>();

  const {data: suppliers, isLoading: areSuppliersLoading} = useGetSuppliersQuery();

  const {data: manufacturers, isLoading: areManufacturersLoading} = useGetManufacturersQuery();

  const {data: handlingUnits, isLoading: areHandlingUnitsLoading} = useGetHandlingUnitsQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [getReceiveNoteItem] = useLazyGetReceiveNoteItemQuery();

  const [getReceiveNoteItemNew] = useLazyGetReceiveNoteItemNewQuery();

  const [getReceiveNoteItemRefreshed, {isFetching: isRefreshingDetails}] =
    useLazyGetReceiveNoteItemRefreshedQuery();

  const [getArticleIdByManufacturerNumberAndManufacturerId] =
    useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery();

  const [putSupplierArticlePromptValidation] = usePutSupplierArticlePromptValidationMutation();

  const [patchReceiveNoteItemManual, {isLoading: isPatchReceiveNoteItemLoading}] =
    usePatchReceiveNoteItemManualMutation();

  const [recalculateReceiveNoteItemPrices] = usePutReceiveNoteItemCalculationMutation();

  const defaultHandlingUnit = handlingUnits?.find((handlingUnit) => handlingUnit.isDefault);

  const articleId = receiveNoteItemNew?.article?.id ?? receiveNoteItem?.article?.id;

  const supplierId =
    receiveNoteItemNew?.supplierArticle?.supplierId ?? receiveNoteItem?.supplierArticle?.supplierId;

  const manufacturerNumber =
    receiveNoteItemNew?.article?.manufacturerNumber ?? receiveNoteItem?.article?.manufacturerNumber;

  const manufacturerId =
    receiveNoteItemNew?.article?.manufacturerId ?? receiveNoteItem?.article?.manufacturerId;

  const deliveryNoteQuantity =
    receiveNoteItemNew?.deliveryNoteItem?.quantity ?? receiveNoteItem?.deliveryNoteItem?.quantity;

  const warehouseUnitId =
    receiveNoteItemNew?.article?.handlingUnit ?? receiveNoteItem?.article?.handlingUnit;

  const articleHandlingUnitName = handlingUnits?.find(
    (handlingUnit) => handlingUnit.id === warehouseUnitId
  )?.name;

  const articlePrices = getArticleDetailCardPrices(
    receiveNoteItemNew?.article ?? receiveNoteItem?.article
  );

  const isEditing = props.isEditing ?? false;
  const isEditingDisabled = isEditing && props.state === 'COMPLETED';
  const doesExist = articleState === 'doesExist';
  const doesNotExist = articleState === 'doesNotExist';
  const hasBeenCreated = articleState === 'hasBeenCreated';
  const isCheckingForArticle = articleState === 'isChecking';
  const isChecked = doesExist || doesNotExist || hasBeenCreated;
  const isNotChecked = not(articleState);
  const shouldCardsBeExpanded = isChecked || isEditing;

  const defaultValues: DeepPartial<ReceiveNoteItemFormType> = {
    receiveNoteItem: {
      quantity: defaultTo(1, receiveNoteItem?.receiveNoteItem?.quantity),
      unitPrice: defaultTo(0, receiveNoteItem?.receiveNoteItem?.purchasePrice),
      totalPrice: defaultTo(0, receiveNoteItem?.receiveNoteItem?.purchaseValue),
      vatType: defaultTo('S', receiveNoteItem?.receiveNoteItem?.vatType),
    },
    deliveryItem: {
      quantityVerified: defaultTo(1, receiveNoteItem?.deliveryNoteItem?.quantityVerified),
      unitPrice: defaultTo(0, receiveNoteItem?.deliveryNoteItem?.purchasePrice),
      totalPrice: defaultTo(0, receiveNoteItem?.deliveryNoteItem?.purchaseValue),
      vatType: defaultTo('S', receiveNoteItem?.deliveryNoteItem?.vatType),
    },
    supplierArticle: {
      supplierQuantity: defaultTo(1, receiveNoteItem?.supplierArticle?.supplierQuantity),
      supplierUnitId: receiveNoteItem?.supplierArticle?.supplierUnitId,
      warehouseQuantity: defaultTo(1, receiveNoteItem?.supplierArticle?.warehouseQuantity),
      warehouseUnitId: receiveNoteItem?.article?.handlingUnit,
      supplierOrderingNumber: receiveNoteItem?.supplierArticle?.supplierOrderingNumber,
      supplierBulkPackageQuantity: defaultTo(
        1,
        receiveNoteItem?.supplierArticle?.supplierBulkPackageQuantity
      ),
      supplier: suppliers?.find(
        (supplier) => supplier.id === receiveNoteItem?.supplierArticle?.supplierId
      )?.name,
    },
  };

  // Needed for proper recalculations
  const prevFormValues = useRef<DeepPartial<ReceiveNoteItemFormType>>(defaultValues);
  // Needed for cancelling recalculating requests from the form onChange event
  const abortControllerInstance = useRef<AbortController | Nullish>(null);

  const formApiRef = useRef<UseFormReturn<ReceiveNoteItemFormType> | null>(null);

  useOnMount(() => {
    if (isEditing || isNil(formApiRef.current)) {
      return;
    }

    handleCheckItem(formApiRef.current);
  });

  // Get the detail of receive note item in edit mode
  useEffect(() => {
    if (not(isEditing)) {
      return;
    }

    const handleGetReceiveNoteItem = async () => {
      setArticleState('isChecking');

      return await getReceiveNoteItem({
        receiveNoteId: props.receiveNoteId as string,
        itemId: props.receiveNoteItemId as string,
      })
        .unwrap()
        .then((receiveNoteItem) => {
          // Set the prevFormValues now, we need them for proper recalculation of prices
          handleMapPrevFormValues(receiveNoteItem);
          setReceiveNoteItem(receiveNoteItem);
          setReceiveNoteItemDetails({
            deliveryItemDetail: {
              manufacturerNumber: receiveNoteItem?.deliveryNoteItem?.manufacturerNumber,
              manufacturerId: receiveNoteItem?.deliveryNoteItem?.manufacturerId,
              supplierNumber: receiveNoteItem?.deliveryNoteItem?.supplierNumber,
              name: receiveNoteItem?.deliveryNoteItem?.name,
              description: receiveNoteItem?.deliveryNoteItem?.description,
            },
            articleDetail: {
              warehouseId: receiveNoteItem?.article?.warehouseId,
              articleId: receiveNoteItem?.article?.id,
              name: receiveNoteItem?.article?.name,
              manufacturerNumber: receiveNoteItem?.article?.manufacturerNumber,
              manufacturerId: receiveNoteItem?.article?.manufacturerId,
              warehouseAccountId: receiveNoteItem?.article?.warehouseAccount,
              storageLocation: receiveNoteItem?.article?.storageLocation,
              leafId: receiveNoteItem?.article?.treeFolder?.leafId,
            },
          });
          setSearchedCatalogueNumber(defaultTo('', receiveNoteItem?.article?.manufacturerNumber));
          setSearchedManufacturerId(defaultTo('', receiveNoteItem?.article?.manufacturerId));
        })
        .then(() => setArticleState('doesExist'))
        .catch(handleApiError);
    };

    handleGetReceiveNoteItem();
    // We need to have the missing dependency (handleMapPrevFormValues) outside of this scope, because we are using it on multiple places
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, props.receiveNoteId, props.receiveNoteItemId, getReceiveNoteItem]);

  const handleGetReceiveNoteItemNew = async (
    articleId: string,
    wasCreated: boolean,
    formApi: UseFormReturn<ReceiveNoteItemFormType> | null
  ) => {
    if (isNil(formApi)) {
      return;
    }

    setArticleState('isChecking');

    const request = {
      receiveNoteId: props.receiveNoteId as string,
      articleId,
    };

    await getReceiveNoteItemNew(request)
      .unwrap()
      .then((receiveNoteItemNew) => {
        handleArticleState(receiveNoteItemNew, wasCreated);
        resetFormValues(receiveNoteItemNew, formApi);
        setReceiveNoteItemDetails({
          deliveryItemDetail: {
            manufacturerNumber: receiveNoteItemNew?.deliveryNoteItem?.manufacturerNumber,
            manufacturerId: receiveNoteItemNew?.deliveryNoteItem?.manufacturerId,
            supplierNumber: receiveNoteItemNew?.deliveryNoteItem?.supplierNumber,
            name: receiveNoteItemNew?.deliveryNoteItem?.name,
            description: receiveNoteItemNew?.deliveryNoteItem?.description,
          },
          articleDetail: {
            warehouseId: receiveNoteItemNew?.article?.warehouseId,
            articleId: receiveNoteItemNew?.article?.id,
            name: receiveNoteItemNew?.article?.name,
            manufacturerNumber: receiveNoteItemNew?.article?.manufacturerNumber,
            manufacturerId: receiveNoteItemNew?.article?.manufacturerId,
            warehouseAccountId: receiveNoteItemNew?.article?.warehouseAccount,
            storageLocation: receiveNoteItemNew?.article?.storageLocation,
            leafId: receiveNoteItemNew?.article?.treeFolder?.leafId,
          },
        });
      })
      .catch(handleApiError);
  };

  const handleCheckItem = async (formApi: UseFormReturn<ReceiveNoteItemFormType>) => {
    let canBeChecked = true;

    if (isNilOrEmpty(searchedCatalogueNumber)) {
      setCatalogueNumberErrorMessage(i18n.t('general.validations.fieldIsRequired'));
      canBeChecked = false;
    } else {
      setCatalogueNumberErrorMessage(undefined);
    }

    if (isNilOrEmpty(searchedManufacturerId)) {
      setManufacturerIdErrorMessage(i18n.t('general.validations.fieldIsRequired'));
      canBeChecked = false;
    } else {
      setManufacturerIdErrorMessage(undefined);
    }

    if (not(canBeChecked)) {
      return;
    }

    const articleIdResponse = await getArticleIdByManufacturerNumberAndManufacturerId({
      warehouseId: props.itemRowData?.warehouseId as string,
      manufacturerNumber: searchedCatalogueNumber as string,
      manufacturerId: searchedManufacturerId as string,
    })
      .unwrap()
      .catch(handleApiError);

    if (!articleIdResponse) {
      setArticleState('doesNotExist');
      setReceiveNoteItemNew(null);
      return;
    }

    await handleGetReceiveNoteItemNew(articleIdResponse.articleId, false, formApi);
  };

  const handleArticleState = (
    receiveNoteItem: GetReceiveNoteItemNewResponse | Nullish,
    wasCreated?: boolean
  ) => {
    if (isNil(receiveNoteItem)) {
      return setArticleState('doesNotExist');
    }

    if (wasCreated) {
      setArticleState('hasBeenCreated');
      handleMapPrevFormValues(receiveNoteItemNew);
      setReceiveNoteItemNew(receiveNoteItem);
      return;
    }

    setArticleState('doesExist');
    handleMapPrevFormValues(receiveNoteItemNew);
    setReceiveNoteItemNew(receiveNoteItem);
  };

  const handleRefreshArticle = async (formApi: UseFormReturn<ReceiveNoteItemFormType>) => {
    await getReceiveNoteItemRefreshed({
      receiveNoteId: props.receiveNoteId as string,
      articleId: articleId as string,
    })
      .unwrap()
      .then((receiveNoteItemRefreshed) => {
        mapWarehouseUnit(receiveNoteItemRefreshed.article.handlingUnit, formApi);
        setReceiveNoteItemDetails({
          deliveryItemDetail: {
            manufacturerNumber: receiveNoteItemRefreshed?.deliveryNoteItem?.manufacturerNumber,
            manufacturerId: receiveNoteItemRefreshed?.deliveryNoteItem?.manufacturerId,
            supplierNumber: receiveNoteItemRefreshed?.deliveryNoteItem?.supplierNumber,
            name: receiveNoteItemRefreshed?.deliveryNoteItem?.name,
            description: receiveNoteItemRefreshed?.deliveryNoteItem?.description,
          },
          articleDetail: {
            warehouseId: receiveNoteItemRefreshed?.article?.warehouseId,
            articleId: receiveNoteItemRefreshed?.article?.id,
            name: receiveNoteItemRefreshed?.article?.name,
            manufacturerNumber: receiveNoteItemRefreshed?.article?.manufacturerNumber,
            manufacturerId: receiveNoteItemRefreshed?.article?.manufacturerId,
            warehouseAccountId: receiveNoteItemRefreshed?.article?.warehouseAccount,
            storageLocation: receiveNoteItemRefreshed?.article?.storageLocation,
            leafId: receiveNoteItemRefreshed?.article?.treeFolder?.leafId,
          },
        });
      })
      .catch(handleApiError);
  };

  const handleSupplierArticlePromptValidation: FormSubmitHandler<ReceiveNoteItemFormType> = async (
    formValues
  ) => {
    if (isNilOrEmpty(articleId)) {
      throw new Error('Missing article id');
    }

    if (isNilOrEmpty(supplierId)) {
      throw new Error('Missing supplier id');
    }

    if (isNilOrEmpty(searchedCatalogueNumber)) {
      throw new Error('Missing manufacturer number');
    }

    const supplierArticlePromptValidationRequestPayload: PutSupplierArticlePromptValidationRequest =
      {
        articleId: articleId as string,
        supplierId: supplierId as string,
        manufacturerNumber: searchedCatalogueNumber as string,
        supplierOrderingNumber: formValues.supplierArticle.supplierOrderingNumber,
        supplierToWarehouseMapping: {
          supplierQuantity: formValues.supplierArticle.supplierQuantity,
          supplierUnitId: formValues.supplierArticle.supplierUnitId,
          warehouseQuantity: formValues.supplierArticle.warehouseQuantity,
          supplierBulkPackageQuantity: formValues.supplierArticle.supplierBulkPackageQuantity,
        },
      };

    await putSupplierArticlePromptValidation(supplierArticlePromptValidationRequestPayload)
      .unwrap()
      .then((result) => {
        if (not(result.isChanged)) {
          return handleSubmit(formValues);
        }

        openDialog(
          <SupplierArticlePrompt
            onDontSave={() => handleSubmit(formValues, false)}
            onSave={() => handleSubmit(formValues, true)}
            data-testid={suffixTestId('supplierArticlePrompt', props)}
          />,
          {
            id: SUPPLIER_ARTICLE_PROMPT,
            size: 'small',
            'data-testid': suffixTestId('dialogs.supplierArticlePrompt', props),
          }
        );
      })
      .catch(handleApiError);
  };

  const handleSubmit = async (
    formValues: ReceiveNoteItemFormType,
    shouldUpdateMapping?: boolean
  ) => {
    if (props.context === 'BASKET') {
      return handleBasketSubmit(formValues, shouldUpdateMapping);
    }

    const receiveNoteItemRequest: PatchReceiveNoteItemManualRequest = {
      receiveNoteItemId: props.receiveNoteItemId as string,
      body: {
        quantity: formValues.receiveNoteItem.quantity,
        purchasePrice: formValues.receiveNoteItem.unitPrice,
        stockValue: formValues.receiveNoteItem.totalPrice,
        vatType: formValues.receiveNoteItem.vatType,
        deliveryItem: {
          quantityVerified: formValues.deliveryItem.quantityVerified,
          unitPrice: formValues.deliveryItem.unitPrice,
          stockValue: formValues.deliveryItem.totalPrice,
          vatType: formValues.deliveryItem.vatType,
        },
        supplierArticle: {
          supplierQuantity: formValues.supplierArticle.supplierQuantity,
          supplierUnitId: formValues.supplierArticle.supplierUnitId,
          warehouseQuantity: formValues.supplierArticle.warehouseQuantity,
          supplierOrderingNumber: formValues.supplierArticle.supplierOrderingNumber,
          supplierBulkPackageQuantity: formValues.supplierArticle.supplierBulkPackageQuantity,
          isUpdateMapping: shouldUpdateMapping ?? false,
        },
      },
    };

    await patchReceiveNoteItemManual(receiveNoteItemRequest)
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('page.warehouse.notification.receiveNoteItemUpdated'))
      )
      .then(() => closeDialog(RECEIVE_NOTE_ITEM_DIALOG))
      .then(() => refreshDatagrid('receive-note-item'))
      .catch(handleApiError);
  };

  const handleBasketSubmit = async (
    formValues: ReceiveNoteItemFormType,
    shouldUpdateMapping?: boolean
  ) => {
    const requestBody: PostReceiveNoteBasketItemRequest['body'] = {
      receiveNoteId: props.receiveNoteId as string,
      articleId: articleId as string,
      quantity: formValues.receiveNoteItem.quantity,
      purchasePrice: formValues.receiveNoteItem.unitPrice,
      stockValue: formValues.receiveNoteItem.totalPrice,
      vatType: formValues.receiveNoteItem.vatType,
      supplierArticle: {
        supplierQuantity: formValues.supplierArticle.supplierQuantity,
        supplierUnitId: formValues.supplierArticle.supplierUnitId,
        warehouseQuantity: formValues.supplierArticle.warehouseQuantity,
        supplierOrderingNumber: formValues.supplierArticle.supplierOrderingNumber,
        supplierBulkPackageQuantity: formValues.supplierArticle.supplierBulkPackageQuantity,
        isUpdateMapping: shouldUpdateMapping ?? false,
      },
    };

    if (isNil(props.onAddToBasket)) {
      throw new Error('Missing onAddToBasket');
    }

    await props.onAddToBasket(requestBody);
  };

  const handleChange = (
    values: DeepPartial<ReceiveNoteItemFormType>,
    // We don't know the correct type here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: any,
    formApi: UseFormReturn<ReceiveNoteItemFormType>
  ) => {
    const copiedValues = JSON.parse(JSON.stringify(values)) as object;
    const {asString} = pipe(
      defaultTo({}),
      flip(diff)(sanitizeObject(copiedValues)),
      objectPaths
    )(prevFormValues.current);

    // Name of changed the field
    const changedField = last<string>(change.name?.split('.') ?? []);
    const changedFieldWithFullPath = change.name;
    // Indicate if the changed field is allowed to trigger recalculation
    const shouldTriggerRecalculation = ALLOWED_VALUES.includes(changedField);
    // Get the difference between the previous and current form values
    const difference = diff(sanitizeObject(prevFormValues.current), sanitizeObject(copiedValues));
    // Difference that contains only allowed values
    const allowedDifference = removeKeys(
      difference,
      concat(ALLOWED_VALUES, ALLOWED_VALUES_IN_OBJECT)
    );

    const isReceiveNoteItemPricesDiffEmpty = allowedDifference?.receiveNoteItem
      ? isEmpty(allowedDifference?.receiveNoteItem)
      : false;
    const isDeliveryItemPricesDiffEmpty = allowedDifference?.deliveryItem
      ? isEmpty(allowedDifference?.deliveryItem)
      : false;
    const isSupplierArticleDiffEmpty = allowedDifference?.supplierArticle
      ? isEmpty(allowedDifference?.supplierArticle)
      : false;

    const isDiffEmpty =
      isReceiveNoteItemPricesDiffEmpty &&
      isDeliveryItemPricesDiffEmpty &&
      isSupplierArticleDiffEmpty;

    if (not(shouldTriggerRecalculation) || isNilOrEmpty(allowedDifference) || isDiffEmpty) {
      return;
    }

    const arrayOfChangedValues = asString.includes('.') ? asString.split(',') : asString;
    const pathToChangedValues = head(
      filterElementsBySuffixMatch(arrayOfChangedValues, ALLOWED_VALUES)
    )?.split('.') as string[];
    const changedValue = path(pathToChangedValues ?? [], values);
    const newPrevFormValues = assocPath(
      pathToChangedValues ?? [],
      changedValue,
      prevFormValues.current
    );

    prevFormValues.current = newPrevFormValues;

    // Map FE field names to BE field names to ensure correct data mapping
    const field = match(changedFieldWithFullPath)
      .with('receiveNoteItem.unitPrice', always('purchasePrice'))
      .with('deliveryItem.totalPrice', always('totalPriceDelivery'))
      .otherwise(always(changedField));

    if (abortControllerInstance.current && isFunction(abortControllerInstance.current.abort)) {
      abortControllerInstance.current.abort();
    }

    const receiveNoteItemCalculateController = new AbortController();

    abortControllerInstance.current = receiveNoteItemCalculateController;

    recalculateReceiveNoteItemPrices({
      body: {
        changedFieldValue: {
          field: field as ReceiveNoteItemChangedField,
        },
        currentFeFieldsValues: {
          quantity: values.receiveNoteItem?.quantity,
          purchasePrice: values.receiveNoteItem?.unitPrice,
          totalPrice: values.receiveNoteItem?.totalPrice,
          deliveryItem: {
            quantityVerified: values.deliveryItem?.quantityVerified,
            unitPrice: values.deliveryItem?.unitPrice,
            totalPriceDelivery: values.deliveryItem?.totalPrice,
          },
          supplierArticle: {
            supplierQuantity: values.supplierArticle?.supplierQuantity,
            warehouseQuantity: values.supplierArticle?.warehouseQuantity,
          },
        },
      },
      signal: receiveNoteItemCalculateController.signal,
    })
      .unwrap()
      .then((response) => {
        // newValues must have exactly the same structure as prevFormValues in handleMapPrevFormValues for recalculations to work properly!
        const newValues = clone(
          sanitizeObject({
            receiveNoteItem: {
              quantity: response.receiveNoteItem.quantity,
              unitPrice: response.receiveNoteItem.purchasePrice,
              totalPrice: response.receiveNoteItem.purchaseValue,
              vatType: defaultTo('S', values.receiveNoteItem?.vatType),
            },
            deliveryItem: {
              quantityVerified: response.deliveryNoteItem.quantityVerified,
              unitPrice: response.deliveryNoteItem.purchasePrice,
              totalPrice: response.deliveryNoteItem.purchaseValue,
              vatType: defaultTo('S', values.deliveryItem?.vatType),
            },
            supplierArticle: {
              supplierQuantity: response.supplierArticle.supplierQuantity,
              supplierUnitId: values.supplierArticle?.supplierUnitId,
              warehouseQuantity: response.supplierArticle.warehouseQuantity,
              warehouseUnitId: values.supplierArticle?.warehouseUnitId,
              supplierOrderingNumber: values.supplierArticle?.supplierOrderingNumber,
              supplierBulkPackageQuantity: values.supplierArticle?.supplierBulkPackageQuantity,
              supplier: values.supplierArticle?.supplier,
            },
          })
        );

        prevFormValues.current = newValues;
        formApi.reset(newValues);
      })
      .catch((error: {error: Error}) => {
        // If the request is aborted, then we don't want to show the error notification
        if (
          error &&
          'error' in error &&
          isString(error.error) &&
          error.error.includes(ABORT_ERROR)
        ) {
          return;
        }
        handleApiError(error as ApiError);
      });
  };

  const handleMapPrevFormValues = (
    data: GetReceiveNoteItemResponse | GetReceiveNoteItemNewResponse | Nullish
  ) => {
    prevFormValues.current = {
      receiveNoteItem: {
        quantity: defaultTo(1, data?.receiveNoteItem?.quantity),
        unitPrice: defaultTo(0, data?.receiveNoteItem?.purchasePrice),
        totalPrice: defaultTo(0, data?.receiveNoteItem?.purchaseValue),
        vatType: defaultTo('S', data?.receiveNoteItem?.vatType),
      },
      deliveryItem: {
        quantityVerified: defaultTo(1, data?.deliveryNoteItem?.quantityVerified),
        unitPrice: defaultTo(0, data?.deliveryNoteItem?.purchasePrice),
        totalPrice: defaultTo(0, data?.deliveryNoteItem?.purchaseValue),
        vatType: defaultTo('S', data?.deliveryNoteItem?.vatType),
      },
      supplierArticle: {
        supplierQuantity: defaultTo(1, data?.supplierArticle?.supplierQuantity),
        supplierUnitId: data?.supplierArticle?.supplierUnitId,
        warehouseQuantity: defaultTo(1, data?.supplierArticle?.warehouseQuantity),
        warehouseUnitId: data?.article?.handlingUnit,
        supplierOrderingNumber: data?.supplierArticle?.supplierOrderingNumber,
        supplierBulkPackageQuantity: defaultTo(
          1,
          data?.supplierArticle?.supplierBulkPackageQuantity
        ),
        supplier: suppliers?.find((supplier) => supplier.id === data?.supplierArticle?.supplierId)
          ?.name,
      },
    };
  };

  const handleDiscard = () => {
    closeCurrentDialog();
  };

  const resetFormValues = (
    receiveNoteItemNew: GetReceiveNoteItemNewResponse,
    formApi: UseFormReturn<ReceiveNoteItemFormType>
  ) => {
    formApi.reset({
      receiveNoteItem: {
        quantity: defaultTo(1, receiveNoteItemNew.receiveNoteItem.quantity),
        unitPrice: defaultTo(0, receiveNoteItemNew.receiveNoteItem.purchasePrice),
        totalPrice: defaultTo(0, receiveNoteItemNew.receiveNoteItem.purchaseValue),
        vatType: defaultTo('S', receiveNoteItemNew.receiveNoteItem.vatType),
      },
      deliveryItem: {
        quantityVerified: defaultTo(1, receiveNoteItemNew.deliveryNoteItem.quantityVerified),
        unitPrice: defaultTo(0, receiveNoteItemNew.deliveryNoteItem.purchasePrice),
        totalPrice: defaultTo(0, receiveNoteItemNew.deliveryNoteItem.purchaseValue),
        vatType: defaultTo('S', receiveNoteItemNew.deliveryNoteItem.vatType),
      },
      supplierArticle: {
        supplierQuantity: receiveNoteItemNew.supplierArticle.supplierQuantity,
        supplierUnitId: receiveNoteItemNew.supplierArticle.supplierUnitId,
        warehouseQuantity: receiveNoteItemNew.supplierArticle.warehouseQuantity,
        warehouseUnitId: receiveNoteItemNew.article.handlingUnit,
        supplierOrderingNumber: receiveNoteItemNew.supplierArticle.supplierOrderingNumber,
        supplierBulkPackageQuantity: receiveNoteItemNew.supplierArticle.supplierBulkPackageQuantity,
        supplier: suppliers?.find(
          (supplier) => supplier.id === receiveNoteItemNew?.supplierArticle?.supplierId
        )?.name,
      },
    });
  };

  const mapWarehouseUnit = (
    handlingUnit: string,
    formApi: UseFormReturn<ReceiveNoteItemFormType>
  ) => {
    formApi.setValue('supplierArticle.warehouseUnitId', handlingUnit);
  };

  const segments = [
    {
      value: Segments.AVAILABILITY,
      label: i18n.t('entity.warehouse.labels.availability'),
      content: (
        <AvailabilitySegment
          articleId={articleId}
          manufacturerNumber={manufacturerNumber}
          manufacturerId={manufacturerId}
          data-testid={suffixTestId('segments.availability', props)}
        />
      ),
    },
    {
      value: Segments.REQUESTS,
      label: i18n.t('entity.warehouse.labels.requests'),
      content: (
        <RequestsSegment
          receiveNoteItemId={props.receiveNoteItemId}
          data-testid={suffixTestId('segments.requests', props)}
        />
      ),
    },
    {
      value: Segments.SUPPLIER_ORDERS,
      label: i18n.t('entity.warehouse.labels.supplierOrders'),
      content: (
        <SupplierOrdersSegment
          receiveNoteItemId={props.receiveNoteItemId}
          data-testid={suffixTestId('segments.supplierOrders', props)}
        />
      ),
    },
  ];

  const segmentContent = segments.find((segment) => segment.value === activeSegment)?.content;

  return (
    <>
      <Form<ReceiveNoteItemFormType>
        key={generateHashFromObjects(defaultValues)}
        schema={formSchema}
        defaultValues={defaultValues}
        onSubmit={handleSupplierArticlePromptValidation}
      >
        {(control, formApi) => {
          // We need to have a ref of formApi to be able to check for item on mount
          formApiRef.current = formApi;
          // For controlling recalculations of prices
          formApi.watch((data, test) => handleChange(data, test, formApi));

          return (
            <VStack>
              <VStack spacing={4}>
                <Show when={not(isEditing) && doesExist}>
                  <Alert
                    type="inline"
                    variant="info"
                    title={i18n.t('entity.warehouse.labels.itemIsAlreadyInDatabase')}
                    message={i18n.t('entity.warehouse.labels.itemDetailsPrefilled')}
                    data-testid={suffixTestId('alert.itemInDatabase', props)}
                  />
                </Show>

                <Show when={not(isEditing) && doesNotExist}>
                  <Alert
                    type="inline"
                    variant="warning"
                    title={i18n.t('entity.warehouse.labels.itemNotInDatabase')}
                    message={i18n.t('entity.warehouse.labels.youCanCreateNewWarehouseArticle')}
                    data-testid={suffixTestId('alerts.itemNotInDatabase', props)}
                  />
                </Show>

                <Show when={not(isEditing) && hasBeenCreated}>
                  <Alert
                    type="inline"
                    variant="success"
                    title={i18n.t('entity.warehouse.labels.warehouseArticleWasSuccessfullyCreated')}
                    data-testid={suffixTestId('alerts.articleCreated', props)}
                  />
                </Show>

                <Grid columns={4} spacing={4}>
                  <GridItem span={2}>
                    <TextInput
                      label={i18n.t('entity.warehouse.labels.catalogueNumber')}
                      value={searchedCatalogueNumber}
                      onChange={setSearchedCatalogueNumber}
                      errorMessage={catalogueNumberErrorMessage}
                      isRequired
                      isDisabled={isCheckingForArticle || isEditing}
                      data-testid={suffixTestId('inputs.manufacturerNumber', props)}
                    />
                  </GridItem>
                  <GridItem span={2}>
                    <Choice
                      label={i18n.t('entity.warehouse.labels.manufacturer')}
                      value={searchedManufacturerId}
                      onChange={setSearchedManufacturerId}
                      errorMessage={manufacturerIdErrorMessage}
                      options={getOptionsFromManufacturers(manufacturers)}
                      isLoading={areManufacturersLoading}
                      isRequired
                      isDisabled={isCheckingForArticle || isEditing}
                      menuInPortal
                      data-testid={suffixTestId('inputs.manufacturer', props)}
                    />
                  </GridItem>

                  <Hide when={isEditing}>
                    <GridItem span={1}>
                      <Button
                        variant="secondary"
                        title={i18n.t('general.labels.checkItem')}
                        onClick={() => handleCheckItem(formApi)}
                        isDisabled={isCheckingForArticle}
                        data-testid={suffixTestId('actions.checkItem', props)}
                      />
                    </GridItem>
                  </Hide>
                </Grid>
              </VStack>

              <Separator />

              <DataStatus isLoading={isCheckingForArticle || areSuppliersLoading} minHeight={100}>
                <Hide when={doesNotExist}>
                  <ReceiveNoteItemForm
                    control={control}
                    handlingUnit={articleHandlingUnitName ?? defaultHandlingUnit?.name}
                    currency={props.warehouse?.currency}
                    isChecked={isChecked}
                    isEditingDisabled={isEditingDisabled}
                    data-testid={suffixTestId('sections.receiveNoteItem', props)}
                  />

                  <Separator />

                  <DeliveryItemForm
                    control={control}
                    deliveryNoteQuantity={deliveryNoteQuantity}
                    handlingUnit={articleHandlingUnitName ?? defaultHandlingUnit?.name}
                    currency={props.warehouse?.currency}
                    isChecked={isChecked}
                    isEditingDisabled={isEditingDisabled}
                    data-testid={suffixTestId('sections.deliveryItem', props)}
                  />

                  <Separator />

                  <SupplierArticleForm
                    control={control}
                    handlingUnits={handlingUnits}
                    areHandlingUnitsLoading={areHandlingUnitsLoading}
                    isChecked={isChecked}
                    isEditingDisabled={isEditingDisabled}
                    data-testid={suffixTestId('sections.supplierArticle', props)}
                  />

                  <Space vertical={4} />

                  <DeliveryItemDetailCard
                    deliveryItemDetail={receiveNoteItemDetails?.deliveryItemDetail}
                    deliveryNoteQuantity={deliveryNoteQuantity}
                    handlingUnit={articleHandlingUnitName ?? defaultHandlingUnit?.name}
                    isDisabled={isNotChecked}
                    isExpanded={shouldCardsBeExpanded}
                    isRefreshingDetails={isRefreshingDetails}
                    data-testid={suffixTestId('sections.deliveryItemDetailCard', props)}
                  />

                  <Space vertical={4} />

                  <ArticleDetailCard
                    articleDetail={receiveNoteItemDetails?.articleDetail}
                    currency={props.warehouse?.currency}
                    prices={articlePrices}
                    isDisabled={isNotChecked}
                    isExpanded={shouldCardsBeExpanded}
                    isRefreshingDetails={isRefreshingDetails}
                    onRefresh={() => handleRefreshArticle(formApi)}
                    data-testid={suffixTestId('sections.articleDetailCard', props)}
                  />

                  <Space vertical={4} />
                </Hide>

                <Show when={isEditing}>
                  <HStack>
                    <Segment<Segments>
                      value={activeSegment}
                      onChange={setActiveSegment}
                      options={segments}
                    />
                  </HStack>
                  <Box>{segmentContent}</Box>
                </Show>

                <DialogFooter>
                  <ButtonGroup align="right">
                    <Button
                      variant="secondary"
                      title={i18n.t('general.labels.discard')}
                      onClick={handleDiscard}
                      data-testid={suffixTestId('actions.discard', props)}
                    />
                    <FormButton
                      control={control}
                      type="submit"
                      variant="primary"
                      title={
                        isEditing
                          ? i18n.t('general.labels.saveChanges')
                          : i18n.t('general.labels.add')
                      }
                      isDisabled={
                        isNotChecked ||
                        doesNotExist ||
                        props.isAddingToBasket ||
                        isPatchReceiveNoteItemLoading ||
                        isEditingDisabled
                      }
                      data-testid={suffixTestId('actions.submit', props)}
                    />
                  </ButtonGroup>
                </DialogFooter>
              </DataStatus>
            </VStack>
          );
        }}
      </Form>

      <Show when={doesNotExist}>
        <ArticleForm
          searchedCatalogueNumber={searchedCatalogueNumber}
          searchedManufacturerId={searchedManufacturerId}
          warehouseId={props.itemRowData?.warehouseId}
          name={props.itemRowData?.name}
          onCreateArticle={(articleId, wasCreated) =>
            handleGetReceiveNoteItemNew(articleId, wasCreated, formApiRef.current)
          }
          manufacturers={manufacturers as GetManufacturersResponse}
          handlingUnit={warehouseUnitId ?? defaultHandlingUnit?.id}
          handlingUnits={handlingUnits}
          areHandlingUnitsLoading={areHandlingUnitsLoading}
          data-testid={suffixTestId('sections.createNewArticle', props)}
        />
      </Show>
    </>
  );
}

const formSchema = object({
  receiveNoteItem: object({
    quantity: yupNumber.positive(),
    unitPrice: yupNumber.positive(),
    totalPrice: yupNumber.positive(),
  }),
  deliveryItem: object({
    quantityVerified: yupNumber.positive(),
    unitPrice: yupNumber.positive(),
    totalPrice: yupNumber.positive(),
  }),
  supplierArticle: object({
    supplierQuantity: yupNumber.positive(),
    supplierUnitId: yupString.required(),
    warehouseQuantity: yupNumber.positive(),
    supplierOrderingNumber: yupString,
    supplierBulkPackageQuantity: yupNumber,
    isUpdateMapping: boolean().default(false),
  }),
});

const ALLOWED_VALUES_IN_OBJECT = ['receiveNoteItem', 'deliveryItem', 'supplierArticle'];

// nested keys from ALLOWED_VALUES_IN_OBJECT
const ALLOWED_VALUES = [
  'quantity',
  'unitPrice',
  'quantityVerified',
  'supplierQuantity',
  'warehouseQuantity',
  'totalPrice',
  'totalPriceDelivery',
];
