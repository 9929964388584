import {z} from 'zod';

import {
  BaseVoidResponseSchema,
  BasketTooltipSchema,
  MinMaxQuantitySchema,
  PriceWithAndWithoutVatSchema,
} from '@dms/api/shared';

const ReceiveNoteBasketItemSchema = z.object({
  id: z.string(),
  warehouse_id: z.string(),
  itemEditingAllowed: z.boolean(),
  priceType: z.string(),
  requestType: z.string().optional(),
  name: z.string(),
  manufacturerNumber: z.string(),
  manufacturerId: z.string(),
  quantity: z.number(),
  unit: z.string().optional(),
  dispensingUnit: z.number(),
  quantityEditingAllowed: z.boolean(),
  unitPrice: PriceWithAndWithoutVatSchema,
  discount: PriceWithAndWithoutVatSchema.merge(z.object({rate: z.number()})),
  totalPrice: PriceWithAndWithoutVatSchema,
  tooltip: z.array(BasketTooltipSchema),
  minMaxQuantity: MinMaxQuantitySchema,
});
export type ReceiveNoteBasketItem = z.infer<typeof ReceiveNoteBasketItemSchema>;

/**
 * GET
 */
export const GetReceiveNoteBasketRequestSchema = z.object({
  receiveNoteId: z.string(),
});
export type GetReceiveNoteBasketRequest = z.infer<typeof GetReceiveNoteBasketRequestSchema>;

export const GetReceiveNoteBasketResponseSchema = z.object({
  receiveNoteBasketItem: z.array(ReceiveNoteBasketItemSchema),
  receiveNoteBasketTotalPrice: PriceWithAndWithoutVatSchema,
});
export type GetReceiveNoteBasketResponse = z.infer<typeof GetReceiveNoteBasketResponseSchema>;

/**
 * POST
 */
export const PostReceiveNoteBasketCheckoutRequestSchema = z.object({
  receiveNoteId: z.string(),
});
export type PostReceiveNoteBasketCheckoutRequest = z.infer<
  typeof PostReceiveNoteBasketCheckoutRequestSchema
>;

export const PostReceiveNoteBasketCheckoutResponseSchema = BaseVoidResponseSchema;
export type PostReceiveNoteBasketCheckoutResponse = z.infer<
  typeof PostReceiveNoteBasketCheckoutResponseSchema
>;

export const PostReceiveNoteBasketItemRequestSchema = z.object({
  body: z.object({
    receiveNoteId: z.string(),
    articleId: z.string(),
    quantity: z.number(),
    purchasePrice: z.number(),
    stockValue: z.number().optional(),
    vatType: z.string().optional(),
    supplierArticle: z.object({
      supplierQuantity: z.number(),
      supplierUnitId: z.string(),
      warehouseQuantity: z.number(),
      supplierOrderingNumber: z.string().optional(),
      supplierBulkPackageQuantity: z.number().optional(),
      isUpdateMapping: z.boolean(),
    }),
  }),
});
export type PostReceiveNoteBasketItemRequest = z.infer<
  typeof PostReceiveNoteBasketItemRequestSchema
>;

export const PostReceiveNoteBasketItemResponseSchema = z.object({
  receiveNoteId: z.string(),
  receiveNoteItemId: z.string(),
});
export type PostReceiveNoteBasketItemResponse = z.infer<
  typeof PostReceiveNoteBasketItemResponseSchema
>;

/**
 * PATCH
 */
export const PatchReceiveNoteBasketItemQuantityRequestSchema = z.object({
  receiveNoteId: z.string(),
  itemId: z.string(),
  body: z.object({
    quantity: z.number(),
  }),
});
export type PatchReceiveNoteBasketItemQuantityRequest = z.infer<
  typeof PatchReceiveNoteBasketItemQuantityRequestSchema
>;

export const PatchReceiveNoteBasketItemQuantityResponseSchema = BaseVoidResponseSchema;
export type PatchReceiveNoteBasketItemQuantityResponse = z.infer<
  typeof PatchReceiveNoteBasketItemQuantityResponseSchema
>;

/**
 * DELETE
 */
export const DeleteReceiveNoteBasketRequestSchema = z.object({
  receiveNoteId: z.string(),
});
export type DeleteReceiveNoteBasketRequest = z.infer<typeof DeleteReceiveNoteBasketRequestSchema>;

export const DeleteReceiveNoteBasketResponseSchema = BaseVoidResponseSchema;
export type DeleteReceiveNoteBasketResponse = z.infer<typeof DeleteReceiveNoteBasketResponseSchema>;

export const BulkDeleteReceiveNoteBasketItemsRequestSchema = z.object({
  receiveNoteId: z.string(),
  body: z.object({
    receiveNoteItemId: z.array(z.string()),
  }),
});
export type BulkDeleteReceiveNoteBasketItemsRequest = z.infer<
  typeof BulkDeleteReceiveNoteBasketItemsRequestSchema
>;

export const BulkDeleteReceiveNoteBasketItemsResponseSchema = BaseVoidResponseSchema;
export type BulkDeleteReceiveNoteBasketItemsResponse = z.infer<
  typeof BulkDeleteReceiveNoteBasketItemsResponseSchema
>;
