import {CustomFilterProps} from 'ag-grid-react';
import {Chips, Choice, MultiChoice, Separator, useTranslationContext} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {always, intersection} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {quickFiltersConst} from '../../constants/quickFilters';
import {useChoiceFilter} from '../../hooks/useChoiceFilter';
import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {QuickFilters} from '../../types/Api';

type Option = {value: string; label: string};

export type ChoiceFilterProps = {
  options: Array<Option>;
  isDisabled: boolean;
  isMulti: boolean;
} & CustomFilterProps &
  QuickFilters &
  TestIdProps;

export function ChoiceFilter(props: ChoiceFilterProps) {
  const t = useTranslationContext();
  const [values, setValues] = useChoiceFilter(props);
  const {onChipsChange} = useFilterOnChipsChange({
    setFilterValue: setValues,
    defaultValue: null,
  });

  const isQuickFilterValue = intersection(isArray(values) ? values : [values], quickFiltersConst);

  const onChange = (values: string[] | string | number | null) => {
    const correctedValues = match<string[] | string | number | null, string[] | Nullish>(values)
      .with(Pattern.array(Pattern.string), (values) => values)
      .with(Pattern.number, (values) => [String(values)])
      .with(Pattern.string, (values) => [values])
      .otherwise(always(null));

    setValues(correctedValues);
  };

  return (
    <VStack>
      {props.quickFilters && props.quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={props.isDisabled}
              value={isQuickFilterValue}
              options={props.quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('choice-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      {props.isMulti ? (
        <MultiChoice
          options={props.options}
          value={isArray(values) ? values : [values]}
          onChange={onChange}
          placeholder={t('page.datagrid.filter.choicePlaceholder', {
            field: props.column.getColDef().headerName,
          })}
          isDisabled={props.isDisabled}
          data-testid={suffixTestId('choice-filter', props)}
        />
      ) : (
        <Choice
          options={props.options}
          value={isArray(values) ? values[0] : values}
          onChange={onChange}
          placeholder={t('page.datagrid.filter.choicePlaceholder', {
            field: props.column.getColDef().headerName,
          })}
          isDisabled={props.isDisabled}
          data-testid={suffixTestId('choice-filter', props)}
        />
      )}
    </VStack>
  );
}
