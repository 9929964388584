import {isFeatureEnabled} from 'feature-flags';
import {Box, Center, Clickable, Icon} from 'platform/foundation';
import styled from 'styled-components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {featureFlags} from '@dms/feature-flags';

import {suffixTestId, TestIdProps} from 'shared';

interface DevSettingsHeaderButtonProps extends TestIdProps {
  isOpen: boolean;
  isAnyDevSettingEnabled: boolean;
  onClick: () => void;
}

export function DevSettingsHeaderButton(props: DevSettingsHeaderButtonProps) {
  if (isFeatureEnabled(featureFlags.SIDEBAR)) {
    return (
      <Clickable onClick={props.onClick}>
        <Center height={12}>
          <StyledDevSettingsHeaderButton
            $isOpen={props.isOpen}
            $isAnyDevSettingEnabled={props.isAnyDevSettingEnabled}
            data-testid={suffixTestId('devSettings-button-icon', props)}
          >
            <Icon size={4} value="action/build" cursor="pointer" />
          </StyledDevSettingsHeaderButton>
        </Center>
      </Clickable>
    );
  }

  return (
    <StyledDevSettingsHeaderButtonWrapper
      $isOpen={props.isOpen}
      data-testid={suffixTestId('devSettings-button', props)}
      onClick={props.onClick}
    >
      <Center height="100%">
        <Box
          width={6}
          height={6}
          backgroundColor={
            props.isAnyDevSettingEnabled ? 'palettes.red.30.100' : 'palettes.yellow.30.100'
          }
          borderRadius="circular"
          data-testid={suffixTestId('devSettings-button-icon', props)}
        >
          <Center height="100%">
            <Icon size={3} value="action/build" cursor="pointer" />
          </Center>
        </Box>
      </Center>
    </StyledDevSettingsHeaderButtonWrapper>
  );
}

const StyledDevSettingsHeaderButtonWrapper = styled.div<{
  $isOpen: boolean;
  $size?: 'small' | 'default';
  $rounded?: boolean;
}>`
  background-color: ${({theme, $isOpen}) => $isOpen && theme.colors.palettes.neutral[30][100]};
  height: ${({theme, $size}) => theme.getSize($size === 'small' ? 6 : 12)};
  width: ${({theme, $size}) => theme.getSize($size === 'small' ? 6 : 12)};
  border-radius: ${({$rounded}) => ($rounded ? '50%' : '0')};
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  }
  cursor: pointer;
`;

const StyledDevSettingsHeaderButton = styled.button<{
  $isOpen: boolean;
  $isAnyDevSettingEnabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({theme}) => theme.getSize(6)};
  height: ${({theme}) => theme.getSize(6)};
  border-radius: ${({theme}) => theme.radii.small};
  background-color: ${({theme, $isAnyDevSettingEnabled, $isOpen}) =>
    match<[boolean, boolean], string>([$isAnyDevSettingEnabled, $isOpen])
      .with([false, false], always(theme.colors.palettes.yellow[30][100]))
      .with([false, true], always(theme.colors.palettes.yellow[50][100]))
      .with([true, false], always(theme.colors.palettes.red[30][100]))
      .with([true, true], always(theme.colors.palettes.red[40][100]))
      .exhaustive()};
  &:hover {
    filter: saturate(2);
  }
`;
