import {useSubscription} from '@apollo/client';
import {showNotification} from 'platform/components';

import {isNotNil} from 'ramda-adjunct';

import {SEND_NOTIFICATION_SUBSCRIPTION} from '@dms/api/graphQl';
import {useListSentToastNotificationsQuery} from '@dms/api/messaging';
import i18n from '@dms/i18n';

import {isDefinedNonZeroValue} from 'shared';

export function useNotifications(pageSize: number) {
  const {data, refetch, isLoading, isFetching, isError} = useListSentToastNotificationsQuery(
    {
      limit: pageSize,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useSubscription(SEND_NOTIFICATION_SUBSCRIPTION, {
    onData: ({data}) => {
      const isToastNotification = isNotNil(data.data.onSendNotification.toastNotificationId);

      if (isToastNotification) {
        refetch();
        showNotification.info(i18n.t('general.labels.newNotificationReceived'));
      }
    },
    fetchPolicy: 'no-cache',
    shouldResubscribe: false,
  });

  const unreadNotificationsCount = data?.notifications?.filter(
    (notification) => !notification.isRead
  ).length;
  const hasUnreadNotifications = isDefinedNonZeroValue(unreadNotificationsCount);

  return {
    data,
    isLoading,
    isFetching,
    isError,
    hasUnreadNotifications,
    unreadNotificationsCount,
  };
}
