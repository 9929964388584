import {
  Card,
  openConfirmDialog,
  openDialog,
  openSuccessDialog,
  ProgressBar,
  showNotification,
  Table,
  TableRow,
  closeCurrentDialog,
} from 'platform/components';
import {Box, Heading, HStack, Spinner, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {
  ChecklistResponseBody,
  useChangeChecklistMutation,
  useMarkTaskDoneMutation,
  useMarkTaskUndoneMutation,
} from '@dms/api/checklist';
import i18n from '@dms/i18n';

import {openFile} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {ChecklistResourceType} from '../types/ChecklistResourceType';
import {TableCellType} from '../types/TableCellType';
import {CommentCell} from './CommentCell';
import {DocumentCell} from './DocumentCell';
import {EditTaskDialog} from './EditTaskDialog';
import {TableHeaderCell} from './TableHeaderCell';
import {TaskNameCell} from './TaskNameCell';
import {TemplateChooser} from './TemplateChooser';
import {UserCell} from './UserCell';

interface OverviewProps {
  recordId: string;
  resourceId: keyof typeof ChecklistResourceType;
  data: ChecklistResponseBody;
  isUpdating: boolean;
}

export function Overview(props: OverviewProps) {
  const [changeChecklist, changeChecklistQuery] = useChangeChecklistMutation();

  const [markTaskDone, markTaskDoneQuery] = useMarkTaskDoneMutation();
  const [markTaskUndone, markTaskUndoneQuery] = useMarkTaskUndoneMutation();

  const isUpdating =
    props.isUpdating || markTaskDoneQuery.isLoading || markTaskUndoneQuery.isLoading;

  const handleTaskCompletion = async (
    resourceId: string,
    recordId: string,
    checklistId: string,
    taskId: string
  ) => {
    const mandatoryTasks = props.data.tasks.filter((task) => task.isMandatory);
    const completedMandatoryTasks = mandatoryTasks.filter((task) => task.markedAsDone);
    const currentTask = props.data.tasks.find((task) => task.id === taskId);

    const willCompleteAllTasks =
      currentTask?.isMandatory &&
      !currentTask.markedAsDone &&
      completedMandatoryTasks.length === mandatoryTasks.length - 1;

    await markTaskDone({
      resourceId,
      recordId,
      checklistId,
      taskId,
    })
      .unwrap()
      .then(() => {
        if (willCompleteAllTasks) {
          openSuccessDialog({
            id: 'checklist-success-dialog',
            heading: i18n.t('entity.checklist.notifications.completed'),
            subheading: i18n.t('entity.checklist.notifications.completedSub'),
            'data-testid': 'checklist-success-dialog',
            buttonLabel: i18n.t('general.actions.close'),
            onClose: closeCurrentDialog,
          });
        }
      })
      .catch(handleApiError);
  };

  return (
    <Box maxWidth={400}>
      <Card>
        <VStack spacing={4}>
          <HStack justify="space-between" align="center">
            <HStack spacing={4} align="center">
              <Heading size={3}>{props.data.name}</Heading>
              {isUpdating && <Spinner size="small" />}
            </HStack>
            <TemplateChooser
              onTemplateSelected={(id: string) => {
                openConfirmDialog({
                  text: i18n.t('entity.checklist.labels.confirmChangeTemplate'),
                  onConfirm: () =>
                    changeChecklist({
                      recordId: props.recordId,
                      resourceId: props.resourceId,
                      body: {
                        definitionId: id,
                      },
                    })
                      .unwrap()
                      .then(() => showNotification.success())
                      .catch(handleApiError),
                });
              }}
              isLoading={changeChecklistQuery.isLoading}
              resourceId={props.resourceId}
              variant="ghostLink"
              forUpdate
            />
          </HStack>
          <Box maxWidth={200}>
            <Text size="small" color="tertiary">
              {props.data.description}
            </Text>
          </Box>
          <VStack spacing={2}>
            <HStack justify="space-between" align="center">
              <Heading size={4}>{i18n.t('general.labels.completed')}</Heading>
              <Text color="tertiary">
                {i18n.t('entity.task.labels.tasks')} {props.data.progress.doneCount}/
                {props.data.progress.totalCount}
              </Text>
            </HStack>
            <Box backgroundColor="palettes.neutral.40.100">
              <ProgressBar percentage={props.data.progress.progress} severity="success" />
            </Box>
          </VStack>
          <Table
            columns={[
              {
                element: <TableHeaderCell label={i18n.t('entity.checklist.labels.taskName')} />,
              },
              {
                element: <TableHeaderCell label={i18n.t('entity.checklist.labels.finishedBy')} />,
              },
              {
                element: <TableHeaderCell label={i18n.t('entity.document.labels.document')} />,
              },
              {
                element: <TableHeaderCell label={i18n.t('entity.checklist.labels.comment')} />,
              },
              {
                element: <TableHeaderCell />,
                width: 12,
              },
            ]}
            variant="bordered"
            tableLayout="fixed"
          >
            {props.data.tasks.map((item) => {
              const openEditDialog = (highlightFileUpload?: boolean) => {
                openDialog(
                  <EditTaskDialog
                    dialogId="edit-task-dialog"
                    taskName={item.description}
                    recordId={props.recordId}
                    resourceId={props.resourceId}
                    checklistId={props.data.id}
                    taskId={item.id}
                    note={item.note}
                    file={
                      item.file
                        ? {
                            id: item.file.id,
                            name: item.file?.originalFilename || '',
                            contentType: item.file?.contentType || '',
                            url: item.file?.fileUri || '',
                          }
                        : undefined
                    }
                    highlightFileUpload={highlightFileUpload}
                  />,
                  {
                    size: 'default',
                    id: 'edit-task-dialog',
                  }
                );
              };

              return (
                <TableRow
                  key={item.id}
                  onRowClick={async () => {
                    if (item.fileRequired && !item.file) {
                      openEditDialog(true);
                      return;
                    }

                    if (item.markedAsDone) {
                      return await markTaskUndone({
                        resourceId: props.resourceId,
                        recordId: props.recordId,
                        checklistId: props.data.id,
                        taskId: item.id,
                      })
                        .unwrap()
                        .catch(handleApiError);
                    }

                    return await handleTaskCompletion(
                      props.resourceId,
                      props.recordId,
                      props.data.id,
                      item.id
                    );
                  }}
                  onCellClick={(event, cell) => {
                    if (!cell) {
                      return;
                    }

                    match(cell.cellType as TableCellType)
                      .with('document', () => {
                        if (!item.file) {
                          return;
                        }

                        event?.preventDefault();
                        event?.stopPropagation();

                        openFile(item.file.fileUri);
                      })
                      .otherwise(() => {});
                  }}
                  actions={{
                    primary: [
                      {
                        icon: 'editor/mode',
                        title: i18n.t('general.labels.edit'),
                        onClick: openEditDialog,
                      },
                    ],
                  }}
                >
                  <TaskNameCell
                    cellType="taskName"
                    recordId={props.recordId}
                    resourceId={props.resourceId}
                    checklistId={props.data.id}
                    taskId={item.id}
                    name={item.description}
                    isMandatory={item.isMandatory}
                    markedAsDone={item.markedAsDone}
                  />
                  <UserCell
                    cellType="finishedBy"
                    userId={item.user?.id}
                    finishedAt={item.finishedAt}
                  />
                  <DocumentCell
                    cellType="document"
                    cellHoverDisabled={!!item.file}
                    fileRequired={item.fileRequired}
                    hasFile={!!item.file}
                    fileUrl={item.file?.fileUri || ''}
                    fileName={item.file?.originalFilename || ''}
                  />
                  <CommentCell cellType="comment" comment={item.note} />
                </TableRow>
              );
            })}
          </Table>
        </VStack>
      </Card>
    </Box>
  );
}
