import {captureException} from '@sentry/browser';
// eslint-disable-next-line eag/no-external-date-time-format-functions -- TODO [T20-67958]
import {format} from 'date-fns';
import {Parameter} from 'platform/components';

import {isNotNil} from 'ramda-adjunct';

import {SimilarVehicleResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {buildArray, parseDate} from 'shared';

import {useVehicleCatalogue} from '../../../hooks/useVehicleCatalogue';

export const useVehicleInformationParameters = (vehicle: SimilarVehicleResponseBody) => {
  const [{getTransmission, getFuelType, getBodyStyle, getDrive}] = useVehicleCatalogue(
    vehicle.type
  );

  return buildArray<Parameter>()
    .add(vehicle.vin ?? i18n.t('entity.vehicle.notifications.noVIN'))
    .add(vehicle.registrationPlate ?? i18n.t('entity.vehicle.notifications.noLicencePlate'))
    .when(isNotNil(vehicle.firstRegistration), () => {
      try {
        return format(parseDate(vehicle.firstRegistration), 'yyyy');
      } catch (error) {
        captureException(
          new Error(`Couldn't parse firstRegistration date value: ${vehicle.firstRegistration}`)
        );
        return null;
      }
    })
    .when(vehicle.transmission, getTransmission(vehicle.transmission))
    .when(vehicle.fuelType, getFuelType(vehicle.fuelType))
    .when(vehicle.bodyStyle, getBodyStyle(vehicle.bodyStyle))
    .when(vehicle.drive, getDrive(vehicle.drive));
};
