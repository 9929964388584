import {isFeatureEnabled} from 'feature-flags';
import {
  Action,
  Card,
  CardProps,
  closeCurrentDialog,
  openDeleteDialog,
  openDialog,
} from 'platform/components';
import {Box, Hide, Show} from 'platform/foundation';

import {useDispatch} from 'react-redux';

import {assoc, isNil, path} from 'ramda';
import {isArray, isNotNilOrEmpty} from 'ramda-adjunct';

import {omneticApi} from '@dms/api/core';
import {useGetParticipationQuery} from '@dms/api/participation';
import {useDeleteCostMutation} from '@dms/api/saleVehicle';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetVehicleQuery} from '@dms/api/vehicle';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {ActionCallback, DataGrid, useRefreshDataGrid} from 'features/datagrid';

import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {useSaleVehicleActions} from '../../hooks/useSaleVehicleActions';
import {handleApiError} from '../../utils/handleApiError';
import {ApplyVehiclePresetForm} from '../ApplyVehiclePresetForm/ApplyVehiclePresetForm';
import {CreateVehiclePresetForm} from '../CreateVehiclePresetForm/CreateVehiclePresetForm';
import {VehicleAddCostForm} from '../VehicleAddCostForm/VehicleAddCostForm';
import {VehicleEarningCostForm} from '../VehicleEarningCostForm/VehicleEarningCostForm';
import {VehicleEditCostForm} from '../VehicleEditCostForm/VehicleEditCostForm';
import {EditCostForm} from './components/EditCostForm';

interface VehicleCostsGridCardProps extends TestIdProps {
  vehicleId: string;
  variant?: CardProps['variant'];
  refreshBusinessCase?: () => void;
  isSimplified?: boolean;
  businessCaseId?: string;
}

export function VehicleCostsGridCard(props: VehicleCostsGridCardProps) {
  const [deleteCost] = useDeleteCostMutation();
  const {isSaleActionEnabled} = useSaleVehicleActions(props.vehicleId);
  const dispatch = useDispatch();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const invalidateBC = () =>
    dispatch(omneticApi.util.invalidateTags(['BusinessCaseDetail', 'BusinessCasePurchasePrices']));

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: props.vehicleId ?? '',
    },
    {skip: isNil(props.vehicleId)}
  );

  const {data: vehicle} = useGetVehicleQuery(
    {vehicleId: props.vehicleId ?? ''},
    {skip: isNil(props.vehicleId)}
  );

  const [canCreateCost, canEditCost, canApplyPresetCost] = usePermissions({
    permissionKeys: ['vehicleCreateCost', 'vehicleEditCost', 'vehicleApplyPresetCost'],
    scopes: {
      vehicleCreateCost: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleEditCost: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleApplyPresetCost: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
    },
  });

  /**
   * Memo is used because of a bug in DataGrid. See https://carvago.atlassian.net/browse/T20-18662
   */
  const queryModifier = assoc('vehicle', props.vehicleId);

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const costId = isArray(rowId) ? rowId[0] : rowId;
    switch (actionKey) {
      case 'delete':
        openDeleteDialog({
          name: path(['name', 'value'], rowData),
          'data-testid': suffixTestId('deleteDialog', props),
          onConfirm: () =>
            deleteCost({vehicleId: props.vehicleId, costId})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError)
              .then(props.refreshBusinessCase)
              .then(() => invalidateBC())
              .catch((error: {response: Error}) => handleApiError(error.response)),
        });
        break;

      case 'edit':
        if (canEditCost) {
          if (isFeatureEnabled(featureFlags.SALES_COSTS_WITH_VAT)) {
            openDialog(
              <VehicleEditCostForm
                data-testid={suffixTestId('editDialog', props)}
                costId={costId}
                vehicleId={props.vehicleId}
                onDiscard={closeCurrentDialog}
                onSuccess={() => {
                  refreshData();
                  closeCurrentDialog();
                  invalidateBC();
                  props.refreshBusinessCase?.();
                }}
              />,
              {title: i18n.t('entity.vehicle.costs.editCost')}
            );
          } else {
            openDialog(
              <EditCostForm
                data-testid={suffixTestId('editDialog', props)}
                costId={costId}
                vehicleId={props.vehicleId}
                onCancel={closeCurrentDialog}
                onSuccess={() => {
                  refreshData();
                  closeCurrentDialog();
                  invalidateBC();
                  props.refreshBusinessCase?.();
                }}
                isSimplified={props.isSimplified}
                isCostsEditable={isSaleActionEnabled('SALE_VEHICLE_EDIT_EXPECTED_COST')}
              />,
              {title: i18n.t('entity.vehicle.costs.editCost')}
            );
          }
        }
        break;
    }
  };

  const openAddDialog = () =>
    isFeatureEnabled(featureFlags.SALES_COSTS_WITH_VAT)
      ? openDialog(
          <VehicleAddCostForm
            data-testid={suffixTestId('addDialog', props)}
            vehicleId={props.vehicleId}
            onDiscard={closeCurrentDialog}
            onSuccess={() => {
              closeCurrentDialog();
              invalidateBC();
              props.refreshBusinessCase?.();
              if (isNotNilOrEmpty(dataGridRef.current)) {
                refreshDataGrid();
              }
            }}
          />,
          {
            title: i18n.t('entity.vehicle.costs.addCost'),
          }
        )
      : openDialog(
          <VehicleEarningCostForm
            data-testid={suffixTestId('addDialog', props)}
            entity="cost"
            vehicleId={props.vehicleId}
            onCancel={closeCurrentDialog}
            onSuccess={() => {
              closeCurrentDialog();
              invalidateBC();
              props.refreshBusinessCase?.();
              if (isNotNilOrEmpty(dataGridRef.current)) {
                refreshDataGrid();
              }
            }}
            businessCaseId={props.businessCaseId}
            isSimplified={props.isSimplified}
            isCostsEditable={isSaleActionEnabled('SALE_VEHICLE_EDIT_EXPECTED_COST')}
          />,
          {
            title: i18n.t('entity.vehicle.costs.addCost'),
            size: props.isSimplified ? 'small' : undefined,
            'data-testid': suffixTestId('addDialog', props),
          }
        );

  const openApplyPresetDialog = () =>
    openDialog(
      <ApplyVehiclePresetForm
        entity="cost"
        vehicleId={props.vehicleId}
        onCancel={closeCurrentDialog}
        onSuccess={() => {
          closeCurrentDialog();
          invalidateBC();
          props.refreshBusinessCase?.();
          if (isNotNilOrEmpty(dataGridRef.current)) {
            refreshDataGrid();
          }
        }}
        businessCaseId={props.businessCaseId}
        data-testid={suffixTestId('applyPresetDialog', props)}
      />,
      {title: i18n.t('entity.earningsCosts.actions.addPreset'), size: 'small'}
    );

  const openCreatePresetDialog = () =>
    openDialog(
      <CreateVehiclePresetForm
        entity="cost"
        vehicleId={props.vehicleId}
        onCancel={closeCurrentDialog}
        onSuccess={closeCurrentDialog}
        data-testid={suffixTestId('createPresetDialog', props)}
      />,
      {title: i18n.t('entity.earningsCosts.actions.savePreset'), size: 'small'}
    );

  const actions = buildArray<Action>()
    .when(canCreateCost, {
      type: 'button',
      variant: 'link',
      onClick: openCreatePresetDialog,
      leftIcon: 'content/save',
      title: i18n.t('entity.earningsCosts.actions.savePreset'),
      'data-testid': suffixTestId('createPreset', props),
    })
    .when(canApplyPresetCost, {
      type: 'button',
      variant: 'link',
      onClick: openApplyPresetDialog,
      leftIcon: 'content/add_circle',
      title: i18n.t('entity.earningsCosts.actions.addFromPreset'),
      'data-testid': suffixTestId('addPresetButton', props),
    })
    .when(canCreateCost, {
      type: 'button',
      variant: 'link',
      onClick: openAddDialog,
      leftIcon: 'content/add_circle',
      title: i18n.t('entity.earningsCosts.actions.addItem'),
      'data-testid': suffixTestId('addButton', props),
    });

  return (
    <>
      <Card
        variant={props.variant}
        title={i18n.t('entity.earningsCosts.labels.costs')}
        actions={actions}
      >
        <Show when={props.isSimplified}>
          <Box height={65} key="business_case_costs">
            <DataGrid
              gridCode="business_case_costs"
              actionCallback={actionCallback}
              queryModifier={queryModifier}
              emptyState={{
                action: canCreateCost
                  ? {
                      title: i18n.t('entity.earningsCosts.actions.addCost'),
                      leftIcon: 'content/add_circle',
                      onClick: openAddDialog,
                    }
                  : undefined,
              }}
              data-testid={suffixTestId('grid', props)}
            />
          </Box>
        </Show>
        <Hide when={props.isSimplified}>
          <Box height={65} key="vehicle_costs">
            <DataGrid
              gridCode="vehicle_costs"
              actionCallback={actionCallback}
              queryModifier={queryModifier}
              emptyState={{
                action: canCreateCost
                  ? {
                      title: i18n.t('entity.earningsCosts.actions.addCost'),
                      leftIcon: 'content/add_circle',
                      onClick: openAddDialog,
                    }
                  : undefined,
              }}
              ref={dataGridRef}
              data-testid={suffixTestId('grid', props)}
            />
          </Box>
        </Hide>
      </Card>
    </>
  );
}
