import {Alpha3CountryCode, Button, Separator} from 'platform/components';
import {HStack, Show, VStack} from 'platform/foundation';

import {useEffect, useMemo} from 'react';

import {head, isEmpty} from 'ramda';

import {COMMON_CURRENCY} from '@dms/api/shared';
import {useSourcingGetVehicleCountQuery, useSourcingVehicleListQuery} from '@dms/api/sourcing';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {formatDocumentTitle} from '@dms/shared';
import {DEFAULT_RECOMMENDED_ID, useSourcingCurrency, useSourcingTargetCountry} from '@dms/teas';

import {useBoolean, useDebouncedValue} from 'shared';

import {SourcingDataStatus} from '../../components/SourcingDataStatus/SourcingDataStatus';
import {SourcingLayout} from '../../components/SourcingLayout/SourcingLayout';
import {useSourcingTable} from '../../components/SourcingTable/hooks/useSourcingTable';
import {useSourcingTableColumns} from '../../components/SourcingTable/hooks/useSourcingTableColumns';
import {SourcingTable} from '../../components/SourcingTable/SourcingTable';
import {VehiclePreview} from '../../components/VehiclePreview/VehiclePreview';
import {CLASSIFIENDS_SORT_BY} from '../../constants/classifiendsSortBy';
import {getSourcingVehicleId} from '../../utils/getSourcingVehicleId';
import {FilterHeader} from './components/FilterHeaderChips/FilterHeader';
import {FilterPresetButtons} from './components/FilterPresetButtons/FilterPresetButtons';
import {Filters} from './components/Filters/Filters';
import {useFilters} from './components/FiltersContext/FiltersContext';
import {useApiEnums} from './hooks/useApiEnums';
import {useSourcingFilterPresets} from './hooks/useSourcingFilterPresets';
import {createFilterBody} from './utils/createFilterBody';
import {hasSomeFilters} from './utils/hasSomeFilters';

export function ClassifiedsPage() {
  const [state, dispatch] = useSourcingTable('all-vehicles', DEFAULT_RECOMMENDED_ID);
  const currency = useSourcingCurrency();
  const {getEnum} = useApiEnums();
  const {filters} = useFilters();
  const debouncedFilters = useDebouncedValue(filters, 500);
  const filterPresetsControl = useSourcingFilterPresets();

  const columns = useSourcingTableColumns();
  const sourcingTargetCountry = useSourcingTargetCountry();
  const allFeatures = useMemo(() => getEnum('feature'), [getEnum]);

  const [isFiltersOpen, openFiltersView, closeFiltersView] = useBoolean();

  const sortByOptions =
    classifiedsAllOfferSortByOptions?.map((item) => ({
      value: item.id,
      label: item.name,
      orderBy: item.orderBy,
    })) ?? [];
  const orderBy = sortByOptions.find((item) => item.value === state.orderBy)?.orderBy;

  const {data, isFetching, isError, error} = useSourcingVehicleListQuery(
    {
      body: createFilterBody(
        debouncedFilters,
        {
          currency: currency?.code ?? COMMON_CURRENCY.czk,
          country: sourcingTargetCountry?.code as Alpha3CountryCode,
        },
        allFeatures
      ),
      page: state.page ?? 1,
      orderBy,
      currency: currency?.code ?? COMMON_CURRENCY.czk,
    },
    {skip: isFiltersOpen}
  );

  const {data: dataCount} = useSourcingGetVehicleCountQuery({
    body: createFilterBody(
      debouncedFilters,
      {
        currency: currency?.code ?? COMMON_CURRENCY.czk,
        country: sourcingTargetCountry?.code as Alpha3CountryCode,
      },
      allFeatures
    ),
    currency: currency?.code ?? COMMON_CURRENCY.czk,
  });

  const {isEmpty: isPresetsEmpty} = useSourcingFilterPresets();
  const hasFilters = hasSomeFilters(filters);
  const rows = useMemo(() => data?.data ?? [], [data?.data]);
  const isDataEmpty = !isFetching && !isError && isEmpty(rows);

  // TODO move this useEffect to useFilters() hook
  // Reset page to 1 when filters are changed
  useEffect(() => {
    dispatch({type: 'changePage', page: 1});
  }, [dispatch, filters]);

  // Load next page if data are empty (user put all data to hidden list)
  const hasMoreRows = (dataCount?.count ?? 0) > rows.length;
  useEffect(() => {
    if (isEmpty(rows) && hasMoreRows && !isFetching) {
      dispatch({type: 'nextPage'});
    }
  }, [dispatch, hasMoreRows, isFetching, rows]);

  if (isFiltersOpen) {
    return (
      <Filters
        filterPresetsControl={filterPresetsControl}
        count={dataCount?.count ?? 0}
        onClose={closeFiltersView}
        data-testid={testIds.sourcing.classifieds('filters')}
      />
    );
  }

  return (
    <SourcingDataStatus
      isEmpty={isDataEmpty}
      iconUrl="/assets/images/empty_images/empty-sourcing.svg"
      text={i18n.t('general.notifications.noResults')}
      textSecondLine={i18n.t('page.filters.notifications.removeSearchFilters')}
      noResultsContent={
        <Button
          title={i18n.t('page.filters.labels.filters')}
          leftIcon="content/filter_list"
          onClick={openFiltersView}
          data-testid="button-classifieds-allOffers-empty.filter"
        />
      }
    >
      <SourcingLayout
        pageTitle={formatDocumentTitle(
          i18n.t('page.sourcing.labels.classifieds'),
          i18n.t('page.sourcing.labels.sourcing')
        )}
        header={
          <VStack width="100%" spacing={4}>
            <HStack spacing={4}>
              <Button
                variant={hasFilters ? 'primary' : 'outlined'}
                title={i18n.t('page.filters.labels.filters')}
                leftIcon="content/filter_list"
                onClick={openFiltersView}
                data-testid={testIds.sourcing.classifieds('filters')}
              />
              <Show when={!isPresetsEmpty}>
                <Separator orientation="vertical" spacing={0} />
              </Show>
              <FilterPresetButtons filterPresetsControl={filterPresetsControl} />
            </HStack>

            <FilterHeader
              filterPresetsControl={filterPresetsControl}
              data-testid={testIds.sourcing.classifieds('filterHeaderChips')}
            />
          </VStack>
        }
        content={
          <SourcingTable
            state={state}
            dispatch={dispatch}
            columns={columns}
            data={rows}
            count={dataCount?.count}
            sortByOptions={sortByOptions}
            isLoading={isFetching}
            error={error}
          />
        }
        iframe={
          <VehiclePreview
            key={getSourcingVehicleId(state.selectedRow ?? head(data?.data ?? []))}
            vehicleId={getSourcingVehicleId(state.selectedRow ?? head(data?.data ?? []))}
          />
        }
      />
    </SourcingDataStatus>
  );
}

const classifiedsAllOfferSortByOptions = [
  {
    name: i18n.t('entity.vehicle.labels.sortByRecommended'),
    orderBy: [] as string[],
    id: DEFAULT_RECOMMENDED_ID,
  },
  ...CLASSIFIENDS_SORT_BY,
  {
    name: i18n.t('entity.vehicle.labels.sortByMaxMargin'),
    orderBy: ['-max_margin'],
    id: 'MAX_MARGIN',
  },
];
