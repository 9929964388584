import {Module} from '@dms/api/shared';
import {moduleKeys} from '@dms/config';
import {featureFlags} from '@dms/feature-flags';
import {analyticsRoutes} from '@dms/routes';
import {permissions} from '@dms/shared';

import {Analytics} from './pages/Analytics/Analytics';
import {AnalyticsCloud} from './pages/AnalyticsCloud/AnalyticsCloud';

export const AnalyticsModule: Module = {
  id: moduleKeys.analytics,
  layoutProps: {
    title: 'analytics.label.title',
    icon: 'action/timeline',
    iconV2: 'sidebar/analytics',
  },
  routerProps: {
    path: analyticsRoutes.overview,
    element: <Analytics />,
  },
  requiredFeatureFlag: (resolve) => {
    const flags = [featureFlags.ACL_REPORTING, featureFlags.GOOD_DATA_COULD];

    return flags.some(resolve);
  },
  requiredPermission: permissions.reportingRead,
  content: [
    {
      id: 'analytics-overview',
      requiredFeatureFlag: featureFlags.ACL_REPORTING,
      routerProps: {
        path: analyticsRoutes.overview,
        element: <Analytics />,
      },
      layoutProps: {
        title: 'analytics.label.title',
        icon: 'sidebar/analytics',
      },
    },
    {
      id: 'analytics-dashboard',
      requiredFeatureFlag: featureFlags.ACL_REPORTING,
      routerProps: {
        path: analyticsRoutes.dashboard,
        element: <Analytics />,
      },
    },
    {
      id: 'analytics-cloud-overview',
      requiredFeatureFlag: featureFlags.GOOD_DATA_COULD,
      routerProps: {
        path: analyticsRoutes.cloudOverview,
        element: <AnalyticsCloud />,
      },
      layoutProps: {
        title: 'analytics.label.cloudTitle',
        icon: 'sidebar/analytics',
      },
    },
    {
      id: 'analytics-cloud-dashboard',
      requiredFeatureFlag: featureFlags.GOOD_DATA_COULD,
      routerProps: {
        path: analyticsRoutes.cloudDashboard,
        element: <AnalyticsCloud />,
      },
    },
  ],
};
