import {z} from 'zod';

import {BaseVoidResponseSchema} from '@dms/api/shared';

export const HeaderDynamicActionsResourcesSchema = z.union([
  z.literal('delivery-note'),
  z.literal('direct-sale'),
  z.literal('credit-note'),
  z.literal('service-order-issue-note'),
  z.literal('parts-request-return'),
  z.literal('service-order-return'),
  z.literal('service-case-order'),
  z.literal('receive-note'),
  z.literal('supplier-order'),
]);
export type HeaderDynamicActionsResources = z.infer<typeof HeaderDynamicActionsResourcesSchema>;

export const HeaderActionTypeSchema = z.union([z.literal('FE'), z.literal('BE')]).optional();
export type HeaderActionType = z.infer<typeof HeaderActionTypeSchema>;

export const HeaderDynamicActionsOptionsSchema = z.array(
  z.object({
    key: z.string(),
    style: z.union([z.literal('enable'), z.literal('hidden'), z.literal('disabled')]),
    position: z.number(),
    title: z.string(),
    variant: z.string().optional(),
    type: HeaderActionTypeSchema,
    tooltip: z
      .object({
        label: z.string().optional(),
        description: z.string().optional(),
      })
      .optional(),
  })
);

export type HeaderDynamicActionsOptions = z.infer<typeof HeaderDynamicActionsOptionsSchema>;

export const HeaderDynamicActionsResponseSchema = z.object({
  actions: z.object({
    optionClick: z.string(),
    options: HeaderDynamicActionsOptionsSchema,
  }),
});
export type HeaderDynamicActionsResponse = z.infer<typeof HeaderDynamicActionsResponseSchema>;

export const HeaderDynamicActionsRequestSchema = z.object({
  resource: HeaderDynamicActionsResourcesSchema,
  id: z.string(),
});
export type HeaderDynamicActionsRequest = z.infer<typeof HeaderDynamicActionsRequestSchema>;

export const HeaderDynamicActionsEventRequestSchema = z.object({
  id: z.string(),
  resource: HeaderDynamicActionsResourcesSchema,
  body: z.object({
    actionKey: z.string(),
    returnedBy: z.string().optional(),
    originEntityHeaderType: HeaderDynamicActionsResourcesSchema.optional(),
    newDirectSaleVariantId: z.string().optional(),
  }),
});

export type HeaderDynamicActionsEventRequest = z.infer<
  typeof HeaderDynamicActionsEventRequestSchema
>;

export const HeaderDynamicActionsEventResponseSchema = BaseVoidResponseSchema;
export type HeaderDynamicActionsEventResponse = z.infer<
  typeof HeaderDynamicActionsEventResponseSchema
>;
