import {useAddVehicleCostMutation, useListVehicleCostCategoriesQuery} from '@dms/api/saleVehicle';

import {DeepNonNullableWithPartial, RequiredTestIdProps} from 'shared';

import {useTenant} from '../../hooks/useTenant';
import {handleApiError} from '../../utils/handleApiError';
import {VehicleTransactionForm} from '../VehicleTransactionForm/VehicleTransactionForm';

type SubmitHandler = Parameters<typeof VehicleTransactionForm>[0]['onSubmit'];

interface VehicleAddCostDialogProps extends RequiredTestIdProps {
  vehicleId: string;
  onSuccess?: (id: string) => void;
  onDiscard?: VoidFunction;
}

export function VehicleAddCostForm(props: VehicleAddCostDialogProps) {
  const [addVehicleCost] = useAddVehicleCostMutation();

  const {data: categories} = useListVehicleCostCategoriesQuery({});

  const {tenantCountry, tenantCurrency} = useTenant();

  const onSubmit: SubmitHandler = async (data) => {
    const {realPrice, ...rest} = data as DeepNonNullableWithPartial<typeof data>;
    await addVehicleCost({
      vehicleId: props.vehicleId,
      addVehicleCostRequestBody: {
        ...rest,
        category: rest.category?.[0] ?? null,
        realPriceOneTime: realPrice!,
        // TODO: https://carvago.atlassian.net/browse/T20-69682
        realPriceRecurringCalculated: null,
        realPriceRecurringSimple: null,
      },
    })
      .unwrap()
      .then((response) => {
        props.onSuccess?.(response.id);
      })
      .catch(handleApiError);
  };

  return (
    <VehicleTransactionForm
      data-testid={props['data-testid']}
      onSubmit={onSubmit}
      onDiscard={props.onDiscard}
      currency={tenantCurrency}
      countryCode={tenantCountry}
      showCategories
      categories={categories ?? []}
    />
  );
}
