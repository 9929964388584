import {ReactNode} from 'react';

import {MenuButton} from './MenuButton';
import {MenuLink} from './MenuLink';

interface MenuItemLinkOrButtonProps {
  to: string;
  isGroup: boolean;
  children: ReactNode;
  onClick: () => void;
}

export function MenuItemLinkOrButton(props: MenuItemLinkOrButtonProps) {
  if (props.isGroup) {
    return <MenuButton onClick={props.onClick}>{props.children}</MenuButton>;
  }

  return (
    <MenuLink to={props.to} onClick={props.onClick}>
      {props.children}
    </MenuLink>
  );
}
