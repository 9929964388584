import {Icon} from 'platform/foundation';

import {useGetMyTasksCountQuery} from '@dms/api/taskManagement';
import {TaskCenterHeaderButton} from '@dms/task-manager';

import {isDefinedNonZeroValue, RequiredTestIdProps, suffixTestId} from 'shared';

import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {formatBadgeNumber} from '../utils/formatBadgeNumber';
import {NavigationButton} from './NavigationButton';

interface TaskCenterButtonProps extends RequiredTestIdProps {
  isOpen: boolean;
}

function TaskCenterButtonMobile(props: TaskCenterButtonProps) {
  const {data: tasksCounts} = useGetMyTasksCountQuery();

  return (
    <NavigationButton
      isActive={props.isOpen}
      badgeValue={
        isDefinedNonZeroValue(tasksCounts?.count) ? formatBadgeNumber(tasksCounts.count) : undefined
      }
      data-testid={suffixTestId('task-center', props)}
    >
      <Icon size={6} value="action/assignment_turned_in" color="text.white" />
    </NavigationButton>
  );
}

export function TaskCenterButton(props: TaskCenterButtonProps) {
  const isMobileNavigation = useIsMobileNavigation();

  if (isMobileNavigation) {
    return <TaskCenterButtonMobile {...props} />;
  }

  return <TaskCenterHeaderButton {...props} />;
}
