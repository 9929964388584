import {Actions, ChipsOption} from 'platform/components';

import {isNil, omit} from 'ramda';

import {useGetCurrentUserInfoQuery} from '@dms/api/user';
import i18n from '@dms/i18n';

import {TestIdProps} from 'shared';

import {FormToDataGridConnectorProps} from 'features/datagrid';

import {PRESET_FILTERS} from '../../constants/presetFilters';

interface TasksExternalFilterProps extends TestIdProps {
  dgProps: FormToDataGridConnectorProps;
}

const presetFilters: ChipsOption[] = [
  {
    label: i18n.t('entity.task.labels.myTasks'),
    value: PRESET_FILTERS.ASSIGNED_TO,
  },
  {
    label: i18n.t('entity.task.labels.byMeTasks'),
    value: PRESET_FILTERS.REPORTED_BY,
  },
] as const;

export function TasksExternalFilter(props: TasksExternalFilterProps) {
  const {data: userInfo} = useGetCurrentUserInfoQuery();
  const userId = userInfo?.id;

  const assignedTo = props.dgProps.values.assignedTo;
  const reportedBy = props.dgProps.values.reportedBy;
  const isAssignedToMe =
    Array.isArray(assignedTo) &&
    assignedTo.length === 1 &&
    assignedTo[0] === userId &&
    isNil(reportedBy);
  const isReportedByMe =
    Array.isArray(reportedBy) &&
    reportedBy.length === 1 &&
    reportedBy[0] === userId &&
    isNil(assignedTo);

  return (
    <Actions
      data-testid={props['data-testid']}
      actions={[
        {
          type: 'chips',
          onChange: (value) => {
            if (!value) {
              return;
            }
            const presetToSet = value[0];
            const omittedDatagridValues = omit(
              [PRESET_FILTERS.ASSIGNED_TO, PRESET_FILTERS.REPORTED_BY],
              props.dgProps.values
            );
            const shouldResetFilters =
              (presetToSet === PRESET_FILTERS.ASSIGNED_TO && isAssignedToMe) ||
              (presetToSet === PRESET_FILTERS.REPORTED_BY && isReportedByMe);

            // WORKAROUND: omitting the value itself would work, however, upon deselecting
            //  the chip when it's the only value present, the datagrid ignores the empty
            //  object and doesn't update the state accordingly. Setting it to null works
            //  the same and forces the datagrid to refresh with updated state accordingly
            props.dgProps.onChange({
              ...omittedDatagridValues,
              [value[0]]: shouldResetFilters ? null : [userId],
            });
          },
          isMultiple: false,
          value:
            isAssignedToMe || isReportedByMe
              ? [isAssignedToMe ? PRESET_FILTERS.ASSIGNED_TO : PRESET_FILTERS.REPORTED_BY]
              : [],
          options: presetFilters,
        },
      ]}
    />
  );
}
