import {z} from 'zod';

import {
  BasePriceSchema,
  PriceWithAndWithoutVatSchema,
  BaseVoidResponseSchema,
} from '@dms/api/shared';

const ArticlePriceIndicatorSchema = z.enum(['EQUAL', 'HIGHER', 'LOWER', 'NO_PRICE']);
export type ArticlePriceIndicator = z.infer<typeof ArticlePriceIndicatorSchema>;

/**
 * GET
 */
export const GetDirectSaleMaterialItemRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
});
export type GetDirectSaleMaterialItemRequest = z.infer<
  typeof GetDirectSaleMaterialItemRequestSchema
>;

export const WarehouseSchema = z.object({
  warehouseId: z.string(),
  warehouseArticleId: z.string(),
  storageLocation: z.string().optional(),
  discountGroup: z.string().optional(),
  warehouseAction: z.string(),
});

export const QuantitySchema = z.object({
  amount: z.number(),
  unit: z.string(),
});

export const VatDetailsSchema = z.object({
  type: z.string(),
  freeCode: z.string().optional(),
});

export const DiscountSourceTypeSchema = z.enum([
  'customer-contract',
  'custom-discount',
  'manual-discount',
]);
export type DiscountSourceType = z.infer<typeof DiscountSourceTypeSchema>;

export const DiscountSchema = z.object({
  isDoNotApplyDiscount: z.boolean(),
  isDiscountEnable: z.boolean(),
  discountSourceType: DiscountSourceTypeSchema,
  discountRate: z.number(),
});
export type Discount = z.infer<typeof DiscountSchema>;

export const AvailableDiscountSchema = z.object({
  discountSourceType: DiscountSourceTypeSchema,
  discountSourceTypeName: z.string(),
  discountRate: z.number(),
  isSet: z.boolean(),
  isChangeable: z.boolean(),
});

export const ItemsDiscountsSchema = z.object({
  isDoNotApplyDiscount: z.boolean(),
  isDiscountEnable: z.boolean(),
  availableDiscounts: z.array(AvailableDiscountSchema).optional(),
});

export const MarginSchema = PriceWithAndWithoutVatSchema.merge(
  z.object({
    marginPercentage: z.number().nullable(),
    isMarginUnderMinimum: z.boolean(),
    isMarginExisting: z.boolean(),
  })
);

export const GetDirectSaleMaterialItemResponseSchema = z.object({
  id: z.string(),
  warehouse: WarehouseSchema,
  name: z.string(),
  number: z.string(),
  neededAt: z.string().optional(),
  quantity: QuantitySchema,
  dispensingUnit: z.number(),
  isUnitPriceWithVat: z.boolean(),
  purchasePricePerUnit: BasePriceSchema,
  sellingPricePerUnit: BasePriceSchema,
  sellingPriceVat: VatDetailsSchema,
  articlePrice: PriceWithAndWithoutVatSchema,
  articlePriceIndicator: ArticlePriceIndicatorSchema,
  assignMechanic: z.string().optional(),
  isCustomPrice: z.boolean(),
  baseSellingPricePerUnit: BasePriceSchema,
  baseSellingPriceVat: z.object({type: z.string()}),
  discountSource: z.string().optional(),
  manufacturerNumber: z.string(),
  manufacturerId: z.string(),
  isAllowedItemEditing: z.boolean(),
  isAllowedQuantityEditing: z.boolean(),
  itemsDiscounts: ItemsDiscountsSchema,
  sellingTotalPrice: PriceWithAndWithoutVatSchema,
  purchaseTotalPrice: PriceWithAndWithoutVatSchema,
  marginTotal: MarginSchema,
  marginPerUnit: MarginSchema,
  discount: BasePriceSchema,
});
export type GetDirectSaleMaterialItemResponse = z.infer<
  typeof GetDirectSaleMaterialItemResponseSchema
>;

/**
 * POST
 */

export const PostDirectSaleMaterialItemCalculatePriceRequestBodySchema = z.object({
  quantity: z.number().nullish(),
  sellingPricePerUnit: z.number().nullish(),
  vatType: z.string().nullish(),
  isCustomPrice: z.boolean().nullish(),
  discount: z.object({
    isDoNotApplyDiscount: z.boolean().nullish(),
    isDiscountEnable: z.boolean().nullish(),
    discountSourceType: DiscountSourceTypeSchema.nullish(),
    discountRate: z.number().nullish(),
  }),
  getCurrentPrice: z.boolean().nullish(),
});

export const PostDirectSaleMaterialItemCalculatePriceRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
  body: PostDirectSaleMaterialItemCalculatePriceRequestBodySchema,
});
export type PostDirectSaleMaterialItemCalculatePriceRequest = z.infer<
  typeof PostDirectSaleMaterialItemCalculatePriceRequestSchema
>;

const MaterialRecalculatedPricesSchema = z.object({
  articlePrice: PriceWithAndWithoutVatSchema,
  articlePriceIndicator: ArticlePriceIndicatorSchema,
  sellingPricePerUnit: BasePriceSchema,
  sellingTotalPrice: PriceWithAndWithoutVatSchema,
  purchaseTotalPrice: PriceWithAndWithoutVatSchema,
  marginTotal: MarginSchema,
  marginPerUnit: MarginSchema,
  discount: BasePriceSchema,
  isCustomPrice: z.boolean(),
  itemsDiscounts: ItemsDiscountsSchema,
});

export const PostDirectSaleMaterialItemCalculatePriceResponseSchema =
  MaterialRecalculatedPricesSchema;
export type PostDirectSaleMaterialItemCalculatePriceResponse = z.infer<
  typeof PostDirectSaleMaterialItemCalculatePriceResponseSchema
>;

/**
 * PATCH
 */

export const PatchDirectSaleMaterialItemRequestBodySchema = z.object({
  quantity: z.number(),
  isUnitPriceWithVat: z.boolean(),
  sellingPricePerUnit: z.number(),
  vatType: z.string(),
  vatFreeCode: z.string().nullish(),
  mechanic: z.string().nullish(),
  isCustomPrice: z.boolean(),
  neededAt: z.string().nullish(),
  discount: z.object({
    isDoNotApplyDiscount: z.boolean().nullish(),
    isDiscountEnable: z.boolean(),
    discountSourceType: DiscountSourceTypeSchema.nullish(),
    discountRate: z.number().nullish(),
  }),
});

export const PatchDirectSaleMaterialItemRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
  body: PatchDirectSaleMaterialItemRequestBodySchema,
});
export type PatchDirectSaleMaterialItemRequest = z.infer<
  typeof PatchDirectSaleMaterialItemRequestSchema
>;

export const PatchDirectSaleMaterialItemResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleMaterialItemResponse = z.infer<
  typeof PatchDirectSaleMaterialItemResponseSchema
>;
