import {ActivitySidebar, ActivitySidebarAction} from '@dms/shared';

import {buildArray} from 'shared';

import {ActivityTab} from './components/ActivityTab';

interface BusinessCaseActivityPanelProps {
  businessCaseId: string;
}

export function BusinessCaseActivityPanel(props: BusinessCaseActivityPanelProps) {
  const actions = buildArray<ActivitySidebarAction>().add({
    label: 'Activity',
    icon: 'sidebar/activity',
    component: <ActivityTab businessCaseId={props.businessCaseId} />,
  });

  return <ActivitySidebar actions={actions} />;
}
