import {isFeatureEnabled} from 'feature-flags';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import featureFlags from '@dms/feature-flags';
import {testIds} from '@dms/routes';
import {Section} from '@dms/shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

interface ActivityLogProps {
  interestId: string;
}

export function ActivityLogs(props: ActivityLogProps) {
  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {record: props.interestId}]),
    [props.interestId]
  );

  return (
    <Section>
      <DataGrid
        data-testid={testIds.interest.detail('activityLog')}
        gridCode={
          isFeatureEnabled(featureFlags.SALE_INTEREST_ACTION_LOG)
            ? 'interest-audit-log'
            : 'interest-action-log'
        }
        autoHeight
        queryModifier={queryModifier}
      />
    </Section>
  );
}
