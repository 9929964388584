import {Module} from '@dms/api/shared';
import {moduleKeys} from '@dms/config';
import {featureFlags} from '@dms/feature-flags';
import {opportunitiesKanbanRoutes} from '@dms/routes';
import {permissions} from '@dms/shared';

import {OpportunitiesKanban} from './components/OpportunitiesKanban';

export const OpportunitiesKanbanModule: Module = {
  id: moduleKeys.opportunitiesKanban,
  layoutProps: {
    icon: 'sidebar/opportunities_kanban',
    iconV2: 'sidebar/opportunities_kanban',
    title: 'page.opportunitiesKanban.title',
  },
  routerProps: {
    path: opportunitiesKanbanRoutes.home,
    element: <OpportunitiesKanban />,
  },
  requiredPermission: permissions.businessCaseRead,
  requiredFeatureFlag: featureFlags.ACL_SALES,
};
