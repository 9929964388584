import {Avatar, IconButton} from 'platform/components';
import {Clickable, Box, HStack, useDevice, Hide} from 'platform/foundation';

import {UserResponseBody} from '@dms/api/shared';
import {getNaturalPersonFullName} from '@dms/shared';

import {EMPTY_PLACEHOLDER, useBoolean} from 'shared';

import {avatarColors} from '../constants/kanbanColors';
import {KanbanUser} from '../types/KanbanUser';
import {KanbanControlDialog} from './KanbanControlDialog';

interface KanbanUserControlProps {
  currentUserId: string;
  users: UserResponseBody[];
  selectedUsers: KanbanUser[];
  onUserSelect: (users: KanbanUser[]) => void;
}

export function KanbanUserControl(props: KanbanUserControlProps) {
  const device = useDevice();
  const [isDialogOpen, openDialog, closeDialog] = useBoolean(false);

  const users = props.users.reduce<KanbanUser[]>((results, user) => {
    if (user.blocked || props.selectedUsers.some((selectedUser) => selectedUser.id === user.id)) {
      return results;
    }
    const kanbanUser: KanbanUser = {
      ...user,
      fullName:
        getNaturalPersonFullName({
          firstName: user.firstName,
          lastName: user.lastName,
        }) || EMPTY_PLACEHOLDER,
      isHidden: false,
    };
    return [...results, kanbanUser];
  }, []);

  return (
    <HStack width="100%" align="center" justify="flex-end" spacing={2}>
      <Hide when={props.selectedUsers.length < 2} onMobile>
        {props.selectedUsers.map((user, userIndex) => (
          <Clickable
            key={`big-calendar-header-user-control-${user.id}`}
            isDisabled={
              device === 'mobile' ||
              (!user.isHidden && props.selectedUsers.filter((user) => !user.isHidden).length === 1)
            }
            onClick={() => {
              props.onUserSelect(
                props.selectedUsers.map((item) =>
                  item.id === user.id ? {...item, isHidden: !item.isHidden} : item
                )
              );
            }}
          >
            <Box opacity={user.isHidden ? 0.2 : 1}>
              <Avatar
                name={user.fullName}
                size="small"
                color={avatarColors[userIndex]}
                textColor="white"
              />
            </Box>
          </Clickable>
        ))}
      </Hide>
      <IconButton
        icon={props.selectedUsers.length > 1 ? 'navigation/more_vert' : 'social/person_add'}
        priority="default"
        onClick={openDialog}
      />
      <KanbanControlDialog
        isOpen={isDialogOpen}
        currentUserId={props.currentUserId}
        users={users}
        selectedUsers={props.selectedUsers}
        onUserSelect={props.onUserSelect}
        onClose={closeDialog}
      />
    </HStack>
  );
}
