import {IconButton} from 'platform/components';
import {Box, Heading, HStack, Space} from 'platform/foundation';

import {ReactNode} from 'react';

interface ActivitySidebarItemProps {
  title: string;
  onClose: () => void;
  children: ReactNode;
}

export function ActivitySidebarItem(props: ActivitySidebarItemProps) {
  return (
    <Box
      width={80}
      height="100%"
      backgroundColor="general.white"
      borderRight="1px solid"
      borderColor="general.separator"
      boxShadow="elevation_3"
    >
      <Box borderBottom="1px solid" borderColor="general.separator" padding={4}>
        <HStack spacing={4} justify="space-between" align="center">
          <Heading size={4}>{props.title}</Heading>
          <HStack>
            <Space horizontal={2} />
            <IconButton icon="navigation/close" onClick={props.onClose} />
          </HStack>
        </HStack>
      </Box>
      <Box height="100%" padding={4}>
        {props.children}
      </Box>
    </Box>
  );
}
