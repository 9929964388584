import {Box, VStack} from 'platform/foundation';

import {useRef} from 'react';

import {TaskCenterContent} from '@dms/task-manager';

import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {TaskCenterButton} from './TaskCenterButton';
import {TopBarItem, TopBarItemRef} from './TopbarItem';

export function TaskCenter() {
  const isMobileNavigation = useIsMobileNavigation();
  const topBarItemRef = useRef<TopBarItemRef>(null);

  return (
    <TopBarItem
      ref={topBarItemRef}
      isFullScreen={isMobileNavigation}
      trigger={(isOpen) => <TaskCenterButton isOpen={isOpen} data-testid="layout-header" />}
    >
      <Box
        backgroundColor="general.white"
        minWidth={['auto', 'auto', 120]}
        width="100%"
        height="100%"
        padding={4}
        paddingTop={0}
      >
        <VStack height="100%">
          <TaskCenterContent onClick={topBarItemRef.current?.close} data-testid="layout-header" />
        </VStack>
      </Box>
    </TopBarItem>
  );
}
