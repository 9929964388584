import {colord} from 'colord';
import {useAnimatedPopper} from 'platform/components';
import {Box} from 'platform/foundation';
import styled, {css, useTheme} from 'styled-components';

import {forwardRef, ReactNode, useImperativeHandle, useRef} from 'react';

import {useOutsideClick} from 'shared';

import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';

export interface TopBarItemRef {
  open: () => void;
  close: () => void;
}

interface TopBarItemProps {
  trigger: (isOpen: boolean) => ReactNode;
  children: ReactNode;
  isFullScreen?: boolean;
}

export const TopBarItem = forwardRef<TopBarItemRef, TopBarItemProps>((props, ref) => {
  const {zIndices} = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobileNavigation = useIsMobileNavigation();
  const {openPopper, togglePopper, referenceRef, popperProps, Popper, closePopper, isOpen} =
    useAnimatedPopper({
      placement: 'bottom-end',
      gutter: isMobileNavigation ? 0 : 4,
      isOutsideClickIgnored: true,
    });

  useOutsideClick({
    ref: containerRef,
    enabled: isOpen,
    handler: () => {
      closePopper();
    },
  });

  useImperativeHandle(ref, () => ({
    open: openPopper,
    close: closePopper,
  }));

  const handleStopPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div
      ref={containerRef}
      css={css`
        width: 100%;
        height: 100%;
      `}
    >
      <div
        css={css`
          cursor: pointer;
          width: 100%;
          height: 100%;
        `}
        ref={referenceRef}
        onClick={togglePopper}
      >
        {props.trigger(isOpen)}
      </div>
      <Popper
        {...popperProps}
        floatingStyles={{...popperProps.floatingStyles, zIndex: zIndices.HEADER_POPPER}}
      >
        <ContentOverlay $isFullScreen={props.isFullScreen} onClick={closePopper}>
          <Content onClick={handleStopPropagation}>
            <Box height="100%" overflowX="hidden" overflowY="auto">
              {props.children}
            </Box>
          </Content>
        </ContentOverlay>
      </Popper>
    </div>
  );
});

const ContentOverlay = styled.div<{$isFullScreen?: boolean}>`
  min-width: ${({$isFullScreen}) => ($isFullScreen ? '100vw' : 'auto')};
  height: ${({$isFullScreen, theme}) =>
    $isFullScreen ? `calc(100vh - ${theme.getSize(8)})` : 'auto'};
  height: ${({$isFullScreen, theme}) =>
    $isFullScreen ? `calc(100dvh - ${theme.getSize(8)})` : 'auto'};
  background-color: ${({theme, $isFullScreen}) =>
    $isFullScreen ? colord(theme.colors.general.black).alpha(0.2).toRgbString() : 'transparent'};
`;

const Content = styled.div`
  overflow: hidden;
  max-height: 80%;
  height: 100%;
  background-color: ${({theme}) => theme.colors.general.white};
  border-radius: ${({theme}) => theme.radii.medium};
  box-shadow: ${({theme}) => theme.shadows.elevation_3};
`;
