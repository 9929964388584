import {FormControl, FormField} from 'platform/components';
import {Box, Hide, HStack, Show, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {always, concat, isNil, times} from 'ramda';

import {useGetTireWarehouseSettingsQuery} from '@dms/api/metadaTires';
import {OccupiedSpaceRequestBody} from '@dms/api/storage';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {usePhoneNumbers} from '../../hooks/usePhoneNumbers';
import {useTireSetOptions} from '../../hooks/useTireSetOptions';
import {TireSetFormType} from '../../types/tires/TireSetFormType';
import {WheelType} from '../../types/tires/WheelType';
import {StorageWarehouse} from './components/StorageWarehouse';

interface TireSetFormHeaderProps extends TestIdProps {
  control: FormControl<TireSetFormType>;
  formApi: UseFormReturn<TireSetFormType>;
  minNumberOfWheels?: number;
  isReadOnly?: boolean;
  isNumberOfWheelsDisabled?: boolean;
  setId?: string;
  orderId: string;
  onSelectStorageLocationWithoutId?: (selectedSpace: OccupiedSpaceRequestBody | undefined) => void;
  selectStorageLocationWithoutId?: OccupiedSpaceRequestBody;
}

const EMPTY_WHEEL: Partial<WheelType> = {
  rimType: 'WITHOUT_RIM',
};

export function TireSetFormHeader(props: TireSetFormHeaderProps) {
  const {mechanicOptions, locationOptions, defaultManufacturer, categoryOptions} =
    useTireSetOptions();
  const {countriesOptions} = usePhoneNumbers();

  const {data: tireWarehouseSettings} = useGetTireWarehouseSettingsQuery();

  const handleNumberTiresChange = (value: number | null) => {
    if (isNil(value)) {
      return;
    }

    const wheels = props.formApi.getValues('wheels');

    let wheelsArray: WheelType[];

    if (value > wheels.length) {
      const dummyWheels = times(always(EMPTY_WHEEL), value - wheels.length);

      const fullWheels = dummyWheels.map((_, index) => ({
        ...EMPTY_WHEEL,
        manufacturer: defaultManufacturer?.id ?? '',
        location: locationOptions?.[(index + wheels.length) % locationOptions?.length]?.value,
      }));

      wheelsArray = concat(wheels, fullWheels);
    } else {
      wheelsArray = wheels.slice(0, value);
    }

    props.formApi.setValue('wheels', wheelsArray);
  };

  return (
    <VStack spacing={4}>
      <HStack spacing={4}>
        <Box flex={1}>
          <FormField
            control={props.control}
            name="contactPerson.name"
            type="text"
            label={i18n.t('entity.businessCase.labels.contactPerson')}
            data-testid={suffixTestId('contactPersonName', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            control={props.control}
            name="contactPerson.phoneNumber"
            type="phone"
            countries={countriesOptions}
            label={i18n.t('general.labels.phone')}
            data-testid={suffixTestId('contactPersonPhone', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            control={props.control}
            name="contactPerson.email"
            type="email"
            label={i18n.t('general.labels.email')}
            data-testid={suffixTestId('contactPersonEmail', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            control={props.control}
            name="note"
            type="text"
            label={i18n.t('general.labels.note')}
            data-testid={suffixTestId('note', props)}
          />
        </Box>
      </HStack>
      <HStack spacing={4}>
        <Box flex={1}>
          <FormField
            control={props.control}
            name="categoryId"
            type="choice"
            label={i18n.t('entity.tireSet.labels.category')}
            options={categoryOptions}
            menuInPortal
            isDisabled={props.isReadOnly}
            data-testid={suffixTestId('category', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            control={props.control}
            name="assignMechanic"
            type="choice"
            label={i18n.t('entity.tireSet.labels.mechanic')}
            options={mechanicOptions}
            menuInPortal
            isDisabled={props.isReadOnly}
            data-testid={suffixTestId('mechanic', props)}
          />
        </Box>
        <Box flex={1}>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={props.control}
                name="storedUntil"
                type="apiDate"
                label={i18n.t('entity.tireSet.labels.storedUntil')}
                isRelativeDatesHidden
                isDisabled={props.isReadOnly}
                data-testid={suffixTestId('storedUntil', props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={props.control}
                name="numberOfTires"
                type="integer"
                label={i18n.t('entity.tireSet.labels.numberOfTires')}
                data-testid={suffixTestId('numberOfTires', props)}
                isRequired
                isStepperVisible
                minStepperValue={props.minNumberOfWheels}
                isDisabled={props.isReadOnly || props.isNumberOfWheelsDisabled}
                onChange={handleNumberTiresChange}
              />
            </Box>
          </HStack>
        </Box>
        <Box flex={1}>
          <Show when={tireWarehouseSettings?.isWarehouseAllowed}>
            <StorageWarehouse
              setId={props.setId}
              orderId={props.orderId}
              isDisabled={props.isReadOnly || false}
              data-testid={suffixTestId('storageWarehouse', props)}
            />
          </Show>
          <Hide when={tireWarehouseSettings?.isWarehouseAllowed}>
            <FormField
              control={props.control}
              name="storedLocation"
              type="text"
              label={i18n.t('entity.tireSet.labels.storedLocation')}
              isDisabled={props.isReadOnly}
              data-testid={suffixTestId('storedLocation', props)}
            />
          </Hide>
        </Box>
      </HStack>
    </VStack>
  );
}
