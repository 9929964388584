import {Action, Card, useDialog} from 'platform/components';

import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {mergeAll} from 'ramda';

import {documentContextApi} from '@dms/api/documentContext';
import {useGetDocumentDatagridActions} from '@dms/document-context';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {Section, UploadDocumentsDialog, useCustomerUrl} from '@dms/shared';

import {buildArray} from 'shared';

import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

export function Documents() {
  const {customerId} = useCustomerUrl();
  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();
  const dispatch = useDispatch();

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {customerId}]),
    [customerId]
  );

  const [actionCallback, openBulkSignatureStepper] = useGetDocumentDatagridActions({
    contextId: customerId,
    contextTarget: 'customer',
    refreshData: refreshDataGrid,
    customerId,
  });

  const onDocumentCreated = () => {
    refreshDataGrid();

    dispatch(
      documentContextApi.util.invalidateTags([{type: 'documentsCount', id: customerId ?? ''}])
    );
  };

  return (
    <Section>
      <Card
        title={i18n.t('page.customer.documents.title')}
        actions={buildArray<Action>([
          {
            variant: 'link',
            type: 'button',
            leftIcon: 'action/backup',
            onClick: openUploadDialog,
            title: i18n.t('entity.document.actions.uploadDocument'),
          },
        ]).whenFeatureEnabled(featureFlags.DIGITAL_SIGNATURE, {
          type: 'button',
          variant: 'link',
          leftIcon: 'image/edit',
          title: i18n.t('entity.document.actions.signMultipleDocument'),
          onClick: openBulkSignatureStepper,
        })}
      >
        <DataGrid
          ref={dataGridRef}
          data-testid="customer-documents"
          gridCode="document-context-list-customer"
          emptyState={{
            headline: i18n.t('page.customer.notifications.noDocuments'),
            subheadline: i18n.t('page.customer.notifications.documentListWillAppearHere'),
          }}
          actionCallback={actionCallback}
          autoHeight
          queryModifier={queryModifier}
        />
      </Card>
      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        onDocumentCreated={onDocumentCreated}
        contextTarget="customer"
        contextId={customerId}
      />
    </Section>
  );
}
