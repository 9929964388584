import {Card, Checkbox, DataStatus, Flag} from 'platform/components';
import {HStack, Show, VStack} from 'platform/foundation';

import {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {isNotNil} from 'ramda';

import {
  SourcingVehicleDetailResponseBody,
  useSourcingGetPriceMapStatisticsQuery,
  useSourcingGetPriceMapVehiclesQuery,
  VehicleTypeForPriceMap,
} from '@dms/api/sourcing';
import i18n from '@dms/i18n';
import {selectUserSettings, useRouter} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {AdvanceVehicleView} from '../../../../components/AdvanceVehicleView/AdvanceVehicleView';
import {getValueFromURL} from '../../../../utils/getValueFromURL';
import {ComparisonFields} from './components/ComparisonFields/ComparisonFields';
import {PriceMap} from './components/PriceMap/PriceMap';

const defaultFallbackValue = 'N/A';

const removeHidden = (v: VehicleTypeForPriceMap): VehicleTypeForPriceMap => ({
  ...v,
  hidden: false,
});

interface PriceMapTabProps extends TestIdProps {
  vehicle: SourcingVehicleDetailResponseBody;
}

export function PriceMapTab({vehicle, ...props}: PriceMapTabProps) {
  const router = useRouter();
  const [onStockChecked, setOnStockChecked] = useState(true);
  const [soldRecentlyChecked, setSoldRecentlyChecked] = useState(true);
  const [soldChecked, setSoldChecked] = useState(true);
  const [selectedVehicleId, setSelectedVehicleId] = useState<string | undefined>();
  const {currency, country: sellerCountry} = useSelector(selectUserSettings);

  const margin = getValueFromURL('marginLte', router.query) as string;

  const requestBody = {
    margin: margin !== defaultFallbackValue ? parseInt(margin, 10) : undefined,
    year: vehicle.sourcingVehicle?.year ?? 0,
    power: vehicle.sourcingVehicle?.power ?? 0,
    mileage: vehicle.sourcingVehicle?.mileage ?? 0,
    currency: currency ?? 'CZK',
    sellerCountry: sellerCountry ?? 'CZ',
    make: vehicle.sourcingVehicle?.make?.key ?? '',
    model: vehicle.sourcingVehicle?.model?.key ?? '',
    drive: vehicle.sourcingVehicle?.driveType?.key ?? '',
    fuelType: vehicle.sourcingVehicle?.fuelType?.key ?? '',
    carStyle: vehicle.sourcingVehicle?.carStyle?.key ?? '',
    transmission: vehicle.sourcingVehicle?.transmission?.key ?? '',
  };

  const {
    data: vehicles,
    isLoading: isLoadingVehicles,
    isFetching,
  } = useSourcingGetPriceMapVehiclesQuery(requestBody);
  const {data: statistics, isLoading: isLoadingStatistics} =
    useSourcingGetPriceMapStatisticsQuery(requestBody);
  const isLoading = isLoadingVehicles || isLoadingStatistics;

  const {onStock, sold, soldRecently} = useMemo(() => {
    const priceMapVehiclesArray = Object.values(vehicles ?? {});

    return {
      // Note: remove hidden, otherwise recharts hide the dot on the chart when "hidden": true
      onStock: priceMapVehiclesArray.filter(({type}) => type === 'onStock').map(removeHidden),
      sold: priceMapVehiclesArray.filter(({type}) => type === 'sold').map(removeHidden),
      soldRecently: priceMapVehiclesArray
        .filter(({type}) => type === 'soldRecently')
        .map(removeHidden),
    };
  }, [vehicles]);

  return (
    <VStack spacing={4} width="100%">
      <ComparisonFields vehicle={vehicle} isLoading={isLoading} statistics={statistics ?? {}} />

      <Card
        title={i18n.t('general.labels.priceMap')}
        tooltip={i18n.t('entity.vehicle.labels.priceMapTooltip')}
      >
        <VStack spacing={8} height={100}>
          <HStack spacing={8} justify="center">
            <HStack align="center" spacing={2}>
              <Checkbox
                value={onStockChecked}
                onChange={setOnStockChecked}
                data-testid={suffixTestId('stockChecked', props)}
              />
              <Flag
                label={i18n.t('general.labels.onStock')}
                colorScheme="blue"
                data-testid={suffixTestId('stock', props)}
              />
            </HStack>
            <HStack align="center" spacing={2}>
              <Checkbox
                value={soldRecentlyChecked}
                onChange={setSoldRecentlyChecked}
                data-testid={suffixTestId('soldRecentlyChecked', props)}
              />
              <Flag
                label={i18n.t('general.labels.soldIn30Days')}
                colorScheme="yellow"
                data-testid={suffixTestId('soldRecently', props)}
              />
            </HStack>
            <HStack align="center" spacing={2}>
              <Checkbox
                value={soldChecked}
                onChange={setSoldChecked}
                data-testid={suffixTestId('soldChecked', props)}
              />
              <Flag
                label={i18n.t('general.labels.soldIn90Days')}
                colorScheme="green"
                data-testid={suffixTestId('sold', props)}
              />
            </HStack>
          </HStack>
          <PriceMap
            currentVehicle={vehicle}
            onStockVehicles={onStockChecked ? onStock : []}
            soldVehicles={soldChecked ? sold : []}
            soldRecentlyVehicles={soldRecentlyChecked ? soldRecently : []}
            onSelectVehicle={setSelectedVehicleId}
            isLoading={isLoading}
          />
        </VStack>
      </Card>

      <Show when={isNotNil(selectedVehicleId)}>
        <DataStatus isLoading={isFetching} minHeight={50}>
          <AdvanceVehicleView
            vehicle={isNotNil(vehicles) ? vehicles[selectedVehicleId!] : undefined}
            data-testid={suffixTestId(
              `selectedVehicle-advancedVehicleView[${{selectedVehicleId}}]`,
              props
            )}
          />
        </DataStatus>
      </Show>
    </VStack>
  );
}
