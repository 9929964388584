/**
 * Convert false values to undefined in the filter values
 * @param filterValues - The filter values to process
 * @returns The processed filter values
 */
export const processFilterValues = (
  filterValues: Record<string, unknown>
): Record<string, unknown> =>
  Object.entries(filterValues).reduce(
    (acc, [key, value]) => {
      acc[key] = value === false ? undefined : value;
      return acc;
    },
    {} as Record<string, unknown>
  );
