import {isNotNil} from 'ramda';

import {omneticApi} from '@dms/api/core';

import {
  AutocarisAuthenticateApiArg,
  AutocarisAuthenticateApiResponse,
  AutoplacPlAuthenticateApiArg,
  AutoplacPlAuthenticateApiResponse,
  DasWeltAutoAuthenticateApiArg,
  DasWeltAutoAuthenticateApiResponse,
  FacebookAuthenticateApiArg,
  FacebookAuthenticateApiResponse,
  FordGeronimoAuthenticateApiArg,
  FordGeronimoAuthenticateApiResponse,
  FordUzywaneAuthenticateApiArg,
  FordUzywaneAuthenticateApiResponse,
  GetAdvertisingStatisticsApiResponse,
  GetAutocarisPlatformApiArg,
  GetAutocarisPlatformApiResponse,
  GetAutoplacPlPlatformApiArg,
  GetAutoplacPlPlatformApiResponse,
  GetDasWeltAutoPlatformApiArg,
  GetDasWeltAutoPlatformApiResponse,
  GetFacebookPlatformApiArg,
  GetFacebookPlatformApiResponse,
  GetFordGeronimoPlatformApiArg,
  GetFordGeronimoPlatformApiResponse,
  GetFordUzywanePlatformApiArg,
  GetFordUzywanePlatformApiResponse,
  GetHyundaiPromisePlatformApiArg,
  GetHyundaiPromisePlatformApiResponse,
  GetMamGoPlatformApiArg,
  GetMamGoPlatformApiResponse,
  GetMercedesNaSkladePlatformApiArg,
  GetMercedesNaSkladePlatformApiResponse,
  GetMobileDePlatformApiArg,
  GetMobileDePlatformApiResponse,
  GetMyWebPlatformApiArg,
  GetMyWebPlatformApiResponse,
  GetOlxPlatformApiResponse,
  GetOtomotoPlatformApiArg,
  GetOtomotoPlatformApiResponse,
  GetSautoPlatformApiArg,
  GetSautoPlatformApiResponse,
  GetSkodaPlusPlatformApiArg,
  GetSkodaPlusPlatformApiResponse,
  GetTipcarsClientDataApiArg,
  GetTipcarsClientDataApiResponse,
  GetTipcarsPlatformApiArg,
  GetTipcarsPlatformApiResponse,
  GetVehisPlatformApiArg,
  GetVehisPlatformApiResponse,
  HyundaiPromiseAuthenticateApiArg,
  HyundaiPromiseAuthenticateApiResponse,
  MamGoAuthenticateApiArg,
  MamGoAuthenticateApiResponse,
  MercedesNaSkladeAuthenticateApiArg,
  MercedesNaSkladeAuthenticateApiResponse,
  MobileDeAuthenticateApiArg,
  MobileDeAuthenticateApiResponse,
  MobileDeOriginalCredentials,
  MyWebAuthenticateApiArg,
  MyWebAuthenticateApiResponse,
  OtomotoAuthenticateApiArg,
  OtomotoAuthenticateApiResponse,
  Platform,
  SautoAuthenticateApiArg,
  SautoAuthenticateApiResponse,
  SkodaPlusAuthenticateApiArg,
  SkodaPlusAuthenticateApiResponse,
  TipcarsAuthenticateApiArg,
  TipcarsAuthenticateApiResponse,
  TipcarsUpdateClientDataApiArg,
  TipcarsUpdateClientDataApiResponse,
  UpdatePlatformRequest,
  VehisAuthenticateApiArg,
  VehisAuthenticateApiResponse,
} from './types';

export const salesPublishingApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getPlatforms: build.query<Platform[], {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform',
        params: {branchId},
      }),
      providesTags: [{type: 'PlatformList'}],
    }),
    updatePlatform: build.mutation<void, UpdatePlatformRequest>({
      query: ({platformCode, branchId, enabled}) => ({
        url: `/dms/v2/sales/publishing/platform/${platformCode}/${enabled ? 'enable' : 'disable'}`,
        method: 'PUT',
        params: {branchId},
      }),
      onQueryStarted: async ({platformCode, branchId, enabled}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          salesPublishingApi.util.updateQueryData('getPlatforms', {branchId}, (draft) => {
            const platform = draft.find((p) => p.code === platformCode);
            if (isNotNil(platform)) {
              platform.enabled = enabled;
            }
          })
        );
        await queryFulfilled.catch(() => {
          patchResult.undo();
        });
      },
    }),
    getOlxPlatform: build.query<GetOlxPlatformApiResponse, void>({
      query: () => ({
        url: `/dms/v2/sales/publishing/platform/olx`,
      }),
    }),
    getDasWeltAutoPlatform: build.query<
      GetDasWeltAutoPlatformApiResponse,
      GetDasWeltAutoPlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/das_weltauto`,
        params: queryArg.params,
      }),
      providesTags: () => [{type: 'PublishingPlatformDasWeltAuto'}],
    }),
    getSkodaPlusPlatform: build.query<GetSkodaPlusPlatformApiResponse, GetSkodaPlusPlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/skoda_plus`,
        params: queryArg.params,
      }),
      providesTags: () => [{type: 'PublishingPlatformSkodaPlus'}],
    }),
    getMyWebPlatform: build.query<GetMyWebPlatformApiResponse, GetMyWebPlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/myweb`,
        params: queryArg?.params,
      }),
      providesTags: [{type: 'PublishingPlatformMyWeb'}],
    }),
    getMercedesNaSkladePlatform: build.query<
      GetMercedesNaSkladePlatformApiResponse,
      GetMercedesNaSkladePlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/mercedes_na_sklade`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformMercedesNaSklade'}],
    }),
    getHyundaiPromisePlatform: build.query<
      GetHyundaiPromisePlatformApiResponse,
      GetHyundaiPromisePlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/hyundai_promise`,
        params: queryArg.params,
      }),
      providesTags: () => [{type: 'PublishingPlatformHyundaiPromise'}],
    }),
    skodaPlusAuthenticate: build.mutation<
      SkodaPlusAuthenticateApiResponse,
      SkodaPlusAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/skoda_plus/authenticate`,
        params: queryArg.params,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: () => [
        // SkodaPlus and DasWeltAuto have same credentials
        {type: 'PublishingPlatformSkodaPlus'},
        {type: 'PublishingPlatformDasWeltAuto'},
        {type: 'PlatformList'},
      ],
    }),
    dasWeltAutoAuthenticate: build.mutation<
      DasWeltAutoAuthenticateApiResponse,
      DasWeltAutoAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/das_weltauto/authenticate`,
        params: queryArg.params,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: () => [
        // SkodaPlus and DasWeltAuto have same credentials
        {type: 'PublishingPlatformDasWeltAuto'},
        {type: 'PublishingPlatformSkodaPlus'},
        {type: 'PlatformList'},
      ],
    }),
    myWebAuthenticate: build.mutation<MyWebAuthenticateApiResponse, MyWebAuthenticateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/myweb/authenticate`,
        params: queryArg.params,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: [{type: 'PublishingPlatformMyWeb'}, {type: 'PlatformList'}],
    }),
    mercedesNaSkladeAuthenticate: build.mutation<
      MercedesNaSkladeAuthenticateApiResponse,
      MercedesNaSkladeAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/mercedes_na_sklade/authenticate`,
        params: queryArg.params,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: [{type: 'PublishingPlatformMercedesNaSklade'}, {type: 'PlatformList'}],
    }),
    hyundaiPromiseAuthenticate: build.mutation<
      HyundaiPromiseAuthenticateApiResponse,
      HyundaiPromiseAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/hyundai_promise/authenticate`,
        params: queryArg.params,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: [{type: 'PublishingPlatformHyundaiPromise'}, {type: 'PlatformList'}],
    }),
    getOtomotoPlatform: build.query<GetOtomotoPlatformApiResponse, GetOtomotoPlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/otomoto`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformOtomoto'}],
    }),
    otomotoAuthenticate: build.mutation<OtomotoAuthenticateApiResponse, OtomotoAuthenticateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/otomoto/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformOtomoto'}, {type: 'PlatformList'}],
    }),
    getFordUzywanePlatform: build.query<
      GetFordUzywanePlatformApiResponse,
      GetFordUzywanePlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/ford_uzywane`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformFordUzywane'}],
    }),
    fordUzywaneAuthenticate: build.mutation<
      FordUzywaneAuthenticateApiResponse,
      FordUzywaneAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/ford_uzywane/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformFordUzywane'}, {type: 'PlatformList'}],
    }),
    getAutoplacPlPlatform: build.query<
      GetAutoplacPlPlatformApiResponse,
      GetAutoplacPlPlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/autoplac_pl`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformAutoplacPl'}],
    }),
    AutoplacPlAuthenticate: build.mutation<
      AutoplacPlAuthenticateApiResponse,
      AutoplacPlAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/autoplac_pl/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformAutoplacPl'}, {type: 'PlatformList'}],
    }),
    getAutoplacPlExtraLink: build.query<{link: string}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform/autoplac_pl/link',
        params: {branchId},
        method: 'GET',
      }),
    }),
    getVehisPlatform: build.query<GetVehisPlatformApiResponse, GetVehisPlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/vehis`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformVehis'}],
    }),
    VehisAuthenticate: build.mutation<VehisAuthenticateApiResponse, VehisAuthenticateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/vehis/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformVehis'}, {type: 'PlatformList'}],
    }),
    getAdvertisingStatistics: build.query<GetAdvertisingStatisticsApiResponse, void>({
      query: () => ({
        url: `/dms/v1/sales/publishing/statistics`,
      }),
    }),
    getVehisExtraLink: build.query<{link: string}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform/vehis/link',
        params: {branchId},
        method: 'GET',
      }),
    }),
    getMamGoPlatform: build.query<GetMamGoPlatformApiResponse, GetMamGoPlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/mamgo`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformMamGo'}],
    }),
    MamGoAuthenticate: build.mutation<MamGoAuthenticateApiResponse, MamGoAuthenticateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/mamgo/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformMamGo'}, {type: 'PlatformList'}],
    }),
    getMamGoExtraLink: build.query<{link: string}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform/mamgo/link',
        params: {branchId},
        method: 'GET',
      }),
    }),
    getFordGeronimoPlatform: build.query<
      GetFordGeronimoPlatformApiResponse,
      GetFordGeronimoPlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/ford_geronimo`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformFordGeronimo'}],
    }),
    FordGeronimoAuthenticate: build.mutation<
      FordGeronimoAuthenticateApiResponse,
      FordGeronimoAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/ford_geronimo/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformFordGeronimo'}, {type: 'PlatformList'}],
    }),
    getFordGeronimoExtraLink: build.query<{link: string}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform/ford_geronimo/link',
        params: {branchId},
        method: 'GET',
      }),
    }),
    getMyWebExtraLink: build.query<{link: string}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform/myweb/link',
        params: {branchId},
        method: 'GET',
      }),
    }),
    myWebPublishingOnTenant: build.mutation<void, {branchId: string; isEnabled: boolean}>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/myweb/publish-on-tenant/${
          queryArg.isEnabled ? 'enable' : 'disable'
        }`,
        params: {branchId: queryArg.branchId},
        method: 'PUT',
      }),
      invalidatesTags: [{type: 'PublishingPlatformMyWeb'}],
    }),
    getFordUzywaneExtraLink: build.query<{link: string}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform/ford_uzywane/link',
        params: {branchId},
        method: 'GET',
      }),
    }),
    getTipCarsDefaultSettings: build.query<{vinHidden: boolean}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v2/sales/publishing/platform/tipcars/settings/defaults',
        params: {branchId},
        method: 'GET',
      }),
      providesTags: [{type: 'TipCarsDefaultSettings'}],
    }),
    setTipCarsDefaultSettings: build.mutation<void, {vinHidden: boolean; branchId?: string}>({
      query: ({vinHidden, branchId}) => ({
        url: `/dms/v2/sales/publishing/platform/tipcars/settings/defaults`,
        params: {branchId},
        body: {vinHidden},
        method: 'PUT',
      }),
      invalidatesTags: [{type: 'TipCarsDefaultSettings'}],
    }),
    getSautoPlatform: build.query<GetSautoPlatformApiResponse, GetSautoPlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/sauto`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformSauto'}],
    }),
    SautoAuthenticate: build.mutation<SautoAuthenticateApiResponse, SautoAuthenticateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/sauto/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformSauto'}, {type: 'PlatformList'}],
    }),
    getTipcarsPlatform: build.query<GetTipcarsPlatformApiResponse, GetTipcarsPlatformApiArg>({
      query: (queryArg) => ({
        url: '/dms/v2/sales/publishing/platform/tipcars',
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformTipcars'}],
    }),
    tipcarsAuthenticate: build.mutation<TipcarsAuthenticateApiResponse, TipcarsAuthenticateApiArg>({
      query: (queryArg) => ({
        url: '/dms/v2/sales/publishing/platform/tipcars/authenticate',
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformTipcars'}, {type: 'PlatformList'}],
    }),
    getTipCarsClientData: build.query<GetTipcarsClientDataApiResponse, GetTipcarsClientDataApiArg>({
      query: (queryArg) => ({
        url: '/dms/v2/sales/publishing/platform/tipcars/client-data',
        params: queryArg.params,
      }),
    }),
    updateTipCarsClientData: build.mutation<
      TipcarsUpdateClientDataApiResponse,
      TipcarsUpdateClientDataApiArg
    >({
      query: (queryArg) => ({
        url: '/dms/v2/sales/publishing/platform/tipcars/client-data',
        method: 'PUT',
        params: queryArg.params,
        body: queryArg.body,
      }),
    }),
    getMobileDePlatform: build.query<GetMobileDePlatformApiResponse, GetMobileDePlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/mobile.de`,
        params: queryArg.params,
      }),
      transformResponse: (
        response: Omit<GetMobileDePlatformApiResponse, 'credentials'> & {
          credentials: MobileDeOriginalCredentials;
        }
      ) => ({
        ...response,
        credentials: {
          username: response.credentials?.sellerUsername ?? '',
          password: response.credentials?.sellerPassword ?? '',
          sellerId: response.credentials?.sellerId ?? '',
        },
      }),
      providesTags: () => [{type: 'PublishingPlatformMobileDe'}],
    }),
    mobileDeAuthenticate: build.mutation<
      MobileDeAuthenticateApiResponse,
      MobileDeAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/mobile.de/authenticate`,
        params: queryArg.params,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: [{type: 'PublishingPlatformMobileDe'}, {type: 'PlatformList'}],
    }),
    getFacebookPlatform: build.query<GetFacebookPlatformApiResponse, GetFacebookPlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/facebook`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformFacebook'}],
    }),
    facebookAuthenticate: build.mutation<
      FacebookAuthenticateApiResponse,
      FacebookAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/facebook/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformFacebook'}, {type: 'PlatformList'}],
    }),
    getFacebookExtraLink: build.query<{link: string}, {branchId?: string}>({
      query: ({branchId}) => ({
        url: '/dms/v1/sales/publishing/platform/facebook/link',
        params: {branchId},
        method: 'GET',
      }),
    }),
    getAutocarisPlatform: build.query<GetAutocarisPlatformApiResponse, GetAutocarisPlatformApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/autocaris`,
        params: queryArg.params,
      }),
      providesTags: [{type: 'PublishingPlatformAutocaris'}],
    }),
    autocarisAuthenticate: build.mutation<
      AutocarisAuthenticateApiResponse,
      AutocarisAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/platform/autocaris/authenticate`,
        method: 'PUT',
        body: queryArg.body,
        params: queryArg.params,
      }),
      invalidatesTags: [{type: 'PublishingPlatformAutocaris'}, {type: 'PlatformList'}],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPlatformsQuery,
  useUpdatePlatformMutation,
  useGetOlxPlatformQuery,
  useGetSkodaPlusPlatformQuery,
  useSkodaPlusAuthenticateMutation,
  useGetDasWeltAutoPlatformQuery,
  useDasWeltAutoAuthenticateMutation,
  useGetMyWebPlatformQuery,
  useMyWebAuthenticateMutation,
  useGetMyWebExtraLinkQuery,
  useMyWebPublishingOnTenantMutation,
  useGetMercedesNaSkladePlatformQuery,
  useMercedesNaSkladeAuthenticateMutation,
  useGetHyundaiPromisePlatformQuery,
  useHyundaiPromiseAuthenticateMutation,
  useGetOtomotoPlatformQuery,
  useOtomotoAuthenticateMutation,
  useGetTipCarsDefaultSettingsQuery,
  useSetTipCarsDefaultSettingsMutation,
  useGetFordUzywanePlatformQuery,
  useFordUzywaneAuthenticateMutation,
  useGetFordUzywaneExtraLinkQuery,
  useGetAutoplacPlPlatformQuery,
  useAutoplacPlAuthenticateMutation,
  useGetAutoplacPlExtraLinkQuery,
  useGetVehisPlatformQuery,
  useVehisAuthenticateMutation,
  useGetVehisExtraLinkQuery,
  useGetMamGoPlatformQuery,
  useMamGoAuthenticateMutation,
  useGetMamGoExtraLinkQuery,
  useGetFordGeronimoPlatformQuery,
  useFordGeronimoAuthenticateMutation,
  useGetFordGeronimoExtraLinkQuery,
  useGetSautoPlatformQuery,
  useSautoAuthenticateMutation,
  useGetTipcarsPlatformQuery,
  useTipcarsAuthenticateMutation,
  useGetTipCarsClientDataQuery,
  useUpdateTipCarsClientDataMutation,
  useGetMobileDePlatformQuery,
  useMobileDeAuthenticateMutation,
  useGetFacebookPlatformQuery,
  useFacebookAuthenticateMutation,
  useGetFacebookExtraLinkQuery,
  useGetAutocarisPlatformQuery,
  useGetAdvertisingStatisticsQuery,
  useAutocarisAuthenticateMutation,
} = salesPublishingApi;
