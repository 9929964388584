import {useDraggable} from '@dnd-kit/core';
import {CSS} from '@dnd-kit/utilities';
import {isPast, subDays} from 'date-fns';
import {getSize, HStack, Icon, Text} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {
  ALL_DAY_EVENT_HEIGHT_IN_PIXELS,
  CSS_ALL_DAY_EVENT_HEIGHT,
} from '../constants/allDayEventHeight';
import {EVENT_FILTER_WHEN_IS_PAST} from '../constants/eventFilterWhenIsPast';
import {BigCalendarEvent} from '../types/BigCalendarEvent';
import {getThemeToken} from '../utils/getThemeToken';
import {repeatingGradient} from '../utils/repeatingGradient';

interface AllDayEventItemProps extends RequiredTestIdProps {
  event: BigCalendarEvent;
  position: number;
  onClick?: (event: BigCalendarEvent) => void;
}

export function AllDayEventItem(props: AllDayEventItemProps) {
  const theme = useTheme();

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: props.event.id,
    disabled: props.event.isReadOnly,
  });

  const eventColor = props.event.color || 'general.black';
  const eventColorHEX = getThemeToken(theme, `colors.${eventColor}`);

  const adjustedStart = subDays(props.event.start, -1);

  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      onClick={() => props.onClick?.(props.event)}
      css={css`
        position: absolute;
        height: ${CSS_ALL_DAY_EVENT_HEIGHT};
        top: ${props.position * ALL_DAY_EVENT_HEIGHT_IN_PIXELS}px;
        left: 0;
        right: 13px;
        padding: 0 ${getSize(1)};
        border-top-left-radius: ${theme.radii.small};
        border-bottom-left-radius: ${theme.radii.small};
        background-color: ${props.event.alternative ? 'transparent' : eventColorHEX};
        background-image: ${props.event.alternative
          ? `${repeatingGradient(eventColorHEX)}`
          : 'none'};
        border: ${props.event.alternative ? `2px solid ${eventColorHEX}` : 'none'};
        cursor: pointer;
        pointer-events: auto;
        transform: ${CSS.Transform.toString(transform)};
        transform-origin: 0 0;
        will-change: transform;
        filter: ${isPast(adjustedStart) ? EVENT_FILTER_WHEN_IS_PAST : 'none'};

        &::after {
          content: '';
          position: absolute;
          right: ${props.event.alternative ? -14 : -12}px;
          top: ${props.event.alternative ? -2 : 0}px;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-left: 12px solid ${eventColorHEX};
        }
      `}
      data-testid={suffixTestId('allDayEventItem', props)}
    >
      <HStack height="100%" spacing={1} align="center" wrap="nowrap">
        {props.event.icon && (
          <Icon
            data-testid={suffixTestId('allDayEventItem-icon', props)}
            value={props.event.icon}
            size={3}
            color={props.event.alternative ? 'general.black' : 'general.white'}
          />
        )}
        <Text
          data-testid={suffixTestId('allDayEventItem-title', props)}
          size="xSmall"
          color={props.event.alternative ? 'primary' : 'white'}
          alternative
          noWrap
        >
          {props.event.title}
        </Text>
      </HStack>
    </div>
  );
}
