import {isBefore} from 'date-fns';
import {openDeleteDialog, TableRowAction} from 'platform/components';

import {useCallback} from 'react';

import {
  useAgreeConsentMutation,
  useDeleteConsentMutation,
  useRejectConsentMutation,
  useWithdrawConsentMutation,
} from '@dms/api/gdpr';
import {ConsentResponseBody, ConsentStatus} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {buildArray, parseDate} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';

export const useGetConsentListActions = (customerId: string) => {
  const [deleteConsent] = useDeleteConsentMutation();
  const [withdrawConsent] = useWithdrawConsentMutation();
  const [rejectConsent] = useRejectConsentMutation();
  const [agreeConsent] = useAgreeConsentMutation();

  const GetConsentListActions = useCallback(
    (consent: ConsentResponseBody) => {
      const options = {consentId: consent.id, customerId};

      const isConsentAgreed = consent.status === ConsentStatus.AGREED;
      const isConsentWithdrawnOrRejected =
        consent.status === ConsentStatus.WITHDRAWN || consent.status === ConsentStatus.REJECTED;
      const isConsentValid =
        !consent.validUntil || isBefore(new Date(), parseDate(consent.validUntil));

      return {
        secondary: buildArray<TableRowAction>()
          .when(isConsentAgreed && isConsentValid, {
            title: i18n.t('entity.gdpr.actions.markAsWithdrawn'),
            icon: 'navigation/refresh',
            onClick: () => withdrawConsent(options).unwrap().catch(handleApiError),
          })
          .when(isConsentAgreed, {
            title: i18n.t('entity.gdpr.actions.markAsRejected'),
            icon: 'navigation/close',
            onClick: () => rejectConsent(options).unwrap().catch(handleApiError),
          })
          .when(isConsentWithdrawnOrRejected, {
            title: i18n.t('entity.gdpr.actions.markAsAgreed'),
            icon: 'action/check_circle',
            onClick: () => agreeConsent(options).unwrap().catch(handleApiError),
          })
          .add({
            title: i18n.t('general.actions.download'),
            icon: 'action/delete',
            onClick: () => window.open(consent.fileUri),
          })
          .add({
            title: i18n.t('general.actions.delete'),
            icon: 'file/download',
            severity: 'danger',
            onClick: () =>
              openDeleteDialog({
                onConfirm: () => deleteConsent(options).unwrap().catch(handleApiError),
              }),
          }),
      };
    },
    [agreeConsent, customerId, deleteConsent, rejectConsent, withdrawConsent]
  );

  return [GetConsentListActions] as const;
};
