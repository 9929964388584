import {Box, HStack, Show} from 'platform/foundation';
import styled, {css} from 'styled-components';

import {useEffect, useRef} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {useAnimatedPopper} from '../AnimatedPopper/hooks/useAnimatedPopper';
import {IconButton} from '../IconButton/IconButton';
import {TextInput} from '../TextInput/TextInput';
import {Hour} from './types/Hour';
import {Minute} from './types/Minute';
import {TimeType} from './types/TimeType';
import {getDropdownTimes} from './utils/getDropdownTimes';
import {getHoursAndMinutesFromTime} from './utils/getHoursAndMinutesFromTime';
import {getInputValue} from './utils/getInputValue';
import {isHourDisabled} from './utils/isHourDisabled';
import {isMinuteDisabled} from './utils/isMinuteDisabled';

export interface TimePickerProps extends FormControlProps<TimeType | null>, TestIdProps {
  /**
   * @about Minute interval for the dropdown. Defaults to 1.
   */
  step?: 1 | 5 | 10 | 15 | 30;
  /**
   * @about Minimum time that can be selected. Defaults to 00:00.
   */
  minTime?: TimeType;
  /**
   * @about Maximum time that can be selected. Defaults to 23:59.
   */
  maxTime?: TimeType;
  isRecommended?: boolean;
  isClearable?: boolean;
}

export function TimePicker(props: TimePickerProps) {
  const {popperProps, Popper, openPopper, closePopper, referenceRef, isOpen} = useAnimatedPopper();

  const isInvalid = props.isInvalid || !!props.errorMessage;

  const hourRef = useRef<HTMLDivElement>(null);
  const minuteRef = useRef<HTMLDivElement>(null);

  const {hours, minutes} = getDropdownTimes(props.step);

  const {hour: selectedHour, minute: selectedMinute} = getHoursAndMinutesFromTime(props.value) || {
    hour: null,
    minute: null,
  };

  const {hour: minHour, minute: minMinute} = getHoursAndMinutesFromTime(props.minTime) || {
    hour: '00',
    minute: '00',
  };
  const {hour: maxHour, minute: maxMinute} = getHoursAndMinutesFromTime(props.maxTime) || {
    hour: '23',
    minute: '59',
  };

  useEffect(() => {
    if (isOpen) {
      hourRef.current?.scrollIntoView({block: 'center'});
      minuteRef.current?.scrollIntoView({block: 'center'});
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      (selectedHour === minHour && selectedMinute === minMinute) ||
      (selectedHour === maxHour && selectedMinute === maxMinute)
    ) {
      minuteRef.current?.scrollIntoView({block: 'center'});
    }
  }, [maxHour, maxMinute, minHour, minMinute, selectedHour, selectedMinute]);

  const onHourSelect = (hour: Hour) => {
    let minute = selectedMinute || '00';

    if (hour === minHour && minute < minMinute) {
      minute = minMinute;
    } else if (hour === maxHour && minute > maxMinute) {
      minute = maxMinute;
    }

    const time: TimeType = `${hour}:${minute}`;
    props.onChange?.(time);
  };

  const onMinuteSelect = (minute: Minute) => {
    if (!selectedHour) {
      return;
    }

    const time: TimeType = `${selectedHour}:${minute}`;
    props.onChange?.(time);
    closePopper();
  };

  return (
    <>
      <div
        ref={referenceRef}
        css={css`
          position: relative;
        `}
      >
        <TextInput
          leftIcon="devices/access_time"
          onClick={props.isDisabled ? undefined : openPopper}
          isRequired={props.isRequired}
          isDisabled={props.isDisabled}
          value={getInputValue(selectedHour, selectedMinute)}
          placeholder=""
          label={props.label}
          tooltip={props.tooltip}
          isInvalid={isInvalid}
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          isRecommended={props.isRecommended}
          data-testid={suffixTestId('input', props)}
        />
        <Show when={Boolean(props.value) && props.isClearable}>
          <Box position="absolute" right={2} top={6}>
            <IconButton
              size="small"
              icon="navigation/close"
              onClick={() => props.onChange?.(null)}
              data-testid={suffixTestId('clearButton', props)}
            />
          </Box>
        </Show>
      </div>
      <Popper {...popperProps}>
        <Box paddingTop={1}>
          <Box
            borderRadius="medium"
            boxShadow="elevation_2"
            border="1px solid"
            borderColor="palettes.neutral.40.100"
            backgroundColor="general.white"
            data-testid={suffixTestId('dropdown', props)}
          >
            <HStack>
              <List data-testid={suffixTestId('hourList', props)}>
                {hours.map((hour) => {
                  const isDisabled = isHourDisabled(hour, minHour, maxHour);
                  return (
                    <Selection
                      $isDisabled={isDisabled}
                      $isSelected={hour === selectedHour}
                      ref={hour === selectedHour || hour === minHour ? hourRef : null}
                      onClick={() => !isDisabled && onHourSelect(hour)}
                      key={hour}
                    >
                      {hour}
                    </Selection>
                  );
                })}
              </List>
              <List data-testid={suffixTestId('minuteList', props)}>
                {minutes.map((minute) => {
                  const isDisabled = isMinuteDisabled(
                    selectedHour,
                    minute,
                    minHour,
                    minMinute,
                    maxHour,
                    maxMinute
                  );
                  return (
                    <Selection
                      $isDisabled={isDisabled}
                      $isSelected={minute === selectedMinute}
                      ref={minute === selectedMinute ? minuteRef : null}
                      onClick={() => !isDisabled && onMinuteSelect(minute)}
                      key={minute}
                    >
                      {minute}
                    </Selection>
                  );
                })}
              </List>
            </HStack>
          </Box>
        </Box>
      </Popper>
    </>
  );
}

interface SelectionProps {
  $isSelected?: boolean;
  $isDisabled?: boolean;
}

const Selection = styled.div<SelectionProps>`
  text-align: center;
  white-space: nowrap;
  width: ${({theme}) => theme.getSize(12)};
  border-radius: ${({theme}) => theme.radii.small};
  padding-top: ${({theme}) => theme.getSize(2)};
  padding-bottom: ${({theme}) => theme.getSize(2)};
  cursor: ${({$isDisabled}) => ($isDisabled ? 'not-allowed' : 'pointer')};
  background-color: ${({$isSelected, theme}) =>
    $isSelected ? theme.colors.palettes.blue[10][100] : 'initial'};
  color: ${({theme, $isSelected, $isDisabled}) => {
    if ($isDisabled) {
      return theme.colors.palettes.neutral[900][40];
    }
    if ($isSelected) {
      return theme.colors.palettes.blue[80][100];
    }
    return theme.colors.text.primary;
  }};
  &:hover {
    background-color: ${({theme, $isDisabled}) =>
      !$isDisabled && theme.colors.palettes.blue[10][100]};
    color: ${({theme, $isDisabled}) => !$isDisabled && theme.colors.palettes.blue[80][100]};
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  scrollbar-gutter: stable;
  overflow-y: auto;
  height: ${({theme}) => theme.getSize(60)};
  &:hover {
    overflow-y: auto;
  }
  padding: ${({theme}) => theme.getSize(4)};
  width: 100%;
`;
