import {captureMessage} from '@sentry/browser';

import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {cachedApi} from '@dms/api/cached';
import {publicApi, omneticApi, metadaApi, fileStorageApi} from '@dms/api/core';
import {branchSlice} from '@dms/api/features';
import {teasReducers} from '@dms/teas';

import {duplicateKeysCheckTransform} from '../utils/duplicateKeysCheckTransform';
import {getLocalStorageValuesSize} from '../utils/getLocalStorageValuesSize';
import {languageCheckTransform} from '../utils/languageCheckTransform';

const handleSetStorageItemError = (error: Error) => {
  const localStorageValueSizes = getLocalStorageValuesSize();
  // revert to captureException when IndexedDB is used - https://carvago.atlassian.net/browse/T20-68638
  captureMessage(error + '\n' + localStorageValueSizes);
};

export const reducers = combineReducers({
  ...teasReducers,
  // RTK Query
  [publicApi.reducerPath]: publicApi.reducer,
  [omneticApi.reducerPath]: omneticApi.reducer,
  [metadaApi.reducerPath]: metadaApi.reducer,
  [fileStorageApi.reducerPath]: fileStorageApi.reducer,
  [cachedApi.reducerPath]: persistReducer(
    {
      key: cachedApi.reducerPath,
      writeFailHandler: handleSetStorageItemError,
      storage,
      transforms: [languageCheckTransform, duplicateKeysCheckTransform],
    },
    cachedApi.reducer
  ),
  [branchSlice.name]: branchSlice.reducer,
});

export type RootState = ReturnType<typeof reducers>;
