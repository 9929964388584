import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {restrictToFirstScrollableAncestor, restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {showNotification} from 'platform/components';
import styled from 'styled-components';

import {useState} from 'react';

import {gte, isNil, update} from 'ramda';

import {MenuItemProps} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {SortableSidebarItem} from './SortableSidebarItem';

interface MenuItem {
  id: string;
  isPinned: boolean;
}

interface SortableMenuItemsProps extends TestIdProps {
  menuItems: MenuItem[];
  menuItemsById: Record<string, MenuItemProps>;
  onChange: (pinnedMenuItems: MenuItem[]) => void;
  areActionsDisabled?: boolean;
}

export function SortableSidebarItems(props: SortableMenuItemsProps) {
  const [activeMenuItem, setActiveMenuItem] = useState<MenuItemProps | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    const eventId = event.active.id.toString();
    const menuItem = props.menuItemsById[eventId];

    if (isNil(menuItem)) {
      showNotification.error(i18n.t('general.labels.error'));
      return;
    }

    setActiveMenuItem(menuItem);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;

    if (over) {
      const overIndex = props.menuItems.findIndex((item) => item.id === over.id);
      const activeIndex = props.menuItems.findIndex((item) => item.id === active.id);

      if (activeIndex !== overIndex && gte(activeIndex, 0) && gte(overIndex, 0)) {
        props.onChange(arrayMove(props.menuItems, activeIndex, overIndex));
      }
    }

    setActiveMenuItem(null);
  };

  return (
    <StyledSortableMenuItems>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          disabled={props.areActionsDisabled}
          items={props.menuItems}
          strategy={verticalListSortingStrategy}
        >
          {props.menuItems.map((menuItem, index) => {
            const item = props.menuItemsById[menuItem.id];

            if (!item) {
              return null;
            }

            return (
              <SortableSidebarItem
                key={item.id}
                id={item.id}
                index={index}
                items={props.menuItems}
                isPinned={menuItem.isPinned}
                title={i18n.t(item.layoutProps.title)}
                icon={item.layoutProps.iconV2 ?? item.layoutProps.icon}
                isDisabled={props.areActionsDisabled}
                onPinClick={() => {
                  props.onChange(
                    update(index, {...menuItem, isPinned: !menuItem.isPinned}, props.menuItems)
                  );
                }}
                data-testid={suffixTestId('sortableSidebarItem', props)}
              />
            );
          })}
        </SortableContext>
        <DragOverlay>
          {activeMenuItem && (
            <SortableSidebarItem
              id={activeMenuItem.id}
              index={-1}
              isDragOverlay
              items={props.menuItems}
              title={i18n.t(activeMenuItem.layoutProps.title)}
              icon={activeMenuItem.layoutProps.iconV2 ?? activeMenuItem.layoutProps.icon}
              isPinned={props.menuItems.some(
                (item) => item.id === activeMenuItem.id && item.isPinned
              )}
              data-testid={suffixTestId('sortableSidebarItemOverlay', props)}
            />
          )}
        </DragOverlay>
      </DndContext>
    </StyledSortableMenuItems>
  );
}

const StyledSortableMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.getSize(1)};
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 50vh; // workaround for restricting the height of the sortable items, so that item can not be dragged outside the scrollable area
`;
