import {Action, openDialog} from 'platform/components';

import {TemplateResponseBodyV2} from '@dms/api/document';
import i18n from '@dms/i18n';
import {downloadFile} from '@dms/shared';

import {UploadDocumentTemplateForm} from '../components/UploadDocumentTemplateForm';

export const getDocumentKindActions = (templates: TemplateResponseBodyV2[]): Action[] => {
  const primaryTemplate = templates.find((template) => template.primary);

  if (!primaryTemplate) {
    return [];
  }

  return [
    {
      type: 'button',
      leftIcon: 'file/download',
      title: i18n.t('entity.document.actions.downloadPrimary'),
      variant: 'link',
      onClick: () => downloadFile(primaryTemplate.url, {fileName: primaryTemplate.fileName}),
    },
    {
      type: 'button',
      leftIcon: 'content/add_circle',
      title: i18n.t('general.actions.upload'),
      variant: 'link',
      onClick: () =>
        openDialog(
          <UploadDocumentTemplateForm documentKindCode={primaryTemplate.documentKindCode} />,
          {title: i18n.t('entity.documents.labels.uploadNewTemplateDialogTitle')}
        ),
    },
  ];
};
