import {Card, DataStatus, Rank, Trend} from 'platform/components';
import {Grid, Heading, HStack, VStack} from 'platform/foundation';
import {useFormatCurrency, useFormatNumber, useFormatPercentage} from 'platform/locale';

import {useSelector} from 'react-redux';

import {isNotNil, path} from 'ramda';

import {PriceMapStatistics, SourcingVehicleDetailResponseBody} from '@dms/api/sourcing';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {useCountry} from '@dms/shared';
import {selectUserSettings} from '@dms/teas';

type ComparisonFieldProps = {
  isLoading: boolean;
  vehicle: SourcingVehicleDetailResponseBody;
  statistics: Record<string, PriceMapStatistics>;
};

export function ComparisonFields({vehicle, isLoading, statistics}: ComparisonFieldProps) {
  const formatNumber = useFormatNumber();
  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();

  const {summary} = vehicle;
  const vehicleCurrencyCode = vehicle.sourcingVehicle?.price?.currency?.key;
  const vehicleCurrencyTranslation = vehicle.sourcingVehicle?.price?.currency?.translation;

  const {getCountryName} = useCountry();
  const {currency, country: sellerCountry} = useSelector(selectUserSettings);
  const translatedCountry = getCountryName(sellerCountry);

  const averagePrice = statistics?.current?.avgPrice ?? 0;
  const averageMileage = statistics?.current?.avgMileage ?? 0;
  const soldIn90Days = statistics?.sold?.count ?? 0;

  const price = path<number>(['price', 'withVat'], vehicle.sourcingVehicle);

  return (
    <Card>
      <DataStatus isLoading={isLoading}>
        <Grid columns={[2, 4]} spacing={2}>
          <VStack spacing={1}>
            <Heading size={5} color="secondary" isSingleLine>
              {`${i18n.t('entity.vehicle.labels.originalPrice')} ${vehicleCurrencyTranslation}`}
            </Heading>
            <Heading size={2} isSingleLine>
              {isNotNil(price) && isNotNil(vehicleCurrencyCode)
                ? formatCurrency(price, vehicleCurrencyCode, 0)
                : ''}
            </Heading>
            <Trend
              isPositive={(price ?? 0) - averagePrice < 0}
              isNegative={(price ?? 0) - averagePrice > 0}
              isSmall
              label={
                (isNotNil(vehicleCurrencyCode) &&
                  formatCurrency(Math.abs((price ?? 0) - averagePrice), vehicleCurrencyCode, 0)) ||
                ''
              }
              data-testid={testIds.sourcing.vehicleDetail('comparisonFields-priceTrend')}
            />
            <Heading size={5} isSingleLine>
              {isNotNil(price) && isNotNil(currency)
                ? `Ø ${translatedCountry}: ${formatCurrency(averagePrice, currency, 0)}`
                : ''}
            </Heading>
          </VStack>

          <VStack spacing={1}>
            <Heading size={5} color="secondary" isSingleLine>
              {i18n.t('entity.vehicle.labels.mileage')}
            </Heading>
            <Heading size={2} isSingleLine>
              {isNotNil(vehicle.sourcingVehicle?.mileage)
                ? `${formatNumber(vehicle.sourcingVehicle?.mileage, 0)} ${i18n.t('general.metric.km')}`
                : ''}
            </Heading>
            <Trend
              isPositive={(vehicle.sourcingVehicle?.mileage ?? 0) - averageMileage < 0}
              isNegative={(vehicle.sourcingVehicle?.mileage ?? 0) - averageMileage > 0}
              isSmall
              label={`${formatNumber(
                Math.abs((vehicle.sourcingVehicle?.mileage ?? 0) - averageMileage),
                0
              )} ${i18n.t('general.metric.km')}`}
              data-testid={testIds.sourcing.vehicleDetail('comparisonFields-mileageTrend')}
            />
            <Heading size={5} isSingleLine>
              {`Ø ${translatedCountry}: ${formatNumber(averageMileage, 0)} ${i18n.t('general.metric.km')}`}
            </Heading>
          </VStack>

          <VStack spacing={1}>
            <Heading size={5} color="secondary" isSingleLine>
              {i18n.t('general.labels.soldIn90Days')}
            </Heading>
            <Heading size={2} isSingleLine>
              {isNotNil(soldIn90Days)
                ? `${formatNumber(soldIn90Days, 0)} ${i18n.t('general.metric.pcs')}`
                : ''}
            </Heading>
          </VStack>

          <VStack spacing={1}>
            <Heading size={5} color="secondary" isSingleLine>
              {i18n.t('entity.vehicle.labels.rankEquipment')}
            </Heading>
            <HStack align="center" spacing={1}>
              <Heading size={2} isSingleLine>
                {isNotNil(summary?.rank) ? `> ${formatPercentage(summary.rank, 'down')}` : ''}
              </Heading>
              <Rank
                percentage={isNotNil(summary?.rank) ? summary.rank * 100 : 0}
                data-testid={testIds.sourcing.vehicleDetail('comparisonFields-rankEquipment')}
              />
            </HStack>
            <Heading size={5} isSingleLine>
              {i18n.t('entity.vehicle.labels.comparedWithMarket')}
            </Heading>
          </VStack>
        </Grid>
      </DataStatus>
    </Card>
  );
}
