import {Icon} from 'platform/foundation';
import styled from 'styled-components';

import {SIDEBAR_MENU_ICON_SIZE} from '../constants/sidebarMenuIconSize';

interface CollapseToggleProps {
  isOpen: boolean;
}

export function CollapseToggle(props: CollapseToggleProps) {
  return (
    <StyledIconWrapper isOpen={props.isOpen}>
      <Icon color="text.white" size={SIDEBAR_MENU_ICON_SIZE} value="navigation/expand_more" />
    </StyledIconWrapper>
  );
}

const StyledIconWrapper = styled.div<{isOpen?: boolean}>`
  transform: rotate(${(props) => (props.isOpen ? '180deg' : '0deg')});
`;
