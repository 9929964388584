import {Action, Card, closeCurrentDialog, DataStatus, openDialog} from 'platform/components';
import {Grid, Hide, Show, Space} from 'platform/foundation';

import {FC} from 'react';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useGetBusinessCaseQuery,
  useGetSaleBusinessCasePricesOverviewQuery,
} from '@dms/api/businessCase';
import {
  OfferPriceResponseBody,
  OfferResponseBody,
  OfferSaleVehicleResponseBody,
} from '@dms/api/commission';
import {useGetSaleVehicleQuery} from '@dms/api/saleVehicle';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {EditVehiclePriceForm, usePricePermissions, useSaleVehicleActions} from '@dms/shared';
import {PriceBox} from '@dms/teas';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

export type BusinessCaseSaleVehiclePriceSummaryType = {
  saleVehicle: OfferSaleVehicleResponseBody;
  offerTotalPrice?: OfferPriceResponseBody | null;
  isBrokeredSale: string | null;
  businessCaseId: string;
  offer?: OfferResponseBody;
} & TestIdProps;

export const BusinessCaseSaleVehiclePriceSummary: FC<BusinessCaseSaleVehiclePriceSummaryType> = ({
  isBrokeredSale,
  ...props
}) => {
  const {data: saleVehicleData} = useGetSaleVehicleQuery(
    {vehicleId: props.saleVehicle?.vehicleId ?? ''},
    {skip: isNilOrEmpty(props.saleVehicle?.vehicleId)}
  );
  const {
    data: saleVehiclePrices,
    isLoading,
    isError,
  } = useGetSaleBusinessCasePricesOverviewQuery({businessCaseId: props.businessCaseId});

  const {
    data: businessCaseData,
    isLoading: isBusinessCaseLoading,
    isError: isBusinessCaseError,
  } = useGetBusinessCaseQuery({businessCaseId: props.businessCaseId});

  const {
    canReadVehicleSellingPrice,
    canReadBusinessCaseSaleTotalPrice,
    canReadVehiclePurchasePrice,
    canEditVehicleSalePrice: canEditSalePrice,
    canReadVehicleCosts: canReadCosts,
    canReadVehicleEarnings,
    canReadBusinessCaseBrokerageFees: canViewBusinessCaseBrokerageFees,
  } = usePricePermissions({
    businessCaseRecordId: props.businessCaseId,
    vehicleRecordId: props.saleVehicle?.vehicleId,
  });

  const {isSaleActionEnabled} = useSaleVehicleActions(props.saleVehicle?.vehicleId ?? '');
  const isBrokerage = saleVehicleData?.type === 'BROKERAGE';
  const isOpenBC =
    businessCaseData?.businessCaseState === 'CONCEPT' ||
    businessCaseData?.businessCaseState === 'OFFER' ||
    businessCaseData?.businessCaseState === 'CONTRACT';

  const totalMarginPercentage = Number(props.saleVehicle?.margin);
  const isTotalVatDeductible =
    props.saleVehicle?.priceTotalAfterDiscount?.withVat?.amount !==
      props.saleVehicle?.priceTotalAfterDiscount?.withoutVat?.amount ||
    (props.saleVehicle?.upsell?.length ?? 0) > 0;

  const handleEditPriceClick = () => {
    openDialog(
      <EditVehiclePriceForm
        saleVehicle={saleVehicleData}
        onClose={closeCurrentDialog}
        isPurchasePriceHidden
        data-testid={props['data-testid']}
        businessCaseId={props.businessCaseId}
      />,
      {
        title: i18n.t('entity.vehicle.actions.editPrice'),
        'data-testid': testIds.businessCase.selling('editPriceDialog'),
      }
    );
  };

  const actions = buildArray<Action>().when(
    isSaleActionEnabled('SALE_VEHICLE_UPDATE') && canEditSalePrice && isOpenBC,
    {
      title: i18n.t('entity.vehicle.actions.editPrice'),
      type: 'button',
      leftIcon: 'image/edit',
      variant: 'link',
      onClick: handleEditPriceClick,
      'data-testid': testIds.businessCase.selling('openEditPriceDialog-button'),
    }
  );

  return (
    <Card
      variant="inlineGrey"
      actions={actions}
      title={i18n.t`page.businessCase.labels.salVehiclePriceSummary`}
      isExpandable
    >
      <Show whenFeatureDisabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
        <Grid columns={4}>
          <Hide when={isBrokeredSale}>
            <PriceBox
              data-testid={suffixTestId(`buyingPrice`, props)}
              align="left"
              price={saleVehicleData?.purchasePrice?.withVat}
              priceSub={saleVehicleData?.purchasePrice?.withoutVat}
              isVatDeductible={saleVehicleData?.isVatDeductible}
              title={i18n.t`entity.businessCase.labels.vehicleBuyingPrice`}
            />
          </Hide>
          <PriceBox
            data-testid={suffixTestId(`sellingPrice`, props)}
            align="left"
            price={props.saleVehicle?.sellingPrice?.withVat}
            priceSub={props.saleVehicle?.sellingPrice?.withoutVat}
            isVatDeductible={props.saleVehicle?.sellingPrice?.vatCode !== 'Z'}
            title={i18n.t`entity.businessCase.labels.vehicleSellingPrice`}
          />
          <PriceBox
            data-testid={suffixTestId(`totalPrice`, props)}
            align="left"
            price={props.offerTotalPrice?.withVat}
            priceSub={props.offerTotalPrice?.withoutVat}
            isVatDeductible={isTotalVatDeductible}
            title={i18n.t`entity.businessCase.labels.totalPrice`}
          />
          <Hide when={isBrokeredSale}>
            <PriceBox
              data-testid={suffixTestId(`profit`, props)}
              colored
              align="left"
              percentTag={totalMarginPercentage}
              price={props.saleVehicle?.profit?.withVat}
              priceSub={props.saleVehicle?.profit?.withoutVat}
              isVatDeductible={isTotalVatDeductible}
              title={i18n.t`page.businessCase.labels.profit`}
            />
          </Hide>
        </Grid>
      </Show>
      <Show whenFeatureEnabled={featureFlags.SALES_VEHICLE_PRICE_SUMMARY}>
        <DataStatus
          isEmpty={isNilOrEmpty(saleVehiclePrices)}
          isLoading={isLoading}
          isError={isError}
          minHeight={10}
        >
          <Grid columns={4}>
            <Show when={canReadVehicleSellingPrice}>
              <PriceBox
                data-testid={suffixTestId(`sellingPrice`, props)}
                align="left"
                price={saleVehiclePrices?.vehicleSellingPrice?.withVat}
                priceSub={saleVehiclePrices?.vehicleSellingPrice?.withoutVat}
                isVatDeductible={saleVehicleData?.isVatDeductible}
                title={i18n.t`entity.businessCase.labels.vehicleSellingPrice`}
              />
            </Show>
            <PriceBox
              data-testid={testIds.businessCase.selling('summary-upsell')}
              align="left"
              price={saleVehiclePrices?.upsell?.withVat}
              priceSub={saleVehiclePrices?.upsell?.withoutVat}
              title={i18n.t('entity.businessCase.labels.upsell')}
            />
            <Hide when={saleVehicleData?.type === 'BROKERAGE' || isBrokeredSale}>
              <PriceBox
                data-testid={testIds.businessCase.selling('summary-discount')}
                align="left"
                price={
                  isNotNilOrEmpty(saleVehiclePrices?.discount?.withVat?.amount)
                    ? {
                        amount: `-${saleVehiclePrices?.discount?.withVat?.amount}`,
                        currency: saleVehiclePrices?.discount?.withVat?.currency,
                      }
                    : undefined
                }
                priceSub={saleVehiclePrices?.discount?.withoutVat}
                isVatDeductible={saleVehicleData?.isVatDeductible}
                title={i18n.t('general.labels.discount')}
              />
            </Hide>
            <Show when={canReadBusinessCaseSaleTotalPrice}>
              <PriceBox
                data-testid={suffixTestId(`totalPrice`, props)}
                align="left"
                price={saleVehiclePrices?.totalPrice?.withVat}
                priceSub={saleVehiclePrices?.totalPrice?.withoutVat}
                title={i18n.t`entity.businessCase.labels.totalPrice`}
              />
            </Show>
            <Hide when={canReadVehicleSellingPrice}>
              <Space fillAvailable />
            </Hide>
            <Show when={saleVehicleData?.type === 'BROKERAGE' || isBrokeredSale}>
              <Space fillAvailable />
            </Show>

            <Show when={canReadVehicleSellingPrice && canReadCosts && canReadVehiclePurchasePrice}>
              <PriceBox
                data-testid={testIds.businessCase.selling('summary-realCosts')}
                align="left"
                price={saleVehiclePrices?.realCosts}
                title={i18n.t('entity.businessCase.labels.realCosts')}
                tooltipText={i18n.t('entity.businessCase.labels.realCostsTooltip')}
              />
            </Show>
            <Hide when={canReadVehicleSellingPrice && canReadCosts}>
              <Space fillAvailable />
            </Hide>
            <Show when={canReadVehicleEarnings}>
              <PriceBox
                data-testid={testIds.businessCase.selling('summary-totalExpectedEarnings')}
                align="left"
                price={saleVehiclePrices?.expectedEarnings}
                title={i18n.t('entity.businessCase.labels.totalExpectedEarnings')}
                tooltipText={i18n.t('entity.businessCase.labels.totalExpectedEarningsTooltip')}
              />
            </Show>
            <Hide when={canReadVehicleEarnings}>
              <Space fillAvailable />
            </Hide>
            <Show
              when={
                canReadCosts &&
                canReadVehicleSellingPrice &&
                canReadVehiclePurchasePrice &&
                canViewBusinessCaseBrokerageFees
              }
            >
              <Show when={isBrokerage ? canReadVehicleEarnings : true}>
                <PriceBox
                  data-testid={testIds.businessCase.selling('summary-totalGrossMargin')}
                  colored
                  align="left"
                  price={saleVehiclePrices?.vehicleMargin?.amount}
                  percentTag={Number(saleVehiclePrices?.vehicleMargin?.percentage)}
                  title={i18n.t('entity.businessCase.labels.grossMarginOnVehicle')}
                  tooltipText={i18n.t('entity.businessCase.labels.grossMarginOnVehicleTooltip')}
                />
              </Show>
              <Show when={canReadVehicleEarnings}>
                <PriceBox
                  data-testid={testIds.businessCase.selling('summary-grossMarginTotal')}
                  title={i18n.t('entity.businessCase.labels.grossMarginTotal')}
                  tooltipText={i18n.t('entity.businessCase.labels.grossMarginTotalTooltip')}
                  hasAmountSign
                  colored
                  price={saleVehiclePrices?.totalMargin?.amount}
                  percentTag={Number(saleVehiclePrices?.totalMargin?.percentage)}
                />
              </Show>
            </Show>
            <Hide when={canReadCosts && canReadVehicleSellingPrice && canReadVehiclePurchasePrice}>
              <Hide when={isBrokerage ? canReadVehicleEarnings : true}>
                <Space fillAvailable />
              </Hide>
              <Hide when={canReadVehicleEarnings}>
                <Space fillAvailable />
              </Hide>
            </Hide>
          </Grid>
        </DataStatus>
      </Show>
    </Card>
  );
};
