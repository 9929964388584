import {DataStatus, Label, MultiChoice} from 'platform/components';
import {Box, Grid} from 'platform/foundation';

import {isEmpty} from 'ramda';

import i18n from '@dms/i18n';
import {filterLimits} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {useApiEnums} from '../../../../hooks/useApiEnums';
import {useFilters} from '../../../FiltersContext/FiltersContext';
import {SourcingSlider} from '../SourcingSlider/SourcingSlider';
import {ColorPicker} from './components/ColorPicker/ColorPicker';

const IGNORED_TRANSMISSIONS = ['TRANSMISSION_SEMI_AUTOMATIC'];
const IGNORED_DRIVES = ['DRIVE_FRONT', 'DRIVE_REAR'];
const currentYear = new Date().getFullYear();

export function FilterSpecification(props: TestIdProps) {
  const {filters, onUpdateFilters} = useFilters();
  const {getEnum, isLoading} = useApiEnums();

  const fuelTypes = getEnum('fuel_type');
  const transmissions = getEnum('transmission').filter(
    (item) => !IGNORED_TRANSMISSIONS.includes(item.key)
  );
  const bodyTypes = getEnum('car_style');
  const drives = getEnum('drive').filter((item) => !IGNORED_DRIVES.includes(item.key));
  const bodyColors = getEnum('body_color');

  const {
    drive,
    bodyType,
    power,
    cubicCapacity,
    selectedColors,
    transmission,
    fuelType,
    year,
    mileageRange,
    numberOfSeats,
    featureScore,
  } = filters;

  return (
    <DataStatus isLoading={isLoading}>
      <Grid columns={4} spacing={8} data-testid={suffixTestId('filtersSpecification', props)}>
        <MultiChoice
          label={i18n.t('entity.vehicle.labels.fuelType')}
          options={fuelTypes}
          value={fuelType ?? null}
          onChange={(option) => onUpdateFilters(['fuelType'], isEmpty(option) ? undefined : option)}
          data-testid={suffixTestId('fuelType', props)}
        />

        <MultiChoice
          label={i18n.t('entity.vehicle.labels.transmission')}
          options={transmissions}
          value={transmission ?? null}
          onChange={(option) =>
            onUpdateFilters(['transmission'], isEmpty(option) ? undefined : option)
          }
          data-testid={suffixTestId('transmission', props)}
        />

        <MultiChoice
          label={i18n.t('entity.vehicle.labels.bodyType')}
          options={bodyTypes}
          value={bodyType ?? null}
          onChange={(option) => onUpdateFilters(['bodyType'], isEmpty(option) ? undefined : option)}
          data-testid={suffixTestId('bodyType', props)}
        />

        <MultiChoice
          label={i18n.t('entity.vehicle.labels.driveType')}
          options={drives}
          value={drive ?? null}
          onChange={(option) => onUpdateFilters(['drive'], isEmpty(option) ? undefined : option)}
          data-testid={suffixTestId('driveType', props)}
        />

        <SourcingSlider
          label={i18n.t('entity.vehicle.labels.mileageRange')}
          min={filterLimits.MILEAGE_RANGE_MIN}
          max={filterLimits.MILEAGE_RANGE_MAX}
          marks={filterLimits.MILEAGE_RANGE_MARKS}
          hasMaxPlus
          value={mileageRange}
          onChange={(value) => onUpdateFilters(['mileageRange'], value)}
          data-testid={suffixTestId('mileageRange', props)}
        />

        <SourcingSlider
          label={i18n.t('entity.vehicle.labels.year')}
          min={filterLimits.YEAR_LIMIT_MIN}
          max={currentYear}
          value={year}
          onChange={(value) => onUpdateFilters(['year'], value)}
          data-testid={suffixTestId('year', props)}
        />

        <SourcingSlider
          label={i18n.t('entity.vehicle.labels.power')}
          min={filterLimits.POWER_LIMIT_MIN}
          marks={filterLimits.POWER_MARKS}
          max={filterLimits.POWER_LIMIT_MAX}
          hasMaxPlus
          value={power}
          onChange={(value) => onUpdateFilters(['power'], value)}
          data-testid={suffixTestId('power', props)}
        />

        <SourcingSlider
          label={i18n.t('entity.vehicle.labels.cubicCapacity')}
          min={filterLimits.CUBIC_CAPACITY_LIMITS[0]}
          max={filterLimits.CUBIC_CAPACITY_LIMITS[1]}
          marks={filterLimits.CUBIC_CAPACITY_MARKS}
          hasMaxPlus
          value={cubicCapacity}
          onChange={(value) => onUpdateFilters(['cubicCapacity'], value)}
          data-testid={suffixTestId('cubicCapacity', props)}
        />

        <SourcingSlider
          label={i18n.t('entity.vehicle.labels.numberOfSeats')}
          min={filterLimits.NUMBER_OF_SEATS_MIN}
          max={filterLimits.NUMBER_OF_SEATS_MAX}
          hasMaxPlus
          value={numberOfSeats}
          onChange={(value) => onUpdateFilters(['numberOfSeats'], value)}
          data-testid={suffixTestId('numberOfSeats', props)}
        />

        <SourcingSlider
          label={i18n.t('entity.vehicle.labels.rankEquipment')}
          step={20}
          min={filterLimits.FEATURE_SCORE_MIN}
          max={filterLimits.FEATURE_SCORE_MAX}
          value={featureScore}
          onChange={(value) => onUpdateFilters(['featureScore'], value)}
          data-testid={suffixTestId('rankEquipment', props)}
        />

        <Box>
          <Label>{i18n.t('general.labels.colors')}</Label>
          <ColorPicker
            colors={bodyColors.map((color) => ({
              key: color.key,
              name: color.label,
              colorCode: color.colorCode,
            }))}
            selectedColors={selectedColors}
            onChange={(colors) => onUpdateFilters(['selectedColors'], colors)}
          />
        </Box>
      </Grid>
    </DataStatus>
  );
}
