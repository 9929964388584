import {BigCalendarEvent, BigCalendarUser} from 'platform/big-calendar';

import {GetDataByDataQueryApiResponse} from '@dms/api/datagrid';

import {Nullish, parseDate} from 'shared';

import {getIconKeyByType} from '../../../utils/getIconKeyByType';
import {getUserColorVariants} from './getUserColorVariants';

export function getBigCalendarDataFromGrid(
  dataGridData: GetDataByDataQueryApiResponse | Nullish,
  selectedUsers: BigCalendarUser[] | Nullish
) {
  return dataGridData?.rows?.reduce<BigCalendarEvent[]>((events, row) => {
    const dueDate: string | null = row.cells.dueDate.value;
    const dueTime: string | null = row.cells.dueTime.value;
    const assignedUserId = row.cells.assignedTo?.value?.id;

    const baseDate = parseDate(dueDate);

    const userIndex = selectedUsers?.findIndex((user) => user.id === assignedUserId);

    const userColorVariants = getUserColorVariants(userIndex);

    if (!baseDate) {
      return events;
    }

    if (!dueTime) {
      events.push({
        id: row.id,
        title: row.cells.title.value,
        start: parseDate(baseDate.setHours(0, 0, 0)),
        end: parseDate(baseDate.setHours(0, 0, 0)),
        color: userColorVariants[row.cells?.priority?.value?.key],
        icon: getIconKeyByType(row.cells?.type?.value?.key),
        alternative: row.cells.state.value?.key === 'DONE',
        isAllDay: true,
      });
      return events;
    }

    const dueTimeParts = dueTime.split(' - ');
    const startTime = dueTimeParts[0];
    const endTime = dueTimeParts[1];

    const start: Date = parseDate(baseDate);
    const [startHours, startMinutes] = startTime.split(':');
    start.setHours(parseInt(startHours, 10), parseInt(startMinutes, 10));

    const end: Date = parseDate(baseDate);
    const [endHours, endMinutes] = endTime.split(':');
    end.setHours(parseInt(endHours, 10), parseInt(endMinutes, 10));

    events.push({
      id: row.id,
      title: row.cells.title.value,
      start,
      end,
      color: userColorVariants[row.cells?.priority?.value?.key],
      icon: getIconKeyByType(row.cells?.type?.value?.key),
      alternative: row.cells.state.value?.key === 'DONE',
    });

    return events;
  }, []);
}
