import {Card} from 'platform/components';
import {VStack} from 'platform/foundation';

import {BaseAuthorizationProfile} from '@dms/api/metadaAdminAuthorizationProfile';
import {PaymentTypeResponseBody} from '@dms/api/metadaErrorLogs';
import {GetWarehousesResponse} from '@dms/api/metadaWarehouse';
import {GetReceiveNoteCorrectionResponse} from '@dms/api/metadaWarehouseReceiveNoteCorrection';
import {BaseSupplier} from '@dms/api/metadaWarehouseSupplier';
import {CurrencyResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {DeliveryNoteOriginalCard} from './DeliveryNoteOriginalCard';

interface DeliveryNoteDetailCardProps extends RequiredTestIdProps {
  receiveNoteCorrection: GetReceiveNoteCorrectionResponse;
  suppliers: BaseSupplier[] | Nullish;
  warehouses: GetWarehousesResponse | Nullish;
  authorizationProfiles: BaseAuthorizationProfile[] | Nullish;
  paymentTypes: PaymentTypeResponseBody[] | Nullish;
  currencies: CurrencyResponseBody[] | Nullish;
}

export function DeliveryNoteDetailCard(props: DeliveryNoteDetailCardProps) {
  return (
    <Card title={i18n.t('entity.warehouse.labels.deliveryNote')} data-testid={props['data-testid']}>
      <VStack spacing={4}>
        <DeliveryNoteOriginalCard
          receiveNoteCorrection={props.receiveNoteCorrection}
          suppliers={props.suppliers}
          warehouses={props.warehouses}
          authorizationProfiles={props.authorizationProfiles}
          paymentTypes={props.paymentTypes}
          currencies={props.currencies}
          data-testid={suffixTestId('cards.original', props)}
        />
      </VStack>
    </Card>
  );
}
