import {useDndContext} from '@dnd-kit/core';
import {useSortable} from '@dnd-kit/sortable';
import {Box, HStack, Icon, Text, ThemeIconKey} from 'platform/foundation';
import styled from 'styled-components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {SIDEBAR_MENU_ICON_SIZE} from '../constants/sidebarMenuIconSize';

interface SortableItemProps {
  id: string;
  index: number;
  title: string;
  items: {id: string}[];
  icon: ThemeIconKey | undefined;
  isPinned?: boolean;
  isDisabled?: boolean;
  isDragOverlay?: boolean;
  onPinClick?: () => void;
}

export function SortableSidebarItem(props: SortableItemProps) {
  const {attributes, listeners, setNodeRef, active, activeIndex} = useSortable({
    id: props.id,
  });
  const {over} = useDndContext();

  const overIndex = over?.id ? props.items.findIndex((item) => item.id === over?.id) : -1;

  const handlePinClick = () => {
    if (props.onPinClick && !props.isDisabled) {
      props.onPinClick();
    }
  };

  return (
    <StyledSortableItem
      ref={setNodeRef}
      $isDisabled={active?.id === props.id}
      $isDragging={active?.id === props.id || !!props.isDragOverlay}
      $indicator={match([
        overIndex !== activeIndex && overIndex === props.index,
        overIndex > activeIndex,
      ])
        .with([true, true], always('after' as const))
        .with([true, false], always('before' as const))
        .otherwise(always(undefined))}
    >
      <StyledDragHandle
        disabled={props.isDisabled}
        $isDragging={props.isDragOverlay}
        {...attributes}
        {...listeners}
      >
        <Icon
          size={SIDEBAR_MENU_ICON_SIZE}
          color="palettes.neutral.70.100"
          value="sidebar/drag_drop_vertical"
        />
      </StyledDragHandle>
      <Box flexGrow={1} padding={2} paddingLeft={0}>
        <HStack spacing={2} align="center">
          <Icon size={SIDEBAR_MENU_ICON_SIZE} value={props.icon} />
          <Text size="small">{props.title}</Text>
        </HStack>
      </Box>
      <StyledPinButton disabled={props.isDisabled} onClick={handlePinClick}>
        <Icon
          size={SIDEBAR_MENU_ICON_SIZE}
          color="palettes.neutral.800.100"
          value={props.isPinned ? 'custom/push_pin' : 'custom/push_pin_outlined'}
        />
      </StyledPinButton>
    </StyledSortableItem>
  );
}

const StyledSortableItem = styled.div<{
  $isDisabled?: boolean;
  $isDragging?: boolean;
  $indicator?: 'before' | 'after';
}>`
  display: flex;
  position: relative;
  align-items: center;
  height: ${({theme}) => theme.getSize(9)};
  width: 100%;
  border-radius: ${({theme}) => theme.getSize(2)};
  opacity: ${({$isDisabled}) => ($isDisabled ? 0.4 : 1)};
  cursor: ${({$isDragging}) => ($isDragging ? 'grabbing' : 'auto')};
  background-color: ${({$isDragging, theme}) =>
    $isDragging ? theme.colors.palettes.neutral['20']['100'] : 'unset'};
  ${({$indicator, theme}) =>
    $indicator &&
    `&&:before {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      top: ${$indicator === 'before' ? 0 : 'unset'};
      bottom: ${$indicator === 'after' ? 0 : 'unset'};
      background-color: ${theme.colors.palettes.blue['60']['100']};}`}
`;

// Global style for button, [role="button"] sets cursor to pointer, that's the reason why `!important` is used
const StyledDragHandle = styled.button<{$isDragging?: boolean; disabled?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: ${({theme}) => theme.getSize(2)};
  cursor: ${({$isDragging, disabled}) => {
    if (disabled) {
      return 'not-allowed';
    }
    return $isDragging ? 'grabbing' : 'grab';
  }} !important;
`;

const StyledPinButton = styled.button<{disabled?: boolean}>`
  display: flex;
  align-items: center;
  padding: 0 ${({theme}) => theme.getSize(2)};
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
`;
