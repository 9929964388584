import {FormControl, FormField, useFormContext} from 'platform/components';
import {Box, Hide, HStack, Integer, Show, Spinner} from 'platform/foundation';

import {useCallback, useEffect, useRef} from 'react';
import {FieldValues, Path} from 'react-hook-form';

import {PriceWithVatDirectionEnum} from '@dms/api/commission';
import {
  useLazyCalculateFromWithoutVatQuery,
  useLazyCalculateFromWithVatQuery,
} from '@dms/api/common';
import {CountryAlpha3CodeEnum, VatRateType} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {
  CurrencyCodeType,
  NonNullableFieldPathByValue,
  RequiredTestIdProps,
  suffixTestId,
} from 'shared';

import {useFormValues} from '../../../hooks/useFormValues';
import {useGetVatRatesOptions} from '../../../hooks/useGetVatRatesOptions';
import {handleApiError} from '../../../utils/handleApiError';
import {CoercedMoney, VatDirection} from '../VehicleTransactionFormSchema';

type VatPriceStructure = {
  withVat: CoercedMoney;
  withoutVat: CoercedMoney;
  vatDirection: VatDirection;
  vatRate: string;
  vatType: string;
};

const INPUT_HEIGHT: Integer = 15;

interface FormVatPriceFieldProps<T extends FieldValues> extends RequiredTestIdProps {
  // TODO: Labels - https://carvago.atlassian.net/browse/T20-69682
  name: NonNullableFieldPathByValue<T, VatPriceStructure>;
  control: FormControl<T>;
  currency: CurrencyCodeType;
  countryCode: CountryAlpha3CodeEnum;
}

export function FormVatPriceField<T extends FieldValues = FieldValues>(
  props: FormVatPriceFieldProps<T>
) {
  const [vatRates] = useGetVatRatesOptions();

  const [calculatePriceWithVat] = useLazyCalculateFromWithVatQuery();
  const [calculatePriceWithoutVat] = useLazyCalculateFromWithoutVatQuery();

  const {setValues} = useFormContext<{vatCode: string}>();

  const {[props.name]: vatFields} = useFormValues<T>(props.name);

  const {withoutVat, vatType} = (vatFields as VatPriceStructure) ?? {};

  const {name: vatFieldsName} = props;

  const buildFieldName = useCallback(
    (fieldName: string, suffix?: string) =>
      `${vatFieldsName as string}.${fieldName}${suffix ? `.${suffix}` : ''}` as Path<T>,
    [vatFieldsName]
  );

  const buildPriceAmountFieldName = (fieldName: 'withVat' | 'withoutVat') =>
    buildFieldName(fieldName, 'amount');

  const dynamicDefaultValuesSet = useRef(false);
  const {currency} = props;
  useEffect(() => {
    if (dynamicDefaultValuesSet.current === false && vatRates.length > 0 && vatType === undefined) {
      // needs to be delayed to avoid "setValue" being called before the form is fully rendered
      setTimeout(() => {
        setValues({
          [buildFieldName('vatRate') as Path<T>]: vatRates[0].rate,
          [buildFieldName('vatType')]: vatRates[0].value,
          [buildFieldName('vatDirection')]: PriceWithVatDirectionEnum.FROM_PRICE_WITHOUT_VAT,
          [buildFieldName('withVat', 'currency')]: currency,
          [buildFieldName('withoutVat', 'currency')]: currency,
        } as Partial<T>);
      });
      dynamicDefaultValuesSet.current = true;
    }
  }, [vatRates, vatType, setValues, buildFieldName, currency]);

  return (
    <>
      <FormField
        type="hidden"
        name={buildFieldName('vatDirection')}
        control={props.control}
        data-testid={suffixTestId('vatDirection', props)}
      />
      <FormField
        type="hidden"
        name={buildFieldName('vatRate')}
        control={props.control}
        data-testid={suffixTestId('vatRate', props)}
      />
      <FormField
        type="hidden"
        name={buildFieldName('withVat', 'currency')}
        control={props.control}
        data-testid={suffixTestId('withVatCurrency', props)}
      />
      <FormField
        type="hidden"
        name={buildFieldName('withoutVat', 'currency')}
        control={props.control}
        data-testid={suffixTestId('withoutVatCurrency', props)}
      />

      <Show when={vatRates.length > 0}>
        <HStack align="center" spacing={4} width="100%">
          <Box flexGrow={1} flexShrink={0} maxWidth="30%" height={INPUT_HEIGHT}>
            <FormField
              data-testid={suffixTestId('withoutVatAmount', props)}
              type="currency"
              name={buildPriceAmountFieldName('withoutVat')}
              label={i18n.t('entity.vehicle.costs.expectedPriceWithoutVAT')}
              control={props.control}
              decimalPlaces={2}
              onChange={(value) => {
                // TODO: Debouncing - https://carvago.atlassian.net/browse/T20-69682
                if (value) {
                  calculatePriceWithoutVat({
                    amount: value.toString(),
                    vatRateType: vatType as VatRateType,
                    countryCode: props.countryCode,
                  })
                    .unwrap()
                    .then((res) => {
                      setValues({
                        [buildPriceAmountFieldName('withVat')]: res.withVat,
                        [buildPriceAmountFieldName('withoutVat')]: res.withoutVat,
                        [buildFieldName('vatDirection')]:
                          PriceWithVatDirectionEnum.FROM_PRICE_WITHOUT_VAT,
                      } as Partial<T>);
                    });
                }
              }}
            />
          </Box>
          <Box flexShrink={0} flexGrow={0} flexBasis={20} height={INPUT_HEIGHT}>
            <FormField
              data-testid={suffixTestId('vatType', props)}
              type="choice"
              name={buildFieldName('vatType')}
              options={vatRates}
              control={props.control}
              label={i18n.t('general.labels.vat')}
              isNotClearable
              menuInPortal
              onChange={(value) => {
                if (value && withoutVat?.amount) {
                  calculatePriceWithoutVat({
                    amount: withoutVat.amount.toString(),
                    vatRateType: value as VatRateType,
                    countryCode: props.countryCode,
                  })
                    .unwrap()
                    .then((res) => {
                      setValues({
                        [buildPriceAmountFieldName('withVat')]: res.withVat,
                        [buildPriceAmountFieldName('withoutVat')]: res.withoutVat,
                        [buildFieldName('vatRate')]: vatRates.find((rate) => rate.value === value)
                          ?.rate,
                        [buildFieldName('vatType')]: value,
                      } as Partial<T>);
                    })
                    .catch(handleApiError);
                }
              }}
            />
          </Box>
          <Box flexGrow={3} flexShrink={0} height={INPUT_HEIGHT}>
            <FormField
              data-testid={suffixTestId('withVatAmount', props)}
              type="currency"
              label={i18n.t('entity.vehicle.costs.expectedPriceWithVAT')}
              name={buildPriceAmountFieldName('withVat')}
              control={props.control}
              decimalPlaces={2}
              onChange={(value) => {
                if (value) {
                  calculatePriceWithVat({
                    amount: value.toString(),
                    vatRateType: vatType as VatRateType,
                    countryCode: props.countryCode,
                  })
                    .unwrap()
                    .then((res) => {
                      setValues({
                        [buildPriceAmountFieldName('withVat')]: res.withVat,
                        [buildPriceAmountFieldName('withoutVat')]: res.withoutVat,
                        [buildFieldName('vatDirection')]:
                          PriceWithVatDirectionEnum.FROM_PRICE_WITH_VAT,
                      } as Partial<T>);
                    })
                    .catch(handleApiError);
                }
              }}
            />
          </Box>
        </HStack>
      </Show>
      <Hide when={vatRates.length > 0}>
        <Spinner />
      </Hide>
    </>
  );
}
