import {captureException} from '@sentry/browser';
import axios, {AxiosRequestConfig, AxiosResponse, CustomParamsSerializer} from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {buildKeyGenerator, buildMemoryStorage, setupCache} from 'axios-cache-interceptor';
import flagsmith from 'flagsmith';
import {showNotification} from 'platform/components';
import {stringify} from 'qs';

import {has} from 'ramda';

import {
  AccessTokenResponseBody,
  buildUrl,
  getWorkspaceFromUri,
  publicApi,
  RefreshTokenApiArg,
} from '@dms/api/core';
import {appWorkspaceKey, browserStorageKey, redirectLink} from '@dms/config';
import i18n from '@dms/i18n';
import {loginRoutes} from '@dms/routes';

import {API_URL} from '../types/CommonAppTypes';
import {parseHostname} from './parseHostname';

/**
 * Use custom definition of auth endpoints because generated endpoints use apiClient internally,
 * causing circular dependencies and Authorization header "injection" which is unwanted for these requests
 * TODO: Remove this method when we stop using apiClient directly in app/core/utils/request.ts
 */
export const refreshTokenRequest = async ({
  workspace,
  refreshToken,
}: {
  workspace: string;
  refreshToken: string;
}): Promise<AccessTokenResponseBody> => {
  const res = await axios({
    method: 'POST',
    url: '/dms/v1/auth/token/refresh',
    baseURL: API_URL,
    headers: {
      'X-Workspace': workspace,
    },
    data: {
      refreshToken,
    },
  });

  return res.data;
};

const getStoreHack = () => typeof window !== 'undefined' && (window as Window).__REDUX_STORE__;
const getRefreshTokenFromCookie = () => localStorage.getItem(browserStorageKey.REFRESH_TOKEN);

const refreshAuthLogic = async (failedRequest: {response: AxiosResponse}): Promise<void> => {
  try {
    const {workspace} = parseHostname(window.location.hostname);
    const rToken = getRefreshTokenFromCookie();
    if (!workspace) {
      throw Error('Refresh token error: No workspace detected.');
    }
    if (!rToken) {
      throw Error('Refresh token error: No refresh token found.');
    }
    const res = await refreshTokenRequest({
      workspace,
      refreshToken: rToken,
    });
    const {token, refreshToken} = res;
    failedRequest.response.config.headers.Authorization = `Bearer ${token}`;

    sessionStorage.setItem(browserStorageKey.ACCESS_TOKEN, token);
    localStorage.setItem(browserStorageKey.REFRESH_TOKEN, refreshToken);
  } catch (error: any) {
    sessionStorage.removeItem(browserStorageKey.ACCESS_TOKEN);
    localStorage.removeItem(browserStorageKey.REFRESH_TOKEN);
    flagsmith.logout();
    window.location.href = buildUrl(loginRoutes.login);
  }
};

// BE only accepts boolean query params as 1 or 0.
// This converts all true/false params to 1/0 for consistency
function convertBooleanParams(value: unknown): unknown {
  if (typeof value === 'boolean') {
    return value ? 1 : 0;
  }
  if (Array.isArray(value)) {
    return value.map(convertBooleanParams);
  }
  if (typeof value === 'object' && value != null) {
    return Object.entries(value).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: convertBooleanParams(value),
      }),
      {}
    );
  }
  return value;
}

const options: AxiosRequestConfig = {
  baseURL: API_URL,
  paramsSerializer: {
    serialize: (params) => stringify(convertBooleanParams(params)),
  },
};

const redirectToLoginPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(redirectLink);

  const query = searchParams.toString();

  const redirectUrl = `${window.location.pathname}${query ? `?${query}` : ''}${
    window.location.hash
  }`;

  sessionStorage.setItem(redirectLink, redirectUrl);

  return `${loginRoutes.login}`;
};

const instance = axios.create(options);
const storage = buildMemoryStorage();
export const client = setupCache(instance, {
  cacheTakeover: false,
  generateKey: buildKeyGenerator(({baseURL, url, paramsSerializer, params}) => {
    const paramsSerialized = has('serialize', paramsSerializer)
      ? (paramsSerializer.serialize as CustomParamsSerializer)(params)
      : '';
    return `${baseURL}${url}${paramsSerialized}`;
  }),
  storage,
  update: (config) => {
    if (config.config.method?.toLowerCase() !== 'get') {
      storage.remove(config.id);
    }
  },
});

// This is just a type error - https://github.com/arthurfiorette/axios-cache-interceptor/issues/953
// Will be fixed in https://carvago.atlassian.net/browse/T20-76269
// @ts-expect-error This is just a type error - https://github.com/arthurfiorette/axios-cache-interceptor/issues/953
createAuthRefreshInterceptor(client, refreshAuthLogic);

client.interceptors.request.use((request) => {
  const accessToken = sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN);
  const {workspace} = getWorkspaceFromUri();

  const headers = {
    'accept-language': localStorage.getItem(browserStorageKey.LAST_KNOWN_LANGUAGE) || 'en',
    'x-country-format': 'iso', // https://carvago.slack.com/archives/G01AN5XCXGX/p1634202539053900
    ...(accessToken ? {Authorization: `Bearer ${accessToken}`} : {}),
    ...(workspace ? {'X-Workspace': workspace} : {}),
  };

  request.headers.set(headers);

  return request;
});

client.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error && error.status >= 500) {
      captureException(error);
    }

    if (error?.response?.status === 401) {
      const getRefreshToken = localStorage.getItem(browserStorageKey.REFRESH_TOKEN);
      const {workspace, shouldRedirectToAppWorkspace} = getWorkspaceFromUri();

      if (!workspace || shouldRedirectToAppWorkspace) {
        window.location.href = buildUrl(loginRoutes.loginWorkspace, appWorkspaceKey);
        return;
      }

      if (!getRefreshToken) {
        window.location.href = buildUrl(redirectToLoginPage(), undefined, false);
        return;
      }

      const refreshTokenArgs: RefreshTokenApiArg = {
        workspace,
        refreshTokenRequestBody: {
          refreshToken: getRefreshToken,
        },
      };

      getStoreHack()
        .dispatch(publicApi.endpoints.refreshToken.initiate(refreshTokenArgs))
        .then((refreshResponse: any) => {
          if (refreshResponse && refreshResponse.refreshToken && refreshResponse.token) {
            sessionStorage.setItem(browserStorageKey.ACCESS_TOKEN, refreshResponse.token);
            localStorage.setItem(browserStorageKey.REFRESH_TOKEN, refreshResponse.refreshToken);
          }
        })
        .catch(() => {
          sessionStorage.removeItem(browserStorageKey.ACCESS_TOKEN);
          localStorage.removeItem(browserStorageKey.REFRESH_TOKEN);
          flagsmith.logout();
          window.location.href = buildUrl(redirectToLoginPage(), undefined, false);
        });
    }

    /**
     * if active branch is not exist in list of branches handle 403 error task https://carvago.atlassian.net/browse/T20-2301
     * **/
    if (error?.response?.status === 403 && error.response.data?.error?.data?.['forbidden-branch']) {
      sessionStorage.removeItem(browserStorageKey.ACCESS_TOKEN);
      localStorage.removeItem(browserStorageKey.REFRESH_TOKEN);
      flagsmith.logout();
      window.location.href = buildUrl(loginRoutes.login);

      showNotification.error(i18n.t('general.actions.branchError'));
    }
    return Promise.reject(error);
  }
);
