import {
  closeCurrentDialog,
  FormSubmitHandler,
  openConfirmDialog,
  openDialog,
  showNotification,
  useLocalStorage,
} from 'platform/components';

import {useState} from 'react';

import {
  SourcingPresetResponseBody,
  useLazySourcingGetPresetQuery,
  useSourcingCreatePresetMutation,
  useSourcingDeletePresetMutation,
  useSourcingGetPresetsListQuery,
  useSourcingUpdatePresetMutation,
} from '@dms/api/sourcing';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {Nullish, useOnMount} from 'shared';

import {
  FilterPresetForm,
  SourcingFilterPresetFormData,
} from '../components/FilterPresetForm/FilterPresetForm';
import {useFilters} from '../components/FiltersContext/FiltersContext';

export interface SourcingFilterPresetsControl {
  selectedFilterPresetId: string | Nullish;
  onSelectFilterPresetId: (id: string) => void;
  onCreateFilterPreset: () => void;
  onUpdateSelectedFilterPreset: () => void;
  onDeleteSelectedFilterPreset: () => void;
  onRenameSelectedFilterPreset: () => void;
  selectedFilterPreset: SourcingPresetResponseBody | Nullish;
  isEmpty: boolean;
}

const useInitialFilterPresetId = () =>
  useLocalStorage<string | null>('dms--sourcing-filter-preset-id', null);

export function useSourcingFilterPresets(): SourcingFilterPresetsControl {
  const {filters, onChange} = useFilters();

  // selectedFilterPresetId is for fast interaction after click on button
  const [selectedFilterPresetId, setSelectedFilterPresetId] = useState<string | Nullish>();

  // selectedFilterPreset is filter preset loaded from api after click on button
  const [selectedFilterPreset, setSelectedFilterPreset] = useState<
    SourcingPresetResponseBody | Nullish
  >();

  const [initialFilterPresetId, saveFilterPresetId] = useInitialFilterPresetId();

  const {data: presetsList} = useSourcingGetPresetsListQuery();

  const [loadPreset] = useLazySourcingGetPresetQuery();

  useOnMount(() => {
    if (initialFilterPresetId) {
      setSelectedFilterPresetId(initialFilterPresetId);
      loadPreset({id: initialFilterPresetId})
        .unwrap()
        .then((filterPreset) => {
          setSelectedFilterPreset(filterPreset);
          onChange(filterPreset.filters);
        })
        .catch(() => {
          setSelectedFilterPresetId(null);
          setSelectedFilterPreset(null);
          saveFilterPresetId(null);
        });
    }
  });

  const [createPreset] = useSourcingCreatePresetMutation();
  const [updatePreset] = useSourcingUpdatePresetMutation();
  const [deletePreset] = useSourcingDeletePresetMutation();

  const onSelectFilterPresetId = (filterPresetId: string) => {
    if (filterPresetId === selectedFilterPresetId) {
      setSelectedFilterPresetId(null);
      setSelectedFilterPreset(null);
      saveFilterPresetId(null);
      return;
    }

    setSelectedFilterPresetId(filterPresetId);
    saveFilterPresetId(filterPresetId);
    loadPreset({id: filterPresetId})
      .unwrap()
      .then((filterPreset) => {
        setSelectedFilterPreset(filterPreset);
        saveFilterPresetId(filterPreset.id);
        onChange(filterPreset.filters);
      })
      .catch(handleApiError);
  };

  const onCreateFilterPreset = () => {
    const onSubmit: FormSubmitHandler<SourcingFilterPresetFormData> = (values) =>
      createPreset({
        createPresetRequestBody: {
          name: values.name,
          filters,
        },
      })
        .unwrap()
        .then((response) => {
          closeCurrentDialog();
          onSelectFilterPresetId(response.id);
        })
        .catch(handleApiError);

    openDialog(
      <FilterPresetForm
        initialValues={{name: selectedFilterPreset?.name ?? ''}}
        onSubmit={onSubmit}
      />,
      {title: i18n.t('entity.sourcing.filterPresets.labels.create')}
    );
  };

  const onRenameSelectedFilterPreset = () => {
    const onSubmit: FormSubmitHandler<SourcingFilterPresetFormData> = async (values) => {
      if (!selectedFilterPreset) {
        return;
      }

      return await updatePreset({
        updatePresetRequestBody: {
          id: selectedFilterPreset.id,
          name: values.name,
          filters: selectedFilterPreset.filters,
        },
      })
        .unwrap()
        .then(closeCurrentDialog)
        .catch(handleApiError);
    };

    openDialog(
      <FilterPresetForm
        initialValues={{name: selectedFilterPreset?.name ?? ''}}
        onSubmit={onSubmit}
      />,
      {title: i18n.t('entity.sourcing.filterPresets.labels.rename')}
    );
  };

  const onUpdateSelectedFilterPreset = () => {
    if (!selectedFilterPresetId) {
      return;
    }

    return updatePreset({
      updatePresetRequestBody: {
        id: selectedFilterPresetId,
        name: presetsList?.find((preset) => preset.id === selectedFilterPresetId)?.name ?? '',
        filters,
      },
    })
      .unwrap()
      .then((r) => {
        setSelectedFilterPreset(r);
        closeCurrentDialog();
        showNotification.success(i18n.t('general.notifications.success'));
      })
      .catch(handleApiError);
  };

  const onDeleteSelectedFilterPreset = () => {
    openConfirmDialog({
      text: i18n.t('entity.sourcing.filterPresets.actions.confirmDelete'),
      onConfirm: () => {
        if (!selectedFilterPresetId) {
          return;
        }

        deletePreset({id: selectedFilterPresetId})
          .unwrap()
          .then(() => {
            setSelectedFilterPresetId(null);
            setSelectedFilterPreset(null);
            saveFilterPresetId(null);
          })
          .catch(handleApiError);
      },
    });
  };

  return {
    selectedFilterPresetId,
    onSelectFilterPresetId,
    onCreateFilterPreset,
    onUpdateSelectedFilterPreset,
    onDeleteSelectedFilterPreset,
    onRenameSelectedFilterPreset,
    selectedFilterPreset,
    isEmpty: (presetsList?.length ?? 0) === 0,
  };
}
