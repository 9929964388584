import {match} from 'ts-pattern';

import {
  TemplateContextEnum,
  useRenderCustomerEmailTemplateExampleMutation,
  useRenderCustomerSmsTemplateExampleMutation,
  useRenderServiceOrderEmailTemplateExampleMutation,
  useRenderServiceOrderSmsTemplateExampleMutation,
} from '@dms/api/messaging';

export const useRenderTemplateExample = (context: TemplateContextEnum | undefined) => {
  const [
    renderCustomerEmailTemplate,
    {
      isLoading: isRenderCustomerEmailInProgress,
      data: renderDataCustomerEmail,
      isError: isRenderCustomerEmailError,
    },
  ] = useRenderCustomerEmailTemplateExampleMutation();
  const [
    renderCustomerSmsTemplate,
    {
      isLoading: isRenderCustomerSmsInProgress,
      data: renderDataCustomerSms,
      isError: isRenderCustomerSmsError,
    },
  ] = useRenderCustomerSmsTemplateExampleMutation();

  const [
    renderServiceOrderSmsTemplate,
    {
      isLoading: isRenderServiceOrderSmsInProgress,
      data: renderDataServiceOrderSms,
      isError: isRenderServiceOrderSmsError,
    },
  ] = useRenderServiceOrderSmsTemplateExampleMutation();
  const [
    renderServiceOrderEmailTemplate,
    {
      isLoading: isRenderServiceOrderEmailInProgress,
      data: renderDataServiceOrderEmail,
      isError: isRenderServiceOrderEmailError,
    },
  ] = useRenderServiceOrderEmailTemplateExampleMutation();

  const [renderEmail, emailData] = match<
    TemplateContextEnum | undefined,
    [
      typeof renderCustomerEmailTemplate | typeof renderServiceOrderEmailTemplate | undefined,
      typeof renderDataCustomerEmail | typeof renderDataServiceOrderEmail | undefined,
    ]
  >(context)
    .with('customer', () => [renderCustomerEmailTemplate, renderDataCustomerEmail])
    .with('service-order', () => [renderServiceOrderEmailTemplate, renderDataServiceOrderEmail])
    .otherwise(() => [undefined, undefined]);

  const [renderSms, smsData] = match<
    TemplateContextEnum | undefined,
    [
      typeof renderCustomerSmsTemplate | typeof renderServiceOrderSmsTemplate | undefined,
      typeof renderDataServiceOrderSms | typeof renderDataCustomerSms | undefined,
    ]
  >(context)
    .with('customer', () => [renderCustomerSmsTemplate, renderDataCustomerSms])
    .with('service-order', () => [renderServiceOrderSmsTemplate, renderDataServiceOrderSms])
    .otherwise(() => [undefined, undefined]);

  const isLoading =
    isRenderCustomerEmailInProgress ||
    isRenderCustomerSmsInProgress ||
    isRenderServiceOrderEmailInProgress ||
    isRenderServiceOrderSmsInProgress;

  const isError =
    isRenderCustomerEmailError ||
    isRenderCustomerSmsError ||
    isRenderServiceOrderEmailError ||
    isRenderServiceOrderSmsError;

  return {
    renderSms,
    renderEmail,
    smsData,
    emailData,
    isLoading,
    isError,
  };
};
