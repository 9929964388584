import {OmneticKeys} from 'i18next';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {ContextTarget} from '@dms/api/documentContext';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

export const getContextSessionName = (context: ContextTarget, documentCount: number | Nullish) => {
  const sessionLabel = match<ContextTarget, OmneticKeys>(context)
    .with('vehicle', always('entity.document.labels.vehicle'))
    .with('customer', always('entity.document.labels.customer'))
    .with('business-case', always('entity.document.labels.business-case'))
    .with('service-case', always('entity.document.labels.service-case'))
    .with('service-case-order', always('entity.document.labels.service-case-order'))
    .with('service-order-return', always('entity.document.labels.service-order-return'))
    .with('warehouse-supplier-order', always('entity.document.labels.warehouse-supplier-order'))
    .with('warehouse-receipt-note', always('entity.document.labels.warehouse-receipt-note'))
    .with('warehouse-credit-note', always('entity.document.labels.warehouse-credit-note'))
    .with('warehouse-issue-note', always('entity.document.labels.warehouse-issue-note'))
    .with('direct-sale', always('entity.document.labels.direct-sale'))
    .with('tire-order', always('entity.document.labels.tire-order'))
    .exhaustive();

  return i18n.t(sessionLabel, {count: documentCount ?? 0});
};
