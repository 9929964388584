import {Box, HStack} from 'platform/foundation';
import styled, {css} from 'styled-components';
import {match, Pattern} from 'ts-pattern';

import {ReactNode, useId, useRef} from 'react';

import {always} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps, useQueryState} from 'shared';

import {Actions} from '../Actions/Actions';
import {Tab, TabProps} from './Tab';
import {determineLazyBehavior} from './utils/determineLazyBehavior';

const SEARCH_QUERY_KEY = 'controlled-tab';

export type TabsProps = TestIdProps & {
  variant?: 'container' | 'condensed' | 'default';
  tabs: TabProps[];
  isFullWidth?: boolean;
  children?: ReactNode;
  shouldWrap?: boolean;
  isLazy?: boolean;
  isFullHeight?: boolean;
  lazyBehavior?: 'unmount' | 'keepMounted';
} & (
    | {
        activeTabId: string | Nullish;
        onChange: (id: string) => void;
        defaultTabId?: never;
      }
    | {
        activeTabId?: never;
        onChange?: never;
        defaultTabId?: string;
      }
  );

export function Tabs(props: TabsProps) {
  const queryId = useId();
  const hasBeenSelected = useRef<Record<string, boolean>>({});
  const [innerActiveTabId, setInnerActiveTabId] = useQueryState(`${queryId}-${SEARCH_QUERY_KEY}`);

  if (isNilOrEmpty(props.tabs)) {
    return null;
  }

  const activeTabId =
    props.activeTabId ?? innerActiveTabId ?? props.defaultTabId ?? props.tabs[0]?.id;
  const setActiveTabId = props.onChange ?? setInnerActiveTabId;
  const activeTabActions = props.tabs.find((item) => item.id === activeTabId)?.actions;

  const handleChangeTab = (tabId: string) => {
    setActiveTabId(tabId);
  };

  return (
    <>
      <HStack justify="space-between">
        <StyledTabList
          $isFullWidth={props.isFullWidth}
          $shouldWrap={props.shouldWrap}
          $variant={props.variant}
        >
          {props.tabs.map((tab) => (
            <Tab
              key={tab.id}
              id={tab.id}
              title={tab.title}
              icon={tab.icon}
              variant={props.variant}
              isDisabled={tab.isDisabled}
              isSelected={tab.id === activeTabId}
              isFullWidth={props.isFullWidth}
              onClick={handleChangeTab}
              data-testid={tab['data-testid']}
            />
          ))}
        </StyledTabList>
        <Actions actions={activeTabActions} />
      </HStack>
      <Box height={props.isFullHeight ? '100%' : undefined} width="100%">
        {props.tabs.map((tab) => {
          const isTabActive = tab.id === activeTabId;

          if (isTabActive) {
            hasBeenSelected.current[tab.id] = true;
          }

          const shouldRenderTab = determineLazyBehavior({
            hasBeenSelected: hasBeenSelected.current[tab.id],
            isSelected: isTabActive,
            isLazy: props.isLazy,
            lazyBehavior: props.lazyBehavior,
          });

          if (!shouldRenderTab) {
            return null;
          }

          return (
            <StyledTabPanel
              $variant={props.variant}
              key={tab.id}
              hidden={!isTabActive}
              $isFullHeight={props.isFullHeight}
              data-testid={suffixTestId('tabPanel', {'data-testid': tab['data-testid']})}
            >
              {tab.content}
            </StyledTabPanel>
          );
        })}
      </Box>
    </>
  );
}

export type StyledTabListProps = {
  $isFullWidth?: TabsProps['isFullWidth'];
  $shouldWrap?: TabsProps['shouldWrap'];
  $variant?: TabsProps['variant'];
};

export const StyledTabList = styled.div<StyledTabListProps>`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex: ${(props) =>
    match([props.$isFullWidth, props.$shouldWrap])
      .with([true, Pattern.any], always('100%'))
      .with([Pattern.any, true], always('auto'))
      .otherwise(always('none'))};
  flex-wrap: ${(props) => (props.$shouldWrap ? 'wrap' : 'nowrap')};
  white-space: ${(props) => props.$shouldWrap && 'nowrap'};
  column-gap: ${({theme, $variant}) => ($variant !== 'container' ? theme.getSize(6) : undefined)};
  row-gap: ${({theme, $variant}) => ($variant !== 'container' ? theme.getSize(2) : undefined)};
`;

export type StyledTabPanelProps = {
  $variant?: TabsProps['variant'];
  $isFullHeight?: TabsProps['isFullHeight'];
};

const getVariantStyles = ($variant?: TabsProps['variant']) => {
  switch ($variant) {
    case 'container':
      return css<StyledTabPanelProps>`
        padding-left: 16px;
        padding-right: 16px;
      `;
    default:
      return css<StyledTabPanelProps>``;
  }
};

const StyledTabPanel = styled.div<StyledTabPanelProps>`
  height: ${(props) => (props.$isFullHeight ? '100%' : undefined)};
  width: 100%;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  ${({$variant}) => getVariantStyles($variant)}
`;
