import {DataStatus} from 'platform/components';

import {
  useGetVehicleCostQuery,
  useListVehicleCostCategoriesQuery,
  useUpdateVehicleCostMutation,
} from '@dms/api/saleVehicle';

import {DeepNonNullableWithPartial, RequiredTestIdProps} from 'shared';

import {useTenant} from '../../hooks/useTenant';
import {VehicleTransactionForm} from '../VehicleTransactionForm/VehicleTransactionForm';

type SubmitHandler = Parameters<typeof VehicleTransactionForm>[0]['onSubmit'];

interface VehicleEditCostDialogProps extends RequiredTestIdProps {
  vehicleId: string;
  costId: string;
  onSuccess?: (id: string) => void;
  onDiscard?: VoidFunction;
}

export function VehicleEditCostForm(props: VehicleEditCostDialogProps) {
  const {
    data: cost,
    isLoading: isCostLoading,
    isError: hasCostError,
  } = useGetVehicleCostQuery({costId: props.costId, vehicleId: props.vehicleId});
  const [updateVehicleCost] = useUpdateVehicleCostMutation();

  const {data: categories} = useListVehicleCostCategoriesQuery({});

  const {tenantCountry, tenantCurrency} = useTenant();

  const onSubmit: SubmitHandler = async (data) => {
    const {realPrice, ...rest} = data as DeepNonNullableWithPartial<typeof data>;
    await updateVehicleCost({
      vehicleId: props.vehicleId,
      costId: props.costId,
      updateVehicleCostRequestBody: {
        ...rest,
        category: rest.category?.[0] ?? null,
        realPriceOneTime: realPrice!,
        // TODO: https://carvago.atlassian.net/browse/T20-69682
        realPriceRecurringCalculated: null,
        realPriceRecurringSimple: null,
      },
    })
      .unwrap()
      .then(() => {
        props.onSuccess?.(props.costId);
      });
  };

  return (
    <DataStatus isLoading={isCostLoading} isError={hasCostError}>
      <VehicleTransactionForm
        data-testid={props['data-testid']}
        values={{
          ...cost,
          name: cost?.name ?? '',
          realPrice: cost?.realPriceOneTime ?? null,
          expectedPrice: cost?.expectedPrice ?? null,
          category: cost?.category ? [cost.category] : null,
          note: cost?.note ?? null,
          fileId: cost?.fileId ?? null,
        }}
        onSubmit={onSubmit}
        onDiscard={props.onDiscard}
        currency={tenantCurrency}
        countryCode={tenantCountry}
        showCategories
        categories={categories ?? []}
      />
    </DataStatus>
  );
}
