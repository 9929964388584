import {match} from 'ts-pattern';

import {EntityResourceIds} from '@dms/api/shared';

import {TaskRelations} from '../types/TaskRelations';

/**
 * @param pathname - URL pathname, beginning with a /.
 */
export function getEntityResourceIdFromPathname(pathname: string) {
  const entity = pathname.split('/')?.at(1);

  return match(entity)
    .returnType<TaskRelations | undefined>()
    .with('customer', () => EntityResourceIds.customer)
    .with('vehicles', () => EntityResourceIds.vehicle)
    .with('business-case', () => EntityResourceIds.businessCase)
    .with('interest', () => EntityResourceIds.interest)
    .otherwise(() => undefined);
}
