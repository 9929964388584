import {Card, DataStatus} from 'platform/components';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import {GetIssueNoteCorrectionResponse} from '@dms/api/metadaWarehouseIssueNoteCorrection';
import i18n from '@dms/i18n';
import {Section} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';

interface DocumentsProps extends RequiredTestIdProps {
  issueNoteCorrection: GetIssueNoteCorrectionResponse | Nullish;
  isIssueNoteCorrectionLoading: boolean;
}

export function Documents(props: DocumentsProps) {
  const {issueNoteCorrectionId} = useWarehouseParams();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([filter, {warehouseIssueNoteCorrectionId: issueNoteCorrectionId}]),
    [issueNoteCorrectionId]
  );

  return (
    <Section isFullHeight data-testid={suffixTestId('wrapper', props)}>
      <DataStatus isLoading={props.isIssueNoteCorrectionLoading} minHeight={60}>
        <Card isFullHeight title={i18n.t('entity.warehouse.labels.documents')}>
          <DataGrid
            gridCode="document-context-list-warehouse-issue-note-correction"
            queryModifier={queryModifier}
            data-testid="documentContextListWarehouseIssueNoteCorrection"
          />
        </Card>
      </DataStatus>
    </Section>
  );
}
