import {Module} from '@dms/api/shared';
import {moduleKeys} from '@dms/config';
import featureFlags from '@dms/feature-flags';
import {taskManagerRoutes} from '@dms/routes';
import {permissions} from '@dms/shared';

import {TaskList} from './pages/TaskList/TaskList';

export const TaskManagerModule: Module = {
  id: moduleKeys.taskManager,
  requiredFeatureFlag: featureFlags.CORE_TASK_MANAGEMENT,
  requiredPermission: permissions.readTask,
  layoutProps: {
    title: 'page.taskManager.title',
    icon: 'action/assignment_turned_in',
    iconV2: 'action/assignment_turned_in',
  },
  routerProps: {
    path: taskManagerRoutes.taskList,
    element: <TaskList />,
  },
  content: [
    {
      id: 'task-calendar',
      requiredFeatureFlag: featureFlags.CORE_TASK_CALENDAR,
      routerProps: {
        path: taskManagerRoutes.taskCalendar,
        element: <TaskList />,
      },
    },
  ],
};
