import {
  Card,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  Label,
  Separator,
} from 'platform/components';
import {GridItem, Grid, Box, Show} from 'platform/foundation';

import {useParams} from 'react-router-dom';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetSeriesListQuery} from '@dms/api/series';
import {useGetBillingInformationListQuery, useGetBranchListQuery} from '@dms/api/tenant';
import {
  useCreateVehicleWarehouseMutation,
  useGetVehicleWarehouseQuery,
  usePatchVehicleWarehouseMutation,
} from '@dms/api/vehicleWarehouse';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {SeriesTypeEnum} from '@dms/teas';

import {useNavigate} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../components/SettingsSection/SettingsSection';
import {useSeriesOptions} from '../../../hooks/useSeriesOptions';
import {
  VehicleWarehouseFormSchema,
  VehicleWarehouseFormSchemaType,
} from './VehicleWarehouseFormSchema';

export function VehicleWarehouseForm() {
  const {id} = useParams();
  const navigate = useNavigate();

  const vehicleWarehouseId = id ?? '';
  const isEdit = isNotNilOrEmpty(vehicleWarehouseId);

  const {
    data: vehicleWarehouse,
    isLoading: isVehicleWarehouseLoading,
    isError: isVehicleWarehouseError,
  } = useGetVehicleWarehouseQuery({vehicleWarehouseId}, {skip: !isEdit});

  const {
    groupedSeries,
    isSeriesLoading: isInvoiceSeriesLoading,
    isSeriesError: isInvoiceSeriesError,
  } = useSeriesOptions([SeriesTypeEnum.ACCOUNTING_INVOICE]);

  const [createVehicleWarehouse] = useCreateVehicleWarehouseMutation();
  const [patchVehicleWarehouse] = usePatchVehicleWarehouseMutation();

  const {
    data: seriesOptions,
    isLoading: isSeriesLoading,
    isError: isSeriesError,
  } = useGetSeriesListQuery(
    {type: ['sales/vehicle-warehouse']},
    {
      selectFromResult: (response) => ({
        ...response,
        data: response.data?.map((series) => ({
          label: series.name,
          value: series.id,
        })),
      }),
    }
  );

  const {
    data: companyOptions,
    isLoading: isBillingInformationLoading,
    isError: isBillingInformationError,
  } = useGetBillingInformationListQuery(undefined, {
    selectFromResult: (response) => ({
      ...response,
      data: response.data?.billingInformationListItems?.map((company) => ({
        label: company.billingName,
        value: company.id,
      })),
    }),
  });

  const {
    data: branchOptions,
    isLoading: isBranchListLoading,
    isError: isBranchListError,
  } = useGetBranchListQuery(undefined, {
    selectFromResult: (response) => ({
      ...response,
      data: response.data?.branchListItems?.map((branch) => ({
        label: branch.marketingName,
        value: branch.id,
      })),
    }),
  });

  const onSubmit: FormSubmitHandler<VehicleWarehouseFormSchemaType> = (values) =>
    isEdit
      ? patchVehicleWarehouse({
          vehicleWarehouseId,
          body: values,
        })
          .unwrap()
          .then(() => {
            navigate(settingsRoutes.vehicleWarehouseManagement);
          })
          .catch(handleApiError)
      : createVehicleWarehouse({body: values})
          .unwrap()
          .then(() => {
            navigate(settingsRoutes.vehicleWarehouseManagement);
          })
          .catch(handleApiError);

  const invoiceSeries = groupedSeries[SeriesTypeEnum.ACCOUNTING_INVOICE] ?? [];

  const isLoading =
    isVehicleWarehouseLoading ||
    isBillingInformationLoading ||
    isBranchListLoading ||
    isSeriesLoading ||
    isInvoiceSeriesLoading;

  const isError =
    isVehicleWarehouseError ||
    isBillingInformationError ||
    isBranchListError ||
    isSeriesError ||
    isInvoiceSeriesError;

  return (
    <SettingsSection>
      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<VehicleWarehouseFormSchemaType>
          defaultValues={{
            ...vehicleWarehouse,
            costCentre: vehicleWarehouse?.costCentre ?? null,
            invoiceSeriesId: vehicleWarehouse?.invoiceSeriesId ?? null,
            proformaInvoiceSeriesId: vehicleWarehouse?.proformaInvoiceSeriesId ?? null,
            taxDocumentSeriesId: vehicleWarehouse?.taxDocumentSeriesId ?? null,
            correctiveTaxDocumentSeriesId: vehicleWarehouse?.correctiveTaxDocumentSeriesId ?? null,
            overrideBusinessCaseSeries: vehicleWarehouse?.overrideBusinessCaseSeries ?? false,
          }}
          onSubmit={onSubmit}
          experimentalZodSchema={VehicleWarehouseFormSchema}
        >
          {(control, formApi) => {
            const overrideBusinessCaseSeries = formApi.watch('overrideBusinessCaseSeries');

            return (
              <>
                <Grid spacing={4} columns={2}>
                  <GridItem span={2}>
                    <FormField
                      name="name"
                      type="text"
                      label={i18n.t('entity.vehicleWarehouse.labels.warehouseName')}
                      control={control}
                      data-testid={testIds.settings.vehicleWarehouseManagement('name')}
                      isRequired
                    />
                  </GridItem>
                  <FormField
                    name="customId"
                    type="text"
                    label={i18n.t('entity.vehicleWarehouse.labels.warehouseId')}
                    control={control}
                    data-testid={testIds.settings.vehicleWarehouseManagement('warehouseId')}
                    isRequired
                  />
                  <FormField
                    name="costCentre"
                    type="text"
                    label={i18n.t('entity.vehicleWarehouse.labels.costCenter')}
                    data-testid={testIds.settings.vehicleWarehouseManagement('costCenter')}
                    control={control}
                  />
                  <FormField
                    name="companyId"
                    type="choice"
                    label={i18n.t('entity.vehicleWarehouse.labels.company')}
                    data-testid={testIds.settings.vehicleWarehouseManagement('companyId')}
                    options={companyOptions}
                    control={control}
                    isDisabled={isEdit}
                    isNotClearable
                    isRequired
                  />
                  <FormField
                    name="branchIds"
                    type="multiChoice"
                    label={i18n.t('entity.vehicleWarehouse.labels.branch')}
                    data-testid={testIds.settings.vehicleWarehouseManagement('branch')}
                    options={branchOptions}
                    control={control}
                    isNotClearable
                    isRequired
                  />
                  <FormField
                    name="receivingSeriesId"
                    type="choice"
                    label={i18n.t('entity.vehicleWarehouse.labels.receivingSeries')}
                    data-testid={testIds.settings.vehicleWarehouseManagement('receivingSeries')}
                    options={seriesOptions}
                    control={control}
                    isDisabled={isEdit}
                    isNotClearable
                    isRequired
                  />
                  <FormField
                    name="issuingSeriesId"
                    type="choice"
                    label={i18n.t('entity.vehicleWarehouse.labels.issuingSeries')}
                    data-testid={testIds.settings.vehicleWarehouseManagement('issuingSeries')}
                    options={seriesOptions}
                    control={control}
                    isDisabled={isEdit}
                    isNotClearable
                    isRequired
                  />
                  <FormField
                    name="serialNumberSeriesId"
                    type="choice"
                    label={i18n.t('entity.vehicleWarehouse.labels.serialNumber')}
                    data-testid={testIds.settings.vehicleWarehouseManagement('serialNumber')}
                    options={seriesOptions}
                    control={control}
                    isDisabled={isEdit}
                    isNotClearable
                    isRequired
                  />

                  <Show whenFeatureEnabled={featureFlags.SALES_WAREHOUSE_SERIES}>
                    <GridItem span={2}>
                      <Card
                        title={i18n.t('entity.vehicleWarehouse.labels.useOwnSaleNumbers')}
                        control={{
                          value: overrideBusinessCaseSeries,
                          type: 'switch',
                          onChange: (value) =>
                            formApi.setValue('overrideBusinessCaseSeries', value),
                        }}
                        variant="inlineGrey"
                      >
                        {overrideBusinessCaseSeries ? (
                          <>
                            <Separator spacing={0} />
                            <Box paddingBottom={4} />
                            <Label>
                              {i18n.t('entity.vehicleWarehouse.labels.overrideBusinessCaseSeries')}
                            </Label>
                            <Box paddingBottom={4} />
                            <Grid columns={2}>
                              <FormField
                                control={control}
                                type="choice"
                                name="invoiceSeriesId"
                                label={i18n.t('entity.settings.labels.invoiceSeriesId')}
                                options={invoiceSeries}
                                menuInPortal
                                isRequired={!!overrideBusinessCaseSeries}
                              />
                              <FormField
                                control={control}
                                type="choice"
                                name="proformaInvoiceSeriesId"
                                label={i18n.t('entity.settings.labels.proformaInvoiceSeriesId')}
                                options={invoiceSeries}
                                menuInPortal
                                isRequired={!!overrideBusinessCaseSeries}
                              />
                              <FormField
                                control={control}
                                type="choice"
                                name="taxDocumentSeriesId"
                                label={i18n.t('entity.settings.labels.taxDocumentSeriesId')}
                                options={invoiceSeries}
                                menuInPortal
                                isRequired={!!overrideBusinessCaseSeries}
                              />
                              <FormField
                                control={control}
                                type="choice"
                                name="correctiveTaxDocumentSeriesId"
                                label={i18n.t(
                                  'entity.settings.labels.correctiveTaxDocumentSeriesId'
                                )}
                                options={invoiceSeries}
                                menuInPortal
                                isRequired={!!overrideBusinessCaseSeries}
                              />
                            </Grid>
                          </>
                        ) : null}
                      </Card>
                    </GridItem>
                  </Show>
                </Grid>

                <SettingsFooter
                  actions={[
                    {
                      type: 'button',
                      variant: 'secondary',
                      title: isEdit
                        ? i18n.t('general.actions.discardChanges')
                        : i18n.t('general.actions.discard'),
                      onClick: () => {
                        navigate(settingsRoutes.vehicleWarehouseManagement);
                      },
                      'data-testid': testIds.settings.vehicleWarehouseManagement('discard'),
                    },
                    {
                      type: 'form-button',
                      buttonType: 'submit',
                      title: isEdit
                        ? i18n.t('general.actions.saveChanges')
                        : i18n.t('general.actions.save'),
                      control,
                      'data-testid': testIds.settings.vehicleWarehouseManagement('save'),
                    },
                  ]}
                />
              </>
            );
          }}
        </Form>
      </DataStatus>
    </SettingsSection>
  );
}
