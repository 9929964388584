import {Box, Center, Text} from 'platform/foundation';
import styled from 'styled-components';

export interface BadgeProps {
  value: string | number;
}

export function NavigationButtonBadge(props: BadgeProps) {
  return (
    <Box position="absolute" top={0} right={0} height={4} width={4}>
      <Center width="100%" height="100%">
        <Box position="relative">
          <StyledCenter>
            <Text size="xxSmall" color="white" align="center">
              {props.value}
            </Text>
          </StyledCenter>
        </Box>
      </Center>
    </Box>
  );
}

const StyledCenter = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 0 ${({theme}) => theme.getSize(1)};
  border-radius: ${({theme}) => theme.radii.circular};
  background-color: ${({theme}) => theme.colors.palettes.red[50][100]};
`;
