import {Button, DataStatus, openDialog} from 'platform/components';
import {VStack, HStack, Show} from 'platform/foundation';

import {isFalse, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetInterestQuery} from '@dms/api/interest';
import {useGetInterestActivityListQuery} from '@dms/api/sales';
import i18n from '@dms/i18n';
import {usePermissions, ActivitySidebarSendMessage, LogContactDialog} from '@dms/shared';

import {parseDate} from 'shared';

import {ActivityList} from './ActivityList';

interface ActivityTabProps {
  interestId: string;
}

export function ActivityTab(props: ActivityTabProps) {
  const [canReadTasks, canCreateTasks] = usePermissions({
    permissionKeys: ['readTask', 'createTask'],
  });

  const {
    data: interestData,
    isLoading: isInterestLoading,
    isError: isInterestErrored,
  } = useGetInterestQuery({id: props.interestId});

  const {
    data,
    isLoading: isInterestActivityLoading,
    isError: isInterestActivityErrored,
  } = useGetInterestActivityListQuery(
    {interestId: props.interestId},
    {skip: isNilOrEmpty(props.interestId)}
  );

  const pastActivities =
    data?.filter((activity) => parseDate(activity.timestamp) < new Date()) ?? [];
  const nextSteps = data?.filter((activity) => parseDate(activity.timestamp) >= new Date()) ?? [];
  const isAddNewTaskEnabled = canReadTasks && canCreateTasks;
  const isLoading = isInterestLoading || isInterestActivityLoading;
  const isError = isInterestErrored || isInterestActivityErrored;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack spacing={4}>
        <HStack spacing={2}>
          <ActivitySidebarSendMessage customerId={interestData?.customerId ?? ''} />

          <Button
            leftIcon="content/add_circle"
            title={i18n.t('entity.task.actions.createTask')}
            variant="link"
            size="small"
            isDisabled={isFalse(isAddNewTaskEnabled)}
          />
          <Button
            leftIcon="communication/phone"
            title={i18n.t('entity.activityPanel.activity.logCall')}
            variant="link"
            size="small"
            onClick={() =>
              openDialog(
                <LogContactDialog
                  interestId={props.interestId}
                  customerId={interestData?.customerId ?? ''}
                />,
                {
                  id: 'LOG_CONTACT_DIALOG',
                  title: i18n.t('entity.activityPanel.logContact.createLog'),
                  scrollBehavior: 'outside',
                }
              )
            }
          />
        </HStack>

        <Show when={isNotNilOrEmpty(nextSteps)}>
          <ActivityList
            label={i18n.t('entity.activityPanel.activity.nextSteps')}
            items={nextSteps}
          />
        </Show>
        <Show when={isNotNilOrEmpty(pastActivities)}>
          <ActivityList
            label={i18n.t('entity.activityPanel.activity.pastActivities')}
            items={pastActivities}
          />
        </Show>
      </VStack>
    </DataStatus>
  );
}
