import {
  Action,
  Actions,
  closeDialog,
  DataStatus,
  openConfirmDialog,
  openDialog,
  Separator,
  showNotification,
} from 'platform/components';
import {Heading, HStack, Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback, useEffect, useState} from 'react';

import {isNil, isNotNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useLazyGetCustomerV2Query} from '@dms/api/customer';
import {GetWarehousesResponse} from '@dms/api/metadaWarehouse';
import {
  GetDirectSaleResponse,
  PatchDirectSaleRequest,
  useBulkAssignMechanicMutation,
  useBulkDeleteDirectSaleItemsMutation,
  useBulkReturnDirectSaleSparePartsMutation,
  useLazyGetDirectSaleQuery,
  usePatchDirectSaleGetCurrentPriceMutation,
  usePatchDirectSaleMutation,
} from '@dms/api/metadaWarehouseDirectSale';
import {usePostIssueNotesMutation} from '@dms/api/metadaWarehouseIssueNote';
import {
  BasePartsRequest,
  usePatchPartsRequestCancelByOriginEntityMutation,
  usePostPartsRequestMutation,
} from '@dms/api/metadaWarehousePartsRequest';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {catchUnhandledDataGridActions, getNaturalPersonFullName, handleApiError} from '@dms/shared';

import {
  getApiDateString,
  isNilOrZero,
  parseDate,
  suffixTestId,
  TestIdProps,
  useBoolean,
  useDebouncedValue,
} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {DirectSaleMaterialReturnModal} from '../../components/DirectSaleMaterialReturnModal';
import {BulkAssignMechanic} from './BulkAssignMechanic';
import {RequestTabEdit, RequestTabEditProps} from './RequestTabEdit';
import {RequestTabForm} from './RequestTabForm';
import {RequestTabLabourModal} from './RequestTabLabourModal';
import {RequestTabMaterialModal} from './RequestTabMaterialModal';
import {RequestTabFormData} from './types/RequestTabFormData';

const DEBOUNCE_DELAY = 1500;

interface RequestTabProps extends TestIdProps {
  directSale: GetDirectSaleResponse;
  isDirectSaleLoading: boolean;
  isEditingDisabled: boolean;
  availableWarehouses: GetWarehousesResponse;
  isMaterialReturnModalVisible: boolean;
  onCloseMaterialReturnModal: VoidFunction;
}

type DirectSaleItemRowData = {
  id: string;
  type: {value: {key: string; label: string}};
  name: {value: string};
  directSaleId?: {value: string};
  articleId?: {value: string};
  warehouseId?: {value: string};
  unitPrice?: {value: {amount: number; currency: string}};
  amount?: {value: number};
  state: {value: {key: string; label: string}};
};

export function RequestTab(props: RequestTabProps) {
  const {directSaleId} = useWarehouseParams();
  const [isLabourModalVisible, openLabourModal, closeLabourModal] = useBoolean();
  const [isMaterialModalVisible, openMaterialModal, closeMaterialModal] = useBoolean();
  const [isMaterialReturnModalVisible, openMaterialReturnModal, closeMaterialReturnModal] =
    useBoolean();

  const [directSaleDetails, setDirectSaleDetails] = useState<RequestTabFormData | null>(null);
  const [editDialogProps, setEditDialogProps] = useState<RequestTabEditProps | null>(null);

  const [datagridRef, refreshDatagrid] = useRefreshDataGrid();

  const [getDirectSaleDetail] = useLazyGetDirectSaleQuery();
  const [getCustomer] = useLazyGetCustomerV2Query();
  const [createIssueNote] = usePostIssueNotesMutation();
  const [createPartsRequest] = usePostPartsRequestMutation();
  const [deleteDirectSalesItems] = useBulkDeleteDirectSaleItemsMutation();
  const [patchDirectSaleGetCurrentPrice] = usePatchDirectSaleGetCurrentPriceMutation();
  const [cancelPartsRequest] = usePatchPartsRequestCancelByOriginEntityMutation();
  const [saveDirectSale] = usePatchDirectSaleMutation();
  const [bulkReturnSpareParts] = useBulkReturnDirectSaleSparePartsMutation();
  const [bulkAssignMechanic, {isLoading: isAssigningMechanic}] = useBulkAssignMechanicMutation();

  const debouncedDirectSaleDetails = useDebouncedValue(directSaleDetails, DEBOUNCE_DELAY);

  useEffect(() => {
    const handleDelayedSave = async (data: PatchDirectSaleRequest['body']) => {
      const formNeededAt = parseDate(data.neededAt || null);
      const requestData = {
        directSaleId,
        body: {
          ...data,
          neededAt: isNotNil(formNeededAt) ? getApiDateString(formNeededAt) : formNeededAt,
        },
      };

      if (isNilOrZero(props.directSale?.itemsCount)) {
        saveDirectSale(requestData).unwrap().catch(handleApiError);
      } else {
        await openIsItemNeededAtUpdateConfirmDialog().then((isItemNeededAtUpdate) => {
          requestData.body.isItemNeededAtUpdate = isItemNeededAtUpdate;
          saveDirectSale(requestData).unwrap().catch(handleApiError);
        });
      }
    };

    if (debouncedDirectSaleDetails) {
      handleDelayedSave(debouncedDirectSaleDetails);
    }
  }, [directSaleId, props.directSale?.itemsCount, debouncedDirectSaleDetails, saveDirectSale]);

  useEffect(() => {
    if (props.isMaterialReturnModalVisible) {
      openMaterialReturnModal();
    }
  }, [props.isMaterialReturnModalVisible, openMaterialReturnModal]);

  const mapDirectSaleItemToPartsRequest = (
    directSaleItem: DirectSaleItemRowData,
    neededAt?: string
  ): {directSaleId: string; body: BasePartsRequest} => ({
    directSaleId,
    body: {
      originEntityType: 'wrh_sale_item',
      requestItems: [
        {
          originEntityId: directSaleItem.id,
          articleId: directSaleItem.articleId?.value ?? '',
          originEntityHeaderType: 'direct-sale',
          originEntityHeaderId: directSaleId,
          itemNeededAtDate: neededAt,
          proposedSalePrice: directSaleItem.unitPrice?.value?.amount,
          quantity: directSaleItem.amount?.value ?? 0,
        },
      ],
    },
  });

  const openIsItemNeededAtUpdateConfirmDialog = () =>
    new Promise<boolean>((resolve) => {
      openConfirmDialog({
        text: i18n.t('page.warehouse.labels.updateNeededAtDateOnExistingItems'),
        onConfirm: () => resolve(true),
        onCloseComplete: () => resolve(false),
      });
    });

  const requestActions: Action[] = [
    {
      type: 'button',
      title: i18n.t('general.actions.addWork'),
      variant: 'ghostLink',
      leftIcon: 'content/add_circle',
      onClick: openLabourModal,
      isDisabled: props.isEditingDisabled,
      'data-testid': suffixTestId('requestAction.addLabour', props),
    },
    {
      type: 'button',
      title: i18n.t('general.actions.addMaterial'),
      variant: 'ghostLink',
      leftIcon: 'content/add_circle',
      onClick: openMaterialModal,
      isDisabled: props.isEditingDisabled,
      'data-testid': suffixTestId('requestAction.addMaterial', props),
    },
  ];

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {directSaleId}]),
    [directSaleId]
  );

  const actionCallback: ActionCallback = async ({
    actionKey,
    rowId,
    rowData,
    refreshData,
    queryId,
    deselectAll,
  }) => {
    const id = isArray(rowId) ? rowId[0] : rowId;
    const directSaleItems = (isArray(rowData) ? rowData : [rowData]) as DirectSaleItemRowData[];

    await match(actionKey)
      .with('getCurrentPrices', async () => {
        await patchDirectSaleGetCurrentPrice({
          directSaleId,
          body: {directSaleItemId: rowId as string[]},
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError);
      })
      .with('redirectDetail', 'edit', async () => {
        const directSaleDetail = getDirectSaleDetail({directSaleId}).unwrap().catch(handleApiError);

        await directSaleDetail.then((data) => {
          const isEditingDisabled =
            data?.state === 'LOCKED' || data?.state === 'ARCHIVED' || data?.state === 'CLOSED';

          setEditDialogProps({
            itemId: id,
            isEditingDisabled,
            dataQueryId: queryId,
            directSaleId,
            authorizationProfileId: data?.authorizationProfileId,
            onAfterSubmit: refreshData,
            onClose: () => setEditDialogProps(null),
            'data-testid': props['data-testid'] as string,
          });
        });
      })
      .with('delete', async () => {
        const itemsIds = directSaleItems.map((directSaleItem) => ({id: directSaleItem.id}));

        await deleteDirectSalesItems({
          directSaleId,
          body: {
            items: itemsIds,
          },
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError);
      })
      .with('issue', async () => {
        const directSaleNumber = props.directSale?.number;
        const directSaleAuthorizationProfileId = props.directSale?.authorizationProfileId;

        if (isNil(directSaleNumber) || isNil(directSaleAuthorizationProfileId)) {
          throw new Error(
            'Cannot issue. The direct sale number or authorization profile id is missing.'
          );
        }

        const customerDataPromise = isNotNil(props.directSale?.customerId)
          ? getCustomer({customerId: props.directSale?.customerId ?? ''})
              .unwrap()
              .catch(handleApiError)
          : Promise.resolve(null);
        await customerDataPromise.then(async (customerData) => {
          await createIssueNote({
            directSaleId,
            body: {
              originDocId: directSaleId,
              originDocType: 'direct-sale',
              originDocNumber: directSaleNumber,
              authorizationProfileId: directSaleAuthorizationProfileId,
              customerName: getNaturalPersonFullName(customerData?.foundingPerson) ?? undefined,
              items: directSaleItems.map((directSaleItem) => ({
                warehouseId: directSaleItem.warehouseId?.value ?? '',
                originItemId: directSaleItem.id,
                articleId: directSaleItem.articleId?.value ?? '',
                requestItemOriginEntityType: 'wrh_sale_item',
              })),
            },
          })
            .unwrap()
            .then(refreshData)
            .catch(handleApiError);
        });
      })
      .with('requestCancellation', async () => {
        const originEntityIds = directSaleItems.map((directSaleItem) => directSaleItem.id);

        await cancelPartsRequest({
          directSaleId,
          body: {
            originEntityId: originEntityIds,
          },
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError);
      })
      .with('request', async () => {
        const directSaleItem = directSaleItems[0];

        await getDirectSaleDetail({directSaleId})
          .unwrap()
          .then((directSale) =>
            createPartsRequest(
              mapDirectSaleItemToPartsRequest(directSaleItem, directSale?.neededAt)
            )
          )
          .then(refreshData)
          .catch(handleApiError);
      })
      .with('return', async () => {
        const ids = isArray(rowId) ? rowId : [rowId];

        await bulkReturnSpareParts({directSaleId, body: {directSaleItemId: ids}})
          .unwrap()
          .then(deselectAll)
          .then(openMaterialReturnModal)
          .catch(handleApiError);
      })
      .with('assignMechanic', () => {
        const ids = isArray(rowId) ? rowId : [rowId];

        const authorizationProfileId = props.directSale?.authorizationProfileId;

        if (isNil(authorizationProfileId)) {
          throw new Error('Cannot assign mechanic. The authorization profile id is missing.');
        }

        openDialog(
          <BulkAssignMechanic
            authorizationProfileId={authorizationProfileId}
            isAssigning={isAssigningMechanic}
            onAssign={async (mechanicId) => {
              await bulkAssignMechanic({directSaleId, body: {itemId: ids, mechanicId}})
                .unwrap()
                .then(() =>
                  showNotification.success(
                    i18n.t('entity.warehouse.notifications.mechanicSuccessfullyAssigned')
                  )
                )
                .then(() => closeDialog(BULK_ASSIGN_MECHANIC_DIALOG))
                .then(refreshData)
                .catch(handleApiError);
            }}
            data-testid={suffixTestId('dialogs.assignMechanic', props)}
          />,
          {
            id: BULK_ASSIGN_MECHANIC_DIALOG,
            title: i18n.t('entity.warehouse.labels.assignMechanic'),
            size: 'small',
            scrollBehavior: 'outside',
          }
        );
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const handleCloseMaterialReturnModal = () => {
    closeMaterialReturnModal();
    props.onCloseMaterialReturnModal();
    refreshDatagrid();
  };

  return (
    <VStack>
      <RequestTabEdit {...(editDialogProps as RequestTabEditProps)} />

      <DataStatus isLoading={props.isDirectSaleLoading}>
        <RequestTabForm
          directSale={props.directSale}
          onChange={setDirectSaleDetails}
          isEditingDisabled={props.isEditingDisabled}
          data-testid={suffixTestId('requestTabForm', props)}
        />

        <Separator />

        <VStack spacing={4} height="50vh">
          <HStack justify="space-between">
            <Heading size={4}>{i18n.t('entity.warehouse.labels.items')}</Heading>
            <Actions actions={requestActions} data-testid={suffixTestId('listActions', props)} />
          </HStack>

          <DataGrid
            ref={datagridRef}
            gridCode="direct-sale-item"
            actionCallback={actionCallback}
            queryModifier={queryModifier}
            emptyState={{
              headline: i18n.t('entity.warehouse.notifications.noWorkItems'),
              subheadline: i18n.t('entity.warehouse.notifications.addWorkItems'),
            }}
            data-testid={suffixTestId('requestedItems', props)}
          />
        </VStack>
      </DataStatus>

      <Show when={isLabourModalVisible}>
        <RequestTabLabourModal
          directSaleId={directSaleId}
          authorizationProfileId={props.directSale.authorizationProfileId}
          onDiscard={closeLabourModal}
          data-testid={testIds.warehouse.directSalesDetailOverview('dialogs.addLabour')}
        />
      </Show>

      <Show when={isMaterialModalVisible}>
        <RequestTabMaterialModal
          directSaleId={directSaleId}
          availableWarehouses={props.availableWarehouses}
          authorizationProfileId={props.directSale?.authorizationProfileId}
          onDiscard={closeMaterialModal}
          data-testid={testIds.warehouse.directSalesDetailOverview('dialogs.addMaterial')}
        />
      </Show>

      <Show when={isMaterialReturnModalVisible}>
        <DirectSaleMaterialReturnModal
          directSaleId={directSaleId}
          authorizationProfileId={props.directSale?.authorizationProfileId}
          onClose={handleCloseMaterialReturnModal}
          data-testid={testIds.warehouse.directSalesDetailOverview('dialogs.returnMaterial')}
        />
      </Show>
    </VStack>
  );
}

const BULK_ASSIGN_MECHANIC_DIALOG = 'bulk-assign-mechanic-dialog';
