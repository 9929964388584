import {Box, getSize, HStack, Show} from 'platform/foundation';
import styled, {useTheme} from 'styled-components';

import {MenuItemProps} from '@dms/api/shared';
import {DevSettings} from '@dms/dev-settings';
import {featureFlags} from '@dms/feature-flags';
import {usePermissions} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {TOP_BAR_ITEM_SIZE} from '../constants/topBarItemSize';
import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {BranchSelect} from './BranchSelect';
import {HeaderContent} from './HeaderContent';
import {HideOnMobileNavigation} from './HideOnMobileNavigation';
import {MyProfileAndSettings} from './MyProfileAndSettings';
import {Notifications} from './Notifications';
import {ReleasePopup} from './ReleasePopup';
import {Settings} from './Settings';
import {TaskCenter} from './TaskCenter';

interface TopbarProps extends RequiredTestIdProps {
  activeModule?: MenuItemProps;
  settingsModule?: MenuItemProps;
}

export function Topbar(props: TopbarProps) {
  const isMobileNavigation = useIsMobileNavigation();
  const [canReadTasks] = usePermissions({
    permissionKeys: ['readTask'],
  });
  const {colors} = useTheme();
  const topBarWrapperProps = isMobileNavigation
    ? ({
        backgroundColor: 'palettes.neutral.900.100',
      } as const)
    : ({
        backgroundColor: 'general.white',
        borderColor: 'general.separator',
        borderBottom: '1px solid',
      } as const);

  return (
    <>
      <meta name="theme-color" content={colors.palettes.neutral[900][100]} />
      <Box
        zIndex="HEADER"
        paddingLeft={[0, 0, 4]}
        height="100%"
        width="100%"
        overflow="hidden"
        {...topBarWrapperProps}
      >
        <HStack width="100%" align="center" justify="space-between" height="100%">
          <HeaderContent
            title={props.activeModule?.layoutProps?.title}
            data-testid={suffixTestId('header-content', props)}
          />
          <HStack grow={1} shrink={0} align="center" justify="flex-end">
            <HideOnMobileNavigation>
              <TopBarActionContainer>
                <BranchSelect />
              </TopBarActionContainer>
            </HideOnMobileNavigation>
            <Show whenFeatureEnabled={featureFlags.CORE_TASK_MANAGEMENT} when={canReadTasks}>
              <TopBarActionContainer>
                <TaskCenter />
              </TopBarActionContainer>
            </Show>
            <Show whenFeatureEnabled={featureFlags.NOTIFICATION_CENTER}>
              <TopBarActionContainer>
                <Notifications data-testid="layout-header" />
              </TopBarActionContainer>
            </Show>
            <HideOnMobileNavigation>
              <TopBarActionContainer>
                <ReleasePopup data-testid="layout-header" />
              </TopBarActionContainer>
              <TopBarActionContainer>
                <Settings settingsModule={props.settingsModule} data-testid="layout-header" />
              </TopBarActionContainer>
              <Box width={TOP_BAR_ITEM_SIZE} height={TOP_BAR_ITEM_SIZE}>
                <DevSettings data-testid="layout-header" />
              </Box>
            </HideOnMobileNavigation>
            <TopBarActionContainer>
              <MyProfileAndSettings data-testid="layout-header" />
            </TopBarActionContainer>
          </HStack>
        </HStack>
      </Box>
    </>
  );
}

export const TopBarActionContainer = styled.div`
  height: ${getSize(TOP_BAR_ITEM_SIZE)};
  min-width: ${getSize(TOP_BAR_ITEM_SIZE)};
  cursor: pointer;
  &:hover {
    button[aria-label*='topBar'] {
      background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
    }
  }
  &:active {
    button[aria-label*='topBar'] {
      background-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};
    }
  }
`;
