import {Tooltip} from 'platform/components';
import {Icon, ThemeIconKey} from 'platform/foundation';

import {Link} from 'react-router-dom';

import i18n from '@dms/i18n';

import {composePath, RequiredTestIdProps} from 'shared';

import {SIDEBAR_ICON_SIZE} from '../constants/sidebarIconSize';
import {NavigationButton} from './NavigationButton';

interface SidebarItemProps extends RequiredTestIdProps {
  path: string;
  isActive: boolean;
  title: string;
  icon?: ThemeIconKey;
}

export function SidebarLink(props: SidebarItemProps) {
  return (
    <Tooltip offset={8} placement="right" label={i18n.t(props.title)}>
      <Link
        to={composePath(props.path, {isSearchParamsPreserved: false})}
        data-testid={props['data-testid']}
      >
        <NavigationButton isActive={props.isActive}>
          <Icon size={SIDEBAR_ICON_SIZE} value={props.icon} color="palettes.neutral.10.100" />
        </NavigationButton>
      </Link>
    </Tooltip>
  );
}
