import {captureException} from '@sentry/browser';
import {closeDialog, openDialog} from 'platform/components';

import {ReactNode, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

import {isNil} from 'ramda';

import {browserStorageKey, CHECK_FOR_APP_UPDATE_INTERVAL_MS} from '@dms/config';
import i18n from '@dms/i18n';

import {persistor} from '../../../store/store';
import {getModuleNameFromPathname} from '../../../utils/getModuleNameFromPathname';
import {useUpdateApp} from '../hooks/useUpdateApp';
import {UpdateApplicationDialog} from './UpdateApplicationDialog';

type ApplicationVersionResponse = {
  version: string;
  pipeline: string;
  job: string;
};

interface ApplicationUpdateBoundaryProps {
  children: ReactNode;
}

const UPDATE_AVAILABLE_DIALOG = 'UPDATE_AVAILABLE_DIALOG';

export function ApplicationUpdateBoundary(props: ApplicationUpdateBoundaryProps) {
  const location = useLocation();
  const isUpdatePostponed = useRef(false);
  const postponedModuleName = useRef<string | undefined>(undefined);

  const updateApp = useUpdateApp();

  useEffect(() => {
    if (!isUpdatePostponed.current) {
      return;
    }

    const currentModuleName = getModuleNameFromPathname(location.pathname);
    if (postponedModuleName.current === currentModuleName) {
      return;
    }

    updateApp();
  }, [updateApp, location]);

  useEffect(() => {
    sessionStorage.removeItem(browserStorageKey.CURRENT_VERSION);

    const checkForAppUpdate = () =>
      fetch('/version.json', {cache: 'no-store'})
        .then((response) => response.json() as Promise<ApplicationVersionResponse>)
        .then(async (data) => {
          try {
            if (isNil(sessionStorage.getItem(browserStorageKey.CURRENT_VERSION))) {
              sessionStorage.setItem(browserStorageKey.CURRENT_VERSION, data.version);
            }
            if (sessionStorage.getItem(browserStorageKey.CURRENT_VERSION) !== data.version) {
              const handleCloseDialog = () => {
                isUpdatePostponed.current = true;
                postponedModuleName.current = getModuleNameFromPathname(location.pathname);
                closeDialog(UPDATE_AVAILABLE_DIALOG);
              };
              clearInterval(intervalId);
              openDialog(
                <UpdateApplicationDialog onPostpone={handleCloseDialog} onUpdate={updateApp} />,
                {
                  id: UPDATE_AVAILABLE_DIALOG,
                  title: i18n.t('general.checkApplicationVersion.updateAvailableTitle'),
                  onCloseComplete: handleCloseDialog,
                }
              );
              await persistor.purge();
            }
          } catch (e) {
            captureException(e);
            window.location.reload();
          }
        })
        .catch(() => window.location.reload());

    const intervalId = setInterval(checkForAppUpdate, CHECK_FOR_APP_UPDATE_INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, [updateApp, location]);

  return <>{props.children}</>;
}
